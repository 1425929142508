import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as storeApi from '../../../apis/store';
import * as adminApi from '../../../apis/admin';

const PaymentSuccess = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [verificationResult, setVerificationResult] = useState(null);
    const [storePaymentInfo, setStorePaymentInfo] = useState({});

    useEffect(() => {
        // console.log('storePaymentInfo : ', storePaymentInfo);
    }, [storePaymentInfo]);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const paymentKey = query.get('paymentKey');
        const orderId = query.get('orderId');
        const amount = query.get('amount');
        const storePayment = JSON.parse(localStorage.getItem('storePayment'));



        // console.log('paymentKey : ', paymentKey);
        // console.log('orderId : ', orderId);
        // console.log('amount : ', amount);

        const verifyPayment = async () => {
            try {
                const response = await axios.post('/api/payment/verify', {
                    paymentKey: paymentKey,
                    orderId: orderId,
                    amount: amount
                });
                setVerificationResult(response.data);
                console.log('response.data : ', response.data);

                let updatedStorePaymentInfo = {};

                if(response.data.method === '가상계좌') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        paymentType : response.data.method,
                        currency : response.data.currency,
                        paymentBankName : response.data.virtualAccount.bankCode,
                        paymentAccountNumber : response.data.virtualAccount.accountNumber,
                        paymentDepositDeadline : response.data.virtualAccount.dueDate
                    };
                } else if(response.data.method === '카드') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        currency : response.data.currency,
                        paymentType : response.data.method,
                        issuerCode : response.data.card.issuerCode
                    };
                } else if(response.data.method === '간편결제') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        currency : response.data.currency,
                        paymentType : response.data.method,
                        issuerCode: response.data.card && response.data.card.issuerCode ? response.data.card.issuerCode : '',
                        easyPay : response.data.easyPay.provider
                    };
                    
                } else if(response.data.method === '계좌이체') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        currency : response.data.currency,
                        paymentType : response.data.method,
                        transferBankCode : response.data.transfer.bankCode
                    };
                    
                } else if(response.data.method === '해외간편결제') {
                    updatedStorePaymentInfo = {
                        ...storePayment,
                        paymentKey: paymentKey,
                        orderId: orderId,
                        amount: amount,
                        paymentType : response.data.method,
                        currency : response.data.currency,
                        easyPay : response.data.easyPay
                    };
                }

                // console.log('updatedStorePaymentInfo : ', updatedStorePaymentInfo);

        
                setStorePaymentInfo(updatedStorePaymentInfo);

                if (response.data.status === "DONE") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: storePayment.paymentPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setStorePaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                storePaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else if(response.data.status === "WAITING_FOR_DEPOSIT") {
                    try {
                        const response = await fetch('/api/payment', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                paymentKey: paymentKey,
                                amount: storePayment.paymentPrice
                            }),
                        });

                        const result = await response.json();
                        // console.log('result : ', result);

                        if (result.success) {
                            setStorePaymentInfo((prevState) => {
                                const newState = { ...prevState, ...result };
                                storePaymentAdd(newState);
                                return newState;
                            });
                        } else {
                            // 서버 검증 실패 시 로직
                            alert(`결제 검증에 실패하였습니다: ${result.error_msg}`);
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                } else {
                    // 결제 실패 처리
                    navigate(`/store/payment/fail`);
                }
            } catch (error) {
                console.error('결제 검증 오류:', error);
                navigate(`/store/payment/fail`);
            } finally {
                setLoading(false);
            }
        };

        verifyPayment();
    }, [location, navigate]);

    const storePaymentAdd = async (paymentInfo) => {
        // console.log('storePaymentInfo : ', paymentInfo);

        const response = await adminApi.kakaoBizTemplateSelect();
        const data = response.data;

        console.log('kakaoBizSelect : ', data);

        const kakaoBizTemplate = data.list.find(item => item.templtCode === "TW_3324");

        console.log('kakaoBizTemplate : ', kakaoBizTemplate);
        
        const payload = {
            paymentInfo,
            kakaoBizTemplate
        }

        // 서버 검증 성공 시 로직
        try {
            const response = await storeApi.storePayment(payload);
            const data = response.data;
            // console.log(data);

            const paymentNumber = paymentInfo.paymentNumber;
            // console.log('paymentNumber = ', paymentNumber);

            navigate(`/store/payment/success/${paymentNumber}`);
        } catch (error) {
            console.error('Error storing payment info:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {verificationResult ? (
                <div>결제가 성공적으로 완료되었습니다!</div>
            ) : (
                <div>결제 검증에 실패하였습니다.</div>
            )}
        </div>
    );
};

export default PaymentSuccess;
