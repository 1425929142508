import React, { useState, useEffect, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../../contexts/LoginContextProvider';
import * as auth from '../../apis/auth';

const MarketInfo = ({ market, admin, containerRef }) => {
    const { userInfo } = useContext(LoginContext);
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0); // 현재 인덱스 상태
    const [popularItem, setPopularItem] = useState([]);
    const [popularSupportItem, setPopularSupportItem] = useState([]);
    const [discountItem, setDiscountItem] = useState([]);
    const [recomandItem, setRecomandItem] = useState([]);
    const [popularReviewItem, setPopularReviewItem] = useState([]);
    const [swiperInstance, setSwiperInstance] = useState(null); // Swiper 인스턴스를 상태로 추가
    const [totalKeyword, setTotalKeyword] = useState([]);
    const [sortedKeywords, setSortedKeywords] = useState([]);

    const [popularSearches, setPopularSearches] = useState([
        {psNo : 1, psImg : '../../img/search/number1.png', psSearches : '앨범', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 2, psImg : '../../img/search/number2.png', psSearches : '커피차', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 3, psImg : '../../img/search/number3.png', psSearches : '생카', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 4, psImg : '../../img/search/number4.png', psSearches : '인형', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 5, psImg : '../../img/search/number5.png', psSearches : '탑꾸', upDownImg : '../../img/search/popularityDown.png'},
        {psNo : 6, psImg : '../../img/search/number6.png', psSearches : '문구', upDownImg : '../../img/search/popularityDown.png'},
        {psNo : 7, psImg : '../../img/search/number7.png', psSearches : '하니', upDownImg : '../../img/search/popularityDown.png'},
        {psNo : 8, psImg : '../../img/search/number8.png', psSearches : '트와이스', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 9, psImg : '../../img/search/number9.png', psSearches : '뉴진스', upDownImg : '../../img/search/popularityUp.png'},
        {psNo : 10, psImg : '../../img/search/number10.png', psSearches : '아일릿', upDownImg : '../../img/search/popularityUp.png'}
    ])

    useEffect(() => {
        if (swiperInstance && swiperInstance.autoplay && typeof swiperInstance.autoplay.start === 'function') {
            setTimeout(() => {
                try {
                    swiperInstance.autoplay.start();
                } catch (error) {
                    console.error("Autoplay start error:", error.message);
                }
            }, 500); // 500ms 지연 후 autoplay 시작
        }

        // 컴포넌트 언마운트 시 또는 swiperInstance 변경 시 cleanup
        return () => {
            if (swiperInstance && swiperInstance.autoplay) {
                try {
                    swiperInstance.autoplay.stop();
                } catch (error) {
                    console.error("Autoplay stop error during cleanup:", error.message);
                }
            }
        };
    }, [swiperInstance]);

    useEffect(() => {
        popularSelect();
        discountSelect();
        recomandSelect();
        supportSelect();
        popularReviewSelect();
        allkeywordSelect();
    }, []);
    
    useEffect(() => {
        const sorted = countAndSortKeywords(totalKeyword);
        setSortedKeywords(sorted);
    }, [totalKeyword]);

    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.realIndex);
    };

    const allkeywordSelect = async () => {
        const response = await auth.allKeywordSelect();
        const data = response.data;

        // console.log('all : ', data);
        setTotalKeyword(data);
    };

    const countAndSortKeywords = (keywords) => {
        const keywordCount = {};
    
        keywords.forEach(item => {
          if (item.keyword) {
            if (keywordCount[item.keyword]) {
              keywordCount[item.keyword]++;
            } else {
              keywordCount[item.keyword] = 1;
            }
          }
        });
    
        const sortedKeywords = Object.keys(keywordCount)
          .map(keyword => ({ keyword, count: keywordCount[keyword] }))
          .sort((a, b) => b.count - a.count);
    
        return sortedKeywords.slice(0, 10);
      };
    

    const handleProductClick = (path) => {
        // containerRef.current.scrollTop을 사용하여 현재 스크롤 위치를 sessionStorage에 저장
        if (containerRef.current) {
            const scrollPosition = containerRef.current.scrollTop;
            // console.log('containerRef scrollPosition : ', scrollPosition);
            sessionStorage.setItem('scrollPosition', scrollPosition);
            navigate(path);
        }
    };

    const sliderSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        arrows: false,
        dots: false
    };

    const popularSelect = async () => {
        try {
            const response = await admin.popularSelect();
            const data = response.data;

            if (response.status === 200) {
                if (Array.isArray(data)) {

                    const filteredData = data.filter(item => item.popularExposure === true);

                    const sortedData = filteredData.sort((a, b) => {
                        if (a.popularSetting === "custom" && b.popularSetting !== "custom") return -1;
                        if (a.popularSetting !== "custom" && b.popularSetting === "custom") return 1;

                        return a.popularExposureOrder - b.popularExposureOrder;
                    });

                    const limitedData = sortedData.slice(0, 10);

                    setPopularItem(limitedData);
                } else {
                    console.warn('Data is not an array:', data);
                    setPopularItem([]);
                }
            }
        } catch (error) {
            console.error('Error in popularSelect:', error);
            setPopularItem([]);
        }
    };

    const discountSelect = async () => {
        try {
            const response = await admin.discountProductSelect();
            const data = response.data;
            if (response.status === 200) {
                if (Array.isArray(data)) {
                    // discountExposure가 true인 상품만 필터링
                    const filteredData = data.filter(item => item.discountExposure === true);
    
                    // discountExposureOrder에 따라 정렬
                    const sortedData = filteredData.sort((a, b) => {
                        return a.discountExposureOrder - b.discountExposureOrder;
                    });
    
                    // 최대 10개의 항목만 선택
                    const limitedData = sortedData.slice(0, 10);
    
                    setDiscountItem(limitedData);
                } else {
                    console.warn('Data is not an array:', data);
                    setDiscountItem([]);
                }
            }
        } catch (error) {
            console.error('Error in discountSelect:', error);
            setDiscountItem([]);
        }
    };
    

    const recomandSelect = async () => {
        try {
            const response = await admin.recomandProductSelect();
            const data = response.data;

            if (response.status === 200) {
                if (Array.isArray(data)) {
                    const filteredData = data.filter(item => item.recomandExposure === true);

                    const sortedData = filteredData.sort((a, b) => {
                        return a.recomandExposureOrder - b.recomandExposureOrder;
                    });

                    const limitedData = sortedData.slice(0, 10);

                    setRecomandItem(limitedData);
                } else {
                    setRecomandItem([]);
                    console.warn('Data is not an array:', data);
                }
            }
        } catch (error) {
            console.error('Error in recomandSelect:', error);
            setRecomandItem([]);
        }
    };

    const supportSelect = async () => {
        try {
            const response = await admin.popularSupportSelect();
            const data = response.data;

            if (response.status === 200) {
                if (Array.isArray(data)) {

                    const filteredData = data.filter(item => item.reservationExposure === true);

                    const sortedData = filteredData.sort((a, b) => {
                        if (a.reservationSetting === "custom" && b.reservationSetting !== "custom") return -1;
                        if (a.reservationSetting !== "custom" && b.reservationSetting === "custom") return 1;

                        return a.reservationExposureOrder - b.reservationExposureOrder;
                    });

                    const limitedData = sortedData.slice(0, 10);

                    setPopularSupportItem(limitedData);
                } else {
                    setPopularSupportItem([]);
                    console.warn('Data is not an array:', data);
                }
            }
        } catch (error) {
            console.error('Error in supportSelect:', error);
            setPopularSupportItem([]);
        }
    };

    const popularReviewSelect = async () => {
        try {
            const response = await admin.popularReviewSelect();
            const data = response.data;

            if (response.status === 200) {
                if (Array.isArray(data)) {
                    const filteredData = data.filter(item => item.reviewExposure === true);

                    const sortedData = filteredData.sort((a, b) => {
                        return a.reviewExposureOrder - b.reviewExposureOrder;
                    });

                    const limitedData = sortedData.slice(0, 10);
                    setPopularReviewItem(limitedData);
                } else {
                    setPopularReviewItem([]);
                    console.warn('Data is not an array:', data);
                }
            }
        } catch (error) {
            console.error('Error in popularReviewSelect:', error);
            setPopularReviewItem([]);
        }
    };

    const onPopularStore = () => {
        navigate(`/market/popular/product`);
    }
    const onPopularSupport = () => {
        navigate(`/market/popular/support`);
    }
    const onDiscountStore = () => {
        navigate(`/market/dicount/product`);
    }
    const onReviewStore = () => {
        navigate(`/market/suggest/product`);
    }

    const handleSearchKeyword = (item) => {
        // console.log('item : ', item);
        // navigate(`/search`, {state : {selectKeyword : item.keyword}});
        navigate(`/search?selectKeyword=${encodeURIComponent(item.keyword)}`);
    }

    return (
        <div>
            <div>
                <div className={`${market.market_main_popularity_search}`}>
                    <div><span>인기검색어</span></div>
                    <div className={`${market.market_main_popularity_search_content}`}>
                        <Slider {...sliderSettings}>
                            {sortedKeywords.map((item, index) => (
                                <div key={index} className={`${market.market_main_popularity_search_box}`} onClick={() => handleSearchKeyword(item)}>
                                    <div>
                                        <div><img src={`${process.env.PUBLIC_URL}/img/search/number${index + 1}.png`} alt="" /></div>
                                        <div className='ps-2'><span>{item.keyword}</span></div>
                                    </div>
                                    {/* <div><img src={item.upDownImg} alt="업다운이미지" /></div> */}
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className={`${market.market_main_popularity_product}`}>
                    <div>
                        <button onClick={onPopularStore}>
                            <div><span>인기상품</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                            className={`${market.market_main_popularity_swiper}`}
                        >
                            {popularItem.map((item, index) => (
                                <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                    <div
                                        onClick={() => handleProductClick(`/store/product/${item.productNo}`)}
                                        className={`${market.market_main_popularity_box}`}
                                    >
                                        <div><img src={item.productMainImage} alt="" /></div>
                                        <div>
                                            <div><span>{item.shopName}</span></div>
                                            <div><span>{item.productName}</span></div>
                                            <div>
                                                <div><span>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                                                {item.productsalePrice !== item.productPrice && (
                                                    <div><span>&#8361;{item.productPrice.toLocaleString()}</span></div>
                                                )}
                                            </div>
                                            <div>
                                                {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                                {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                                {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                                {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                {/* 인기 예약, 할인 상품, 인기 리뷰 등의 다른 섹션에도 같은 방식으로 handleProductClick을 적용 */}
                <div className={`${market.market_main_popularity_product}`}>
                    <div>
                        <button onClick={onPopularSupport}>
                            <div><span>인기예약</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                            className={`${market.market_main_popularity_swiper}`}
                        >
                            {popularSupportItem.map((item, index) => (
                                <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                    <div
                                        onClick={() => handleProductClick(`/venue/${item.productNumber}`)}
                                        className={`${market.market_main_popularity_box}`}
                                    >
                                        <div><img src={item.representativeImage} alt="" /></div>
                                        <div>
                                            <div><span>{item.shopName}</span></div>
                                            <div><span>{item.productName}</span></div>
                                            <div>
                                                <div><span>&#8361;{item.mainItem[0]?.mainItemPrice.toLocaleString()} ~</span></div>
                                            </div>
                                            <div>
                                                {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                                {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                                {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                                {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                <div className={`${market.market_main_popularity_product}`}>
                    <div>
                        <button onClick={onDiscountStore}>
                            <div><span>할인상품</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                            className={`${market.market_main_popularity_swiper}`}
                        >
                            {discountItem.map((item, index) => (
                                <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                    <div
                                        onClick={() => handleProductClick(`/store/product/${item.productNo}`)}
                                        className={`${market.market_main_popularity_box}`}
                                    >
                                        <div><img src={item.productMainImage} alt="" /></div>
                                        <div>
                                            <div><span>{item.shopName}</span></div>
                                            <div><span>{item.productName}</span></div>
                                            <div>
                                                <div><span>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                                                {item.productsalePrice !== item.productPrice && (
                                                    <div><span>&#8361;{item.productPrice.toLocaleString()}</span></div>
                                                )}
                                            </div>
                                            <div>
                                                {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                                {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                                {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                                {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                <div className={`${market.market_main_popular_reviews}`}>
                    <div>
                        <button>
                            <div><span>인기리뷰</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        {popularReviewItem[currentIndex] && (
                            <div className={`${market.preview_content}`}>
                                <img src={popularReviewItem[currentIndex].photo1} alt="Preview" />
                            </div>
                        )}
                        <Swiper
                            onSwiper={(swiper) => {
                                setSwiperInstance(swiper);
                                if (swiper && swiper.autoplay && typeof swiper.autoplay.start === 'function') {
                                    try {
                                        swiper.autoplay.start();
                                    } catch (error) {
                                        console.error('Autoplay start error:', error.message);  // 콘솔에만 에러 출력
                                    }
                                }
                            }}
                            slidesPerView={5}
                            spaceBetween={0}
                            loop={true}
                            centeredSlides={true}
                            loopFillGroupWithBlank={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            onSlideChange={handleSlideChange}
                            modules={[Pagination, Navigation, Autoplay]}
                            className={`${market.market_main_popular_reviews_swiper}`}
                        >
                            {popularReviewItem.map((item, index) => (
                                <SwiperSlide key={index} className={`${market.market_main_popular_reviews_swiper_box}`}>
                                    <button className={`${market.market_main_popular_reviews_box}`} onClick={() => setCurrentIndex(index)}>
                                        <div style={{ border: currentIndex === index ? '1px solid rgba(255, 3, 102, 1)' : 'none' }}>
                                            <img src={item.photo1} alt={`Preview ${index + 1}`} />
                                        </div>
                                    </button>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>

                <div className={`${market.market_main_popularity_product}`}>
                    <div>
                        <button onClick={onReviewStore}>
                            <div><span>추천상품</span></div>
                            <div><span className="material-symbols-outlined">keyboard_arrow_right</span></div>
                        </button>
                    </div>
                    <div>
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            modules={[Pagination, Navigation]} // Autoplay 모듈 추가
                            className={`${market.market_main_popularity_swiper}`}
                        >
                            {recomandItem.map((item, index) => (
                                <SwiperSlide className={`${market.market_main_popularity_swiper_box}`} key={index}>
                                    <div
                                        onClick={() => handleProductClick(`/store/product/${item.productNo}`)}
                                        className={`${market.market_main_popularity_box}`}
                                    >
                                        <div><img src={item.productMainImage} alt="" /></div>
                                        <div>
                                            <div><span>{item.shopName}</span></div>
                                            <div><span>{item.productName}</span></div>
                                            <div>
                                                <div><span>&#8361;{item.productsalePrice.toLocaleString()}</span></div>
                                                {item.productsalePrice !== item.productPrice && (
                                                    <div><span>&#8361;{item.productPrice.toLocaleString()}</span></div>
                                                )}
                                            </div>
                                            <div>
                                                {item.productBadge === 'freeDelivery' && <span className={market.freeDelivery}>무료배송</span>}
                                                {item.productBadge === 'md' && <span className={market.md}>MD추천</span>}
                                                {item.productBadge === 'best' && <span className={market.best}>BEST</span>}
                                                {item.productBadge === 'hot' && <span className={market.hot}>HOT</span>}
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketInfo;
