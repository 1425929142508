import React from 'react'

const SupportCountries = ({setShowSupportCountry}) => {
  return (
    <div className='join_support_countries_container'>
        <div className='join_support_countries_box'>
            <div className='join_support_countries_header'>
                <div><span>Supported Countries and Country Codes</span></div>
                <div><button onClick={() => setShowSupportCountry(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className='join_support_countries_table'>
                <div className='join_support_countries_table_header'>
                    <div><span>Country Name</span></div>
                    <div><span>Country Code</span></div>
                </div>
                <div className='join_support_countries_table_content'>
                    <div>
                        <span>United States / Canada</span><span>1</span>
                    </div>
                    <div>
                        <span>Russia / Kazakhstan</span><span>7</span>
                    </div>
                    <div>
                        <span>Egypt</span><span>20</span>
                    </div>
                    <div>
                        <span>South Africa</span><span>27</span>
                    </div>
                    <div>
                        <span>Greece</span><span>30</span>
                    </div>
                    <div>
                        <span>Netherlands</span><span>31</span>
                    </div>
                    <div>
                        <span>Belgium</span><span>32</span>
                    </div>
                    <div>
                        <span>France</span><span>33</span>
                    </div>
                    <div>
                        <span>Spain</span><span>34</span>
                    </div>
                    <div>
                        <span>Hungary</span><span>36</span>
                    </div>
                    <div>
                        <span>Italy</span><span>39</span>
                    </div>
                    <div>
                        <span>Romania</span><span>40</span>
                    </div>
                    <div>
                        <span>Switzerland</span><span>41</span>
                    </div>
                    <div>
                        <span>Austria</span><span>43</span>
                    </div>
                    <div>
                        <span>United Kingdom</span><span>44</span>
                    </div>
                    <div>
                        <span>Denmark</span><span>45</span>
                    </div>
                    <div>
                        <span>Sweden</span><span>46</span>
                    </div>
                    <div>
                        <span>Norway</span><span>47</span>
                    </div>
                    <div>
                        <span>Poland</span><span>48</span>
                    </div>
                    <div>
                        <span>Germany</span><span>49</span>
                    </div>
                    <div>
                        <span>Peru</span><span>51</span>
                    </div>
                    <div>
                        <span>Mexico</span><span>52</span>
                    </div>
                    <div>
                        <span>Cuba</span><span>53</span>
                    </div>
                    <div>
                        <span>Argentina</span><span>54</span>
                    </div>
                    <div>
                        <span>Brazil</span><span>55</span>
                    </div>
                    <div>
                        <span>Chile</span><span>56</span>
                    </div>
                    <div>
                        <span>Colombia</span><span>57</span>
                    </div>
                    <div>
                        <span>Venezuela</span><span>58</span>
                    </div>
                    <div>
                        <span>Malaysia</span><span>60</span>
                    </div>
                    <div>
                        <span>Australia</span><span>61</span>
                    </div>
                    <div>
                        <span>Indonesia</span><span>62</span>
                    </div>
                    <div>
                        <span>Philippines</span><span>63</span>
                    </div>
                    <div>
                        <span>New Zealand</span><span>64</span>
                    </div>
                    <div>
                        <span>Singapore</span><span>65</span>
                    </div>
                    <div>
                        <span>Thailand</span><span>66</span>
                    </div>
                    <div>
                        <span>Japan</span><span>81</span>
                    </div>
                    <div>
                        <span>Vietnam</span><span>84</span>
                    </div>
                    <div>
                        <span>China</span><span>86</span>
                    </div>
                    <div>
                        <span>Turkey</span><span>90</span>
                    </div>
                    <div>
                        <span>India</span><span>91</span>
                    </div>
                    <div>
                        <span>Pakistan</span><span>92</span>
                    </div>
                    <div>
                        <span>Afghanistan</span><span>93</span>
                    </div>
                    <div>
                        <span>Sri Lanka</span><span>94</span>
                    </div>
                    <div>
                        <span>Myanmar</span><span>95</span>
                    </div>
                    <div>
                        <span>Iran</span><span>98</span>
                    </div>
                    <div>
                        <span>South Sudan</span><span>211</span>
                    </div>
                    <div>
                        <span>Morocco</span><span>212</span>
                    </div>
                    <div>
                        <span>Algeria</span><span>213</span>
                    </div>
                    <div>
                        <span>Tunisia</span><span>216</span>
                    </div>
                    <div>
                        <span>Libya</span><span>218</span>
                    </div>
                    <div>
                        <span>Hong Kong</span><span>852</span>
                    </div>
                    <div>
                        <span>Macao</span><span>853</span>
                    </div>
                    <div>
                        <span>Cambodia</span><span>855</span>
                    </div>
                    <div>
                        <span>Laos</span><span>856</span>
                    </div>
                    <div>
                        <span>Bangladesh</span><span>880</span>
                    </div>
                    <div>
                        <span>Taiwan</span><span>886</span>
                    </div>
                    <div>
                        <span>Maldives</span><span>960</span>
                    </div>
                    <div>
                        <span>Lebanon</span><span>961</span>
                    </div>
                    <div>
                        <span>Jordan</span><span>962</span>
                    </div>
                    <div>
                        <span>Syria</span><span>963</span>
                    </div>
                    <div>
                        <span>Iraq</span><span>964</span>
                    </div>
                    <div>
                        <span>Kuwait</span><span>965</span>
                    </div>
                    <div>
                        <span>Saudi Arabia</span><span>966</span>
                    </div>
                    <div>
                        <span>Yemen</span><span>967</span>
                    </div>
                    <div>
                        <span>Oman</span><span>968</span>
                    </div>
                    <div>
                        <span>Palestine</span><span>970</span>
                    </div>
                    <div>
                        <span>United Arab Emirates</span><span>971</span>
                    </div>
                    <div>
                        <span>Israel</span><span>972</span>
                    </div>
                    <div>
                        <span>Bahrain</span><span>973</span>
                    </div>
                    <div>
                        <span>Qatar</span><span>974</span>
                    </div>
                    <div>
                        <span>Bhutan</span><span>975</span>
                    </div>
                    <div>
                        <span>Mongolia</span><span>976</span>
                    </div>
                    <div>
                        <span>Nepal</span><span>977</span>
                    </div>
                    <div>
                        <span>Tajikistan</span><span>992</span>
                    </div>
                    <div>
                        <span>Turkmenistan</span><span>993</span>
                    </div>
                    <div>
                        <span>Azerbaijan</span><span>994</span>
                    </div>
                    <div>
                        <span>Georgia</span><span>995</span>
                    </div>
                    <div>
                        <span>Kyrgyzstan</span><span>996</span>
                    </div>
                    <div>
                        <span>Uzbekistan</span><span>998</span>
                    </div>
                    <div>
                        <span>Bahamas</span><span>1242</span>
                    </div>
                    <div>
                        <span>Barbados</span><span>1246</span>
                    </div>
                    <div>
                        <span>Anguilla (UK)</span><span>1264</span>
                    </div>
                    <div>
                        <span>Antigua and Barbuda</span><span>1268</span>
                    </div>
                    <div>
                        <span>U.S. Virgin Islands</span><span>1284</span>
                    </div>
                    <div>
                        <span>British Virgin Islands</span><span>1340</span>
                    </div>
                    <div>
                        <span>Cayman Islands (UK)</span><span>1345</span>
                    </div>
                    <div>
                        <span>Bermuda (UK)</span><span>1441</span>
                    </div>
                    <div>
                        <span>Grenada</span><span>1473</span>
                    </div>
                    <div>
                        <span>Turks and Caicos Islands (UK)</span><span>1649</span>
                    </div>
                    <div>
                        <span>Montserrat (UK)</span><span>1664</span>
                    </div>
                    <div>
                        <span>Northern Mariana Islands (U.S.)</span><span>1670</span>
                    </div>
                    <div>
                        <span>Guam</span><span>1671</span>
                    </div>
                    <div>
                        <span>American Samoa</span><span>1684</span>
                    </div>
                    <div>
                        <span>Saint Lucia</span><span>1758</span>
                    </div>
                    <div>
                        <span>Dominica</span><span>1767</span>
                    </div>
                    <div>
                        <span>Saint Vincent and the Grenadines</span><span>1784</span>
                    </div>
                    <div>
                        <span>Puerto Rico</span><span>1787, 1939</span>
                    </div>
                    <div>
                        <span>Dominican Republic</span><span>1809, 1829, 1849</span>
                    </div>
                    <div>
                        <span>Trinidad and Tobago</span><span>1868</span>
                    </div>
                    <div>
                        <span>Saint Kitts and Nevis</span><span>1869</span>
                    </div>
                    <div>
                        <span>Jamaica</span><span>1876</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SupportCountries