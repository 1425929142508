import React, { useContext } from 'react'
import { LoginContext } from '../../../contexts/LoginContextProvider';

const AdminHeader = ( {admin, setShowPwChange} ) => {

  const { isLogin, adminlogout, userInfo, loginCheck, roles } = useContext(LoginContext);

  return (
    <div className={`${admin.admin_header_container}`}>
        <div className={`${admin.admin_header_left}`}>
            <div><img src={`${process.env.PUBLIC_URL}/img/logo_black.png`} alt="로고" /></div>
            <div className='ps-2'><span>관리자 페이지</span></div>
        </div>
        <div className={`${admin.admin_header_right}`}>
            <div className='dd-fs-7 px-3'><button onClick={() => setShowPwChange(true)}>비밀번호 재설정</button></div>
            <div className='dd-fs-7 '><button onClick={() => adminlogout()}>로그아웃</button></div>
        </div>
    </div>
  )
}

export default AdminHeader