import React, { useEffect, useState } from "react";

const AdminCombinationUpdate = ({seller, productDetailList, setProductDetailList}) => {

  const [isInitialized, setIsInitialized] = useState(false);

  const [data, setData] = useState({
    name: "",
    options: [],
  });

  useEffect(() => {
    // 초기화 로직: productDetailList에서 데이터를 가져옴
    if (productDetailList?.combinationOption) {
      setData({
        name: productDetailList.combinationOption.name || "",
        options: productDetailList.combinationOption.options || [],
      });
    }
  }, []); // 빈 배열로 설정하여 초기 렌더링 시점에만 실행

  useEffect(() => {
    // data가 빈 값이 아닌 경우에만 업데이트
    if (data.name || data.options.length > 0) {
      setProductDetailList((prev) => ({
        ...prev,
        combinationOption: {
          ...prev.combinationOption,
          name: data.name,
          options: data.options,
        },
      }));
    }
  }, [data, setProductDetailList]);

  // Add a new option
  const addOption = () => {
    setData((prev) => ({
      ...prev,
      options: [
        ...prev.options,
        { value: "", price: 0, groups: [] }, // 기본 옵션 구조
      ],
    }));
  };

  // Remove an option
  const removeOption = (optionIndex) => {
    if (window.confirm("이 옵션을 삭제하시겠습니까? 모든 하위 옵션이 함께 삭제됩니다.")) {
      const updatedOptions = [...data.options];
      updatedOptions.splice(optionIndex, 1);
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add a new group to an option
  const addGroup = (optionIndex) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups.push({
      groupName: "",
      items: [],
    });
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Remove a group
  const removeGroup = (optionIndex, groupIndex) => {
    if (window.confirm("이 그룹을 삭제하시겠습니까? 모든 하위 항목이 함께 삭제됩니다.")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups.splice(groupIndex, 1);
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add an item to a group
  const addItem = (optionIndex, groupIndex) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups[groupIndex].items.push({
      value: "",
      price: 0,
      subGroup: [],
    });
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Remove an item
  const removeItem = (optionIndex, groupIndex, itemIndex) => {
    if (window.confirm("이 항목을 삭제하시겠습니까? 모든 하위 그룹이 함께 삭제됩니다.")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups[groupIndex].items.splice(itemIndex, 1);
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add a subGroup to an item
  const addSubGroup = (optionIndex, groupIndex, itemIndex) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup.push({
      subGroupName: "",
      subGroupItems: [],
    });
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Remove a subGroup
  const removeSubGroup = (optionIndex, groupIndex, itemIndex, subGroupIndex) => {
    if (window.confirm("이 하위 그룹을 삭제하시겠습니까? 모든 하위 항목이 함께 삭제됩니다.")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup.splice(
        subGroupIndex,
        1
      );
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add a subGroupItem to a subGroup
  const addSubGroupItem = (optionIndex, groupIndex, itemIndex, subGroupIndex) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
      subGroupIndex
    ].subGroupItems.push({ value: "", price: 0, subsubGroup: [] });
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Remove a subGroupItem
  const removeSubGroupItem = (
    optionIndex,
    groupIndex,
    itemIndex,
    subGroupIndex,
    subGroupItemIndex
  ) => {
    if (window.confirm("이 하위 항목을 삭제하시겠습니까?")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
        subGroupIndex
      ].subGroupItems.splice(subGroupItemIndex, 1);
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add a subsubGroup to a subGroupItem
  const addSubsubGroup = (
    optionIndex,
    groupIndex,
    itemIndex,
    subGroupIndex,
    subGroupItemIndex
  ) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
      subGroupIndex
    ].subGroupItems[subGroupItemIndex].subsubGroup.push({
      subsubGroupName: "",
      subsubGroupItems: [],
    });
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Remove a subsubGroup
  const removeSubsubGroup = (
    optionIndex,
    groupIndex,
    itemIndex,
    subGroupIndex,
    subGroupItemIndex,
    subsubGroupIndex
  ) => {
    if (window.confirm("이 하위 그룹을 삭제하시겠습니까?")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
        subGroupIndex
      ].subGroupItems[subGroupItemIndex].subsubGroup.splice(subsubGroupIndex, 1);
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  // Add a subsubGroupItem to a subsubGroup
  const addSubsubGroupItem = (
    optionIndex,
    groupIndex,
    itemIndex,
    subGroupIndex,
    subGroupItemIndex,
    subsubGroupIndex
  ) => {
    const updatedOptions = [...data.options];
    updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
      subGroupIndex
    ].subGroupItems[subGroupItemIndex].subsubGroup[subsubGroupIndex].subsubGroupItems.push(
      { value: "", price: 0 }
    );
    setData((prev) => ({ ...prev, options: updatedOptions }));
  };

  // Add a function to remove SubSubGroupItem
  const removeSubsubGroupItem = (
    optionIndex,
    groupIndex,
    itemIndex,
    subGroupIndex,
    subGroupItemIndex,
    subsubGroupIndex,
    subsubGroupItemIndex
  ) => {
    if (window.confirm("이 하위 항목을 삭제하시겠습니까?")) {
      const updatedOptions = [...data.options];
      updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[
        subGroupIndex
      ].subGroupItems[subGroupItemIndex].subsubGroup[subsubGroupIndex].subsubGroupItems.splice(
        subsubGroupItemIndex,
        1
      );
      setData((prev) => ({ ...prev, options: updatedOptions }));
    }
  };

  const formatNumber = (value) => {
    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const unformatNumber = (value) => {
      return value.replace(/,/g, ""); // 콤마 제거
  };

  return (
    <div className={`${seller.seller_combination_option_box}`}>
      {/* Root Name */}
      <div className={`${seller.seller_combination_top_option}`}>
        <div><span>상위 옵션 그룹 이름</span></div>
        <div>
          <input
            type="text"
            value={data.name}
            onChange={(e) => setData((prev) => ({ ...prev, name: e.target.value }))}
          />
        </div>
      </div>

      {/* Options */}
      <button className={`${seller.seller_combination_top_option_btn}`} onClick={addOption}>옵션추가</button>
      {data.options.map((option, optionIndex) => (
        <div key={optionIndex} style={{ marginLeft: 20 }} className={`border-bottom pb-3`}>
          <div className={`${seller.seller_combination_first_option_box}`}>
            <div><span>옵션 {optionIndex + 1}</span></div>
            <div>
              <div><span>옵션값</span></div>
              <div>
                <input
                  type="text"
                  value={option.value}
                  onChange={(e) => {
                    const updatedOptions = [...data.options];
                    updatedOptions[optionIndex].value = e.target.value;
                    setData((prev) => ({ ...prev, options: updatedOptions }));
                  }}
                />
              </div>
            </div>
            <div>
              <div><span>옵션금액</span></div>
              <div>
                {/* <input
                  type="number"
                  value={option.price}
                  onChange={(e) => {
                    const updatedOptions = [...data.options];
                    updatedOptions[optionIndex].price = Number(e.target.value);
                    setData((prev) => ({ ...prev, options: updatedOptions }));
                  }}
                /> */}
                <input
                    type="text" // type="number" 대신 text로 변경
                    placeholder="0"
                    value={formatNumber(option.price || 0)} // 콤마 포맷 적용
                    onChange={(e) => {
                        const updatedOptions = [...data.options];
                        // 콤마 제거 후 숫자 변환
                        const numericValue = Number(unformatNumber(e.target.value));
                        updatedOptions[optionIndex].price = numericValue;
                        setData((prev) => ({ ...prev, options: updatedOptions }));
                    }}
                />
              </div>
            </div>
            <button className={`${seller.seller_combination_first_option_delete}`} onClick={() => removeOption(optionIndex)}><span className="material-symbols-outlined">delete</span></button>
            <button className={`${seller.seller_combination_first_option_add_group}`} onClick={() => addGroup(optionIndex)}>그룹 추가</button>
          </div>

          {option.groups.map((group, groupIndex) => (
            <div key={groupIndex} style={{ marginLeft: 20 }}>
              <div className={`${seller.seller_combination_second_option_group_box}`}>
                <div><span>옵션 아이템 그룹 {groupIndex + 1}</span></div>
                <div>
                  <div><span>옵션 아이템 그룹 이름</span></div>
                  <div>
                    <input
                      type="text"
                      value={group.groupName}
                      onChange={(e) => {
                        const updatedOptions = [...data.options];
                        updatedOptions[optionIndex].groups[groupIndex].groupName =
                        e.target.value;
                        setData((prev) => ({ ...prev, options: updatedOptions }));
                      }}
                    />
                  </div>
                </div>
                <button className={`${seller.seller_combination_first_option_delete}`} onClick={() => removeGroup(optionIndex, groupIndex)}><span className="material-symbols-outlined">delete</span></button>
                <button className={`${seller.seller_combination_first_option_add_group}`} onClick={() => addItem(optionIndex, groupIndex)}>옵션 추가</button>
              </div>

              {group.items.map((item, itemIndex) => (
                <div key={itemIndex} style={{ marginLeft: 20 }}>
                  <div className={`${seller.seller_combination_second_option_box}`}>
                    <div><span>옵션 아이템 {itemIndex + 1}</span></div>
                    <div>
                      <div><span>옵션값</span></div>
                      <div>
                        <input
                          type="text"
                          value={item.value}
                          onChange={(e) => {
                            const updatedOptions = [...data.options];
                            updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].value =
                              e.target.value;
                            setData((prev) => ({ ...prev, options: updatedOptions }));
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div><span>옵션금액</span></div>
                      <div>
                        {/* <input
                          type="number"
                          value={item.price}
                          onChange={(e) => {
                            const updatedOptions = [...data.options];
                            updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].price =
                              Number(e.target.value);
                            setData((prev) => ({ ...prev, options: updatedOptions }));
                          }}
                        /> */}
                        
                        <input
                            type="text" // type="number" 대신 text로 변경
                            placeholder="0"
                            value={formatNumber(item.price || 0)} // 콤마 포맷 적용
                            onChange={(e) => {
                                const updatedOptions = [...data.options];
                                // 콤마 제거 후 숫자 변환
                                const numericValue = Number(unformatNumber(e.target.value));
                                updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].price = numericValue;
                                setData((prev) => ({ ...prev, options: updatedOptions }));
                            }}
                        />
                      </div>
                    </div>
                    
                    <button className={`${seller.seller_combination_first_option_delete}`} onClick={() => removeItem(optionIndex, groupIndex, itemIndex)}><span className="material-symbols-outlined">delete</span></button>
                    <button className={`${seller.seller_combination_first_option_add_group}`} onClick={() => addSubGroup(optionIndex, groupIndex, itemIndex)}>그룹 추가</button>
                  </div>

                  {item.subGroup.map((subGroup, subGroupIndex) => (
                    <div key={subGroupIndex} style={{ marginLeft: 20 }}>
                      <div className={`${seller.seller_combination_third_option_group_box}`}>
                        <div><span>서브 옵션 그룹 {subGroupIndex + 1}</span></div>
                        <div>
                          <div><span>서브 옵션 그룹 이름</span></div>
                          <div>
                            <input
                              type="text"
                              value={subGroup.subGroupName}
                              onChange={(e) => {
                                const updatedOptions = [...data.options];
                                updatedOptions[optionIndex].groups[groupIndex].items[
                                  itemIndex
                                ].subGroup[subGroupIndex].subGroupName = e.target.value;
                                setData((prev) => ({ ...prev, options: updatedOptions }));
                              }}
                            />
                          </div>
                        </div>
                        <button
                          className={`${seller.seller_combination_first_option_delete}`}
                          onClick={() =>
                            removeSubGroup(optionIndex, groupIndex, itemIndex, subGroupIndex)
                          }
                        >
                          <span className="material-symbols-outlined">delete</span>
                        </button>
                        <button
                          className={`${seller.seller_combination_first_option_add_group}`}
                          onClick={() =>
                            addSubGroupItem(optionIndex, groupIndex, itemIndex, subGroupIndex)
                          }
                        >
                          옵션 추가
                        </button>
                      </div>

                      {subGroup.subGroupItems.map((subGroupItem, subGroupItemIndex) => (
                        <div key={subGroupItemIndex} style={{ marginLeft: 20 }}>
                          <div className={`${seller.seller_combination_third_option_box}`}>
                            <div><span>서브 옵션 아이템 {subGroupItemIndex + 1}</span></div>
                            <div>
                              <div><span>옵션값</span></div>
                              <div>
                                <input
                                  type="text"
                                  value={subGroupItem.value}
                                  onChange={(e) => {
                                    const updatedOptions = [...data.options];
                                    updatedOptions[optionIndex].groups[groupIndex].items[
                                      itemIndex
                                    ].subGroup[subGroupIndex].subGroupItems[
                                      subGroupItemIndex
                                    ].value = e.target.value;
                                    setData((prev) => ({ ...prev, options: updatedOptions }));
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <div><span>옵션금액</span></div>
                              <div>
                                {/* <input
                                  type="number"
                                  value={subGroupItem.price}
                                  onChange={(e) => {
                                    const updatedOptions = [...data.options];
                                    updatedOptions[optionIndex].groups[groupIndex].items[
                                      itemIndex
                                    ].subGroup[subGroupIndex].subGroupItems[
                                      subGroupItemIndex
                                    ].price = Number(e.target.value);
                                    setData((prev) => ({ ...prev, options: updatedOptions }));
                                  }}
                                /> */}
                                <input
                                    type="text" // type="number" 대신 text로 변경
                                    placeholder="0"
                                    value={formatNumber(subGroupItem.price || 0)} // 콤마 포맷 적용
                                    onChange={(e) => {
                                        const updatedOptions = [...data.options];
                                        // 콤마 제거 후 숫자 변환
                                        const numericValue = Number(unformatNumber(e.target.value));
                                        updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[subGroupIndex].subGroupItems[subGroupItemIndex].price = numericValue;
                                        setData((prev) => ({ ...prev, options: updatedOptions }));
                                    }}
                                />
                              </div>
                            </div>
                            <button
                              className={`${seller.seller_combination_first_option_delete}`}
                              onClick={() =>
                                removeSubGroupItem(
                                  optionIndex,
                                  groupIndex,
                                  itemIndex,
                                  subGroupIndex,
                                  subGroupItemIndex
                                )
                              }
                            >
                              <span className="material-symbols-outlined">delete</span>
                            </button>
                            <button
                              className={`${seller.seller_combination_first_option_add_group}`}
                              onClick={() =>
                                addSubsubGroup(
                                  optionIndex,
                                  groupIndex,
                                  itemIndex,
                                  subGroupIndex,
                                  subGroupItemIndex
                                )
                              }
                            >
                              그룹추가
                            </button>
                          </div>

                          {subGroupItem.subsubGroup.map(
                            (subsubGroup, subsubGroupIndex) => (
                              <div key={subsubGroupIndex} style={{ marginLeft: 20 }}>
                                <div className={`${seller.seller_combination_fourth_option_group_box}`}>
                                  <div><span>하위 옵션 그룹 {subsubGroupIndex + 1}</span></div>
                                  <div>
                                    <div><span>하위 옵션 그룹 이름</span></div>
                                    <div>
                                      <input
                                        type="text"
                                        value={subsubGroup.subsubGroupName}
                                        onChange={(e) => {
                                          const updatedOptions = [...data.options];
                                          updatedOptions[optionIndex].groups[groupIndex].items[
                                            itemIndex
                                          ].subGroup[subGroupIndex].subGroupItems[
                                            subGroupItemIndex
                                          ].subsubGroup[subsubGroupIndex].subsubGroupName =
                                            e.target.value;
                                          setData((prev) => ({ ...prev, options: updatedOptions }));
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <button
                                    className={`${seller.seller_combination_first_option_delete}`}
                                    onClick={() =>
                                      removeSubsubGroup(
                                        optionIndex,
                                        groupIndex,
                                        itemIndex,
                                        subGroupIndex,
                                        subGroupItemIndex,
                                        subsubGroupIndex
                                      )
                                    }
                                  >
                                    <span className="material-symbols-outlined">delete</span>
                                  </button>
                                  <button
                                    className={`${seller.seller_combination_first_option_add_group}`}
                                    onClick={() =>
                                      addSubsubGroupItem(
                                        optionIndex,
                                        groupIndex,
                                        itemIndex,
                                        subGroupIndex,
                                        subGroupItemIndex,
                                        subsubGroupIndex
                                      )
                                    }
                                  >
                                    옵션추가
                                  </button>
                                </div>

                                {subsubGroup.subsubGroupItems.map(
                                  (subsubGroupItem, subsubGroupItemIndex) => (
                                    <div
                                      key={subsubGroupItemIndex}
                                      style={{ marginLeft: 20 }}
                                    >
                                      <div className={`${seller.seller_combination_fourth_option_box}`}>
                                        <div><span>하위옵션 아이템 {subsubGroupItemIndex + 1}</span></div>
                                        <div>
                                          <div><span>옵션값</span></div>
                                          <div>
                                            <input
                                              type="text"
                                              value={subsubGroupItem.value}
                                              onChange={(e) => {
                                                const updatedOptions = [...data.options];
                                                updatedOptions[optionIndex].groups[groupIndex].items[
                                                  itemIndex
                                                ].subGroup[subGroupIndex].subGroupItems[
                                                  subGroupItemIndex
                                                ].subsubGroup[subsubGroupIndex].subsubGroupItems[
                                                  subsubGroupItemIndex
                                                ].value = e.target.value;
                                                setData((prev) => ({
                                                  ...prev,
                                                  options: updatedOptions,
                                                }));
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div>
                                          <div><span>옵션금액</span></div>
                                          <div>
                                            {/* <input
                                              type="number"
                                              value={subsubGroupItem.price}
                                              onChange={(e) => {
                                                const updatedOptions = [...data.options];
                                                updatedOptions[optionIndex].groups[groupIndex].items[
                                                  itemIndex
                                                ].subGroup[subGroupIndex].subGroupItems[
                                                  subGroupItemIndex
                                                ].subsubGroup[subsubGroupIndex].subsubGroupItems[
                                                  subsubGroupItemIndex
                                                ].price = Number(e.target.value);
                                                setData((prev) => ({
                                                  ...prev,
                                                  options: updatedOptions,
                                                }));
                                              }}
                                            /> */}
                                            <input
                                                type="text" // type="number" 대신 text로 변경
                                                placeholder="0"
                                                value={formatNumber(subsubGroupItem.price || 0)} // 콤마 포맷 적용
                                                onChange={(e) => {
                                                    const updatedOptions = [...data.options];
                                                    // 콤마 제거 후 숫자 변환
                                                    const numericValue = Number(unformatNumber(e.target.value));
                                                    updatedOptions[optionIndex].groups[groupIndex].items[itemIndex].subGroup[subGroupIndex].subGroupItems[subGroupItemIndex].subsubGroup[subsubGroupIndex].subsubGroupItems[subsubGroupItemIndex].price = numericValue;
                                                    setData((prev) => ({ ...prev, options: updatedOptions }));
                                                }}
                                            />
                                          </div>
                                        </div>

                                        <button
                                          className={`${seller.seller_combination_first_option_delete}`}
                                          onClick={() =>
                                            removeSubsubGroupItem(
                                              optionIndex,
                                              groupIndex,
                                              itemIndex,
                                              subGroupIndex,
                                              subGroupItemIndex,
                                              subsubGroupIndex,
                                              subsubGroupItemIndex
                                            )
                                          }
                                        >
                                          <span className="material-symbols-outlined">delete</span>
                                        </button>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}

      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  );
};

export default AdminCombinationUpdate;
