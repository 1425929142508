// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.

// The editor creator to use.
import  Editor  from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useState } from 'react';
import * as support from '../../../../apis/support';
// import MyUploadAdapter from './MyUploadAdapter';

const TextEditor = ({setData, admin, fileName, setFileName, onDetailContent, update, data}) => {
    const [editorInstance, setEditorInstance] = useState(null);
    const [previousImages, setPreviousImages] = useState(new Set());
    

    const editorConfiguration = {
        // extraPlugins: [MyUploadAdapter],
        toolbar: {
            items: [
                'heading',
                // 'style',
                'bold',
                'italic',
                'link',
                'fontColor',
                'fontBackgroundColor',
                'blockQuote',
                'indent',
                'outdent',
                'numberedList',
                'bulletedList',
                'alignment',
                'fontFamily',
                'fontSize',
                'pageBreak',
                'undo',
                'redo',
                'strikethrough',
                'subscript',
                'superscript',
                'insertTable',
                'findAndReplace',
                // 'imageUpload'
            ]
        },
        language: 'ko',
		fontSize: {
			options: [
				'8px','9px','10px', '11px', '12px', '13px', '14px',
				'16px', '18px', '20px', '24px', '28px',
				'32px', '36px', '48px', '60px', '72px'
			],
			supportAllValues: true // 모든 px 값을 수동 입력으로도 지원
		},
        image: {
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                'imageStyle:inline',
				'imageStyle:block',
				'imageStyle:side',
				'alignment:left',   // 이미지 왼쪽 정렬
				'alignment:center', // 이미지 가운데 정렬
				'alignment:right'   // 이미지 오른쪽 정렬
			],
			styles: [
				'inline', 'alignLeft', 'alignCenter', 'alignRight' // 이미지 스타일 옵션
			]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
            ]
        },
		removePlugins: ['imageUpload']  // imageUpload 플러그인 비활성화
    };
	// 이미지 업로드 처리 함수
	const handleImageUpload = async (event, editor) => {
		const file = event.target.files[0];
		setFileName(file.name);
	
		const formData = new FormData();
		formData.append('image', file);
	
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		};
	
		try {
			const response = await support.uploadImageToServer(formData, config);
	
			// 서버에서 반환한 로컬 파일 경로
			const localFilePath = response.data;
	
			// 로컬 파일 경로를 서버의 이미지 URL로 변환
			const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(localFilePath)}`;
	
			// console.log(imageUrl);
	
			// CKEditor 내에 이미지 삽입
			if (editor) {
				editor.model.change(writer => {
					const imageElement = writer.createElement('imageInline', {
						src: imageUrl
					});

					// 현재 선택된 위치에 이미지를 삽입
					const insertPosition = editor.model.document.selection.getFirstPosition();
					editor.model.insertContent(imageElement, insertPosition);

					// 줄바꿈 추가
					const paragraphElement = writer.createElement('paragraph');
					// console.log('paragraphElement : ', paragraphElement);
					const positionAfterImage = writer.createPositionAfter(imageElement);
					editor.model.insertContent(paragraphElement, positionAfterImage);

					// 커서를 새로 생성된 단락(줄바꿈)으로 이동
					writer.setSelection(paragraphElement, 'in');
				});
			}
	
		} catch (error) {
			console.error(error);
			throw error; // 에러를 다시 던져서 호출자가 처리할 수 있게 합니다.
		}
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		const currentImages = new Set();
		const parser = new DOMParser();
		const doc = parser.parseFromString(data, 'text/html');
	
		// 현재 에디터 내의 모든 이미지 URL 추출
		doc.querySelectorAll('img').forEach(img => {
			const src = img.getAttribute('src');
			currentImages.add(src);
			// 이전에 없던 이미지라면 previousImages에 추가
			if (!previousImages.has(src)) {
				previousImages.add(src);
			}
		});
	
		// 이전 이미지 목록에서 현재 이미지 목록을 빼서 삭제된 이미지를 찾음
		const deletedImages = new Set([...previousImages].filter(x => !currentImages.has(x)));
	
		// 삭제된 이미지가 있다면 서버에 삭제 요청
		deletedImages.forEach(async (imageUrl) => {
			// 이미지 URL이 유효한지 확인
			if (imageUrl) {
				// New approach to extracting query parameters from the image URL
				const queryParamIndex = imageUrl.indexOf('?');
				if (queryParamIndex !== -1) {
					const queryParams = imageUrl.substring(queryParamIndex + 1);
					let filePath = '';
					const params = queryParams.split('&');
					for (let param of params) {
						if (param.startsWith('file=')) {
							filePath = decodeURIComponent(param.split('=')[1]);
							break;
						}
					}
		
					if (filePath) {
						// Extracting only the file name from the file path
						const fileName = filePath.split('/').pop();
						// console.log('fileName : ' + fileName);
						// Using the DELETE method to send a deletion request to the server
						try {
							await fetch(`/api/file/delete/${fileName}`, {
								method: 'DELETE',
							});
		
							// If deletion is successful, remove from previousImages
							previousImages.delete(imageUrl);
						} catch (error) {
							console.error('Failed to delete image from server', error);
						}
					}
				}
			} else {
				console.error('Invalid image URL encountered');
			}
		});
	
		// 상태 업데이트
		setPreviousImages(currentImages);
	};

	const handleFileInputClick = () => {
		// 숨겨진 file input을 찾아서 click 이벤트를 발생시킵니다.
		document.getElementById('hiddenFileInput').click();
	}
	
    return (
		<>
			<CKEditor 
				editor={Editor}
				config={editorConfiguration}
				data={data}
                onReady={editor => {
                    setEditorInstance(editor); // CKEditor 인스턴스를 editorInstance 상태에 저장
                }}
				disabled={update}
				onChange={(event, editor) => {
				setData(editor.getData()); // 에디터의 데이터를 부모 컴포넌트로 전달
				handleEditorChange(event, editor);
				onDetailContent(editor.getData());
				}}
			/>
			<div className='d-flex align-items-center border border-top-0 rounded-bottom'>
                <div className={`${admin.seller_edit_upload_left} text-center`}><span>이미지</span></div>
                <div className={`${admin.seller_edit_upload_middle} py-3`}>
                  <div className='ps-3'>
					{/* 사용자 정의 스타일을 적용한 업로드 버튼 */}
					<button className={`${admin.seller_edit_upload_btn}`} onClick={handleFileInputClick}>
						파일 선택
					</button>
					<input type='text' className={`${admin.seller_edit_upload_input} ps-2 dd-fs-7`} disabled={true} placeholder={fileName != '' ? fileName : '파일을 선택해주세요.'}/>
					{/* 실제 파일 input 요소는 숨깁니다. */}
					<input
					id="hiddenFileInput"
					type="file"
					onChange={(event) => handleImageUpload(event, editorInstance)}
					style={{display: 'none'}}
					/>
				</div>
                </div>
                <div className={`${admin.seller_edit_upload_right}`}>
                  <div><span>*업로드 가능한 파일 확장자 : jpg, gif, png</span></div>
                  <div><span>*이미지 용량 : 5MB 이하</span></div>
                </div>
              </div>
		</>
    )
}

export default TextEditor;