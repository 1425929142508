import React, { useContext, useEffect, useRef, useState } from 'react'
import { animated } from 'react-spring';
import { useSpring, useTransition } from '@react-spring/core';
import Country from '../../components/join/Country';
import { LoginContext } from '../../contexts/LoginContextProvider';
import PostModal from './PostModal';
import * as auth from '../../apis/auth';
import * as adminApi from '../../apis/admin';
import { checkEmail, checkUserId, cernumber, phoneAuth } from '../../apis/auth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dailyduck from './MyContainner.module.css'
import { useNavigate } from 'react-router-dom';
import AddressCountry from './AddressCountry';
import axios from 'axios';
import SupportCountries from '../../components/join/SupportCountries';

const MyInfo = ( { showMyInfo, setShowMyInfo, setShowDisplay } ) => {
    
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal);
    const context = useContext(LoginContext);
    const countries = context.countries;
    const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);
    
    const [ users, setUser ] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTermInternational, setSearchTermInternational] = useState('');
    const [ showCountry, setShowCountry ] = useState(false);
    const [ showInternationalCountry, setShowInternationalCountry ] = useState(false);
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [emailChange, setEmailChange] = useState(true);
    const [addressSave, setAddressSave] = useState({});
    const [ userImg, setUserImg ] = useState(users && users.userProfile ? users.userProfile : '../../img/my/Profile_Circle.png');
    const [selectedCountry, setSelectedCountry] = useState({
        img:  "../img/icon/country/South_Korea_(KR).png",
        name: users && users.userRepublic ? users.userRepublic : "Republic of Korea",
        number: "+82"
    });
    const [internationalSelectedCountry, setInternationalSelectedCountry] = useState({
        img:  "",
        name: "",
        number: "",
        code: ""
    });
    const [ nickNameState, setNickNameState ] = useState(false);
    const [ nickNameMessage, setNickNameMessage ] = useState('');
    const [nickName, setNickName] = useState(users ? users.userNick : '');
    const [ nickCheckState, setNickCheckState ] = useState(false);
    const [ nickStateColor, setNickStateColor] = useState('');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('black');
    const [borderColor, setBorderColor] = useState("");
    const [ nickMessage, setNickMessage ] = useState('');
    const [ nickColor, setNickColor ] = useState('');

    const [isCheckEmail, setIsCheckEmail] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState({
        hasEnglish: false,
        hasNumber: false,
        hasSpecialChar: false,
        isValidLength: false,
    });

    const [isFocused2, setIsFocused2] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [password2, setPassword2] = useState("");
    const [isPasswordValid2, setIsPasswordValid2] = useState({
        hasEnglish2: false,
        hasNumber2: false,
        hasSpecialChar2: false,
        isValidLength2: false,
    });

    
    const [passwordVisible3, setPasswordVisible3] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [passwordConfirmCheck, setPasswordConfirmCheck] = useState(false);

    const [showMargin, setShowMargin] = useState(false);
    const [email, setEmail] = useState('');
    const [active, setActive] = useState(false);
    const [newPssword, setNewPassword] = useState(false);

    const [pwConfirm, setPwConfirm] = useState(false);
    const [pwTimer, setPwTimer] = useState(null);
    const [hasInput, setHasInput] = useState(false); // 사용자 입력 추적 상태
    const [passwordError, setPasswordError] = useState(false); // 비밀번호 오류 상태
    const [pwTimer2, setPwTimer2] = useState(null);
    const [hasInput2, setHasInput2] = useState(false); // 사용자 입력 추적 상태
    const [passwordError2, setPasswordError2] = useState(false); // 비밀번호 오류 상태

    const [addressCheck, setAddressCheck] = useState(false);
    const [address, setAddress] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [zoneCode, setZoneCode] = useState('');
    const [roadAddress, setroadAddress] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [sido, setSido] =useState('');
    const [detailAddress, setDetailAddress] = useState();
    const [keywordList, setKeywordList] = useState([]);

    const [addressChangeActive, setAddressChangeActive] = useState('domestic');
    const [activeIntenationalSearch, setActiveIntenationalSearch] = useState(false);
    const [intenationalZipCode, setIntenationalZipCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [showAddressCompl, setShowAddressCompl] = useState(false);
    const [internationalAddressComponent, setInternationalAddressComponent] = useState({});

    // input 태그를 참조하기 위한 ref 생성
    const fileInputRef = useRef(null);
    // // console.log(users);

    // 타이머 관리할 ref 추가
    const timerRef = useRef(null);
    const [authEmail, setAuthEmail] = useState(false);
    const [timer, setTimer] = useState(300);

    const phoneTimerRef = useRef(null);
    const [phoneTimer, setPhoneTimer] = useState(300);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [mockCertificationNumber, setMockCertificationNumber] = useState('000000');
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가
    const [isPhoneChange, setIsPhoneChange] = useState(false);

    const [ isLoading, setIsLoading ] = useState(false);

    const [ socialBoon, setSocialBoon ] =useState(false);
    
    const [showSupportCountry, setShowSupportCountry] = useState(false);

    const fetchData = async () => {
        try {
            if(userInfo) {
                userSelect();
                // // console.log(response.data);
            }
        } catch (error) {
            // 오류 처리
            console.error("데이터를 불러오는 데 실패했습니다.", error);
        }
    };
    
    const userSelect = async () => {
        // 비동기 요청 실행
        const response = await auth.userselect(userInfo.userId);
        // 데이터 설정
        setUser(response.data);
    }
    useEffect(() => {
        fetchData();
        excludedSelect()
    }, [userInfo]);

    const excludedSelect = async () => {
        const response = await adminApi.excludedKeywordRead();
        const data = response.data;

        // console.log('excludedKeyword : ', data);
        setKeywordList(data);
    }

    // 뒤로가기 버튼
    const handleBack = () => {
        navigate('/my');
    }

    // 국가 선택 기능
    const handleCountry = () => {
        setShowCountry(true);
    }

    // 국제 국가 선택 기능
    const handleIntenationalCountry = () => {
        setShowInternationalCountry(true);
    }

    const handleCountryClose = () => {
        setShowCountry(false);
    }
    const handleIntenationalCountryClose = () => {
        setShowInternationalCountry(false);
        reSet();
    }

    // 회원탈퇴
    const withdraw = () => {
        navigate('/my/myinfo/withdraw', {state : {users}});
    }

    const onAddressSave = async () => {
        const user = {
            // 기타 Users 객체 필드
            userId: userInfo.userId,
            zipCode: zoneCode,
            streetNameAddress: roadAddress,
            detailAddress: detailAddress,
            sigungu: sigungu,
            sido: sido,
            userTel: users.userTel,
            userName: users.userName
            // 기타 필요한 Users 객체의 다른 필드들도 여기에 포함시키세요.
        };
    
        // // console.log(user);
    
        const response = await auth.addressChange(user);
        const data = await response.data;
    
        // // console.log(data);

        if(data === 'SUCCESS') {
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>기본 주소지가 변경되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>결제시 사용되는 배송지에도 추가되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', addressConfirm);
            
            function addressConfirm(e) {
                if(e.target && e.target.id == 'address_confirm') {
                    userSelect();
                    setAddressCheck(false);
                    MySwal.close();
                    document.removeEventListener('click', addressConfirm);
                }
            }
        }
    }


    const onEmailChange = () => {
        setEmailChange(false);
    }
    const onEmailCancle = () => {
        setEmailChange(true);
    }

    // 타이머 초기화 함수
    const startTimer = () => {
        // 기존 타이머를 클리어합니다.
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        setTimer(300); // 타이머를 초기값으로 설정합니다.
        timerRef.current = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(timerRef.current); // 타이머 정지
                    setActive(true); // 타이머가 끝나면 버튼을 활성화
                    return 300;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };
    const onEmailChangeSuccess = async () => {

        setActive(false);
        setIsLoading(true);

        const response = await auth.emailCheck(email);
        const data = response.data; 
        
        // console.log('onEmailChangeSuccess : ', data);

        if(data === 'SUCCESS') {
            setIsLoading(false);
            MySwal.fire({
                customClass: {
                    popup: `${dailyduck.my_info_address_popup}`
                },
                html : 
                `
                <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증메일이 발송되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>인증 메일이 보이지 않을 경우 스팸메일함을 확인해 주세요.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                    <button id='address_confirm'>확인</button>
                </div>
                </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
              });
              document.addEventListener('click', addressConfirm);
              
              function addressConfirm(e) {
                  if(e.target && e.target.id == 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setAuthEmail(true);
                      setActive(false);
                      
                      // 타이머 시작
                      startTimer();
                  }
              }
        }

    
    }
    const handlePasswordChangeBtn = () => {
        setNewPassword(true);
    }
    const handlePasswordChangeBtnCancle = () => {
        setNewPassword(false);
    }

    const transitions = useTransition(showMyInfo, {
        from: { transform: "translateX(100%)" },
        enter: { transform: "translateX(0)" },
        leave: { transform: "translateX(100%)" },
        config: {
            duration: 300,
        },
    });


    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    }

    function handleSelectedCountry(country) {
        
        if (!country) {
            // // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });
    }
    function handleInternationalSelectedCountry(country) {
        
        if (!country) {
            // // console.log('Country not defined');
            return;
        }

        setInternationalSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img,
            code: country.code
        });
    }

    useEffect(() => {
        // users가 변경될 때마다 userProfile의 존재 여부를 확인하여 상태를 업데이트합니다.
        if (users && users.userProfile) {
            if (users.userProfile.startsWith('http')) {
            // 외부 URL인 경우 직접 URL을 사용합니다.
            setUserImg(users.userProfile);
            } else {
            // 내부 URL인 경우 API 서버 URL을 사용합니다.
            setUserImg(`${process.env.REACT_APP_API_SERVER}/img?file=${users.userProfile}`);
            }
        } else {
            // users.userProfile이 없는 경우 기본 이미지를 사용합니다.
            setUserImg(`${process.env.PUBLIC_URL}/img/my/Profile_Circle.png`);
        }
    }, [users, setUserImg]);

  
    const handleImageChange = async (e) => {
      const file = e.target.files[0];
      if (file && file.type.substr(0, 5) === 'image') {
        const reader = new FileReader();
  
        reader.onloadend = () => {
            setUserImg('');
            setUserImg(reader.result);
        };
  
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append('image', fileInputRef.current.files[0]);
        formData.append('userId', userInfo ? userInfo.userId : 'null');
        // // console.log(formData);

        try {
            const response = await fetch('/api/users/upload', {
            method: 'POST',
            body: formData,
            });

            const result = await response.json();
            // // console.log(result);
        } catch (error) {
            console.error('이미지 업로드 실패:', error);
        }
        
      } else {
        console.error("선택된 파일이 이미지가 아닙니다.");
      }
    };


    const handlePasswordChange = (e) => {
        const updatedPassword = e.target.value;
        setPassword(updatedPassword); // 항상 setPassword 호출
    
        if(updatedPassword.length > 7) {
            setIsPasswordValid(passwordCheck(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출
            setHasInput(true);
        } else {
            setHasInput(false);
            setPasswordError(false);
            setIsPasswordValid(passwordCheck(updatedPassword));
        }
    
        // 기존에 설정된 타이머가 있다면 취소
        if (pwTimer) {
            clearTimeout(pwTimer);
        }
    
        // 입력값이 있을 때만 pwCheck 함수를 호출하도록 조건 추가
        const newTimer = updatedPassword.length > 7 && setTimeout(() => {
            pwCheck(updatedPassword);
        }, 1000); // 1000ms = 1초
    
        // 새로운 타이머를 상태에 저장
        setPwTimer(newTimer);
    };
    


    const pwCheck = async (passwordCheck) => {
        try {
            const response = await auth.pwCheck(passwordCheck, users ? users.userPw : 'null');
            const data = await response.data;
    
            if(data === 'Password matches') {
                setPasswordError(false); // 오류 상태를 false로 설정
                // // console.log(data);
            } else {
                setPasswordError(true); // 오류 상태를 true로 설정
                // // console.log('다른값');
            }
        } catch (error) {
            console.error("error : " + error);
            setPasswordError(true); // 오류가 발생했으므로 오류 상태를 true로 설정
        }
    }
    
    const handlePasswordChange2 = (e) => {
        const updatedPassword = e.target.value;
        setPassword2(updatedPassword);
        
        if(updatedPassword.length > 7) {
            setIsPasswordValid2(passwordCheck2(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출
            setHasInput2(true);
        } else {
            setHasInput2(false);
            setPasswordError2(false);
            setIsPasswordValid2(passwordCheck2(updatedPassword)); // passwordCheck 함수를 업데이트된 비밀번호로 호출
        }
    
        // 기존에 설정된 타이머가 있다면 취소
        if (pwTimer2) {
            clearTimeout(pwTimer2);
        }
    
        // 입력값이 있을 때만 pwCheck 함수를 호출하도록 조건 추가
        const newTimer = updatedPassword.length > 7 && setTimeout(() => {
            pwCheck2(updatedPassword);
        }, 1000); // 1000ms = 1초
    
        // 새로운 타이머를 상태에 저장
        setPwTimer2(newTimer);
    };

    const pwCheck2 = async (passwordCheck) => {
        try {
            const response = await auth.pwCheck(passwordCheck, users ? users.userPw : 'null');
            const data = await response.data;
    
            if(data === 'Password matches') {
                setPasswordError2(false); // 오류 상태를 false로 설정
                // // console.log(data);
            } else {
                setPasswordError2(true); // 오류 상태를 true로 설정
                // // console.log('다른값');
            }
        } catch (error) {
            setPasswordError2(true); // 오류가 발생했으므로 오류 상태를 true로 설정
        }
    }
  
    // '변경하기' 버튼 클릭 시 input 태그를 트리거하는 함수
    const handleClick = () => {
      fileInputRef.current.click();
    };

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const handleTogglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };
    const handleTogglePasswordVisibility3 = () => {
        setPasswordVisible3(!passwordVisible3);
    };

    const handleFocus = () => { setIsFocused(true); setShowMargin(true); };
    const handleBlur = () => {
        if (!password) {
            setIsFocused(false);
            setShowMargin(false);
        }
    };
    const handleFocus2 = () => { setIsFocused2(true); setShowMargin(true); };
    const handleBlur2 = () => {
        if (!password2) {
            setIsFocused2(false);
            setShowMargin(false);
        }
    };

    const checkEnglish = password => /[a-zA-Z]/.test(password);
    const checkNumber = password => /\d/.test(password);
    const checkSpecialChar = password => /[~@%^&*_|<>{}!]/.test(password);
    const checkLength = password => password.length >= 8;

    const checkEnglish2 = password => /[a-zA-Z]/.test(password);
    const checkNumber2 = password => /\d/.test(password);
    const checkSpecialChar2 = password => /[~@%^&*_|<>{}!]/.test(password);
    const checkLength2 = password => password.length >= 8;

    
    const passwordCheck = (password) => {
        // password가 문자열이 아니라면, 모든 검사를 false로 설정
        if (typeof password !== 'string') {
            return {
              hasEnglish: false,
              hasNumber: false,
              hasSpecialChar: false,
              isValidLength: false,
            };
        }
    
        return {
            hasEnglish: checkEnglish(password),
            hasNumber: checkNumber(password),
            hasSpecialChar: checkSpecialChar(password),
            isValidLength: checkLength(password),
        };
    };
    const passwordCheck2 = (password) => {
        // password가 문자열이 아니라면, 모든 검사를 false로 설정
        if (typeof password !== 'string') {
            return {
              hasEnglish2: false,
              hasNumber2: false,
              hasSpecialChar2: false,
              isValidLength2: false,
            };
        }
    
        return {
            hasEnglish2: checkEnglish2(password),
            hasNumber2: checkNumber2(password),
            hasSpecialChar2: checkSpecialChar2(password),
            isValidLength2: checkLength2(password),
        };
    };

    const onEmailCheck = (e) => {
        setEmail(e.target.value);
    }

    useEffect(() => {
        const emailRegex = 
        /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;
    
        // 이메일 유효성 검사를 먼저 수행
        const isValidEmail = emailRegex.test(email);

        if (emailRegex.test(email)) { // 정규 표현식을 사용하여 이메일 형식을 검증
            const timer = setTimeout(() => {
                checkEmail(email)  // emailCheck 함수 호출
                    .then(res => {
                        if (res.data.userEmail) {
                            setMessage('중복된 이메일입니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor("rgba(255, 92, 92, 1)");
                        } else {
                            setMessage('사용 가능한 이메일입니다.');
                            setColor('green');
                            setBorderColor("green")
                            setActive(isValidEmail);
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);
    
            return () => clearTimeout(timer);
        } else {
            // 이메일 형식이 아닌 경우 메시지 업데이트
            if(email !== '') {
                setMessage('유효하지 않은 이메일 형식입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor("rgba(255, 92, 92, 1)");
            } else {
                setMessage('');
            }
        }
    
        // 유효성 검사 결과에 따라 setActive를 설정
    
    }, [email]); // 의존성 배열을 [email]로 변경

    const isPasswordCheck = () => {
        
        return isPasswordValid
        && isPasswordValid.hasEnglish && isPasswordValid.hasNumber
        && isPasswordValid.hasSpecialChar && isPasswordValid.isValidLength &&
        isPasswordValid2
        && isPasswordValid2.hasEnglish2 && isPasswordValid2.hasNumber2
        && isPasswordValid2.hasSpecialChar2 && isPasswordValid2.isValidLength2 &&
        passwordConfirmCheck
        ;
    }

    const handlePasswordConfirm = (e) => {

        setPasswordConfirm(e.target.value);
        
    }
    useEffect(() => {
        if(password2 === passwordConfirm) {
            setPasswordConfirmCheck(true);
        } else {
            setPasswordConfirmCheck(false);
        }
    }, [handlePasswordConfirm])


    // 주소 정보를 처리하는 함수
    const handleAddress = (data) => {

        setZoneCode(data.zonecode);
        setSigungu(data.sigungu);
        setSido(data.sido);

        let refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');
        setroadAddress(refinedRoadAddress);
        setAddress(data.address); // 전체 주소
    };
    
    const onDetailAddress = (e) => {
        setDetailAddress(e.target.value);
    }

    const handleCloseModal = () => {
        if (address === '') {
            setAddressCheck(false);
        }
        setModalVisible(false);
    };

    useEffect(() => {
        setAddressCheck(address !== '');
    }, [address]);

    // 여기
    useEffect(() => {
        // console.log('timer : ', timer);
        if(timer === 0) {
            alert('인증시간이 만료되었습니다. 다시 시도해 주세요.');
            setTimer(300);
            setEmailChange(true);
            setActive(false);
            setEmail('');
        }
    }, [timer]);

    const checkCertificationAuthEmailNumber = (e) => {
        // 입력된 값에서 숫자가 아닌 문자를 제거합니다.
        let value = e.target.value.replace(/\D/g, '');
        
        // 입력된 숫자가 6자리를 초과하지 않도록 합니다.
        if (value.length > 6) {
            value = value.slice(0, 6);
        }
    
        // 업데이트된 값을 input에 다시 설정합니다.
        e.target.value = value;
    
        // // console.log(value);
    
        // 입력된 숫자가 6자리일 경우 서버에 요청을 보냅니다.
        if (value.length === 6) {
            // 서버 요청을 보내는 함수를 호출합니다.
            // 이 예제에서는 서버 요청 함수를 sendRequestToServer라 가정합니다.
            sendRequestToServer(value);
        }
    }

    const sendRequestToServer = async (authNumber) => {
        // // console.log("서버에 요청을 보냅니다: ", authNumber);
        const response = await auth.emailAuthNumberCheck(email, authNumber);
        const data = response.data;

        // console.log(data);

        if(data == 'SUCCESS') {
            setAuthEmail(false);
            setEmailChange(true);
            setIsCheckEmail(true);

            const emailChangeResponse = await auth.emailChange(userInfo&&userInfo?.userId, email)
            const emailChangeData = emailChangeResponse.data;

            // console.log('emailChangeData : ', emailChangeData);

            if(emailChangeData ==='SUCCESS') {
                alert('이메일 변경이 완료되었습니다.');
                fetchData();
                clearInterval(timerRef.current);
            }
        } else {
            setIsCheckEmail(false);
        }
    }

    const onPasswordChange = async () => {
        if(isPasswordCheck()) {
            const response = await auth.pwChange(passwordConfirm, userInfo ? userInfo.userId : 'null');
            const data = await response.data;

            // // console.log(data);

            if(data === 'SUCCESS') {
                setNewPassword(false);
            }
        }
    }

    // 타이머 초기화 함수
    const startPhoneTimer = () => {
        // 기존 타이머를 클리어합니다.
        if (phoneTimerRef.current) {
        clearInterval(phoneTimerRef.current);
        }

        setPhoneTimer(300); // 타이머를 초기값으로 설정합니다.
        phoneTimerRef.current = setInterval(() => {
        setPhoneTimer(prevPhoneTimer => {
            if (prevPhoneTimer <= 1) {
            clearInterval(phoneTimerRef.current); // 타이머 정지
            setIsCheck(true); // 타이머가 끝나면 버튼을 활성화
            return 0;
            }
            return prevPhoneTimer - 1;
        });
        }, 1000);
    };

    useEffect(() => {
        // console.log('phoneTimer : ', phoneTimer);
    }, [phoneTimer])

    const onPhoneChange = () => {
        setIsPhoneChange(true);
    }

    const certificationNumber = async () => {
        setIsCheck(false); // 인증번호 받기 버튼 비활성화
        try {
          const response = await phoneAuth(phoneNumber);
          const statusCode = response.status;
    
          if (statusCode === 200) {
            MySwal.fire({
              customClass: {
                popup: `${dailyduck.my_info_address_popup}`
              },
              html: `
              <div>
                <div class='${dailyduck.my_info_address_title}'><span>인증문자가 발송되었습니다.</span></div>
                <div class='${dailyduck.my_info_address_sub_title}'><span>인증 번호가 도착하지 않을 경우 휴대폰 번호를 확인해 주세요.</span></div>
                <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                  <button id='address_confirm'>확인</button>
                </div>
              </div>
              `,
              showConfirmButton: false,
              showCancelButton: false,
            });
    
            document.addEventListener('click', addressConfirm);
    
            function addressConfirm(e) {
              if (e.target && e.target.id === 'address_confirm') {
                MySwal.close();
                document.removeEventListener('click', addressConfirm);
                setCertificationInputVisible(true); // 인증번호 인풋 창 활성화
    
                startPhoneTimer(); // 타이머 시작
              }
            }
          } else {
            console.error(response);
          }
        } catch (error) {
          console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }
    };

    const overseasCertificationNumber = async () => {
        setIsCheck(true); // 인증번호 받기 버튼 비활성화
        console.log('overseas : ', selectedCountry.number+phoneNumber);

        let phoneNum = selectedCountry.number+phoneNumber;

        try {
            const response = await auth.overseasPhoneAuth(phoneNum);
            const data = await response.data;
            const statusCode = response.status;

            if (statusCode === 200) {
                MySwal.fire({
                    customClass: {
                      popup: `${dailyduck.my_info_address_popup}`
                    },
                    html: `
                    <div>
                      <div class='${dailyduck.my_info_address_title}'><span>인증문자가 발송되었습니다.</span></div>
                      <div class='${dailyduck.my_info_address_sub_title}'><span>인증문자가 도착하지 않을 경우 핸드폰번호를 확인해주세요.</span></div>
                      <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                        <button id='address_confirm'>확인</button>
                      </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
          
                  document.addEventListener('click', addressConfirm);
          
                  function addressConfirm(e) {
                    if (e.target && e.target.id === 'address_confirm') {
                      MySwal.close();
                      document.removeEventListener('click', addressConfirm);
                      setCertificationInputVisible(true); // 인증번호 인풋 창 활성화
          
                      startPhoneTimer(); // 타이머 시작
                    }
                  }

            } else {
                console.error(response);
            }

        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }

    };
    
    useEffect(() => {
        // 컴포넌트 언마운트 시 타이머 정리
        return () => {
          if (phoneTimerRef.current) {
            clearInterval(phoneTimerRef.current);
          }
        };
    }, []);

    useEffect(() => {
        // console.log('userCertificationNumber : ', userCertificationNumber);
        if (userCertificationNumber === '') return;
        
        const phoneTimer = setPhoneTimer(() => {
            checkCertificationNumber(userCertificationNumber);
        }, 3000);

        return () => clearTimeout(phoneTimer);
    }, [userCertificationNumber]);

    const checkCertificationNumber = async (certificationNumber) => {
        try {
            if(certificationNumber.length === 6) {
                let phoneNumberCheck = '';

                if(selectedCountry.number === '+82') {
                    phoneNumberCheck = phoneNumber;
                } else {
                    phoneNumberCheck = selectedCountry.number + phoneNumber;
                }
                const response = await auth.phoneAuthCheck(phoneNumberCheck, certificationNumber);
                const data = response.data;
    
                if (data === 'SUCCESS') {
                    setIsCheck(true);
                    setCertificationSuccess(true);
                    setCertificationInputVisible(false);
                    setIsPhoneChange(false);
                    backServerPhoneChange();
                    
                    // 인증 성공 시 타이머 초기화
                    clearInterval(phoneTimer.current);
                } else {
                    alert('인증에 실패하였습니다. 인증번호를 다시 확인해주세요.(Authentication failed. Please check the authentication number again.)');
                    setIsCheck(false);
                    setCertificationSuccess(false);
                    setCertificationInputVisible(true);
                }
            }
        } catch (error) {
            console.error('Error checking certification number:', error);
        }
    };

    const backServerPhoneChange = async () => {
        const response = await auth.phoneChange(phoneNumber, userInfo ? userInfo.userId : 'null');
        const data = response.data;

        // console.log(data);

        if(data === 'SUCCESS') {
            alert('인증에 성공하여 정상적으로 휴대폰번호가 변경되었습니다.(Authentication was successful and the mobile phone number was successfully changed.)');
            fetchData();
        }
    }

    let debounceTimer;

    const onNickName = (e) => {
        let newNickName = e.target.value;
        setNickCheckState(false);

        // 닉네임이 8자를 초과할 경우 8자로 잘라냄
        if (newNickName.length > 8) {
            newNickName = newNickName.slice(0, 8);  // 8자로 강제 제한
            setNickMessage('닉네임은 최대 8자까지 입력 가능합니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickName(newNickName);
            setNickCheckState(false);
        } else {
            setNickMessage('');
            setNickColor('black');
            setNickName(newNickName);
        }

        if (newNickName.trim().length < 2) {
            // 2자 미만일 경우 경고 메시지 표시
            setNickMessage('닉네임은 최소 2자 이상 입력해야 합니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickCheckState(false);
            return;
        }
    };

    const nickCheck = async () => {

        // 입력값이 빈 문자열인 경우
        if (nickName === '') {
            setNickNameMessage('입력된 값이 없습니다.');
            setNickCheckState(false);
            return;
        }
        // 입력값이 기존 닉네임과 동일한 경우
        if (nickName === users.userNick) {
            setNickNameMessage('기존에 사용했던 닉네임과 같습니다.');
            setNickStateColor('rgba(255, 3, 102, 1)');
            setNickCheckState(false);
            return;
        }

        if (nickName.trim().length < 2) {
            // 2자 미만일 경우 경고 메시지 표시
            setNickMessage('닉네임은 최소 2자 이상 입력해야 합니다.');
            setNickColor('rgba(255, 92, 92, 1)');
            setNickCheckState(false);
            return;
        }

        // 로컬에서 제외 키워드 리스트 확인
        const isExcluded = keywordList.some(keyword => keyword.excludedKeyword === nickName);
        if (isExcluded) {
            // console.log('여기');
            setNickNameMessage('사용할 수 없는 닉네임입니다.');
            setNickStateColor('rgba(255, 3, 102, 1)');
            setNickName('');
            setNickCheckState(false);
            return;
        }

        const response = await auth.nickNameCheck(nickName);
        const data = response.data;
        
        // console.log(data);
        
        if(data === 'exist') {
            setNickNameMessage('다른 유저가 사용중인 닉네임입니다.');
            setNickStateColor('rgba(255, 3, 102, 1)');
            setNickCheckState(false);
        } else {
            setNickNameMessage('사용가능한 닉네임입니다.');
            setNickStateColor('rgba(0, 189, 103, 1)');
            setNickCheckState(true);
        }

    };

    const onNickUpdate = async () => {

        const isExcluded = keywordList.some(keyword => keyword.excludedKeyword === nickName);

        if (isExcluded) {
            alert('사용할 수 없는 닉네임을 입력하셨습니다.');
            return;
        } else {
            const response = await auth.nickUpdate(users.userId, nickName);
            const data = response.data;
    
            // console.log('data : ', data);
    
            if(data === 'SUCCESS') {
                alert('닉네임 변경이 완료되었습니다.');
                setNickNameState(false);
            }
        }
    }

    const onInternationalZipCode = (e) => {
        let zipCode = e.target.value;

        setIntenationalZipCode(zipCode);
    }

    const addrSearch = async () => {
        if(intenationalZipCode === '' || internationalSelectedCountry.code === ''){
            alert("Please select your zip code and country.");
            return;
        }

        try {
            setLoading(true);
    
            const response = await auth.addressRequest(intenationalZipCode, internationalSelectedCountry.code);
            const data = response.data;
    
            // console.log(data);
            
            if(response.status === 200) {
                setLoading(false);
                // console.log('full Address : ', data);
                setInternationalAddressComponent(data);
                setShowAddressCompl(true);
    
            } else {
                alert('Address search failed.');
            }
        } catch (error) {
            console.error(error);
        }

    };

    const reSet = () => {
        setIntenationalZipCode('');
    }

    const onInternationalDtailAddress = (e) => {
        setInternationalAddressComponent({
            ...internationalAddressComponent, detailAddress : e.target.value
        });
    }
    const handleGlobalAddressInsert = async () => {
        // console.log('internationalAddressComponent : ', internationalAddressComponent);
        // console.log('userId : ', userInfo.userId);
        // console.log('internationalSelectedCountry : ', internationalSelectedCountry);

        const newItem = {
            globalZipCode : intenationalZipCode,
            globalCountryCode : internationalSelectedCountry.code,
            globalAddress : internationalAddressComponent.fullAddress,
            globalDtailAddress : internationalAddressComponent.detailAddress,
            globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
            globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
            globalCountryNumber : internationalSelectedCountry.number,
            userId : userInfo && userInfo?.userId
        }

        // console.log('newItem : ', newItem);

        const response = await auth.globalAddressUpdate(newItem);
        const data = response.data;

        if(data === 'SUCCESS') {
            setActiveIntenationalSearch(false);
            setShowAddressCompl(false);
            fetchData();
        }
    }

    const handleNoSocialBoon = () => {
        setSocialBoon(!socialBoon);
    }
    
    const onOverseaseInfoBtn = () => {
        setShowSupportCountry(!showSupportCountry);
    }


    return (
        <>
        {
            isLoading &&
            <div className={`${dailyduck.loading_page}`}>
                <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="" /></div>
            </div>
        }
        {
            <div className={`${dailyduck.myinfo_container}`}>
                    <div className={dailyduck.myinfo_box}>
                        <div className={`${dailyduck.my_info_header}`}>
                            <div>
                                <button onClick={() => handleBack()}><span className="material-symbols-outlined">arrow_back_ios</span></button>
                            </div>
                            <div>
                                <div><span>내정보 수정</span></div>
                            </div>
                        </div>
                        <div className={`${dailyduck.my_info_content}`}>
                            <div className='py-3 bg-white'>
                                <div className={`text-center ${dailyduck.user_img_box}`}>
                                    <img src={`${userImg}`} alt="유저이미지" style={{width: '100px', height: '100px', objectFit: 'cover'}} />
                                    <div className='pt-2'>
                                        {/* 실제 파일 입력 필드는 숨김 처리 */}
                                        <input type="file" onChange={handleImageChange} ref={fileInputRef} style={{display: 'none'}} />
                                        {/* 사용자에게 보여지는 버튼. 클릭 시 파일 입력 필드를 트리거함 */}
                                        <button onClick={handleClick}>변경하기</button>
                                    </div>
                                </div>
                                <div className='pt-3 px-3'>
                                    <div><span>닉네임</span></div>
                                    <div className={`${dailyduck.nick_name_btn_box}`}>
                                        <div><input type="text" className='join_input_tag' placeholder={users ? users.userNick : '데일리덕'} value={nickName} disabled={!nickNameState} onChange={(e) => onNickName(e)}/></div>

                                        {
                                            !nickNameState ?
                                            <div><button onClick={() => setNickNameState(!nickNameState)}>변경</button></div>
                                            :
                                            <div><button onClick={() => nickCheck()}>중복체크</button></div>
                                        }
                                    </div>
                                    {
                                        nickNameMessage !== '' &&
                                        <div className={`${dailyduck.nick_name_check_txt}`}><span style={{ color: nickStateColor }}>{nickNameMessage}</span></div>
                                    }
                                    {
                                        nickNameState &&
                                        <div>
                                            <div className={`${dailyduck.nick_name_change_text}`}>
                                                {
                                                    nickMessage === '' ?
                                                    <span>최소 2자 이상 최대 8자 이하로 입력해주세요.</span>
                                                    :
                                                    <span style={{color : nickColor}}>{nickMessage}</span>
                                                }
                                            </div>
                                            <div className={`${dailyduck.nick_name_change_btn_box}`}>
                                                <div><button onClick={() => {setNickNameState(!nickNameState); setNickName(users.userNick ? users.userNick : ''); setNickNameMessage('')}}>변경취소</button></div>
                                                <div><button disabled={!nickCheckState} className={nickCheckState ? dailyduck.nick_name_change_btn_active : ''} onClick={() => onNickUpdate()}>수정하기</button></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className='pt-3 px-3'>
                                    <div><span>이메일</span></div>
                                    {
                                        emailChange ? (
                                            <div className={`d-flex ${dailyduck.email_btn_box}`}>
                                                <div className='pe-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.userEmail : 'newjeanduck@gmail.com'} disabled={emailChange} />
                                                </div>
                                                <div>
                                                    <button onClick={onEmailChange}>변경</button>
                                                </div>
                                            </div>
                                            ) : (
                                            <div className={`${dailyduck.email_btn_box2}`}>
                                                <div className='pe-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.userEmail : 'newjeanduck@gmail.com'} disabled={emailChange} onChange={(e) => onEmailCheck(e)}/>
                                                </div>
                                                <span style={{ color: color }}>{message}</span>
                                                {
                                                    authEmail &&
                                                    <div>
                                                        <div className='join_input position-relative py-2'>
                                                            <input 
                                                            type="number" 
                                                            className='join_input_tag' 
                                                            placeholder='인증번호' 
                                                            maxLength="6"
                                                            // 숫자만 입력받도록 하고, 최대 길이를 6으로 제한합니다.
                                                            // input의 type이 number일 때 maxLength가 직접적으로 동작하지 않으므로 onChange에서 처리해야 합니다.
                                                            onChange={(e) => checkCertificationAuthEmailNumber(e)} 
                                                            />
                                                            <span className='cer_span'>{Math.floor(timer / 60)}:{('0' + timer % 60).slice(-2)}</span>
                                                        </div>
                                                        <div>
                                                            {
                                                                isCheckEmail ? 
                                                                <span></span>
                                                                :
                                                                <span style={{color : 'red'}}>인증번호가 일치하지 않습니다.</span>
                                                            }
                                                        </div>
                                                        <div className={`${dailyduck.email_auth_info_resend}`}>
                                                            <div><span>인증번호를 받지 못하셨나요?</span></div>
                                                            <div><button onClick={onEmailChangeSuccess}>인증번호 재발송</button></div>
                                                        </div>
                                                        <div className={`${dailyduck.email_auth_info_spam_text}`}>
                                                            <div><span>*인증 메일이 보이지 않을 경우 스팸메일함을 확인해 주세요.</span></div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className='d-flex justify-content-center pt-2'>
                                                    <button className='mx-1' onClick={onEmailCancle}>변경취소</button>
                                                    <button className={`mx-1 ${active ? dailyduck.email_auth_active : ''}`} disabled={!active} onClick={onEmailChangeSuccess}>인증메일 발송</button>
                                                </div>
                                            </div>
                                            )
                                    }
                                </div>
                                {
                                    users.google === null && users.line === null && users.kakao === null && users.apple === null && users.twitter === null ?
                                    <div className='pt-3 px-3'>
                                        <div><span>비밀번호</span></div>
                                            {
                                                !newPssword ? (
                                                    <div className={`${dailyduck.password_btn_box} ${showMargin ? dailyduck.marginB : ''}`}>
                                                        <div className='join_input pe-1'>
                                                            <input 
                                                                type="password"
                                                                className='join_input_tag' 
                                                                placeholder={users ? users.userPw : '비밀번호'}
                                                                value={users && users.userPw}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div>
                                                            <button onClick={handlePasswordChangeBtn}>변경</button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className='pb-2' style={{ position: 'relative'}}>
                                                        <input 
                                                            type={passwordVisible ? "text" : "password"}
                                                            className='join_input_tag'
                                                            onFocus={handleFocus} 
                                                            onBlur={handleBlur} 
                                                            onChange={(e) => {
                                                                handlePasswordChange(e);
                                                                pwCheck(e.target.value); // 비밀번호 입력 시, 바로 체크
                                                            }}
                                                            value={password} 
                                                            placeholder='현재 비밀번호를 입력해주세요.'
                                                            style={{ paddingRight: '40px' }}
                                                        />


                                                        {
                                                            isFocused &&
                                                            <button className='password_eye_btn' onClick={handleTogglePasswordVisibility} >
                                                                    {passwordVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                                            </button>
                                                        }
                                                        </div>
                                                        {
                                                        hasInput && passwordError && 
                                                        <div>
                                                            <span style={{color : 'red'}}>기존 비밀번호와 일치하지 않습니다.</span>
                                                        </div>
                                                        }
                                                        {
                                                        isFocused && (
                                                                <div className='d-flex justify-content-center pw_check_point'>
                                                                    <div className={`pb-2 px-2 eng_check ${dailyduck.myinfo_w20}`}>
                                                                        <span style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasEnglish ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>영문
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 number_check ${dailyduck.myinfo_w20}`}>
                                                                        <span style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasNumber ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>숫자
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 spec_check ${dailyduck.myinfo_w30}`}>
                                                                        <span style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.hasSpecialChar ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>특수문자
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 word_check ${dailyduck.myinfo_w30}`}>
                                                                        <span style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid.isValidLength ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>8자 이상
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                        )}
                                                        <div className='pb-2' style={{ position: 'relative'}}>
                                                            <input 
                                                                type={passwordVisible2 ? "text" : "password"}
                                                                className='join_input_tag'
                                                                onFocus={handleFocus2} 
                                                                onBlur={handleBlur2} 
                                                                onChange={(e) => handlePasswordChange2(e)} 
                                                                value={password2} 
                                                                placeholder='새 비밀번호를 입력해주세요.'
                                                                style={{ paddingRight: '40px' }}
                                                        />
                                                        {
                                                            isFocused2 &&
                                                            <button className='password_eye_btn' onClick={handleTogglePasswordVisibility2} >
                                                                    {passwordVisible2 ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                                            </button>
                                                        }
                                                        </div>
                                                        {
                                                        hasInput2 && !passwordError2 && 
                                                        <div>
                                                            <span style={{color : 'red'}}>기존에 사용했던 비밀번호와 같습니다.</span>
                                                        </div>
                                                        }
                                                        {
                                                        isFocused2 && (
                                                                <div className='d-flex justify-content-center pw_check_point'>
                                                                    <div className={`pb-2 px-2 eng_check ${dailyduck.myinfo_w20}`}>
                                                                        <span style={{ color: isPasswordValid2.hasEnglish2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid2.hasEnglish2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>영문
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 number_check ${dailyduck.myinfo_w20}`}>
                                                                        <span style={{ color: isPasswordValid2.hasNumber2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid2.hasNumber2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)" }}>check</span>숫자
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 spec_check ${dailyduck.myinfo_w30}`}>
                                                                        <span style={{ color: isPasswordValid2.hasSpecialChar2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid2.hasSpecialChar2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>특수문자
                                                                        </span>
                                                                    </div>
                                                                    <div className={`pb-2 px-2 word_check ${dailyduck.myinfo_w30}`}>
                                                                        <span style={{ color: isPasswordValid2.isValidLength2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>
                                                                        <span className="material-symbols-outlined" style={{ color: isPasswordValid2.isValidLength2 ? "rgba(0, 189, 103, 1)" : "rgba(196, 199, 202, 1)"}}>check</span>8자 이상
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                        )}
                                                        <div className='pb-2' style={{ position: 'relative'}}>
                                                            <div className='join_input' style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                                                <input 
                                                                    type={passwordVisible3 ? "text" : "password"} 
                                                                    className='join_input_tag' 
                                                                    placeholder='비밀번호 확인' 
                                                                    style={{ paddingRight: '40px' }}
                                                                    onChange={(e) => handlePasswordConfirm(e)} 
                                                                />
                                                                <button className='password_eye_btn' onClick={handleTogglePasswordVisibility3} >
                                                                    {passwordVisible3 ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                                                </button>
                                                            </div>
                                                                {passwordConfirm !== '' && (
                                                                    password2 === passwordConfirm ? 
                                                                    <span style={{ color: 'green' }}>비밀번호가 일치합니다.</span> :
                                                                    <span style={{ color: 'red' }}>비밀번호가 일치하지 않습니다.</span>
                                                                )}
                                                        
                                                        </div>
                                                        <div className={`d-flex ${dailyduck.pwChange_btnBox} w-100`}>
                                                            <div className='mx-1'><button onClick={handlePasswordChangeBtnCancle}>변경취소</button></div>
                                                            <div className='mx-1'><button className={`${isPasswordCheck() ? dailyduck.email_auth_active : ''}`} disabled={!isPasswordCheck()} onClick={onPasswordChange}>확인</button></div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                    </div>
                                    :
                                    <div className={dailyduck.my_info_social_box}>
                                        <div>
                                            <div><span>소셜 계정은 비밀번호 설정이 불가합니다.</span></div>
                                            <div>
                                                <div><span>데일리덕 계정으로 전환하고 많은 혜택받으세요!</span></div>
                                                <div><button onClick={handleNoSocialBoon}>혜택보기</button></div>
                                            </div>
                                        </div>
                                        <div><button>데일리덕 계정 전환</button></div>
                                        {
                                            socialBoon &&
                                            <div className={dailyduck.my_info_social_modal}>
                                                <div><span>아직 혜택 정보가 없어요😭</span></div>
                                                {/* <div><span>혜택내용입니다.</span></div>
                                                <div><span>혜택내용입니다.</span></div>
                                                <div><span>혜택내용입니다.</span></div> */}
                                            </div>
                                        }
                                    </div>
                                }
                                <div className={dailyduck.my_info_address_btn}>
                                    <div className={addressChangeActive === 'domestic' ? dailyduck.my_info_address_active : ''}><button className={addressChangeActive === 'domestic' ? dailyduck.my_info_address_active_color : ''} onClick={() => setAddressChangeActive('domestic')}>국내주소지</button></div>
                                    <div className={addressChangeActive === 'international' ? dailyduck.my_info_address_active : ''}><button className={addressChangeActive === 'international' ? dailyduck.my_info_address_active_color : ''} onClick={() => setAddressChangeActive('international')}>Global Address</button></div>
                                </div>
                                {
                                    addressChangeActive === 'domestic' &&
                                    <div className='pt-3 px-3'>
                                        <div><span>기본 배송지</span></div>
                                        <div className={`${dailyduck.adress_box}`}>
                                            <div>
                                                <div className='py-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.zipCode : '우편번호'} disabled={true} />
                                                </div>
                                                <div className='py-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.streetNameAddress : '도로명 주소'} value={roadAddress ? roadAddress : ''} disabled={true} />
                                                </div>
                                                <div className='py-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.detailAddress : '상세주소'} onChange={(e) => onDetailAddress(e)} value={detailAddress} disabled={!addressCheck} />
                                                </div>
                                                <div className='py-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.sigungu : '시/군/구'} value={sigungu ? sigungu : ''} disabled={true} />
                                                </div>
                                                <div className='py-1'>
                                                    <input type="text" className='join_input_tag' placeholder={users ? users.sido : '특별시/광역시/도'} value={sido ? sido : ''} disabled={true} />
                                                </div>
                                            </div>
                                            <div className='pt-1'>
                                                {
                                                    addressCheck ? 
                                                        <button className={`mx-1 ${detailAddress ? dailyduck.email_auth_active : ''}`} disabled={!detailAddress} onClick={onAddressSave}>배송지 저장</button>
                                                    : 
                                                        <button onClick={() => {setModalVisible(true); setAddressCheck(true);}} className='mx-1'>배송지 변경</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    addressChangeActive === 'international' &&
                                    <div className={`${dailyduck.my_info_international_box}`}>
                                        <div className='px-3 pt-3'>
                                            <span>zip code</span>
                                            <input type="text" className='join_input_tag' value={users && users.globalZipCode} disabled={true}/>
                                        </div>
                                        <div className='px-3 pt-1'>
                                            <span>address</span>
                                            <input type="text" className='join_input_tag' value={users && users.globalAddress} disabled={true}/>
                                        </div>
                                        <div className='px-3 py-1'>
                                            <span>detail address</span>
                                            <input type="text" className='join_input_tag' value={users && users.globalDetailAddress} disabled={true}/>
                                        </div>
                                        <div className={`${dailyduck.my_info_international_btn}`}><button onClick={() => setActiveIntenationalSearch(true)}>International Address Search</button></div>
                                    </div>
                                }
                                <div className='myinfo_input_box pt-3 px-3'>
                                    <div className='pb-1'><span>국가</span></div>
                                    <div className='join_input'>
                                        {!selectedCountry ? 
                                            <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                <span className='country_name_input'>국가선택</span>
                                                <span className="material-symbols-outlined">expand_more</span>
                                            </button> : 
                                            <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                                <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                                <span className='country_name_input'>{selectedCountry?.name}</span>
                                                <span className="material-symbols-outlined">expand_more</span>
                                            </button>
                                        }
                                    </div>
                                </div>

                                <div className='myinfo_input_box pt-3 px-3'>
                                    <div className='pb-1 join_phone_txt'>
                                        <span>휴대폰 번호</span>
                                        {
                                            selectedCountry.name !== 'Republic of Korea' &&
                                            <button className='join_phone_overseaes_info_btn' onClick={() => onOverseaseInfoBtn()}>Supported Countries and Country Codes</button>
                                        }
                                    </div>
                                    {
                                        !isPhoneChange ? (
                                            <div className={`d-flex ${dailyduck.myinfo_phone_box}`}>
                                                <div className={`join_input ${dailyduck.phone_number_box} pe-1`}>
                                                    <input type="number" className='join_input_tag' placeholder={users ? users.userTel : '핸드폰 번호'} onChange={(e) => handlePhoneNumber(e)} disabled={true} />
                                                    <span style={{color : 'rgba(255, 3, 102, 1)'}}>{selectedCountry?.number}</span>
                                                </div>
                                                <div className={`${dailyduck.myinfo_phone_btn_box}`}>
                                                    <button onClick={onPhoneChange}>변경</button>
                                                </div>
                                            </div>

                                        ) : (
                                            <div className={`d-flex ${dailyduck.myinfo_phone_box}`}>
                                                <div className={`join_input ${dailyduck.phone_number_box} pe-1`}>
                                                    <input type="number" className='join_input_tag' placeholder={'휴대폰 번호'} onChange={(e) => handlePhoneNumber(e)} />
                                                    <span style={{color : 'rgba(255, 3, 102, 1)'}}>{selectedCountry?.number}</span>
                                                </div>
                                                <div className={`${dailyduck.myinfo_phone_btn_box}`}>
                                                    {
                                                        selectedCountry.name === 'Republic of Korea' ?
                                                        <button className={`${!certificationInputVisible && dailyduck.myinfo_authnumber_btn_active}`} disabled={certificationInputVisible} onClick={certificationNumber}>인증</button>
                                                        :
                                                        <button className={`${!certificationInputVisible && dailyduck.myinfo_authnumber_btn_active}`} disabled={certificationInputVisible} onClick={overseasCertificationNumber}>인증</button>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        certificationInputVisible && (
                                            <div className='mt-1'>
                                                <div className='join_input position-relative py-2'>
                                                    <input type="number" className='join_input_tag' placeholder='인증번호' onChange={(e) => setUserCertificationNumber(e.target.value)} />
                                                    <span className='cer_span'>{Math.floor(phoneTimer / 60)}:{('0' + phoneTimer % 60).slice(-2)}</span>
                                                </div>
                                                <div>
                                                    {
                                                        selectedCountry.name === 'Republic of Korea' ?
                                                        <>
                                                            <span className='dd-fs-7 gray_color'>인증번호를 받지 못하셨나요?</span><span><button className={`${dailyduck.myinfo_authNumber_re} ps-2`} onClick={certificationNumber}>인증번호 재요청</button></span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className='dd-fs-7 gray_color'>인증번호를 받지 못하셨나요?</span><span><button className={`${dailyduck.myinfo_authNumber_re} ps-2`} onClick={overseasCertificationNumber}>인증번호 재요청</button></span>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className={`${dailyduck.logout_remove_btnBox} pt-2 px-3 mt-2 pb-4`}>
                                <div className='border-bottom'>
                                    <button onClick={() => logout()}>로그아웃</button>
                                </div>
                                <div className='border-bottom'>
                                    <button onClick={() => withdraw()}>회원탈퇴</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        }
        {
            showCountry &&
                <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose} 
                        searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                        filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                        setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
        }
        {
            showInternationalCountry &&
                <AddressCountry showInternationalCountry={showInternationalCountry} setShowInternationalCountry={setShowInternationalCountry} handleIntenationalCountryClose={handleIntenationalCountryClose} 
                        searchTermInternational={searchTermInternational} setSearchTermInternational={setSearchTermInternational} countries={countries}
                        filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                        setInternationalSelectedCountry={setInternationalSelectedCountry} handleInternationalSelectedCountry={handleInternationalSelectedCountry} />
        }
        {
        modalVisible && (
            <PostModal
            onClose={handleCloseModal}
            onComplete={handleAddress}
            dailyduck={dailyduck}
            />
            )
        }
        {
            activeIntenationalSearch && 
            <div className={`${dailyduck.my_info_international_modal_container}`}>
                <div className={`${dailyduck.my_info_international_modal_box}`}>
                    <div className={`${dailyduck.my_info_international_modal_header}`}>
                        <div><span>Address Search</span></div>
                        <div><button onClick={() => setActiveIntenationalSearch(false)}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div>
                        <div className={`myinfo_input_box pt-3 px-3 ${dailyduck.my_info_international_country_box}`}>
                            <div><span>Select country</span></div>
                            <div className='join_input'>
                                {!internationalSelectedCountry ? 
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> : 
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                        <img src={internationalSelectedCountry?.img} alt={internationalSelectedCountry?.name} />
                                        <span className='country_name_input'>{internationalSelectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className={`${dailyduck.my_info_international_zip_code}`}>
                            <div><span>zip code</span></div>
                            <div><input type="text" value={intenationalZipCode !== '' ? intenationalZipCode : ''} onChange={(e) => onInternationalZipCode(e)}/></div>
                        </div>
                        <div className={`${dailyduck.my_info_international_modal_search_btn}`}>
                            <div><button onClick={addrSearch}>search</button></div>
                        </div>
                    </div>
                </div>
            </div>
        }
        {
            showAddressCompl &&
            <div className={`${dailyduck.my_info_international_compl_address_container}`}>
                <div className={`${dailyduck.my_info_international_compl_address_box}`}>
                    <div className={`${dailyduck.my_info_international_compl_address_zip_code}`}>
                        <div><span>zip code</span></div>
                        <div><input type="text" value={intenationalZipCode} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_basic_address}`}>
                        <div><span>address</span></div>
                        <div><input type="text" value={internationalAddressComponent.fullAddress} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_detail_address}`}>
                        <div><span>detail address</span></div>
                        <div><input type="text" onChange={(e) => onInternationalDtailAddress(e)}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_btn}`}><button onClick={handleGlobalAddressInsert}>저장</button></div>
                </div>
            </div>
        }
        
        {
            showSupportCountry &&
            <SupportCountries setShowSupportCountry={setShowSupportCountry}/>
        }
        </>
    )
    }

export default MyInfo