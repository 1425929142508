// <path-to-your-build>/src/ckeditor.ts or file containing editor configuration if you are integrating an editor from source.

// The editor creator to use.
import  Editor  from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';


const TextEditor2 = ({setData2, onExchangeReturn, data2}) => {
    const edrtorConfiguration = {
		toolbar: {
			items: [
				'bold',
				'italic',
				'link',
				'bulletedList',
				'fontSize',
				'undo',
				'redo',
				'strikethrough',
				'subscript',
				'superscript',
				'insertTable',
				'findAndReplace'
			]
		},
		language: 'ko',
		fontSize: {
			options: [
				'8px','9px','10px', '11px', '12px', '13px', '14px',
				'16px', '18px', '20px', '24px', '28px',
				'32px', '36px', '48px', '60px', '72px'
			],
			supportAllValues: true // 모든 px 값을 수동 입력으로도 지원
		},
		blockToolbar: [
		],
		table: {
			contentToolbar: [
				'tableColumn',
				'tableRow',
				'mergeTableCells',
				'tableCellProperties',
				'tableProperties'
			]
		}
    };

    return (
        <CKEditor 
        editor={Editor}
        config={edrtorConfiguration}
        data={data2}
        onChange={(event, editor) => {
            setData2(editor.getData()); // 에디터 작성 내용 저장 
			onExchangeReturn(editor.getData());
        }}
        />
    )
}

export default TextEditor2;