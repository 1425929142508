import React, { useContext, useEffect, useState } from 'react';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as sellerapi from '../../../../../apis/seller';
import { formatDistanceToNow } from 'date-fns';
import { ko } from 'date-fns/locale';
import 'dayjs/locale/ko';
import SellerProductDetail from './SellerProductDetail';
import seller from '../../../../seller.module.css';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';
import { CSSTransition } from 'react-transition-group';


const SellerProductMain = () => {
  const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const MySwal = withReactContent(Swal);

  const [activeButton, setActiveButton] = useState('entire');
  const [stateButton, setStateButton] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [totalItems, setTotalItems] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

  const [productList, setProductList] = useState([]);
  const [productDetailList, setProductDetailList] = useState({
    productNo : '', productNumber : '', productName : '', productInfo : '', mainCategory: 'support', middleCategory: '', subCategory: '', favorityArtist: '',
    relatedProduct: '', setAmount: '', regularPrice: '', salePrice : '', discount : '', mainItem : [], mainItemOption: [], combinationOption: {},
    representativeImage : '', listImage1 : '', listImage2 : '', listImage3 : '', listImage4 : '', userId : userInfo?.userId,
    detailContents : '', faq : '', dateSelect : '', photoSelect : '', contentSelect : '', exchangeReturn : '', faqs : [], minDate : 0, minDateState : false
  });
  const [productInfo, setProductInfo] = useState('');
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showStateMenu, setShowStateMenu] = useState(false);
  const [charCount, setCharCount] = useState(0); // State to track character count
  const charLimit = 500; // Character limit

  const [counts, setCounts] = useState({
    entire: 0,
    request: 0,
    approved: 0,
    denied: 0,
    sale: 0,
    soldout: 0,
    hiding: 0,
  });

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [ showFaqModal, setShowFaqModal ] = useState(false);
  const [ faqList, setFaqList ] = useState([]);
  const [ selectedFaqs, setSelectedFaqs ] = useState([]); // 체크된 FAQ를 저장할 상태

  const [ showFaqAdd, setShowFaqAdd ] = useState(false);

  useEffect(() => {
    // console.log('productDetailList : ', productDetailList);
    // console.log('selectedFaqs : ', selectedFaqs);
  }, [productDetailList, selectedFaqs]);

  useEffect(() => {
    selectProduct();
    faqListSelect();
  }, [userInfo]);

  const faqListSelect = async () => {
      const response = await sellerapi.sellerFaqSelect(userInfo && userInfo.userId);
      const data = response.data;

      // console.log(data);
      setFaqList(data);

  }

  const sellerFaqAdd = () => {
      setShowFaqAdd(true);
  }

  
  // 검색 필터링
  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTerm(searchValue);

    if (searchValue) {
        const filteredList = productList.filter((item) =>
            item.productName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.shopName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.userId.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredProductList(filteredList);
        setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 설정
        setTotalItems(filteredList.length);
    } else {
        setFilteredProductList(productList); // 검색어가 없으면 전체 리스트로 복원
        setTotalItems(productList.length);
    }
  };


  // 페이지네이션 데이터 계산
  const getPaginatedData = () => {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return filteredProductList.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
      setCurrentPage(page);
  };

  const handleDetaoleCheckboxChange = (faqItem) => {
    setSelectedFaqs((prevSelected = []) => { // prevSelected가 undefined인 경우 빈 배열로 초기화
        if (prevSelected.includes(faqItem)) {
            return prevSelected.filter(item => item !== faqItem);
        } else {
            return [...prevSelected, faqItem];
        }
    });
  };


  const handleFaqSelect = () => {
      const selectedFaqNos = selectedFaqs.map(faq => faq.sellerFaqNo);
      setProductDetailList((prevProductDetaileList) => ({
          ...prevProductDetaileList,
          faq: selectedFaqNos // 배열로 저장
      }));
      setShowFaqModal(false);
  };

  const handleRemoveFaq = (faqItem) => {
      setSelectedFaqs((prevSelected) =>
          prevSelected.filter((item) => item.sellerFaqNo !== faqItem.sellerFaqNo)
      );

      // console.log('prevProductDetaileList.faqs.filter((faqNo) => faqNo !== faqItem.sellerFaqNo) : ', productDetailList.faqs.filter((faqNo) => faqNo !== faqItem.sellerFaqNo))

      setProductDetailList((prevProductDetaileList) => ({
          ...prevProductDetaileList,
          faq: prevProductDetaileList.faq.filter((faqNo) => faqNo !== faqItem.sellerFaqNo),
      }));
  };

  const selectProduct = async () => {
    const response = await sellerapi.supportProductSelect(userInfo && userInfo.userId);
    const data = response.data;

    // console.log('productList : ', data);

    setProductList(data);
    setFilteredProductList(data);
    setCheckedItems(new Array(data.length).fill(false));
    setTotalItems(data.length);
    updateCounts(data);
  };

  const updateCounts = (data) => {
    const newCounts = {
      entire: data.length,
      request: data.filter((item) => item.supportState === '승인요청').length,
      approved: data.filter((item) => item.supportState === '승인완료').length,
      denied: data.filter((item) => item.supportState === '승인거절').length,
      sale: data.filter((item) => item.supportSaleState === '판매중').length,
      soldout: data.filter((item) => item.supportSaleState === '품절').length,
      hiding: data.filter((item) => item.supportSaleState === '숨김').length,
    };
    setCounts(newCounts);
  };

  const filterProducts = (filterType) => {
    let filteredList = productList;

    switch (filterType) {
      case 'request':
        filteredList = productList.filter((item) => item.supportState === '승인요청');
        break;
      case 'approved':
        filteredList = productList.filter((item) => item.supportState === '승인완료');
        break;
      case 'denied':
        filteredList = productList.filter((item) => item.supportState === '승인거절');
        break;
      case 'sale':
        filteredList = productList.filter((item) => item.supportSaleState === '판매중');
        break;
      case 'soldout':
        filteredList = productList.filter((item) => item.supportSaleState === '품절');
        break;
      case 'hiding':
        filteredList = productList.filter((item) => item.supportSaleState === '숨김');
        break;
      default:
        filteredList = productList;
    }

    setFilteredProductList(filteredList);
    setActiveButton(filterType);
    setTotalItems(filteredList.length);
  };

  const getLowestMainItemPrice = (mainItems) => {
    if (!mainItems || mainItems.length === 0) return null;
    const prices = mainItems.map((item) => item.mainItemPrice);
    return Math.min(...prices);
  };

  const categoryMapping = {
    venue_rental: '장소대관',
    cheering_car: '응원차',
    catering: '케이터링',
    wreath: '화환',
    gift_wrapping: '선물포장',
    advertisement: '광고',
    donation: '기부',
    big_scale: '빅스케일',
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const kstDate = new Date(date.getTime() - 9 * 60 * 60 * 1000); // 9시간 빼기
    return formatDistanceToNow(kstDate, { addSuffix: true, locale: ko });
  };
  

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setCheckedItems(new Array(filteredProductList.length).fill(checked));
  };

  const handleCheckboxChange = (index, e) => {
    e.stopPropagation();
    const updatedCheckedItems = checkedItems.map((item, idx) => (idx === index ? !item : item));
    setCheckedItems(updatedCheckedItems);
  };

  const getSelectedProductNos = () => {
    const selectedProducts = filteredProductList.filter((_, index) => checkedItems[index]);
    const productNos = selectedProducts.map((product) => product.productNo);
    // console.log('Selected Product Nos: ', productNos);
    return productNos;
  };

  const showAlert = (message) => {
    MySwal.fire({
      icon: 'warning',
      title: '경고',
      text: message,
    });
  };
  const stateChange = async (state, productNos) => {
    const response = await sellerapi.stateChange(state, productNos);
    const data = response.data;

    // console.log(data);

    if(response.status === 200) {
      selectProduct();
    }
  }
  const registrationStateChange = async (state, productNos) => {
    const response = await sellerapi.registrationStateChange(state, productNos);
    const data = response.data;

    // console.log(data);

    if(response.status === 200) {
      selectProduct();
    }
  }

  // 승인요청
  const onRequest = () => {
    const selectedProducts = filteredProductList.filter((_, index) => checkedItems[index]);
    const selectedCount = selectedProducts.length;

    if (selectedCount === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    const productsToRequest = selectedProducts.filter((product) => product.supportState === '상품등록' || product.supportState === '승인거절');
    const productNos = productsToRequest.map((product) => product.productNo);

    if (productsToRequest.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '처리 가능한 상품이 없습니다.',
        text: '승인완료 상태이거나 승인요청 상태인 상품입니다.',
      });
      return;
    }

    // console.log('Selected Product Nos for Request: ', productNos);

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
        <div>
          <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
            <div>승인요청</div>
            <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
          </div>
          <div class='${seller.seller_date_title} pb-3'>
            <div><span>*선택하신 ${selectedCount}개의 상품 중 처리 가능한 주문은 ${productNos.length}건입니다.</span></div>
            <div><span>${productNos.length}건에 대해 처리를 하시겠습니까?</span></div>
          </div>
          <div class='d-flex justify-content-center pt-3'>
            <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
            <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    // '확인' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerRequestStateChange);

    // '취소' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerRequestStateCancel);

    // '확인' 버튼에 대한 이벤트 리스너 추가
    function sellerRequestStateChange(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {

        registrationStateChange('승인요청', productNos);

        MySwal.fire({
          customClass: {
            popup: `${seller.seller_date_success_popup}`,
          },
          html: `
            <div>
              <div class='${seller.seller_date_success_header}'>
                <div class='${seller.seller_date_success_header_title}'><span>승인요청</span></div>
                <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
              </div>
              <div class='${seller.seller_date_success_title}'>
                <div><span>*선택하신 ${selectedCount}개의 주문 중 총 ${productNos.length}건에 대해서 처리가 완료되었습니다.</span></div>
                <div><span>*관리자의 검토 후 승인 완료되면 상품을 판매하실 수 있습니다.</span></div>
              </div>
              <div class='${seller.seller_date_success_btn}'>
                <button id='seller_swal_success'>확인</button>
              </div>
            </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });

        document.addEventListener('click', sellerRequestStateSuccess);

        function sellerRequestStateSuccess(e) {
          if (e.target && e.target.id == 'seller_swal_success') {
            MySwal.close();
            document.removeEventListener('click', sellerRequestStateChange);
            document.removeEventListener('click', sellerRequestStateCancel);
            document.removeEventListener('click', sellerRequestStateSuccess);
          }
        }
      }
    }

    // '취소' 버튼에 대한 이벤트 리스너 추가
    function sellerRequestStateCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
      }
    }
  };

  // 판매중 처리
  const onSales = () => {
    const selectedProducts = filteredProductList.filter((_, index) => checkedItems[index]);
    const selectedCount = selectedProducts.length;

    if (selectedCount === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    const productsToSell = selectedProducts.filter((product) => product.supportState === '승인완료' && product.supportSaleState !== '판매중');
    const productNos = productsToSell.map((product) => product.productNo);

    if (productsToSell.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '처리 가능한 상품이 없습니다.',
        text: '승인완료 상태가 아니거나 이미 판매중인 상품입니다.',
      });
      return;
    }

    // console.log('Selected Product Nos for Sale: ', productNos);

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
        <div>
          <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
            <div>판매중 처리</div>
            <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
          </div>
          <div class='${seller.seller_date_title} pb-3'>
            <div><span>*선택하신 ${selectedCount}개의 상품 중 처리 가능한 주문은 ${productNos.length}건입니다.</span></div>
            <div><span>${productNos.length}건에 대해 처리를 하시겠습니까?</span></div>
          </div>
          <div class='d-flex justify-content-center pt-3'>
            <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
            <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    // '확인' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerSaleStateChange);
    
    // '취소' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerSaleStateCancel);
    
    // '확인' 버튼에 대한 이벤트 리스너 추가
    function sellerSaleStateChange(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {

        stateChange('판매중', productNos);

        MySwal.fire({
          customClass: {
            popup: `${seller.seller_date_success_popup}`,
          },
          html: `
            <div>
              <div class='${seller.seller_date_success_header}'>
                <div class='${seller.seller_date_success_header_title}'><span>판매중 처리</span></div>
                <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
              </div>
              <div class='${seller.seller_date_success_title}'>
                <div><span>*선택하신 ${selectedCount}개의 주문 중 총 ${productNos.length}건에 대해서 처리가 완료되었습니다.</span></div>
              </div>
              <div class='${seller.seller_date_success_btn}'>
                <button id='seller_swal_success'>확인</button>
              </div>
            </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });

        document.addEventListener('click', sellerSaleStateSuccess);

        function sellerSaleStateSuccess(e) {
          if (e.target && e.target.id == 'seller_swal_success') {
            MySwal.close();
            document.removeEventListener('click', sellerSaleStateChange);
            document.removeEventListener('click', sellerSaleStateCancel);
            document.removeEventListener('click', sellerSaleStateSuccess);
          }
        }
      }
    }

    // '취소' 버튼에 대한 이벤트 리스너 추가
    function sellerSaleStateCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
      }
    }
  };

  // 품절 처리
  const onSoldOut = () => {
    const selectedProducts = filteredProductList.filter((_, index) => checkedItems[index]);
    const selectedCount = selectedProducts.length;

    if (selectedCount === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    const productsToSoldOut = selectedProducts.filter((product) => product.supportState === '승인완료' && product.supportSaleState !== '품절');
    const productNos = productsToSoldOut.map((product) => product.productNo);

    if (productsToSoldOut.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '처리 가능한 상품이 없습니다.',
        text: '승인완료 상태가 아니거나 이미 품절인 상품입니다.',
      });
      return;
    }

    // console.log('Selected Product Nos for Sold Out: ', productNos);

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
        <div>
          <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
            <div>품절 처리</div>
            <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
          </div>
          <div class='${seller.seller_date_title} pb-3'>
            <div><span>*선택하신 ${selectedCount}개의 상품 중 처리 가능한 주문은 ${productNos.length}건입니다.</span></div>
            <div><span>${productNos.length}건에 대해 처리를 하시겠습니까?</span></div>
          </div>
          <div class='d-flex justify-content-center pt-3'>
            <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
            <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    // '확인' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerSoldOutStateChange);
    
    // '취소' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerSoldOutStateCancel);
    
    // '확인' 버튼에 대한 이벤트 리스너 추가
    function sellerSoldOutStateChange(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {

        stateChange('품절', productNos);

        MySwal.fire({
          customClass: {
            popup: `${seller.seller_date_success_popup}`,
          },
          html: `
            <div>
              <div class='${seller.seller_date_success_header}'>
                <div class='${seller.seller_date_success_header_title}'><span>품절 처리</span></div>
                <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
              </div>
              <div class='${seller.seller_date_success_title}'>
                <div><span>*선택하신 ${selectedCount}개의 주문 중 총 ${productNos.length}건에 대해서 처리가 완료되었습니다.</span></div>
              </div>
              <div class='${seller.seller_date_success_btn}'>
                <button id='seller_swal_success'>확인</button>
              </div>
            </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });

        document.addEventListener('click', sellerSoldOutStateSuccess);

        function sellerSoldOutStateSuccess(e) {
          if (e.target && e.target.id == 'seller_swal_success') {
            MySwal.close();
            document.removeEventListener('click', sellerSoldOutStateChange);
            document.removeEventListener('click', sellerSoldOutStateCancel);
            document.removeEventListener('click', sellerSoldOutStateSuccess);
          }
        }
      }
    }

    // '취소' 버튼에 대한 이벤트 리스너 추가
    function sellerSoldOutStateCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
      }
    }
  };

  // 숨김 처리
  const onHiding = () => {
    const selectedProducts = filteredProductList.filter((_, index) => checkedItems[index]);
    const selectedCount = selectedProducts.length;

    if (selectedCount === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    const productsToHide = selectedProducts.filter((product) => product.supportState === '승인완료' && product.supportSaleState !== '숨김');
    const productNos = productsToHide.map((product) => product.productNo);

    if (productsToHide.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '처리 가능한 상품이 없습니다.',
        text: '승인완료 상태가 아니거나 이미 숨김 상태인 상품입니다.',
      });
      return;
    }

    // console.log('Selected Product Nos for Hiding: ', productNos);

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
        <div>
          <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
            <div>숨김 처리</div>
            <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
          </div>
          <div class='${seller.seller_date_title} pb-3'>
            <div><span>*선택하신 ${selectedCount}개의 상품 중 처리 가능한 주문은 ${productNos.length}건입니다.</span></div>
            <div><span>${productNos.length}건에 대해 처리를 하시겠습니까?</span></div>
          </div>
          <div class='d-flex justify-content-center pt-3'>
            <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
            <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
          </div>
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    // '확인' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerHideStateChange);
    
    // '취소' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerHideStateCancel);
    
    // '확인' 버튼에 대한 이벤트 리스너 추가
    function sellerHideStateChange(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {

        stateChange('숨김', productNos);

        MySwal.fire({
          customClass: {
            popup: `${seller.seller_date_success_popup}`,
          },
          html: `
            <div>
              <div class='${seller.seller_date_success_header}'>
                <div class='${seller.seller_date_success_header_title}'><span>숨김 처리</span></div>
                <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
              </div>
              <div class='${seller.seller_date_success_title}'>
                <div><span>*선택하신 ${selectedCount}개의 주문 중 총 ${productNos.length}건에 대해서 처리가 완료되었습니다.</span></div>
              </div>
              <div class='${seller.seller_date_success_btn}'>
                <button id='seller_swal_success'>확인</button>
              </div>
            </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });

        document.addEventListener('click', sellerHideStateSuccess);

        function sellerHideStateSuccess(e) {
          if (e.target && e.target.id == 'seller_swal_success') {
            MySwal.close();
            document.removeEventListener('click', sellerHideStateChange);
            document.removeEventListener('click', sellerHideStateCancel);
            document.removeEventListener('click', sellerHideStateSuccess);
          }
        }
      }
    }

    // '취소' 버튼에 대한 이벤트 리스너 추가
    function sellerHideStateCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
      }
    }
  };

  // 복제
  const onCopy = () => {
    const selectedProductNos = getSelectedProductNos();

    if (selectedProductNos.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
          <div>
              <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                  <div>복제</div>
                  <div class=${seller.seller_date_close}><button id="swal-close-button"><span class="material-symbols-outlined">close</span></button></div>
              </div>
              <div class='${seller.seller_date_title} pb-3'>
                <div><span>*선택하신 ${selectedProductNos.length}개의 주문 중 처리 가능한 주문은 ${selectedProductNos.length}건입니다.</span></div>
                <div><span>*복제 시 "상품 등록" 상태로 복제되며, 승인 완료된 상품을 복제하는 경우 판매를 위해 "승인 요청"을 통해 관리자의 재승인이 필요합니다.</span></div>
              </div>
              <div class='d-flex justify-content-center pt-3'>
              <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
              <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
              </div>
          </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    document.addEventListener('click', sellerCopyConfirm);
    document.addEventListener('click', sellerCopyCancel);

    // '확인' 버튼에 대한 이벤트 리스너 추가
    async function sellerCopyConfirm(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {

        const response = await sellerapi.copyProducts(selectedProductNos);
        const data = response.data;

        // console.log(data);

        if(response.status === 200) {
          MySwal.fire({
            customClass: {
              popup: `${seller.seller_date_success_popup}`,
            },
            html: `
                        <div>
                            <div class='${seller.seller_date_success_header}'>
                                <div class='${seller.seller_date_success_header_title}'><span>복제</span></div>
                                <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
                            </div>
                            <div class='${seller.seller_date_success_title}'>
                              <div><span>*상품 복제 처리가 완료되었습니다.</span></div>
                            </div>
                            <div class='${seller.seller_date_success_btn}'>
                                <button id='seller_swal_success'>확인</button>
                            </div>
                        </div>
                    `,
            showConfirmButton: false,
            showCancelButton: false,
          });

          document.addEventListener('click', sellerCopySuccess);

          function sellerCopySuccess(e) {
            if (e.target && e.target.id == 'seller_swal_success') {
              MySwal.close();
              selectProduct();
              document.removeEventListener('click', sellerCopyConfirm);
              document.removeEventListener('click', sellerCopyCancel);
              document.removeEventListener('click', sellerCopySuccess);
            }
          }
        }
      }
    }

    function sellerCopyCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
        document.removeEventListener('click', sellerCopyConfirm);
        document.removeEventListener('click', sellerCopyCancel);
      }
    }
  };

  // 삭제
  const onDelete = async () => {
    const selectedProductNos = getSelectedProductNos();

    if (selectedProductNos.length === 0) {
      MySwal.fire({
        icon: 'warning',
        title: '선택된 상품이 없습니다.',
        text: '상품을 선택해주세요.',
      });
      return;
    }

    MySwal.fire({
      customClass: {
        popup: `${seller.seller_product_popup}`,
        htmlContainer: `${seller.seller_date_html_container}`,
      },
      html: `
          <div>
              <div class='d-flex justify-content-center align-items-center position-relative pb-5 pt-2'>
                  <div>삭제</div>
                  <div class=${seller.seller_date_close}><button id="swal_close_button"><span class="material-symbols-outlined">close</span></button></div>
              </div>
              <div class='${seller.seller_date_title} pb-3'>
                <div><span>*선택하신 ${selectedProductNos.length}개의 상품을 삭제하시겠습니까?</span></div>
              </div>
              <div class='d-flex justify-content-center pt-3'>
              <div><button class='${seller.seller_date_cancel}' id="custom-cancel-button">취소</button></div>
              <div><button class='${seller.seller_date_confirm}' id="custom-confirm-button">확인</button></div>
              </div>
          </div>
      `,
      showConfirmButton: false,
      showCancelButton: false,
      cancelButtonText: '취소',
    });

    // '확인' 버튼에 대한 이벤트 리스너 추가
    document.addEventListener('click', sellerDeleteConfirm);
    document.addEventListener('click', sellerDeleteCancel);

    // '확인' 버튼에 대한 이벤트 리스너 추가
    async function sellerDeleteConfirm(e) {
      if (e.target && e.target.id == 'custom-confirm-button') {
        await sellerapi.deleteProducts(selectedProductNos, userInfo.userId);
  
        MySwal.fire({
          customClass: {
            popup: `${seller.seller_date_success_popup}`,
          },
          html: `
                      <div>
                          <div class='${seller.seller_date_success_header}'>
                              <div class='${seller.seller_date_success_header_title}'><span>삭제 완료</span></div>
                              <div class='${seller.seller_date_success_header_btn}'><button><span class="material-symbols-outlined" id='seller_swal_success_close'>close</span></button></div>
                          </div>
                          <div class='${seller.seller_date_success_title}'>
                            <div><span>*선택하신 ${selectedProductNos.length}개의 상품이 삭제되었습니다.</span></div>
                          </div>
                          <div class='${seller.seller_date_success_btn}'>
                              <button id='seller_swal_success'>확인</button>
                          </div>
                      </div>
                  `,
          showConfirmButton: false,
          showCancelButton: false,
        });
  
        document.addEventListener('click', function (e) {
          if (e.target && e.target.id == 'seller_swal_success') {
            MySwal.close();
            selectProduct();
            document.removeEventListener('click', sellerDeleteConfirm);
            document.removeEventListener('click', sellerDeleteCancel);
          }
        });
        
        document.addEventListener('click', function (e) {
          if (e.target && e.target.id == 'swal_close_button') {
            MySwal.close();
            document.removeEventListener('click', sellerDeleteConfirm);
            document.removeEventListener('click', sellerDeleteCancel);
          }
        });
      }
    }
    
    function sellerDeleteCancel(e) {
      if (e.target && e.target.id == 'custom-cancel-button') {
        MySwal.close();
        document.removeEventListener('click', sellerDeleteConfirm);
        document.removeEventListener('click', sellerDeleteCancel);
      }
    }
  };

  const [detailProduct, setDetailProduct] = useState([]);
  const [showDetail, setShowDetail] = useState(false);

  const onProductClick = async (no) => {
    // console.log('No : ', no);
    const response = await sellerapi.detailProductSelect(no);
    const data = response.data;

    // console.log(data);
    setDetailProduct(data);
    setShowDetail(true);
  };

  const onProductUpdate = () => {
      MySwal.fire({
          customClass: {
              popup: `${seller.admin_seller_add_popup}`
          },
          html : 
          `
              <div>
                  <div class='${seller.seller_seller_cancel_title} py-2'><span>상품을 수정하시겠습니까?</span></div>
                  <div class='${seller.seller_seller_cancel_sub_title} py-2'><span>수정 전 내용으로 돌아갈 수 없으며,</span><br/><span>관리자의 재승인이 필요합니다.</span></div>
                  <div class='${seller.seller_seller_cancel_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_update_cancel'>취소</button>
                      <button class='mx-1' id='seller_update_success'>확인</button>
                  </div>
              </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
      });
      document.addEventListener('click', sellerAddSuccess);
      document.addEventListener('click', sellerAddCancel);
      
      function sellerAddSuccess(e) {
          if(e.target && e.target.id == 'seller_update_success') {
              productUpdate();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
              MySwal.close();
          }
      }

      async function productUpdate() {
          const productData = {
            ...productDetailList,
            faq: productDetailList.faq.join(",")
          };

          const response = await sellerapi.productUpdate(productData, userInfo.userId);
          const data = response.data;

          // console.log(data);

          if(data === 'SUCCESS') {
              MySwal.fire({
                  customClass: {
                      popup: `${seller.seller_update_popup}`
                  },
                  html : 
                  `
                  <div>
                  <div class='${seller.seller_update_title}'><span>상품 수정이 완료 되었습니다.</span></div>
                  <div class='${seller.seller_update_sub_title}'><span>승인 완료 후 자동으로 노출됩니다.</span></div>
                  <div class='${seller.seller_confirm_btn_box} py-2 d-flex w-100 justify-content-center'>
                      <button class='mx-1' id='seller_add_confirm'>확인</button>
                  </div>
                  </div>
                  `,
                  showConfirmButton: false,
                  showCancelButton: false,
              });
              document.addEventListener('click', sellerAddConfirm);
              document.removeEventListener('click', sellerAddSuccess);
              
              function sellerAddConfirm(e) {
                  if(e.target && e.target.id == 'seller_add_confirm') {
                      selectProduct();
                      setShowDetail(false);
                      MySwal.close();
                      document.removeEventListener('click', sellerAddConfirm);
                  }
              }
          }
      }
      
      function sellerAddCancel(e) {
          if(e.target && e.target.id == 'seller_update_cancel') {
              MySwal.close();
              document.removeEventListener('click', sellerAddSuccess);
              document.removeEventListener('click', sellerAddCancel);
          }
      }
  }

  return (
    <div className={`${seller.seller_dashboard_container} py-3 px-3`}>
      <div>
        <div className='pb-3 w-100 border-bottom fs-5'>
          <span>상품 관리</span>
        </div>
      </div>
      <div>
        <div className={`${seller.seller_dashboard_tab_btn} pt-4 pb-3`}>
          <div className={`w-100 ${activeButton == 'entire' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('entire')}>전체 ({counts.entire})</button>
          </div>
          <div className={`w-100 ${activeButton == 'request' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('request')}>승인요청 ({counts.request})</button>
          </div>
          <div className={`w-100 ${activeButton == 'approved' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('approved')}>승인완료 ({counts.approved})</button>
          </div>
          <div className={`w-100 ${activeButton == 'denied' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('denied')}>승인거절 ({counts.denied})</button>
          </div>
          <div className={`w-100 ${activeButton == 'sale' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('sale')}>판매중 ({counts.sale})</button>
          </div>
          <div className={`w-100 ${activeButton == 'soldout' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('soldout')}>품절 ({counts.soldout})</button>
          </div>
          <div className={`w-100 ${activeButton == 'hiding' ? seller.seller_dashboard_tab_active : ''}`}>
            <button onClick={() => filterProducts('hiding')}>숨김 ({counts.hiding})</button>
          </div>
        </div>
      </div>

      {/* 상단 버튼 */}
      <div className={`py-3 ${seller.seller_product_state_box}`}>
        <div className={`${seller.seller_product_state}`}>
          <div className={`${seller.seller_product_state_change_btn}`} onClick={() => setShowStateMenu(!showStateMenu)}><span className="material-symbols-outlined">grid_view</span><span>상태변경</span><span className="material-symbols-outlined">{showStateMenu ? 'arrow_drop_up' : 'arrow_drop_down'}</span></div>
          <CSSTransition in={showStateMenu} timeout={300} classNames="review" unmountOnExit>
            <div className={`${seller.seller_product_state_width}`}>
              <div>
                <button className={`${seller.seller_product_state_btn}`} onClick={() => onRequest()}>
                  승인요청
                </button>
              </div>
              <div>
                <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onSales()}>
                  판매중 처리
                </button>
              </div>
              <div>
                <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onSoldOut()}>
                  품절 처리
                </button>
              </div>
              <div>
                <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onHiding()}>
                  숨김 처리
                </button>
              </div>
              {checkedItems.some((item) => item) && (
                <div>
                  <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onCopy()}>
                    복제
                  </button>
                </div>
              )}
              {checkedItems.some((item) => item) && (
                <div>
                  <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onDelete()}>
                    삭제
                  </button>
                </div>
              )}
            </div>
          </CSSTransition>
          <div className={`${seller.seller_product_state_desktop}`}>
            <div>
              <button className={`${seller.seller_product_state_btn}`} onClick={() => onRequest()}>
                승인요청
              </button>
            </div>
            <div>
              <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onSales()}>
                판매중 처리
              </button>
            </div>
            <div>
              <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onSoldOut()}>
                품절 처리
              </button>
            </div>
            <div>
              <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onHiding()}>
                숨김 처리
              </button>
            </div>
            {checkedItems.some((item) => item) && (
              <div>
                <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onCopy()}>
                  복제
                </button>
              </div>
            )}
            {checkedItems.some((item) => item) && (
              <div>
                <button className={`${seller.seller_product_state_btn} ms-2`} onClick={() => onDelete()}>
                  삭제
                </button>
              </div>
            )}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div>
            <button className={`d-flex align-items-center ${seller.seller_product_export_btn} py-1 px-2`}>
              <span>내보내기</span>
              <span className='material-symbols-outlined ps-1'>download</span>
            </button>
          </div>
          <div className='position-relative ps-2'>
            <input 
                className={`${seller.seller_product_search_input} py-1`} 
                placeholder="상품명을 검색하세요"
                type='text' 
                value={searchTerm}
                onChange={handleSearch}
            />
            <span className={`material-symbols-outlined ${seller.seller_product_search_icon}`}>search</span>
          </div>
        </div>
      </div>

      {/* 상단 메뉴 */}
      <div className={`py-2 ${seller.seller_product_item_header}`}>
        <div className={`${seller.seller_product_item_one}`}>
          <input type='checkbox' onChange={handleSelectAll} />
        </div>
        <div className={`${seller.seller_product_item_two}`}>
          <span>번호</span>
        </div>
        <div className={`${seller.seller_product_item_three}`}>
          <span>등록 상태</span>
        </div>
        <div className={`${seller.seller_product_item_four}`}>
          <span>등록일</span>
        </div>
        <div className={`${seller.seller_product_item_five}`}>
          <span>등록 상품 정보</span>
        </div>
        <div className={`${seller.seller_product_item_six}`}>
          <span>카테고리</span>
        </div>
        <div className={`${seller.seller_product_item_seven}`}>
          <span>-</span>
        </div>
        <div className={`${seller.seller_product_item_eight}`}>
          <span>판매 상태</span>
        </div>
      </div>
      <div>
        {getPaginatedData().length ? (
          getPaginatedData().map((item, index) => (
            <div className={`${seller.seller_product_item_content}`} key={index} onClick={() => onProductClick(item.productNumber)}>
              <div className={`${seller.seller_product_item_one}`}>
                <input
                  type='checkbox'
                  checked={checkedItems[index]}
                  onChange={(e) => {handleCheckboxChange(index, e)}}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <div className={`${seller.seller_product_item_two}`}>
                <span>{index + 1}</span>
              </div>
              <div className={`${seller.seller_product_item_three}`}>
                <span>{item.supportState}</span>
              </div>
              <div className={`${seller.seller_product_item_four}`}>
                <span>{formatDate(item.supportRegDate)}</span>
              </div>
              <div className={`${seller.seller_product_item_five}`}>
                <div className={`${seller.seller_product_item_content_info_box} px-2`}>
                  <div>
                    <img src={item.representativeImage} alt='' />
                  </div>
                  <div className='text-start px-2'>
                    <div>
                      <span>{item.productName}</span>
                    </div>
                    <div>
                      <span>상품 {item.mainItem.length}종</span>
                    </div>
                    <div>
                      <span>&#8361;{item.mainItem.length !== 0 ? getLowestMainItemPrice(item.mainItem).toLocaleString() : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${seller.seller_product_item_six}`}>
                <span>{categoryMapping[item.middleCategory] || item.middleCategory}</span>
              </div>
              <div className={`${seller.seller_product_item_seven}`}>
                <span>-</span>
              </div>
              <div className={`${seller.seller_product_item_eight}`}>
                <span>{item.supportSaleState}</span>
              </div>
            </div>
          ))
        ) : (
          <div className='w-100 text-center py-5'>
            <div>
              <span>등록된 내역이 없습니다.</span>
            </div>
          </div>
        )}
      </div>
      
      <Pagination
            current={currentPage}
            total={totalItems}
            onChange={handlePageChange}
            pageSize={itemsPerPage}
      />

      {
        showDetail && <SellerProductDetail seller={seller} sellerapi={sellerapi} userInfo={userInfo} detailProduct={detailProduct} setShowDetail={setShowDetail} productDetailList={productDetailList} setProductDetailList={setProductDetailList} onProductUpdate={onProductUpdate} 
                        productInfo={productInfo} setProductInfo={setProductInfo} charCount={charCount} setCharCount={setCharCount} charLimit={charLimit}
                        showFaqModal={showFaqModal} setShowFaqModal={setShowFaqModal} faqList={faqList}
                        setFaqList={setFaqList} selectedFaqs={selectedFaqs} setSelectedFaqs={setSelectedFaqs} showFaqAdd={showFaqAdd} setShowFaqAdd={setShowFaqAdd}
                        faqListSelect={faqListSelect} sellerFaqAdd={sellerFaqAdd} handleDetaoleCheckboxChange={handleDetaoleCheckboxChange} handleFaqSelect={handleFaqSelect} handleRemoveFaq={handleRemoveFaq}
                      />
      }
    </div>
  );
};

export default SellerProductMain;
