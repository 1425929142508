import React, { useContext, useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TextEditor from '../store/TextEditor';
import seller from '../../../../seller.module.css';
import admin from '../../../../../admin/admin.module.css';
import * as support from '../../../../../apis/support';
import * as sellerapi from '../../../../../apis/seller';
import { LoginContext } from '../../../../../contexts/LoginContextProvider';

const SellerStoreMain = () => {
  const { isLogin, sellerlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
  const MySwal = withReactContent(Swal);
  const [ceoSay, setCeoSay] = useState('');
  const [update, setUpdate] = useState(false);
  const [updateSave, setUpdateSave] = useState(false);
  const [newData, setNewData] = useState(false);
  const [ shopInfo, setShopInfo ] = useState('');
  const [ showBankSelect, setShowBankSelect ] = useState(false);
  const [ selectBank, setSelectBank ] = useState({bankImg : '', bankName : ''});
  const [ activeBankStock, setActiveBankStock ] = useState('bank');
  const [ bankList, setBankList ] = useState([]);
  const [ stockList, setStockList ] = useState([]);

  const [ userImg1, setUserImg1 ] = useState('');
  const [ userImg2, setUserImg2 ] = useState('');

  // 에디터 내용 담을 변수
  const [data, setData] = useState("");
  const [fileName, setFileName] = useState('');

  let message = '내용을 입력하세요.';

  useEffect(() => {
    setBankList([
      {bankImg : '../../img/bank/kb.jpg', bankName : '국민은행'},{bankImg : '../../img/bank/ibk.jpg', bankName : '기업은행'},{bankImg : '../../img/bank/nh.jpg', bankName : '농협은행'},
      {bankImg : '../../img/bank/shinhan.jpg', bankName : '신한은행'},{bankImg : '../../img/bank/koreapost.jpg', bankName : '우체국'},{bankImg : '../../img/bank/sc.jpg', bankName : 'SC제일은행'},
      {bankImg : '../../img/bank/hana.jpg', bankName : '하나은행'},{bankImg : '../../img/bank/citi.jpg', bankName : '한국씨티은행'},{bankImg : '../../img/bank/woori.jpg', bankName : '우리은행'},
      {bankImg : '../../img/bank/bnk.jpg', bankName : '경남은행'},{bankImg : '../../img/bank/kjb.jpg', bankName : '광주은행'},{bankImg : '../../img/bank/dgb.jpg', bankName : '대구은행'},
      {bankImg : '../../img/bank/deutsche.jpg', bankName : '도이치은행'},{bankImg : '../../img/bank/bnk.jpg', bankName : '부산은행'},{bankImg : '../../img/bank/kdb.jpg', bankName : '산업은행'},
      {bankImg : '../../img/bank/suhyup.jpg', bankName : '수협중앙회'},{bankImg : '../../img/bank/kjb.jpg', bankName : '전북은행'},{bankImg : '../../img/bank/shinhan.jpg', bankName : '제주은행'},
      {bankImg : '../../img/bank/mg.jpg', bankName : '새마을금고'},{bankImg : '../../img/bank/shinhyup.jpg', bankName : '신용협동조합(신협)'},{bankImg : '../../img/bank/hsbc.jpg', bankName : 'HSBC은행'},
      {bankImg : '../../img/bank/sb.jpg', bankName : '저축은행'},{bankImg : '../../img/bank/boa.jpg', bankName : 'BOA은행'},{bankImg : '../../img/bank/jd.jpg', bankName : 'JP모간체이스은행'},
      {bankImg : '../../img/bank/bnp.jpg', bankName : 'BNP파리바은행'},{bankImg : '../../img/bank/ccb.jpg', bankName : '중국건설은행'},{bankImg : '../../img/bank/sj.jpg', bankName : '산림조합'},
      {bankImg : '../../img/bank/icbc.jpg', bankName : '중국공상은행'},{bankImg : '../../img/bank/nh.jpg', bankName : '지역농축협'},{bankImg : '../../img/bank/kakaobank.jpg', bankName : '카카오뱅크'},
      {bankImg : '../../img/bank/toss.jpg', bankName : '토스뱅크'},{bankImg : '../../img/bank/kbank.jpg', bankName : '케이뱅크'}
    ]);
    setStockList([
      {bankImg : '../../img/bank/nh.jpg', bankName : 'NH투자증권'},{bankImg : '../../img/bank/yuanta.jpg', bankName : '유안타증권'},{bankImg : '../../img/bank/kb.jpg', bankName : 'KB증권'},
      {bankImg : '../../img/bank/mirae.jpg', bankName : '미래에셋대우'},{bankImg : '../../img/bank/samsung.jpg', bankName : '삼성증권'},{bankImg : '../../img/bank/truetriend.jpg', bankName : '한국투자증권'},
      {bankImg : '../../img/bank/kyonbo.jpg', bankName : '교보증권'},{bankImg : '../../img/bank/hi.jpg', bankName : '하이투자증권'},{bankImg : '../../img/bank/hyundai.jpg', bankName : '현대차증권'},
      {bankImg : '../../img/bank/sk.jpg', bankName : 'SK증권'},{bankImg : '../../img/bank/hanhwa.jpg', bankName : '한화투자증권'},{bankImg : '../../img/bank/hana.jpg', bankName : '하나금융투자'},
      {bankImg : '../../img/bank/shinhan.jpg', bankName : '신한금융투자'},{bankImg : '../../img/bank/eugene.jpg', bankName : '유진투자증권'},{bankImg : '../../img/bank/meritz.jpg', bankName : '메리츠종합금융증권'},
      {bankImg : '../../img/bank/si.jpg', bankName : '신영증권'},{bankImg : '../../img/bank/ebest.jpg', bankName : '이베스트투자증권'},{bankImg : '../../img/bank/cape.jpg', bankName : '케이프투자증권'},
      {bankImg : '../../img/bank/buguk.jpg', bankName : '부국증권'},{bankImg : '../../img/bank/kiwoom.jpg', bankName : '키움증권'},{bankImg : '../../img/bank/daishin.jpg', bankName : '대신증권'},
      {bankImg : '../../img/bank/db.jpg', bankName : 'DB금융투자'},{bankImg : '../../img/bank/ktb.jpg', bankName : 'KTB투자증권'},{bankImg : '../../img/bank/fundonline.jpg', bankName : '한국포스증권'},
      {bankImg : '../../img/bank/toss.jpg', bankName : '토스증권'}
    ]);
  }, []);

  useEffect(() => {
    if(userInfo) {
      shopSelect();
    }
  }, [userInfo])

  const [ shopInfoList, setShopInfoList ] = useState(
    {
      userNo: '', userId: `${userInfo && userInfo?.userId}`, shopName : '', shopDetailContent : '', 
      shopProfileImg: ''
    }
  );

  useEffect(() => {
    // console.log(shopInfoList);
  },[shopInfoList])

  const shopSelect = async () => {
    const response = await sellerapi.shopSelect(userInfo?.userId);
    const data = response.data;
    
    if(data == '') {
      setNewData(true);
      setUpdate(true);
      setUpdateSave(true);
    } else {
      setUpdate(true);
      const shopNo = response.data?.shopNo;
      const shopName = response.data?.shopName;
      const shopDetailContent = response.data?.shopDetailContent;
      const shopProfileImg = response.data?.shopProfileImg;
      const shopBackImg = response.data?.shopBackImg;
      const shopBankImg = response.data?.shopBankImg;
      const shopBankName = response.data?.shopBankName;
      const shopAccountNumber = response.data?.shopAccountNumber;
      const shopDepositorName = response.data?.shopDepositorName;

      // console.log("shopName : " + shopName);
      // console.log("shopDetailContent : " + shopDetailContent);
      // console.log("shopProfileImg : " + shopProfileImg);
      // console.log("shopBackImg : " + shopBackImg);

      setShopInfoList(
        {
          shopNo: shopNo, userId: `${userInfo && userInfo?.userId}`, shopName : shopName, 
          shopDetailContent : shopDetailContent, shopProfileImg: shopProfileImg, shopBackImg: shopBackImg,
          shopBankImg: shopBankImg, shopBankName: shopBankName, shopAccountNumber: shopAccountNumber, shopDepositorName: shopDepositorName
        }
      );
      setSelectBank({
        ...selectBank, bankImg: shopBankImg, bankName: shopBankName
      })

      setUserImg1(shopProfileImg);
      setUserImg2(shopBackImg);

      setShopInfo(shopDetailContent);
    }
  }

  const onShopName = (e) => {
    const value = e.target.value;
    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopName: value};
    });
  }
  const onCeoSay = (e) => {
    const { value } = e.target;
    if (value.length <= maxLength) {
      setCeoSay(value);
    }
    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopInfo: value};
    });
  }
  const maxLength = 200;


  const handleImageChange = async (e) => {

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: {
        'content-type' : 'multipart/form-data'
      }
    };

    const response = await support.uploadImageToServer(formData, config);
    const data = response.data;

    const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

    setUserImg1(imageUrl);

    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopProfileImg: imageUrl};
    });
  };

  const handleClick = () => {
    document.getElementById('hiddenShopProfileImg').click();
  };

  const handleDeleteClick = async () => {
    try {
      // 이미지 URL에서 쿼리 파라미터를 추출
      const queryParams = userImg1.split('?')[1];

      // 'file=' 파라미터 값을 얻기 위해 쿼리 파라미터를 파싱
      let filePath = '';
      const params = queryParams.split('&');
      for (let param of params) {
      if (param.startsWith('file=')) {
        filePath = decodeURIComponent(param.split('=')[1]);
        break;
      }
      }

      // 파일 경로에서 파일 이름만 추출
      const fileName = filePath.split('/').pop();
      // console.log('fileName : ' + fileName);
      // DELETE 메소드를 사용하여 서버에 삭제 요청
      await fetch(`/api/file/delete/${fileName}`, {
        method: 'DELETE',
      });
  
      // 삭제 성공 시 previousImages에서도 제거
      setUserImg1('');

      setShopInfoList(shopInfoList => {
        return {...shopInfoList, shopProfileImg: ''};
      });

    } catch (error) {
      console.error('Failed to delete image from server', error);
    }
  }
  const handleImageChange2 = async (e) => {

    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('image', file);

    const config = {
      headers: {
        'content-type' : 'multipart/form-data'
      }
    };

    const response = await support.uploadImageToServer(formData, config);
    const data = response.data;

    const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

    setUserImg2(imageUrl);

    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopBackImg: imageUrl};
    });
  };
const handleClick2 = () => {
    document.getElementById('hiddenShopProfileImg2').click();
  };
const handleDeleteClick2 = async () => {
    try {
      // 이미지 URL에서 쿼리 파라미터를 추출
      const queryParams = userImg2.split('?')[1];

      // 'file=' 파라미터 값을 얻기 위해 쿼리 파라미터를 파싱
      let filePath = '';
      const params = queryParams.split('&');
      for (let param of params) {
      if (param.startsWith('file=')) {
        filePath = decodeURIComponent(param.split('=')[1]);
        break;
      }
      }

      // 파일 경로에서 파일 이름만 추출
      const fileName = filePath.split('/').pop();
      // console.log('fileName : ' + fileName);
      // DELETE 메소드를 사용하여 서버에 삭제 요청
      await fetch(`/api/file/delete/${fileName}`, {
        method: 'DELETE',
      });
  
      // 삭제 성공 시 previousImages에서도 제거
      setUserImg2('');

      setShopInfoList(shopInfoList => {
        return {...shopInfoList, shopBackImg: ''};
      });

    } catch (error) {
      console.error('Failed to delete image from server', error);
    }
  }
  const onUpdateBtn = () => {
    setUpdate(false);
  }

  const onSaveBtn = async () => {
    try {
      const response = await sellerapi.shopSave(shopInfoList);
      const data = response.data;

      // // console.log(response);
      // console.log(data);

      if(data === 'SUCCESS') {
        MySwal.fire({
          customClass: {
              popup: `${seller.seller_date_save_popup}`
          },
          html : 
          `
              <div>
                  <div class='${seller.seller_date_save_title}'><span>프로필 저장 완료</span></div>
                  <div class='${seller.seller_date_save_content}'><span>프로필 저장이 완료되었습니다.</span></div>
                  <div class='${seller.seller_date_save_btn} py-2'><button id='seller_date_save'>확인</button></div>
              </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });
        document.addEventListener('click', sellerProfileSuccess);

        function sellerProfileSuccess(e) {
          if(e.target && e.target.id == 'seller_date_save') {
            MySwal.close();
            setUpdate(true);
          }
          document.removeEventListener('click', sellerProfileSuccess);
        }
          
      }

      if(data === 'UPDATE SUCCESS') {
        MySwal.fire({
          customClass: {
              popup: `${seller.seller_date_save_popup}`
          },
          html : 
          `
              <div>
                  <div class='${seller.seller_date_save_title}'><span>수정 완료</span></div>
                  <div class='${seller.seller_date_save_content}'><span>상점 정보 수정이 완료되었습니다. 상점명 변경은 관리자에게 문의해주세요.</span></div>
                  <div class='${seller.seller_date_save_btn} py-2'><button id='seller_date_save'>확인</button></div>
              </div>
          `,
          showConfirmButton: false,
          showCancelButton: false,
        });

        document.addEventListener('click', sellerProfileChangeSuccess);

        function sellerProfileChangeSuccess(e) {
          if(e.target && e.target.id == 'seller_date_save') {
            MySwal.close();
            setUpdate(true);
          }
          document.removeEventListener('click', sellerProfileChangeSuccess);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [btnState, setBtnState] = useState(false);

  useEffect(() => {

    if(shopInfo && 
      shopInfoList.shopName && shopInfoList.shopProfileImg) {
        setBtnState(true);
      } else {
        setBtnState(false);
      }
  }, [shopInfoList]);

  const onNewData = () => {
    setUpdate(false);
  }



  const onShopInfo = (e) => {
    // console.log(e.target.value);

    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopDetailContent: e.target.value};
    });

    setShopInfo(e.target.value);
  }

  const [ detailState, setDetailState ] = useState(false);


  const onBankSelect = (item) => {
    // console.log('item : ', item);
    setSelectBank({
      ...selectBank, ...item
    });
    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopBankImg: item.bankImg, shopBankName: item.bankName};
    });
    setShowBankSelect(false);
  }

  const onBankAccount = (e) => {
    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopAccountNumber: e.target.value};
    });
  }

  const onDepositerName = (e) => {
    setShopInfoList(shopInfoList => {
      return {...shopInfoList, shopDepositorName: e.target.value};
    });
  }
  return (
    <div className={`px-3 ${seller.seller_right_container}`}>
      <div>
        <div className={`pt-3 pb-2 border-bottom ${seller.seller_shop_main_title}`}><span>프로필</span></div>
        <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>상점 정보 등록</span></div>
        <div className={`${seller.seller_shop_main_content}`}>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>썸네일 이미지 등록</span></div>
            <div className={`${seller.seller_settlement_middle} px-3`}>
              <div className={`${seller.seller_settlement_store_profile_img} position-relative`}>
                {
                  userImg1 == '' ? 
                  <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                    <img src="../../img/seller/icon/default2.png" alt="이미지" />
                  </div>
                  :
                  <img src={userImg1} alt="상점 프로필" /> 
                }
              </div>
              <div className='pt-2'>
                  {/* 실제 파일 입력 필드는 숨김 처리 */}
                  <input type="file" onChange={(e) => handleImageChange(e)} id='hiddenShopProfileImg' style={{display: 'none'}} />
                  {/* 사용자에게 보여지는 버튼. 클릭 시 파일 입력 필드를 트리거함 */}
                  
                  {
                    !update &&
                    <>
                      <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={() => handleClick()} disabled={update}>등록</button>
                      <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={handleDeleteClick} disabled={update}>삭제</button>
                    </>
                  }
              </div>
            </div>
            <div className={`${seller.seller_settlement_right}`}>
              <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
              <div><span>*이미지 용량: 1MB 이하</span></div>
            </div>
          </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
              <div className={`${seller.seller_settlement_left}`}><span>상점 배경 사진 등록</span></div>
              <div className={`${seller.seller_settlement_middle} px-3`}>
                <div className={`${admin.admin_store_setting_back_img} position-relative`}>
                  {
                    userImg2 == '' ? 
                    <div className={`position-absolute ${seller.seller_settlement_default_img}`}>
                      <img src="../../img/seller/icon/default2.png" alt="이미지" />
                    </div>
                    :
                    <img src={userImg2} alt="상점 프로필" /> 
                  }
                </div>
                <div className='pt-2'>
                    {/* 실제 파일 입력 필드는 숨김 처리 */}
                    <input type="file" onChange={(e) => handleImageChange2(e)} id='hiddenShopProfileImg2' style={{display: 'none'}} />
                    {/* 사용자에게 보여지는 버튼. 클릭 시 파일 입력 필드를 트리거함 */}
                    {
                      !update &&
                      <>
                        <button className='bg-body py-1 px-3 me-1 rounded border border-1' onClick={() => handleClick2()} disabled={update}>등록</button>
                        <button className='bg-body py-1 px-3 ms-1 rounded border border-1' onClick={handleDeleteClick2} disabled={update}>삭제</button>
                      </>
                    }
                </div>
              </div>
              <div className={`${seller.seller_settlement_right}`}>
                <div><span>*업로드 가능한 파일 확장자: jpg, png, heic</span></div>
                <div><span>*이미지 용량: 1MB 이하</span></div>
              </div>
            </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점명</span></div>
              <div className={`${seller.seller_settlement_middle} px-3`}>
                {
                  updateSave ?
                  <input type="text" placeholder={shopInfoList.shopName != '' ? shopInfoList.shopName : '상점명을 입력하세요.'} onChange={(e) => onShopName(e)} disabled={update}/>
                  :
                  <input type="text" placeholder={shopInfoList.shopName != '' ? shopInfoList.shopName : '상점명을 입력하세요.'} onChange={(e) => onShopName(e)} disabled={true}/>
                }
              </div>
            <div className={`${seller.seller_settlement_right}`}><span>*상점명은 최대 10자까지 입력 가능합니다.</span></div>
          </div>
          <div className='d-flex w-100 border-bottom align-items-center py-3'>
            <div className={`${seller.seller_settlement_left}`}><span>상점소개</span></div>
              <div className={`${seller.seller_settlement_middle} px-3`}>
                <textarea name="" id="" onChange={(e) => onShopInfo(e)} disabled={update} placeholder={shopInfo ? shopInfo : '상점소개를 입력해주세요'}></textarea>
              </div>
            <div className={`${seller.seller_settlement_right}`}><span>*상점소개는 최대 500자까지 입력 가능합니다.</span></div>
          </div>
          {/* <div>
            <div className={`pt-5 pb-1 ${seller.seller_border_bottom_color} d-flex justify-content-between`}>
              <div>
                <span>대표 이미지 등록</span>
              </div>
              <div>
                <span>펼치기</span>
                <span><span className="material-symbols-outlined">expand_more</span></span>
              </div>
            </div>

            <div className='d-flex align-items-center py-3'>
              <div className={`${seller.seller_edit_img_left} text-end pe-4`}><button className={`${seller.seller_edit_imgadd_reset_btn}`} onClick={() => totalImgDelete()} disabled={update}>이미지 등록 초기화</button></div>
              <div className={`${seller.seller_edit_img_right}`}>
                <div>
                  <span>*업로드 가능한 파일 확장자 : jpg, gif, png</span>
                </div>
                <div>
                  <span>*이미지 용량: 5MB 이하</span>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* 제품 상세 */}
        {/* <div>
          <div className={`pt-4 pb-1 ${seller.seller_border_bottom_color}`}><span>제품 상세</span></div>
          <div className='py-3'>
            <div><span>상세 내용</span></div>
            <div>
              {
                update ? 
                <div className='position-relative'>
                  <div className={`${seller.seller_shop_detail_preview} ${detailState ? seller.seller_shop_detail_height_auto : ''}`} dangerouslySetInnerHTML={{ __html: data }}></div>
                  <div className={`${seller.seller_shop_detail_btn}`}><button onClick={() => onDetailBtn()}><span className="material-symbols-outlined">{detailState ? 'expand_less' : 'expand_more'}</span>{detailState ? '접기' : '더보기'}</button></div>
                </div>
                :
                <div className='position-relative'>
                  <TextEditor setData={setData} seller={seller} fileName={fileName} setFileName={setFileName} onDetailContent={onDetailContent} update={update} data={data}/>
                </div>
              }
            </div>
          </div>
        </div> */}
        <div className='d-flex w-100 border-bottom align-items-center py-3'>
              <div className={`${seller.seller_settlement_left}`}><span>무통장</span></div>
              <div className={`${seller.seller_settlement_middle} px-3`}>
                <div>
                  <div><span>은행</span></div>
                  <div>
                    <button onClick={() => setShowBankSelect(true)} disabled={update} className={`${seller.seller_shop_setting_bank_select_btn} ${selectBank.bankImg === '' && selectBank.bankName === '' ? seller.seller_bank_no_data : seller.seller_bank_data}`}>
                      {
                        selectBank.bankImg === '' && selectBank.bankName === '' ?
                        <div>
                          <span>은행을 선택해주세요.</span>
                        </div>
                        :
                        <div>
                          <div><img src={selectBank.bankImg} alt="은행이미지" /></div>
                          <div><span>{selectBank.bankName}</span></div>
                        </div>
                      }
                      
                    </button>
                  </div>
                </div>
                <div>
                  <div><span>계좌번호</span></div>
                  <div><input type="text" value={shopInfoList.shopAccountNumber !== '' ? shopInfoList.shopAccountNumber : ''} onChange={(e) => onBankAccount(e)} disabled={update}/></div>
                </div>
                <div>
                  <div><span>예금주명</span></div>
                  <div><input type="text" value={shopInfoList.shopDepositorName !== '' ? shopInfoList.shopDepositorName : ''} onChange={(e) => onDepositerName(e)} disabled={update}/></div>
                </div>
              </div>
              <div className={`${seller.seller_settlement_right}`}><span>*교환 처리시 구매자 귀책 사유로 인한 배송비를 입금받을 계좌입니다.</span></div>
            </div>
        <div className='w-100 d-flex justify-content-center align-items-center py-5'>
          <div className={`${seller.seller_settlement_save_btn}`}>
            {
              newData ?
              <div>
                {
                  update ?
                  <button className={`${seller.seller_shop_savebtn}`} onClick={onNewData}>등록하기</button>
                  :
                  <button onClick={onSaveBtn} disabled={!btnState} className={`${btnState ? seller.seller_shop_savebtn : ''}`} >저장하기</button>
                }
              </div>  
              :
              <div>
                {
                  update ?
                  <button onClick={onUpdateBtn} className={`${seller.seller_shop_savebtn}`}>수정하기</button>
                  :
                  <div>
                    <button className='me-1' onClick={()=>setUpdate(true)}>취소하기</button>
                    <button onClick={onSaveBtn} disabled={!btnState} className={`${btnState ? seller.seller_shop_savebtn : ''}`} >저장하기</button>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>

      {
        showBankSelect &&
        <div className={`${seller.seller_bank_select_modal_container}`}>
          <div className={`${seller.seller_bank_select_modal_box}`}>
            <div className={`${seller.seller_bank_select_modal_header}`}>
              <div><span>은행선택</span></div>
              <div><button onClick={() => setShowBankSelect(false)}><span className="material-symbols-outlined">close</span></button></div>
            </div>
            <div className={`${seller.seller_bank_select_modal_tab}`}>
              <div><button className={activeBankStock === 'bank' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('bank')}>은행</button></div>
              <div><button className={activeBankStock === 'stock' ? seller.bank_select_active : ''} onClick={() => setActiveBankStock('stock')}>증권</button></div>
            </div>
            <div className={`${seller.seller_bank_select_modal_content} `}>
              {
                activeBankStock === 'bank' &&
                <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                  {
                    bankList.map((item, index) => (
                      <button key={index} onClick={() => onBankSelect(item)}>
                        <div><img src={item.bankImg} alt="은행이미지" /></div>
                        <div><span>{item.bankName}</span></div>
                      </button>
                    ))
                  }
                </div>
              }
              {
                activeBankStock === 'stock' &&
                <div className={`${seller.seller_bank_select_modal_bank_box} `}>
                  {
                    stockList.map((item, index) => (
                      <button key={index} onClick={() => onBankSelect(item)}>
                        <div><img src={item.bankImg} alt="은행이미지" /></div>
                        <div><span>{item.bankName}</span></div>
                      </button>
                    ))
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SellerStoreMain