import React, { useContext, useEffect, useState } from 'react';
import cart from './cart.module.css';
import store from '../../containers/market/store/store.module.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import * as storeapi from '../../apis/store';
import * as support from '../../apis/support';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import DatePicker from 'react-datepicker';
import Country from '../join/Country';
import PostModal from './store/PostModal';
import admin from '../../admin/admin.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddressCountry from '../../containers/my/AddressCountry';
import dailyduck from '../../containers/my/MyContainner.module.css';

const MarketCart = () => {
    const { isLogin, sellerlogout, userInfo, loginCheck, roles, isUserInfoLoaded } = useContext(LoginContext);
    const context = useContext(LoginContext);
const MySwal = withReactContent(Swal);
    const countries = context.countries;
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [cartList, setCartList] = useState([]);
    const [supportCartList, setSupportCartList] = useState([]);
    const [viewMode, setViewMode] = useState('all');
    const [expandedItems, setExpandedItems] = useState({}); // 확장 상태를 저장하는 객체
    const [selectedRange, setSelectedRange] = useState({}); // 선택된 날짜 범위를 저장
    const [holidays, setHolidays] = useState([]);
    const [temporaryDays, setTemporaryDays] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [userDeliveryList, setUserDeliveryList] = useState([]);
    const [selectDelivery, setSelectDelivery] = useState(null);
    const [showDeliveryChange, setShowDeliveryChange] = useState(false);
    const [showDeliveryUpdate, setShowDeliveryUpdate] = useState(false);
    const [updateDeliveryInfo, setUpdateDeliveryInfo] = useState(null);
    const [showDeliveryAdd, setShowDeliveryAdd] = useState(false);
    const [addDeliveryInfo, setAddDeliveryInfo] = useState(null);
    const [showCountry, setShowCountry] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [addressCheck, setAddressCheck] = useState(false);
    const [address, setAddress] = useState('');
    const [detailAddress, setDetailAddress] = useState();
    const [zoneCode, setZoneCode] = useState('');
    const [roadAddress, setroadAddress] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [sido, setSido] = useState('');
    const [itemCount, setItemCount] = useState(0);  // 아이템 수 상태 추가
    const [selectedCountry, setSelectedCountry] = useState({
        img: "../../../../img/icon/country/South_Korea_(KR).png",
        name: user && user.userRepublic ? user.userRepublic : "Republic of Korea",
        number: "+82"
    });

    const [showDeliveryOverseasAdd, setShowDeliveryOverseasAdd] = useState(false);
    const [showDeliveryBubbleSelect, setShowDeliveryBubbleSelect] = useState(false);
    const [ showInternationalCountry, setShowInternationalCountry ] = useState(false);
    const [activeIntenationalSearch, setActiveIntenationalSearch] = useState(false);
    const [intenationalZipCode, setIntenationalZipCode] = useState('');
    const [searchTermInternational, setSearchTermInternational] = useState('');    
    const [internationalSelectedCountry, setInternationalSelectedCountry] = useState({
        img:  "",
        name: "",
        number: "",
        code: ""
    });
    
    const [loading, setLoading] = useState(false);
    const [internationalAddressComponent, setInternationalAddressComponent] = useState({});
    const [showAddressCompl, setShowAddressCompl] = useState(false);
    const [globalAddressSearch, setGlobalAddressSearch] = useState(false);

    useEffect(() => {
        if (!isUserInfoLoaded && !userInfo) {
            alert('로그인 후 이용해주세요.');
            navigate('/');
        }
    }, [userInfo, isUserInfoLoaded]);


    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        // 페이지가 로드될 때 모든 아이템의 isSelected를 true로 설정
        setCartList(prevCartList => prevCartList.map(item => ({
            ...item,
            selectOption: item.selectOption
                ? item.selectOption.map(opt => ({ ...opt, isSelected: true })) // 각 옵션마다 isSelected를 true로 설정
                : item.selectOption, // 옵션이 없는 경우 그대로 유지
            isSelected: true  // 기본 아이템도 선택
        })));
        setSupportCartList(prevSupportCartList => prevSupportCartList.map(item => ({
            ...item,
            isSelected: true // 서포트 아이템도 선택
        })));
    }, []);

    useEffect(() => {
        // console.log('cartList : ', cartList);
        // console.log('supportCartList : ', supportCartList);
        // console.log('selectDelivery : ', selectDelivery);
    }, [cartList, supportCartList, selectDelivery]);

    useEffect(() => {
        setIsLoading(true);
    }, []);

    useEffect(() => {
        if(userInfo) {
            cartData();
            supportCartData();
            holidaySelect();
            temporarySelect();
            setIsLoading(false);
            userDeliverySelect();
            userSelect();
        }
    }, [userInfo]);


    useEffect(() => {
        // 렌더링 된 카트 아이템 수 계산 및 상태 업데이트
        const { totalItemCount } = renderCartItems();
        setItemCount(totalItemCount);  // 전체 아이템 수 상태 업데이트
    }, [cartList, supportCartList, viewMode]);

    const userSelect = async () => {
        if (userInfo?.userId) {
            const response = await auth.userselect(userInfo.userId);
            const data = response.data;

            setUser(data);
        }
    };

    const userDeliverySelect = async () => {
        if (userInfo?.userId) {
            try {
                const response = await auth.userDeliverySelect(userInfo.userId);
                const data = response.data;

                setUserDeliveryList(data);
                const defaultDelivery = data.find(delivery => delivery.deliveryType === 1);
                if (defaultDelivery) {
                    setSelectDelivery(defaultDelivery);
                }
            } catch (error) {
                console.error('error : ', error);
            }
        }
    };

    const cartData = async () => {
        const response = await storeapi.cartSelect(userInfo && userInfo.userId);
        const data = response.data.map(item => ({
            ...item,
            // 옵션이 없는 경우 초기 isSelected 값을 false로 설정
            isSelected: item.selectOption ? false : item.isSelected || false
        }));
        setCartList(data);
    };

    const supportCartData = async () => {
        const response = await support.supportCartSelect(userInfo && userInfo.userId);
        const data = response.data.map(item => ({
            ...item,
            isSelected: false,
            reservationStartDate: new Date(item.reservationStartDate),
            reservationEndDate: new Date(item.reservationEndDate),
        }));
        setSupportCartList(data);
    };

    const holidaySelect = async () => {
        const response = await support.holidaySelect();
        const data = response.data.map(item => new Date(item.holidayDate));
        setHolidays(data);
    }

    const temporarySelect = async () => {
        const response = await support.temporarySelect();
        const data = response.data.map(item => ({
            startDate: new Date(item.temporaryDate),
            endDate: new Date(item.temporaryEndDate)
        }));
        setTemporaryDays(data);
    }

    const isAllSelected = () => {
        if (viewMode === 'store') {
            return cartList.every(item => 
                item.selectOption 
                    ? groupByOptionId(item.selectOption).every(opt => opt.isSelected)
                    : item.isSelected
            );
        }
        if (viewMode === 'support') {
            return supportCartList.every(item => item.isSelected);
        }
        return (
            cartList.every(item => 
                item.selectOption 
                    ? groupByOptionId(item.selectOption).every(opt => opt.isSelected)
                    : item.isSelected
            ) &&
            supportCartList.every(item => item.isSelected)
        );
    };
    

    const handleSelectAll = (event) => {
        console.log('전체선택');
        const isSelected = event.target.checked;
        setCartList(prevCartList => prevCartList.map(item => ({
            ...item,
            selectOption: item.selectOption
                ? item.selectOption.map(opt => ({ ...opt, isSelected })) // 각 옵션마다 isSelected 적용
                : item.selectOption, // 옵션이 없는 경우
            isSelected  // 기본 아이템도 선택
        })));
        setSupportCartList(prevSupportCartList => prevSupportCartList.map(item => ({
            ...item,
            isSelected
        })));
    };
    
    

    const handleItemSelect = (cartNo, optionIndex = null) => {
        setCartList(prevCartList => prevCartList.map(item => {
            if (item.cartNo === cartNo) {
                if (item.selectOption && optionIndex !== null) {
                    const groupedOptions = groupByOptionId(item.selectOption);
                    const optionId = groupedOptions[optionIndex].id; // 그룹화된 옵션의 ID 사용
                    const updatedOptions = item.selectOption.map((opt) =>
                        opt.id === optionId ? { ...opt, isSelected: !opt.isSelected } : opt
                    );
                    const isSelected = updatedOptions.some(opt => opt.isSelected);
                    return {
                        ...item,
                        selectOption: updatedOptions,
                        isSelected: isSelected
                    };
                } else {
                    return {
                        ...item,
                        isSelected: !item.isSelected
                    };
                }
            }
            return item;
        }));
    };
    
    

    const handleShopSelect = (cartNo) => {
        setSupportCartList(supportCartList.map(item =>
            item.cartNo === cartNo
                ? { ...item, isSelected: !item.isSelected }
                : item
        ));
    };

    const incrementQuantity = (cartNo, optionId = null, isSupport = false) => {
        if (optionId === null) {
            const item = cartList.find(item => item.cartNo === cartNo);
            if (item) {
                nonOptionIncrementQuantityUpdate(cartNo, item.totalQuantity, item.totalPrice, item.productSalePrice);
            }
        } else {
            if (isSupport) {
                setSupportCartList(supportCartList.map(item => {
                    if (item.cartNo === cartNo) {
                        const updatedSupportList = {
                            ...item,
                            totalQuantity: item.totalQuantity + 1,
                            totalPrice: item.totalPrice + item.productSalePrice
                        };
                        return updatedSupportList;
                    }
                    return item;
                }));
            } else {
                setCartList(cartList.map(item => {
                    if (item.cartNo === cartNo) {
                        const option = item.selectOption.find(opt => opt.id === optionId);
                        
                        // 옵션을 찾지 못한 경우 예외 처리
                        if (!option) {
                            console.error(`옵션을 찾을 수 없습니다: optionId = ${optionId}`);
                            return item; // 오류 방지, 원본 아이템을 그대로 반환
                        }
                        
                        if (option.quantity < option.stockCnt) {
                            const updatedCartList = {
                                ...item,
                                selectOption: item.selectOption.map(opt =>
                                    opt.id === optionId ? { ...opt, quantity: opt.quantity + 1 } : opt
                                )
                            };

                            // console.log('incrementQuantity updatedCartList : ', updatedCartList);
    
                            const totalQuantity = updatedCartList.selectOption[0].quantity;
                            const totalPrice = (item.productSalePrice + item.optionPrice) * updatedCartList.selectOption[0].quantity;
    
                            quantityUpdate(item.cartNo, updatedCartList.selectOption.find(opt => opt.id === optionId).quantity, updatedCartList.selectOption.find(opt => opt.id === optionId).optionValue, totalQuantity, totalPrice);
                            return updatedCartList;
                        } else {
                            toast.warn('재고수량이 부족합니다.');
                            return item; // 재고 부족 시 원본 아이템 반환
                        }
                    }
                    return item;
                }));
            }
        }
    };
    
    const decrementQuantity = (cartNo, optionId = null, isSupport = false) => {
        if (optionId === null) {
            const item = cartList.find(item => item.cartNo === cartNo);
            if (item) {
                if(item.totalQuantity > 1) {
                    nonOptionDecrementQuantityUpdate(cartNo, item.totalQuantity, item.totalPrice, item.productSalePrice);
                } else {
                    alert(`1 이하로 설정할 수 없습니다.`);
                    return;
                }
            }
        } else {
            if (isSupport) {
                setSupportCartList(supportCartList.map(item => {
                    if (item.cartNo === cartNo && item.totalQuantity > 1) {
                        const updatedSupportList = {
                            ...item,
                            totalQuantity: item.totalQuantity - 1,
                            totalPrice: item.totalPrice - item.productSalePrice
                        };
                        return updatedSupportList;
                    }
                    return item;
                }));
            } else {
                setCartList(cartList.map(item => {
                    if (item.cartNo === cartNo) {
                        const option = item.selectOption.find(opt => opt.id === optionId);
                        
                        // 옵션을 찾지 못한 경우 예외 처리
                        if (!option) {
                            console.error(`옵션을 찾을 수 없습니다: optionId = ${optionId}`);
                            return item; // 오류 방지, 원본 아이템을 그대로 반환
                        }
    
                        if (option.quantity > 1) {
                            const updatedCartList = {
                                ...item,
                                selectOption: item.selectOption.map(opt =>
                                    opt.id === optionId ? { ...opt, quantity: opt.quantity - 1 } : opt
                                )
                            };

                            console.log('decrementQuantity updatedCartList : ', updatedCartList);
    
                            const totalQuantity = updatedCartList.selectOption[0].quantity;
                            const totalPrice = updatedCartList.selectOption.reduce((total, opt) =>
                                total + (item.productSalePrice + opt.addPrice) * opt.quantity, 0
                            );
    
                            quantityUpdate(item.cartNo, updatedCartList.selectOption.find(opt => opt.id === optionId).quantity, updatedCartList.selectOption.find(opt => opt.id === optionId).optionValue, totalQuantity, totalPrice);
                            return updatedCartList;
                        }
                    }
                    return item;
                }));
            }
        }
    };

    const quantityUpdate = async (cartNo, quantity, value, totalQuantity, totalPrice) => {
        setIsLoading(true);
        const response = await storeapi.quantityUpdate(cartNo, quantity, value, userInfo && userInfo.userId, totalQuantity, totalPrice);
        const data = response.data;

        if (data === 'SUCCESS') {
            cartData();
            supportCartData();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    };
    const nonOptionIncrementQuantityUpdate = async (cartNo, totalQuantity, totalPrice, productSalePrice) => {
        setIsLoading(true);

        let totalQuantityCheck = totalQuantity + 1;

        const item = cartList.find(item => item.cartNo === cartNo);
        let response;
        let data;
        if(totalQuantityCheck <= item.totalStock) {
            response = await storeapi.nonOptionIncrementQuantityUpdate(cartNo, totalQuantity, totalPrice, productSalePrice);
            data = response.data;
        } else {
            alert(`재고 수량이 부족합니다.(${item.totalStock}개 이하로 선택해주세요.)`);
            setIsLoading(false);
            return;
        }

        if(data === 'SUCCESS') {
            cartData();
            supportCartData();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }

    const nonOptionDecrementQuantityUpdate = async (cartNo, totalQuantity, totalPrice, productSalePrice) => {
        setIsLoading(true);
        const response = await storeapi.nonOptionDecrementQuantityUpdate(cartNo, totalQuantity, totalPrice, productSalePrice);
        const data = response.data;

        console.log('nonOptionDecrementQuantityUpdate : ', data);

        if(data === 'SUCCESS') {
            cartData();
            supportCartData();
            // 1초 후에 isLoading을 false로 변경
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }

    const nonOptionQuantityUpdateDirect = async (cartNo, totalQuantity, totalPrice, productSalePrice) => {
        setIsLoading(true);
        const response = await storeapi.nonOptionQuantityUpdateDirect(cartNo, totalQuantity, totalPrice, productSalePrice);
        const data = response.data;

        console.log('nonOptionQuantityUpdateDirect : ', data);

        if(data === 'SUCCESS') {
            cartData();
            supportCartData();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }

    const handleItemDelete = async (cartNo, isSupport = false) => {
        if (isSupport) {
            const response = await support.supportCartDelete(cartNo);
            const data = response.data;

            if (data === 'SUCCESS') {
                setSupportCartList(supportCartList => supportCartList.filter(item => item.cartNo !== cartNo));
            }
        } else {
            const response = await storeapi.cartDelete(cartNo);
            const data = response.data;

            if (data === 'SUCCESS') {
                setCartList(cartList => cartList.filter(item => item.cartNo !== cartNo));
            }
        }
    };

    const handleSelectDelete = async () => {
        // 선택된 아이템들의 cartNo를 가져옵니다.
        const selectedCartNos = cartList
            .filter(item => item.isSelected || (item.selectOption && item.selectOption.some(opt => opt.isSelected)))
            .map(item => item.cartNo);
        const selectedSupportCartNos = supportCartList
            .filter(item => item.isSelected)
            .map(item => item.cartNo);

        // 모든 선택된 cartNo들을 합칩니다.
        const allSelectedCartNos = [...selectedCartNos, ...selectedSupportCartNos];

        if (allSelectedCartNos.length === 0) {
            alert('선택된 아이템이 없습니다.');
            return;
        }

        // 선택된 cartNo들을 삭제하는 API 요청을 수행합니다.
        try {
            await Promise.all(allSelectedCartNos.map(cartNo => handleItemDelete(cartNo, selectedSupportCartNos.includes(cartNo))));
            toast.success('선택된 아이템이 삭제되었습니다.', {
                autoClose: 1000,
                hideProgressBar: true,
                position: "top-center"
            });
        } catch (error) {
            toast.error('선택된 아이템 삭제에 실패했습니다.', {
                autoClose: 1000,
                hideProgressBar: true,
                position: "top-center"
            });
            console.error('Error deleting selected items:', error);
        }
    };

    const toggleExpand = (cartNo) => {
        setExpandedItems(prevState => ({
            ...prevState,
            [cartNo]: !prevState[cartNo]
        }));
    };

    const isDateInHolidayOrTemporary = (date) => {
        // Check if the date is within the holidays or temporary days
        const isHoliday = holidays.some(holiday => holiday.getTime() === date.getTime());
        const isTemporaryDay = temporaryDays.some(temp => date >= temp.startDate && date <= temp.endDate);

        return isHoliday || isTemporaryDay;
    };

    const isDateRangeInHolidayOrTemporary = (startDate, endDate) => {
        // 시작일과 종료일 사이의 모든 날짜를 확인
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            if (isDateInHolidayOrTemporary(currentDate)) {
                return true;
            }
            currentDate.setDate(currentDate.getDate() + 1); // 하루씩 증가
        }
        return false;
    };
    
    const handleDateRangeChange = (cartNo, startDate, endDate, sellerId) => {
        // 날짜 비교 시 시간 정보를 제거한 상태에서 비교
        const start = new Date(startDate.setHours(0, 0, 0, 0));
        const end = new Date(endDate.setHours(0, 0, 0, 0));
    
        if (start > end) {
            alert('시작일은 종료일보다 클 수 없습니다.');
            const item = supportCartList.find(item => item.cartNo === cartNo);
            setSelectedRange({ startDate: item.reservationStartDate, endDate: item.reservationEndDate });
            return;
        }
    
        // 시작일과 종료일 사이에 예약 불가능한 날짜가 있는지 확인
        if (isDateRangeInHolidayOrTemporary(startDate, endDate)) {
            alert('선택하신 날짜 범위에 예약이 불가능한 날짜가 포함되어 있습니다.');
            const item = supportCartList.find(item => item.cartNo === cartNo);
            setSelectedRange({ startDate: item.reservationStartDate, endDate: item.reservationEndDate });
        } else {
            setSupportCartList(prevList =>
                prevList.map(item =>
                    item.cartNo === cartNo
                        ? {
                            ...item,
                            reservationStartDate: startDate,
                            reservationEndDate: endDate,
                        }
                        : item
                )
            );
            setSelectedRange({ startDate, endDate });
    
            reservationDateUpdate(cartNo, startDate, endDate);
        }
    };

    const reservationDateUpdate = async (cartNo, startDate, endDate) => {
        console.log('cartNo : ', cartNo);
        console.log('startDate : ', startDate);
        console.log('endDate : ', endDate);
        const response = await support.reservationDateUpdate(cartNo, startDate, endDate);
        const data = response.data;

        console.log('reservationDate: ', data);

        if(data === 'SUCCESS') {
            alert('예약 날짜가 변경되었습니다.');
            cartData();
            supportCartData();
        }
    }

    const onStoreTotalDelete = async (cartNo) => {
        console.log('cartNo : ', cartNo)
        const response = await storeapi.storeCartDelete(cartNo, userInfo&&userInfo.userId);
        const data = response.data;

        if(data === 'SUCCESS') {
            alert('해당 아이템이 장바구니에서 삭제되었습니다.');
            cartData();
            supportCartData();
        }
    }
    const onSupportTotalDelete = async (cartNo) => {
        console.log('cartNo : ', cartNo)
        const response = await support.supportCartDelete(cartNo, userInfo&&userInfo.userId);
        const data = response.data;

        if(data === 'SUCCESS') {
            alert('해당 아이템이 장바구니에서 삭제되었습니다.');
            cartData();
            supportCartData();
        }
    }

    const groupByOptionId = (selectOption) => {
        if (!selectOption || selectOption.length === 0) {
            return [];
        }
        
        const groupedOptions = selectOption.reduce((acc, option) => {
            if (!acc[option.id]) {
                acc[option.id] = {
                    ...option,
                    quantity: option.quantity,
                    optionNames: [option.optionName],
                    optionValues: [option.optionValue]
                };
            } else {
                acc[option.id].quantity = Math.max(acc[option.id].quantity, option.quantity);
                acc[option.id].optionNames.push(option.optionName);
                acc[option.id].optionValues.push(option.optionValue);
            }
            return acc;
        }, {});
    
        return Object.values(groupedOptions);
    };

    const handleQuantityInputChange = (cartNo, optionId, newValue) => {
        const quantity = parseInt(newValue, 10);
    
        if (quantity >= 1) {
            if (optionId === null) {
                // 옵션이 없는 경우
                setCartList(cartList.map(item =>
                    item.cartNo === cartNo ? { ...item, totalQuantity: quantity } : item
                ));
            } else {
                // 옵션이 있는 경우
                setCartList(cartList.map(item => {
                    if (item.cartNo === cartNo) {
                        return {
                            ...item,
                            selectOption: item.selectOption.map(opt =>
                                opt.id === optionId ? { ...opt, quantity } : opt
                            )
                        };
                    }
                    return item;
                }));
            }
        }
    };
    

    const handleQuantityBlur = async (cartNo, optionId, currentQuantity) => {
        const item = cartList.find(item => item.cartNo === cartNo);
    
        if (optionId === null) {
            // 옵션이 없는 경우
            if (currentQuantity > 0) {
                if (currentQuantity <= item.totalStock) {
                    await nonOptionQuantityUpdateDirect(cartNo, currentQuantity, item.totalPrice, item.productSalePrice);
                } else {
                    alert(`재고 수량이 부족합니다. (${item.totalStock}개 이하로 입력해주세요.)`);
                    await nonOptionQuantityUpdateDirect(cartNo, item.totalStock, item.totalPrice, item.productSalePrice);
                }
            }
        } else {
            // 옵션이 있는 경우
            // console.log('currentQuantity : ', currentQuantity);
    
            if (currentQuantity > 0) {
                // `id`를 기준으로 그룹화하여 첫 번째 항목만 사용
                const uniqueOptions = Object.values(
                    item.selectOption.reduce((acc, opt) => {
                        if (!acc[opt.id]) {
                            acc[opt.id] = opt; // 처음 등장한 `id`만 추가
                        }
                        return acc;
                    }, {})
                );
    
                // console.log('Unique Options:', uniqueOptions);
    
                const totalQuantity = uniqueOptions.reduce((total, opt) => total + opt.quantity, 0);
                const totalPrice = uniqueOptions.reduce(
                    (total, opt) => total + (item.productSalePrice + opt.addPrice) * opt.quantity,
                    0
                );
    
                // 현재 `optionId`에 해당하는 옵션의 `optionValue` 찾기
                const updatedOption = uniqueOptions.find(opt => opt.id === optionId);
                const value = updatedOption ? updatedOption.optionValue : null;
    
                // console.log('Total Quantity:', totalQuantity);
                // console.log('Total Price:', totalPrice);
                // console.log('Value:', value);
    
                // value 값을 전달
                await quantityUpdate(cartNo, currentQuantity, value, totalQuantity, totalPrice);
            } else {
                alert('재고 수량이 부족합니다.');
                handleQuantityInputChange(cartNo, optionId, item.totalStock);
            }
        }
    };

    const calculateOverseasShippingCost = (totalWeight, kgString, costString) => {
        if (!kgString || !costString) return 0;

        // console.log('totalWeight : ', totalWeight);
        // console.log('totalWeight : ', kgString);
        // console.log('totalWeight : ', costString);

        const kgArray = kgString.split(';').map(parseFloat);
        const costArray = costString.split(';').map(parseFloat);

        let shippingCost = 0;

        for (let i = 1; i < kgArray.length; i++) {
            if (totalWeight > kgArray[i - 1] && totalWeight <= kgArray[i]) {
                shippingCost = costArray[i - 1];
                break;
            }
        }

        if (totalWeight <= kgArray[0]) {
            shippingCost = costArray[0];
        }

        if (totalWeight > kgArray[kgArray.length - 1]) {
            shippingCost = costArray[costArray.length - 1];
        }

        return shippingCost;
    };

    const handleSupportDecrementBtn = async (item) => {
        setIsLoading(true);
        console.log('handleSupportDecrementBtn : ', item);
        const response = await support.supportCartProductDecrement(item);
        const data = response.data;

        if(data === 'SUCCESS') {
            setIsLoading(false);
            supportCartData();
        }
    }
    const handleSupportIncrementBtn = async (item) => {
        setIsLoading(true);
        console.log('handleSupportIncrementBtn : ', item);
        
        const response = await support.supportCartProductIncrement(item);
        const data = response.data;
        
        console.log('data : ', data);
        if(data === 'SUCCESS') {
            setIsLoading(false);
            supportCartData();
        }
    }

    const renderCartItems = () => {
        let grandTotalProductAmount = 0;
        let totalShippingFee = 0;
        let totalItemCount = 0;
    
        const storeItems = [];
        const supportItems = [];

        
    
        if (viewMode === 'store' || viewMode === 'all') {
            storeItems.push(...cartList.map(item => {
                let shopTotalProductAmount = 0;
                let shopShippingFee = item.deliveryPrice;
    
                // 옵션이 있는 경우에만 그룹화
                const groupedOptions = item.selectOption ? groupByOptionId(item.selectOption) : [];
    
                // 옵션이 있는 경우
                if (groupedOptions.length > 0) {
                    groupedOptions.forEach(option => {
                        if (option.isSelected) {  // 선택된 옵션만 금액에 포함
                            const optionTotalPrice = (item.productSalePrice + option.addPrice) * option.quantity;
                            shopTotalProductAmount += optionTotalPrice;
                        }
                    });
                } else if (item.isSelected) {  // 선택된 아이템만 금액에 포함
                    shopTotalProductAmount = item.totalPrice;
                }
    
                if (selectDelivery?.deliveryState === '국내') {
                    if (shopTotalProductAmount === 0) {
                        shopShippingFee = 0; // 상품 금액이 없으면 배송비는 0
                    } else if (shopTotalProductAmount >= item.deliveryFree) {
                        shopShippingFee = 0; // 무료 배송 조건 충족 시 배송비 0
                    }
                } else if (selectDelivery?.deliveryState === '해외' && item.deliveryInfo?.overseasShippingState) {
                    const totalWeight = item.productWeight * item.totalQuantity;
                    if (shopTotalProductAmount !== 0) {
                        shopShippingFee = calculateOverseasShippingCost(
                            totalWeight,
                            item.deliveryInfo.overseasShippingKg,
                            item.deliveryInfo.overseasShippingCost
                        );
                    } else {
                        shopShippingFee = 0; // 상품 금액이 없으면 해외 배송비도 0
                    }
                } else {
                    shopShippingFee = 0; // 배송 조건이 충족되지 않을 경우 기본값은 0
                }
    
                grandTotalProductAmount += shopTotalProductAmount;
                totalShippingFee += shopShippingFee;
    
                const isExpanded = expandedItems[item.cartNo] || false;
    
                return (
                    <div className={`${cart.cart_border_bottom}`} key={item.cartNo}>
                        <div className={`${cart.cart_list_shop_info_box} px-3 py-3`}>
                            <button className={`${cart.cart_list_shop_info_button}`}>
                                <div><img src={item.shopProfile} alt="프로필" /></div>
                                <div className='ps-2'><span>{item.shopName}</span></div>
                            </button>
                        </div>
                        <div
                            className={`${cart.store_cart_item_box}`}
                            style={{ maxHeight: isExpanded ? 'none' : '400px', overflow: 'hidden' }}
                        >
                            {groupedOptions.length > 0 ? (
                                groupedOptions.map((option, idx) => (
                                    <div className='px-3' key={idx}>
                                        <div className={`${cart.store_cart_list_prdocut_box}`}>
                                            <div className={`${cart.store_cart_list_prdocut_input}`}>
                                                <input
                                                    type="checkbox"
                                                    checked={option.isSelected}
                                                    onChange={() => handleItemSelect(item.cartNo, idx)}
                                                />
                                            </div>
                                            <div className={`${cart.store_cart_list_prdocut_img}`}>
                                                <div>
                                                    <img src={item.productImg} alt="" />
                                                </div>
                                            </div>
                                            <div className={`${cart.store_cart_list_prdocut_info}`}>
                                                <div className={`${cart.store_cart_list_product_info_title}`}>
                                                    <div><span>{item.productName}</span></div>
                                                    <div>
                                                        {option.optionNames && option.optionNames.map((name, i) => (
                                                            option.optionValues[i] ? (
                                                                <div><span key={i}>{name}: {option.optionValues[i]}</span></div>
                                                            ) : null
                                                        ))}

                                                    </div>
                                                </div>
                                                <div className={`${cart.store_cart_list_product_info_price}`}>
                                                    <span>{((item.productSalePrice + option.addPrice) * option.quantity).toLocaleString()}원</span>
                                                </div>
                                                <div className={`${cart.store_cart_list_product_info_btn}`}>
                                                    <button onClick={() => decrementQuantity(item.cartNo, option.id)}>-</button>
                                                    <input
                                                        type="number"
                                                        value={option.quantity}
                                                        min="1"
                                                        max={option.stockCnt}
                                                        onChange={(e) => handleQuantityInputChange(item.cartNo, option.id, e.target.value)}
                                                        onBlur={() => handleQuantityBlur(item.cartNo, option.id, option.quantity)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter') {
                                                                handleQuantityBlur(item.cartNo, option.id, option.quantity);
                                                                e.target.blur(); // 엔터를 누르면 포커스를 해제하여 onBlur 중복 실행 방지
                                                            }
                                                        }}
                                                        className={`${cart.store_cart_list_quantity_input}`}
                                                    />
                                                    <button onClick={() => incrementQuantity(item.cartNo, option.id)}>+</button>
                                                </div>
                                            </div>
                                            <div className={`${cart.store_cart_list_product_delete}`}><button onClick={() => handleItemDelete(item.cartNo)}><span className="material-symbols-outlined">close</span></button></div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                // 옵션이 없을 경우, 아이템 자체를 렌더링
                                <div className='px-3'>
                                    <div className={`${cart.store_cart_list_prdocut_box}`}>
                                        <div className={`${cart.store_cart_list_prdocut_input}`}>
                                            <input
                                                type="checkbox"
                                                checked={item.isSelected}
                                                onChange={() => handleItemSelect(item.cartNo)} // 옵션이 없을 때, 아이템 자체의 체크 상태 변경
                                            />
                                        </div>
                                        <div className={`${cart.store_cart_list_prdocut_img}`}>
                                            <div>
                                                <img src={item.productImg} alt="" />
                                            </div>
                                        </div>
                                        <div className={`${cart.store_cart_list_prdocut_info} px-2`}>
                                            <div className={`${cart.store_cart_list_product_info_title}`}>
                                                <span>{item.productName}</span>
                                            </div>
                                            <div className={`${cart.store_cart_list_product_info_price}`}>
                                                <span>{(item.totalPrice).toLocaleString()}원</span>
                                            </div>
                                            <div className={`${cart.store_cart_list_product_info_btn}`}>
                                                <button onClick={() => decrementQuantity(item.cartNo, null)}>-</button>
                                                <input
                                                    type="number"
                                                    value={item.totalQuantity}
                                                    min="1"
                                                    onChange={(e) => handleQuantityInputChange(item.cartNo, null, e.target.value)}
                                                    onBlur={(e) => {
                                                        if (!e.relatedTarget || e.relatedTarget.tagName !== 'INPUT') {
                                                            handleQuantityBlur(item.cartNo, null, item.totalQuantity);
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleQuantityBlur(item.cartNo, null, item.totalQuantity);
                                                            e.target.blur(); // 엔터를 누르면 포커스를 해제하여 onBlur 중복 실행 방지
                                                        }
                                                    }}
                                                    className={`${cart.store_cart_list_quantity_input}`}
                                                />
                                                <button onClick={() => incrementQuantity(item.cartNo, null)}>+</button>
                                            </div>
                                        </div>
                                        <div className={`${cart.store_cart_list_product_delete}`}><button onClick={() => handleItemDelete(item.cartNo)}><span className="material-symbols-outlined">close</span></button></div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={`${cart.cart_delete_btn}`}><button onClick={() => onStoreTotalDelete(item.cartNo)}><span className="material-symbols-outlined">delete</span></button></div>
                        <button onClick={() => toggleExpand(item.cartNo)} className={`${cart.cart_more_btn}`}>
                            {isExpanded ? '숨기기' : '더보기'}
                        </button>
                        <div className='px-3 py-3'>
                            <div className='d-flex justify-content-between py-2'>
                                <div className={`${cart.cart_gray_color}`}><span>{selectDelivery ? selectDelivery?.deliveryState === '국내' ? `배송비` : `해외배송비` : '배송비'}</span></div>
                                <div className={`${cart.cart_gray_color}`}>
                                    <span>
                                        {
                                            selectDelivery?.deliveryState === '국내' ? (
                                                shopTotalProductAmount === 0
                                                    ? '0원'
                                                    : (shopShippingFee === 0
                                                        ? '무료배송'
                                                        : `${shopShippingFee.toLocaleString()}원`)
                                            )
                                            :
                                            (
                                                `${shopShippingFee.toLocaleString()}원`
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between py-2'>
                                <div className={`${cart.cart_gray_color}`}><span>상품금액</span></div>
                                <div><span>{shopTotalProductAmount.toLocaleString()}원</span></div>
                            </div>
                        </div>
                    </div>
                );
            }));
        }
    
        if (viewMode === 'support' || viewMode === 'all') {
            supportItems.push(...supportCartList.map(item => {
                const isExpanded = expandedItems[item.cartNo] || false;
                const rangeKey = `${item.cartNo}`;
                const startDate = selectedRange[rangeKey]?.startDate || item.reservationStartDate;
                const endDate = selectedRange[rangeKey]?.endDate || item.reservationEndDate;
                grandTotalProductAmount += item.isSelected ? item.totalPrice : 0;
        
                // 중복 생성을 방지하기 위해 각 item별로 groupedOptions를 한 번만 생성합니다.
                // const groupedOptions = Object.values(item.optionItem.reduce((acc, option) => {
                //     const { name, value, price, quantity } = option;
                //     const priceQuantity = `${value}(+${price.toLocaleString()}원) - ${quantity}개`;
        
                //     if (!acc[name]) {
                //         acc[name] = { name, value: priceQuantity };
                //     } else {
                //         acc[name].value += `, ${priceQuantity}`;
                //     }
                //     return acc;
                // }, {}));
        
                return (
                    <div className={`${cart.cart_border_bottom}`} key={item.cartNo}>
                        <div className={`${cart.cart_list_shop_info_box} px-3 py-3 d-flex align-items-center`}>
                            <input
                                type="checkbox"
                                checked={item.isSelected}
                                onChange={() => handleShopSelect(item.cartNo)}
                            />
                            <button className={`${cart.cart_list_shop_info_button} ms-2`}>
                                <div><img src={item.shopProfile} alt="프로필" /></div>
                                <div className='ps-2'><span>{item.shopName}</span></div>
                            </button>
                        </div>
                        <div
                            className={`${cart.cart_item_box}`}
                            style={{ maxHeight: isExpanded ? 'none' : '400px', overflow: 'hidden' }}
                        >
                            <div className={`${cart.cart_item_info_box}`}>
                                <div className={`${cart.cart_item_info_box_img}`}><img src={item.productImg} alt="" /></div>
                                <div className={`${cart.cart_item_info_box_info}`}>
                                    <div className={`${cart.cart_list_product_info_title}`}><span>{item.productName}</span></div>
                                    <div className={`${cart.cart_list_product_info_basic_price}`}>
                                        <span>기본 상품금액 : <span className={`${cart.cart_list_product_info_price}`}>{(parseInt(item.productMinPrice)).toLocaleString()}원</span></span>
                                    </div>
                                    {
                                        item.optionItem ? item.optionItem.map((option, idx) => (
                                            <div key={idx}>
                                                <div className={`${cart.cart_list_prdocut_box}`}>
                                                    {
                                                        option.type === 'fileUpload' && (
                                                    <div className={`${cart.cart_list_prdocut_img}`}>
                                                        <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${option.serverFileName}`} alt="" /></div>
                                                    </div>
                                                        )
                                                    } 
                                                    <div className={`${cart.cart_list_prdocut_info}`}>
                                                        <div><span>{option.name} :</span><span> {option.value}</span></div>
                                                        <div className={`${cart.cart_list_product_info_price}`}><span>(+{((option.price * option.quantity)).toLocaleString()}원)</span></div>
                                                        <div><span>&nbsp;{option.quantity}개</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : (
                                            <div>
                                                <div className={`${cart.cart_list_prdocut_box}`}>
                                                    {/* <div className={`${cart.cart_list_prdocut_img}`}>
                                                        <div>
                                                            <img src={item.productImg} alt="" />
                                                        </div>
                                                    </div> */}
                                                    <div className={`${cart.cart_list_prdocut_info}`}>
                                                        {/* <div className={`${cart.cart_list_product_info_title}`}><span>{item.productName}</span></div> */}
                                                        <div><span>단일 상품입니다.</span></div>
                                                        {/* <div className={`${cart.cart_list_product_info_price}`}><span>{((item.totalPrice)).toLocaleString()}원</span></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
        
                                    {/* fileUpload 타입의 옵션만 개별적으로 렌더링 */}
                                    {/* {item.optionItem.map((option, idx) => (
                                        option.type === 'fileUpload' && (
                                            <div key={idx} className={`${cart.cart_list_prdocut_box}`}>
                                                <div className={`${cart.cart_list_prdocut_img}`}>
                                                    <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${option.serverFileName}`} alt="" /></div>
                                                </div>
                                            </div>
                                        )
                                    ))} */}
        
                                    {/* groupedOptions는 한 번만 렌더링 */}
                                    {/* {groupedOptions.map((group, idx) => (
                                        <div key={idx} className={`${cart.cart_list_prdocut_box}`}>
                                            <div className={`${cart.cart_list_prdocut_info}`}>
                                                <div><span>{group.name} :</span><span> {group.value}</span></div>
                                            </div>
                                        </div>
                                    ))} */}
                                    
                                    <div className={`${cart.cart_list_product_info_total_price}`}>
                                        <span>{item.totalPrice ? ((item.totalPrice)).toLocaleString() : 0}원</span>
                                    </div>
                                    {
                                        item.mainItemProductQuantity &&
                                        <div className={`${cart.store_cart_list_product_info_btn}`}>
                                            <button onClick={() => handleSupportDecrementBtn(item)}>-</button>
                                            <input
                                                type="number"
                                                value={item.totalItemQuantity}
                                                min="1"
                                                // onChange={(e) => handleQuantityInputChange(item.cartNo, null, e.target.value)}
                                                // onBlur={(e) => {
                                                //     if (!e.relatedTarget || e.relatedTarget.tagName !== 'INPUT') {
                                                //         handleQuantityBlur(item.cartNo, null, item.totalQuantity);
                                                //     }
                                                // }}
                                                // onKeyDown={(e) => {
                                                //     if (e.key === 'Enter') {
                                                //         handleQuantityBlur(item.cartNo, null, item.totalQuantity);
                                                //         e.target.blur(); // 엔터를 누르면 포커스를 해제하여 onBlur 중복 실행 방지
                                                //     }
                                                // }}
                                                className={`${cart.store_cart_list_quantity_input}`}
                                            />

                                            <button onClick={() => handleSupportIncrementBtn(item)}>+</button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={cart.cart_date_range_box}>
                                <DateRangePicker
                                    cartNo={item.cartNo}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={handleDateRangeChange}
                                    cart={cart}
                                    sellerId={item.sellerId}
                                />
                            </div>
                        </div>
                        <div className={`${cart.cart_delete_btn}`}>
                            <button onClick={() => onSupportTotalDelete(item.cartNo)}>
                                <span className="material-symbols-outlined">delete</span>
                            </button>
                        </div>
                        <button onClick={() => toggleExpand(item.cartNo)} className={`${cart.cart_more_btn}`}>
                            {isExpanded ? '숨기기' : '더보기'}
                        </button>
                        <div className='px-3 py-3'>
                            <div className='d-flex justify-content-between py-2'>
                                <div className={`${cart.cart_gray_color}`}><span>상품금액</span></div>
                                <div><span>{item.isSelected ? item && item.totalPrice ? (item.totalPrice).toLocaleString() : 0 : 0}원</span></div>
                            </div>
                        </div>
                    </div>
                );
            }));
        }
    
        return {
            items: [...storeItems, ...supportItems],
            grandTotalProductAmount,
            totalShippingFee,
            totalItemCount
        };
    };
    

    const onCartPayment = () => {
        
        const selectedStoreItems = cartList
        .map(item => ({
            ...item,
            selectOption: item.selectOption ? item.selectOption.filter(opt => opt.isSelected) : [],
            isSelected: item.selectOption ? item.isSelected : item.isSelected,
        }))
        .filter(item => item.selectOption.length > 0 || item.isSelected);
        
        
        if(selectedStoreItems.length !== 0) {
            if(!selectDelivery) {
                alert('스토어 상품이 있는 경우 배송지를 설정해주세요.');
                return;
            }
        }

        const selectedSupportItems = supportCartList.filter(item => item.isSelected);
    
        if (selectedStoreItems.length === 0 && selectedSupportItems.length === 0) {
            alert('선택된 주문값이 없습니다.');
            return;
        }
    
        const processedStoreItems = selectedStoreItems.map(item => {
            if (item.selectOption && item.selectOption.length > 0) {
                // 옵션을 각 id별로 그룹화하여 개별 총액을 계산
                const groupedOptions = groupByOptionId(item.selectOption);
                
                // 각 그룹에 대해 총 상품 금액 계산
                const shopTotalProductAmount = groupedOptions.reduce((total, optionGroup) => {
                    const optionAmount = (item.productSalePrice + optionGroup.addPrice) * optionGroup.quantity;
                    return total + optionAmount;
                }, 0);
        
                // 배송비 조건 적용
                if (shopTotalProductAmount >= item.deliveryFree) {
                    console.log(`Item with cartNo ${item.cartNo} has totalPrice >= deliveryFree, setting deliveryPrice to 0`);
                    return { ...item, deliveryPrice: 0, totalPrice: shopTotalProductAmount };
                } else {
                    return { ...item, totalPrice: shopTotalProductAmount };
                }
            } else {
                // 옵션이 없는 경우 기본 totalPrice 사용
                return item;
            }
        });
    
        // 총 상품 금액 계산 (옵션을 그룹화하여 처리)
        const totalProductAmount = processedStoreItems.reduce((total, item) => {
            if (item.selectOption && item.selectOption.length > 0) {
                const groupedOptions = groupByOptionId(item.selectOption);
                return total + groupedOptions.reduce((subTotal, opt) => {
                    return subTotal + (item.productSalePrice + opt.addPrice) * opt.quantity;
                }, 0);
            } else {
                return total + item.totalPrice;
            }
        }, 0) + selectedSupportItems.reduce((total, item) => total + item.totalPrice, 0);
    
        let totalShippingFee = processedStoreItems.reduce((total, item) => total + item.deliveryPrice, 0);

        // 해외 배송지가 선택된 경우 추가 배송비 계산
        if (selectDelivery.deliveryState === '해외') {
            totalShippingFee += processedStoreItems.reduce((total, item) => {
                if (item.deliveryInfo?.overseasShippingState) {
                    const totalWeight = item.productWeight * item.totalQuantity;
                    return total + calculateOverseasShippingCost(
                        totalWeight,
                        item.deliveryInfo.overseasShippingKg,
                        item.deliveryInfo.overseasShippingCost
                    );
                }
                return total;
            }, 0);
        } else {
            totalShippingFee += processedStoreItems.reduce((total, item) => total + item.deliveryPrice, 0)
        }
    
        // console.log('Processed Store Items:', processedStoreItems);
        // console.log('Selected Support Items:', selectedSupportItems);
        // console.log('총 상품금액:', totalProductAmount);
        // console.log('총 배송비:', totalShippingFee);
    
        navigate('/cart/payment', {
            state: {
                selectedStoreItems: processedStoreItems,
                selectedSupportItems,
                totalProductAmount,
                totalShippingFee,
                selectDelivery
            }
        });
    };
    

    const handlePopularProduct = () => {
        navigate('/market/popular/product');
    }
    const handleSuggestSupport = () => {
        navigate('/market/popular/support');
    }

    useEffect(() => {
        console.log('selectDelivery : ', selectDelivery);
    }, [selectDelivery])

    const addAddress = (sido, sigungu, streetNameAddress) => {
        return `${sido} ${sigungu} ${streetNameAddress}`;
    };

    const onDeliveryUpdate = (item) => {
        setUpdateDeliveryInfo(item);
        setShowDeliveryUpdate(true);
    };

    const onDeliveryDelete = async (deliveryNo) => {
        const response = await auth.deliveryDelete(deliveryNo);
        const data = response.data;

        if (data === 'SUCCESS') {
            alert('배송지가 삭제되었습니다.');
            userDeliverySelect();
            setSelectDelivery(null);
        }
    };

    const handleCountry = () => {
        setShowCountry(true);
    };

    const handleCountryClose = () => {
        setShowCountry(false);
    };

    const onAddDeliveryName = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryName: e.target.value
        });
    };

    const onAddDeliveryUser = (e) => {
        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryUser: e.target.value
        });
    };
    const onAddDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setAddDeliveryInfo({
            ...addDeliveryInfo, detailAddress: e.target.value
        });
    };
    const handleAddPhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setAddDeliveryInfo({
            ...addDeliveryInfo, deliveryPhone: e.target.value
        });
    };
    const onAddDeliverySave = async () => {
        try {
            const response = await auth.deliveryAdd(addDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                alert('배송지가 등록되었습니다.');
                userDeliverySelect();
                setShowDeliveryAdd(false);
            };
        } catch (error) {
            console.error('error : ', error);
        }
    };
    function handleSelectedCountry(country) {
        if (!country) {
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });
    }
    const onUpdateDeliveryUser = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryUser: e.target.value
        });
    };
    const onUpdateDeliveryName = (e) => {
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryName: e.target.value
        });
    };
    const onDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, detailAddress: e.target.value
        });
    };
    const onGlobalDetailAddress = (e) => {
        setDetailAddress(e.target.value);
        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, globalDtailAddress: e.target.value
        });
    };
    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);

        setUpdateDeliveryInfo({
            ...updateDeliveryInfo, deliveryPhone: e.target.value
        });
    };
    const onUpdateDeliverySave = async () => {
        try {
            const response = await auth.deliveryUpdate(updateDeliveryInfo);
            const data = response.data;

            if (data === 'SUCCESS') {
                userDeliverySelect();
                setShowDeliveryUpdate(false);
            }
        } catch (error) {
            console.error('error : ', error);
        }
    };
    const handleCloseModal = () => {
        if (address === '') {
            setAddressCheck(false);
        }
        setModalVisible(false);
    };

    const handleAddress = (data) => {
        const refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');

        setZoneCode(data.zonecode);
        setSigungu(data.sigungu);
        setSido(data.sido);
        setroadAddress(refinedRoadAddress);
        setAddress(data.address);

        setUpdateDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setAddDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            zipcode: data.zonecode,
            sigungu: data.sigungu,
            sido: data.sido,
            streetNameAddress: refinedRoadAddress,
            detailAddress: ''
        }));

        setTimeout(() => {
            setAddressCheck(true);
        }, 100);
    };

    const handleIntenationalCountryClose = () => {
        setShowInternationalCountry(false);
        reSet();
    }

    const reSet = () => {
        setIntenationalZipCode('');
    }

    function handleInternationalSelectedCountry(country) {
        
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setInternationalSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img,
            code: country.code
        });
    }

    // 국제 국가 선택 기능
    const handleIntenationalCountry = () => {
        setShowInternationalCountry(true);
    }

    const onInternationalZipCode = (e) => {
        let zipCode = e.target.value;

        setIntenationalZipCode(zipCode);
    }

    const addrSearch = async () => {
        if(intenationalZipCode === '' || internationalSelectedCountry.code === ''){
            alert("Please select your zip code and country.");
            return;
        }

        try {
            setLoading(true);
    
            const response = await auth.addressRequest(intenationalZipCode, internationalSelectedCountry.code);
            const data = response.data;
    
            console.log(data);
            
            if(response.status === 200) {
                setLoading(false);
                console.log('full Address : ', data);
                setInternationalAddressComponent(data);
                setShowAddressCompl(true);
    
            } else {
                alert('Address search failed.');
            }
        } catch (error) {
            console.error(error);
        }

    };
    const onInternationalDtailAddress = (e) => {
        setInternationalAddressComponent({
            ...internationalAddressComponent, detailAddress : e.target.value
        });
    }

    const handleGlobalAddressInsert = async () => {
        console.log('internationalAddressComponent : ', internationalAddressComponent);
        console.log('userId : ', userInfo.userId);
        console.log('internationalSelectedCountry : ', internationalSelectedCountry);

        // const newItem = {
        //     globalZipCode : intenationalZipCode,
        //     globalCountryCode : internationalSelectedCountry.code,
        //     globalAddress : internationalAddressComponent.fullAddress,
        //     globalDtailAddress : internationalAddressComponent.detailAddress,
        //     globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
        //     globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
        //     globalCountryNumber : internationalSelectedCountry.number,
        //     userId : userInfo && userInfo?.userId
        // }

        // console.log('newItem : ', newItem);

        // const response = await auth.globalAddressUpdate(newItem);
        // const data = response.data;

        // if(data === 'SUCCESS') {
        //     setActiveIntenationalSearch(false);
        //     setShowAddressCompl(false);
        //     // fetchData();
        // }

        setUpdateDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            globalZipCode : intenationalZipCode,
            globalCountryCode : internationalSelectedCountry.code,
            globalAddress : internationalAddressComponent.fullAddress,
            globalDtailAddress : internationalAddressComponent.detailAddress,
            globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
            globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
            globalCountryNumber : internationalSelectedCountry.number
        }));

        setAddDeliveryInfo((prevInfo) => ({
            ...prevInfo,
            globalZipCode : intenationalZipCode,
            globalCountryCode : internationalSelectedCountry.code,
            globalAddress : internationalAddressComponent.fullAddress,
            globalDtailAddress : internationalAddressComponent.detailAddress,
            globalCity : internationalAddressComponent.city || internationalAddressComponent.city_district || internationalAddressComponent.town || internationalAddressComponent._normalized_city,
            globalState : internationalAddressComponent.state || internationalAddressComponent.province || internationalAddressComponent.county,
            globalCountryNumber : internationalSelectedCountry.number
        }));

            setActiveIntenationalSearch(false);
            setShowAddressCompl(false);
            setGlobalAddressSearch(true);
    }

    const isGlobalAddressConfirm = () => {
        return (
            addDeliveryInfo.deliveryName &&
            addDeliveryInfo.deliveryUser &&
            // addDeliveryInfo.globalAddress &&
            addDeliveryInfo.globalZipCode &&
            addDeliveryInfo.deliveryPhone 
        )
    }

    const selectedDelivery = (item) => {
        if (item.deliveryState === '해외') {
            // 조건에 맞지 않는 상품을 필터링하여 목록 생성
            const invalidItems = cartList
                .filter((cartItem) => {
                    return !(cartItem.consignmentStatus === true || 
                             cartItem.sellerId === 'admin' || 
                             cartItem.sellerId === 'dailyduck');
                })
                .map((cartItem) => `${cartItem.shopName} - ${cartItem.productName}`);
            
            if (invalidItems.length > 0) {
                // 조건에 맞지 않는 상품이 있을 경우 경고창 표시
                MySwal.fire({
                    customClass: {
                        popup: `${admin.admin_seller_join_popup}`,
                    },
                    html: `
                        <div>
                          <div class='${admin.admin_seller_join_title}'><span>해외배송이 불가능한 상품이 포함되어 있습니다.</span></div>
                          <div class='${admin.admin_seller_join_sub_title}'><span>(This order contains products that cannot be shipped overseas.)</span></div>
                          <div class='${admin.admin_seller_join_sub_title}'><span>상품 목록:</span><br>${invalidItems.join('<br>')}</div>
                          <div class='${admin.admin_seller_join_confirm_btn_box}'>
                            <button id='address_confirm'>확인</button>
                          </div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
    
                document.addEventListener('click', addressConfirm);
    
                function addressConfirm(e) {
                    if (e.target && e.target.id === 'address_confirm') {
                        MySwal.close();
                        document.removeEventListener('click', addressConfirm);
                    }
                }
            } else {
                // 모든 아이템이 조건에 부합할 때만 배송지 설정
                setSelectDelivery(item);
            }
        } else {
            setSelectDelivery(item); // 국내 배송일 경우
        }
    };

    const isFormValid = () => {
        return (
          selectedCountry &&
          addDeliveryInfo?.deliveryName !== '' &&
          addDeliveryInfo?.deliveryUser !== '' &&
          addDeliveryInfo?.zipcode !== '' &&
          addDeliveryInfo?.sido !== '' &&
          addDeliveryInfo?.sigungu !== '' &&
          addDeliveryInfo?.streetNameAddress !== '' &&
          phoneNumber.trim() !== ''
        );
    };
    const isUpdateFormValid = () => {
        return (
          selectedCountry &&
          updateDeliveryInfo?.deliveryName !== '' &&
          updateDeliveryInfo?.deliveryUser !== '' &&
          updateDeliveryInfo?.zipcode !== '' &&
          updateDeliveryInfo?.sido !== '' &&
          updateDeliveryInfo?.sigungu !== '' &&
          updateDeliveryInfo?.streetNameAddress !== '' &&
          updateDeliveryInfo.deliveryPhone !== ''
        );
    };
    
    

    return (
        <>
            <div className={`${cart.cart_container}`}>
                <div className={`${cart.cart_header} py-3`}>
                    <div className={`${cart.cart_header_back}`}><button onClick={handleBack}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                    <div className={`${cart.cart_header_title}`}><span>장바구니</span></div>
                </div>
                <div className={`${cart.cart_tap_btn}`}>
                    <div className={viewMode === 'all' ? cart.cart_tab_btn_active : ''}><button className={viewMode === 'all' ? cart.cart_tab_btn_active_color : ''} onClick={() => setViewMode('all')}>전체({cartList.length + supportCartList.length})</button></div>
                    <div className={viewMode === 'store' ? cart.cart_tab_btn_active : ''}><button className={viewMode === 'store' ? cart.cart_tab_btn_active_color : ''} onClick={() => setViewMode('store')}>스토어({cartList.length})</button></div>
                    <div className={viewMode === 'support' ? cart.cart_tab_btn_active : ''}><button className={viewMode === 'support' ? cart.cart_tab_btn_active_color : ''} onClick={() => setViewMode('support')}>서포트({supportCartList.length})</button></div>
                </div>
                {
                    cartList.length === 0 && supportCartList.length === 0 ?
                    <div className={cart.cart_no_item_container}>
                        <div className={cart.cart_no_item_box}>
                            <div><img src="../../img/shop/cart.png" alt="" /></div>
                            <div><span>장바구니가 비어있어요</span></div>
                            <div><span>데일리덕의 인기상품을 보러 갈까요?</span></div>
                            <div>
                                <div><button onClick={handlePopularProduct}>인기덕템 보러가기</button></div>
                                <div><button onClick={handleSuggestSupport}>추천 서포트 보러가기</button></div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className='w-100 px-3 py-2'>
                            {
                                selectDelivery ?
                                    <button className={`${cart.cart_address_select}`} onClick={() => setShowDeliveryChange(true)}>
                                        <div className={`d-flex ${cart.cart_address_select_left}`}>
                                            <div><span className="material-symbols-outlined">location_on</span></div>
                                            <div className={`${cart.cart_address_info_box} ps-2`}>
                                                <div className={`${cart.cart_address_select_default}`}><span>{selectDelivery.deliveryName}</span></div>
                                                <div>
                                                    <span>
                                                        {selectDelivery.deliveryState === '국내' ? 
                                                            addAddress(selectDelivery.sido, selectDelivery.sigungu, selectDelivery.streetNameAddress) + ' ' + selectDelivery.detailAddress  :
                                                            selectDelivery.globalAddress + ' ' + selectDelivery.globalDetailAddress
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${cart.cart_address_select_right}`}><span className="material-symbols-outlined">chevron_right</span></div>
                                    </button>
                                :
                                <div className='py-3 text-center dd-fs-8'>
                                    <div><span>회원 정보에 등록된 배송지가 없습니다.</span></div>
                                    <div><span>배송지 등록을 진행해주세요.</span></div>
                                    <div className='pt-2'><button  className={`${cart.cart_address_select_delivery_add_btn}`} onClick={() => setShowDeliveryChange(true)}>배송지 등록</button></div>
                                </div>

                            }
                        </div>
                        <div className={`d-flex justify-content-between px-3 py-2 ${cart.cart_select_con}`}>
                            <div className={`${cart.cart_select_box}`}>
                                <div className={cart.cart_select_input_box}>
                                    <input
                                        type="checkbox"
                                        id="all_check"
                                        checked={isAllSelected()}
                                        onChange={handleSelectAll}
                                    />
                                </div>
                                <div className={`${cart.cart_select_input_label_box} ps-2`}>
                                    <label htmlFor="all_check">전체선택
                                        (
                                        {viewMode === 'store' ?
                                            cartList.reduce((acc, item) => {
                                                if (item.selectOption) {
                                                    // 옵션이 있는 경우 id로 그룹화된 옵션 수를 카운트
                                                    const groupedOptions = groupByOptionId(item.selectOption);
                                                    return acc + groupedOptions.filter(opt => opt.isSelected).length;
                                                } else {
                                                    return acc + (item.isSelected ? 1 : 0); // 옵션 없는 경우 기본 1개
                                                }
                                            }, 0)
                                        : viewMode === 'support' ?
                                            supportCartList.reduce((acc, item) => acc + (item.isSelected ? 1 : 0), 0) // 서포트 리스트
                                        : 
                                            cartList.reduce((acc, item) => {
                                                if (item.selectOption) {
                                                    const groupedOptions = groupByOptionId(item.selectOption);
                                                    return acc + groupedOptions.filter(opt => opt.isSelected).length;
                                                } else {
                                                    return acc + (item.isSelected ? 1 : 0);
                                                }
                                            }, 0) + supportCartList.reduce((acc, item) => acc + (item.isSelected ? 1 : 0), 0)
                                        }
                                        /
                                        {viewMode === 'store' ?
                                            cartList.reduce((acc, item) => {
                                                if (item.selectOption) {
                                                    // id로 그룹화된 옵션 수만 카운트
                                                    const groupedOptions = groupByOptionId(item.selectOption);
                                                    return acc + groupedOptions.length;
                                                } else {
                                                    return acc + 1; // 옵션 없는 경우 기본 1개
                                                }
                                            }, 0)
                                        : viewMode === 'support' ?
                                            supportCartList.length // 서포트 리스트 전체 수
                                        : 
                                            cartList.reduce((acc, item) => {
                                                if (item.selectOption) {
                                                    const groupedOptions = groupByOptionId(item.selectOption);
                                                    return acc + groupedOptions.length;
                                                } else {
                                                    return acc + 1;
                                                }
                                            }, 0) + supportCartList.length
                                        }
                                        )
                                    </label>
                                </div>
                            </div>
                            <div className={`${cart.cart_select_btn_box}`}>
                                <button onClick={handleSelectDelete}>선택삭제</button>
                            </div>
                        </div>


                        <div className={`${cart.cart_render_box}`}>
                            {renderCartItems().items}
                        </div>
                        <div className={`${cart.cart_footer}`}>
                            <div>
                                <div className={`${cart.cart_payment_title}`}><span>결제금액</span></div>
                                <div className={`${cart.cart_payment_content} py-2`}>
                                    <div className={`${cart.cart_payment_content_info_box}`}>
                                        <div><span>총 상품금액</span></div>
                                        <div><span>{renderCartItems().grandTotalProductAmount.toLocaleString()}원</span></div>
                                    </div>
                                    <div className={`${cart.cart_payment_content_info_box}`}>
                                        <div><span>총 배송비</span></div>
                                        <div><span>{renderCartItems().totalShippingFee.toLocaleString()}원</span></div>
                                    </div>
                                </div>
                                <div className={`${cart.cart_payment_total} py-3`}>
                                    <div><span>결제예정금액</span></div>
                                    <div><span>{(renderCartItems().grandTotalProductAmount + renderCartItems().totalShippingFee).toLocaleString()}원</span></div>
                                </div>
                                <div className={`${cart.cart_payment_info}`}><span>쿠폰 및 포인트는 주문서에서 사용 가능합니다.</span></div>
                            </div>
                            <div className={`${cart.cart_payment_btn}`}>
                                <button onClick={() => onCartPayment()}>
                                    총 {
                                        cartList.reduce((acc, item) => {
                                            // 옵션이 있는 경우, 옵션들을 그룹화한 후 선택된 옵션들만 카운트
                                            if (item.selectOption) {
                                                const groupedOptions = groupByOptionId(item.selectOption);
                                                return acc + groupedOptions.filter(opt => opt.isSelected).length;
                                            } 
                                            // 옵션이 없는 경우, isSelected가 true이면 카운트
                                            else {
                                                return acc + (item.isSelected ? 1 : 0);
                                            }
                                        }, 0) + 
                                        supportCartList.reduce((acc, item) =>
                                            acc + (item.isSelected ? 1 : 0), 0)
                                    }건 주문하기
                                </button>
                            </div>
                        </div>

                    </div>
                }
            </div>
            <ToastContainer />

            {
                isLoading &&
                <div className={cart.cart_loading_back}>
                    <div><img src="../../img/kwangduck_loading.gif" alt="" /></div>
                </div>
            }
            {showDeliveryChange &&
                <div className={`${store.store_delivery_change_modal_box}`}>
                    <div>
                        <div className={`${store.store_delivery_change_modal_header}`}>
                            <div><button onClick={() => setShowDeliveryChange(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                            <div><span>배송지 변경</span></div>
                        </div>
                        <div className={`${store.store_delivery_change_modal_content}`}>
                            {
                                Array.isArray(userDeliveryList) && userDeliveryList.map((item, index) => (
                                    <button
                                        key={index}
                                        className={`${store.store_delivery_change_modal_list_box}`}
                                        onClick={() => selectedDelivery(item)}
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    <span>{item.deliveryUser} ({item.deliveryName})</span>
                                                </div>
                                                {
                                                    item.deliveryType === 1 &&
                                                    <div><span>기본배송지</span></div>
                                                }
                                            </div>
                                            <div>
                                                <input type="radio" name="delivery" checked={selectDelivery !== null && selectDelivery.deliveryNo === item.deliveryNo} readOnly />
                                            </div>
                                        </div>
                                        <div><span>{item.deliveryPhone}</span></div>
                                        {/* <div>
                                            <div><span>{item.deliveryState === '국내' ? item.zipcode : item.globalZipCode}</span></div>
                                            <div>
                                                <span>
                                                    {
                                                        item.deliveryState === '국내' ? 
                                                            addAddress(item.sido, item.sigungu, item.streetNameAddress) :
                                                            item.globalAddress
                                                    }
                                                </span>
                                            </div>
                                            <div><span>{item.deliveryState === '국내' ? item.detailAddress : item.globalDetailAddress}</span></div>
                                        </div> */}
                                        
                                        <div>
                                            <div><span>{item.deliveryState === '국내' ? item.zipcode : item.globalZipCode}</span></div>
                                            <div><span>{item.deliveryState === '국내' ? addAddress(item.sido, item.sigungu, item.streetNameAddress) : item.globalAddress}</span></div>
                                            <div><span>{item.deliveryState === '국내' ? item.detailAddress : item.globalDetailAddress}</span></div>
                                        </div>
                                        <div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryUpdate(item) }}>수정하기</button></div>
                                            <div><button onClick={(e) => { e.stopPropagation(); onDeliveryDelete(item.deliveryNo) }}>삭제하기</button></div>
                                        </div>
                                    </button>
                                ))
                            }
                        </div>
                        <div className={`${store.store_delivery_change_modal_footer}`}>
                        <div><button onClick={() => setShowDeliveryBubbleSelect(!showDeliveryBubbleSelect)}>배송지 추가</button></div>
                            <div><button onClick={() => setShowDeliveryChange(false)}>배송지 선택</button></div>
                        </div>
                        
                        {
                            showDeliveryBubbleSelect &&
                            <div className={`${store.store_delivery_change_modal_btn_select}`}>
                                <div className={`${store.store_delivery_change_modal_btn_bubble}`}>
                                    <div><button onClick={() => { setShowDeliveryBubbleSelect(false); setShowDeliveryAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId, deliveryState: '국내' }); }}>국내 배송지</button></div>
                                    <div><button onClick={() => { setShowDeliveryOverseasAdd(true); setAddDeliveryInfo({ ...addDeliveryInfo, deliveryUserId: userInfo.userId, deliveryState: '해외' });}}>Global Address</button></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
            {showDeliveryAdd &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryAdd(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 추가</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'우편번호'} value={addDeliveryInfo?.zipcode ? addDeliveryInfo?.zipcode : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'특별시/광역시/도'} value={addDeliveryInfo?.sido ? addDeliveryInfo?.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'시/군/구'} value={addDeliveryInfo?.sigungu ? addDeliveryInfo?.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'도로명 주소'} value={addDeliveryInfo?.streetNameAddress ? addDeliveryInfo?.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'상세주소'} value={addDeliveryInfo?.detailAddress ? addDeliveryInfo?.detailAddress : ''} onChange={(e) => onAddDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={'핸드폰 번호'} onChange={(e) => handleAddPhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button className={`${isFormValid() ? store.store_global_address_active : ''}`} onClick={onAddDeliverySave} disabled={!isFormValid()}>완료</button>
                    </div>
                </div>
            }
            {showDeliveryOverseasAdd &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryOverseasAdd(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>Global address add</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>Country</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='Country' onClick={handleCountry}>
                                        <span className='country_name_input'>Country Select</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='Country' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>Shipping address name</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>User Name</span></div>
                            <div><input type="text" className='join_input_tag' onChange={(e) => onAddDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => setActiveIntenationalSearch(true)}>International Address Search</button>
                                </div>
                            </div>
                            {/* <div className={`${store.adress_box}`}> */}
                                {/* <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'우편번호'} value={addDeliveryInfo?.zipcode ? addDeliveryInfo?.zipcode : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'특별시/광역시/도'} value={addDeliveryInfo?.sido ? addDeliveryInfo?.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'시/군/구'} value={addDeliveryInfo?.sigungu ? addDeliveryInfo?.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'도로명 주소'} value={addDeliveryInfo?.streetNameAddress ? addDeliveryInfo?.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={'상세주소'} value={addDeliveryInfo?.detailAddress ? addDeliveryInfo?.detailAddress : ''} onChange={(e) => onAddDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div> */}
                                
                                <div className={`${dailyduck.my_info_international_box}`}>
                                    <div className=''>
                                        <span>zip code</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalZipCode : 'zip-code'} disabled={true} />
                                    </div>
                                    <div className='pt-1'>
                                        <span>address</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalAddress : 'address'} value={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <span>detail address</span>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.globalDtailAddress : 'detail Address'} value={updateDeliveryInfo ? updateDeliveryInfo.globalDtailAddress : ''} onChange={(e) => onGlobalDetailAddress(e)} disabled={!globalAddressSearch}/>
                                    </div>
                                    {/* <div className={`${dailyduck.my_info_international_btn}`}><button onClick={() => setActiveIntenationalSearch(true)}>International Address Search</button></div> */}
                                </div>
                            {/* </div> */}
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>Phone number</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={'phone number'} onChange={(e) => handleAddPhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button className={`${isGlobalAddressConfirm() ? store.store_global_address_active : ''}`} onClick={onAddDeliverySave} disabled={!isGlobalAddressConfirm()}>SUCCESS</button>
                    </div>
                </div>
            }
            {showDeliveryUpdate &&
                <div className={`${store.store_delivery_update_box}`}>
                    <div className={`${store.store_delivery_update_box_header}`}>
                        <div><button onClick={() => setShowDeliveryUpdate(false)}><span className="material-symbols-outlined">arrow_back_ios</span></button></div>
                        <div><span>배송지 수정</span></div>
                    </div>
                    <div className={`${store.store_delivery_update_box_content}`}>
                        <div className='myinfo_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='py-3'>
                            <div><span>배송지이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryName} onChange={(e) => onUpdateDeliveryName(e)} /></div>
                        </div>
                        <div className='py-3'>
                            <div><span>이름</span></div>
                            <div><input type="text" className='join_input_tag' value={updateDeliveryInfo.deliveryUser} onChange={(e) => onUpdateDeliveryUser(e)} /></div>
                        </div>
                        <div className=''>
                            <div className={`${store.store_delivery_update_address_search_box}`}>
                                <div>
                                    <span>배송지</span>
                                </div>
                                <div className='pt-1'>
                                    <button onClick={() => { setModalVisible(true); setAddressCheck(true); }}>주소검색</button>
                                </div>
                            </div>
                            <div className={`${store.adress_box}`}>
                                <div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.zipcode : '우편번호'} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sido : '특별시/광역시/도'} value={updateDeliveryInfo ? updateDeliveryInfo.sido : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.sigungu : '시/군/구'} value={updateDeliveryInfo ? updateDeliveryInfo.sigungu : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : '도로명 주소'} value={updateDeliveryInfo ? updateDeliveryInfo.streetNameAddress : ''} disabled={true} />
                                    </div>
                                    <div className='py-1'>
                                        <input type="text" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.detailAddress : '상세주소'} onChange={(e) => onDetailAddress(e)} disabled={!addressCheck} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-3'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className={`d-flex ${store.myinfo_phone_box}`}>
                                <div className={`join_input ${store.phone_number_box} pe-1`}>
                                    <input type="number" className='join_input_tag' placeholder={updateDeliveryInfo ? updateDeliveryInfo.deliveryPhone : '핸드폰 번호'} value={updateDeliveryInfo.deliveryPhone} onChange={(e) => handlePhoneNumber(e)} />
                                    <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${store.store_delivery_update_box_footer}`}>
                        <button className={`${isUpdateFormValid() ? store.store_global_address_active : ''}`} onClick={onUpdateDeliverySave} disabled={!isUpdateFormValid()}>완료</button>
                    </div>
                </div>
            }
            {
                showCountry &&
                <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose}
                    searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                    filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                    setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
            }
            {
                modalVisible && (
                    <PostModal
                        onClose={handleCloseModal}
                        onComplete={handleAddress}
                        store={store}
                    />
                )
            }
            {
                showInternationalCountry &&
                    <AddressCountry showInternationalCountry={showInternationalCountry} setShowInternationalCountry={setShowInternationalCountry} handleIntenationalCountryClose={handleIntenationalCountryClose} 
                            searchTermInternational={searchTermInternational} setSearchTermInternational={setSearchTermInternational} countries={countries}
                            filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                            setInternationalSelectedCountry={setInternationalSelectedCountry} handleInternationalSelectedCountry={handleInternationalSelectedCountry} />
            }
            {
            activeIntenationalSearch && 
                <div className={`${dailyduck.my_info_international_modal_container}`}>
                    <div className={`${dailyduck.my_info_international_modal_box}`}>
                        <div className={`${dailyduck.my_info_international_modal_header}`}>
                            <div><span>Address Search</span></div>
                            <div><button onClick={() => setActiveIntenationalSearch(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div>
                            <div className={`myinfo_input_box pt-3 px-3 ${dailyduck.my_info_international_country_box}`}>
                                <div><span>Select country</span></div>
                                <div className='join_input'>
                                    {!internationalSelectedCountry ? 
                                        <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                            <span className='country_name_input'>국가선택</span>
                                            <span className="material-symbols-outlined">expand_more</span>
                                        </button> : 
                                        <button type="text" className='d-flex' placeholder='국가' onClick={handleIntenationalCountry}>
                                            <img src={internationalSelectedCountry?.img} alt={internationalSelectedCountry?.name} />
                                            <span className='country_name_input'>{internationalSelectedCountry?.name}</span>
                                            <span className="material-symbols-outlined">expand_more</span>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className={`${dailyduck.my_info_international_zip_code}`}>
                                <div><span>zip code</span></div>
                                <div><input type="text" value={intenationalZipCode !== '' ? intenationalZipCode : ''} onChange={(e) => onInternationalZipCode(e)}/></div>
                            </div>
                            <div className={`${dailyduck.my_info_international_modal_search_btn}`}>
                                <div><button onClick={addrSearch}>search</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
            showAddressCompl &&
            <div className={`${dailyduck.my_info_international_compl_address_container}`}>
                <div className={`${dailyduck.my_info_international_compl_address_box}`}>
                    <div className={`${dailyduck.my_info_international_compl_address_zip_code}`}>
                        <div><span>zip code</span></div>
                        <div><input type="text" value={intenationalZipCode} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_basic_address}`}>
                        <div><span>address</span></div>
                        <div><input type="text" value={internationalAddressComponent.fullAddress} readOnly={showAddressCompl}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_detail_address}`}>
                        <div><span>detail address</span></div>
                        <div><input type="text" onChange={(e) => onInternationalDtailAddress(e)}/></div>
                    </div>
                    <div className={`${dailyduck.my_info_international_compl_address_btn}`}><button onClick={handleGlobalAddressInsert}>저장</button></div>
                </div>
            </div>
            }
        </>
    );
};

const DateRangePicker = ({ cartNo, startDate, endDate, onChange, cart, sellerId }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [localStartDate, setLocalStartDate] = useState(startDate);
    const [localEndDate, setLocalEndDate] = useState(endDate);

    const toKSTISOString = (date) => {
        const kstDate = new Date(date.getTime() + 9 * 60 * 60 * 1000);
        return kstDate.toISOString().substring(0, 10);
    };

    const handleDateChange = (newStartDate, newEndDate) => {
        // 조건 충족 시에만 onChange 호출
        if (newStartDate && newEndDate) {
            onChange(cartNo, newStartDate, newEndDate, sellerId);
        }
    };

    return (
        <div>
            <input
                type="text"
                value={`${toKSTISOString(startDate)} - ${toKSTISOString(endDate)}`}
                onClick={() => setShowCalendar(!showCalendar)}
                readOnly
                className={`${cart.cart_date_input}`}
            />
            {showCalendar && (
                <div className={`calendar-popup ${cart.cart_date_popup}`}>
                    <input
                        type="date"
                        value={toKSTISOString(localStartDate)}
                        onChange={(e) => {
                            const newStartDate = new Date(e.target.value);
                            setLocalStartDate(newStartDate);
                        }}
                    />
                    <span className='mx-1'> ~ </span>
                    <input
                        type="date"
                        value={toKSTISOString(localEndDate)}
                        onChange={(e) => {
                            const newEndDate = new Date(e.target.value);
                            setLocalEndDate(newEndDate);
                        }}
                    />
                    <button
                        onClick={() => {
                            if (localStartDate && localEndDate) {
                                handleDateChange(localStartDate, localEndDate);
                            }
                            setShowCalendar(false);
                        }}
                        className={cart.cart_date_change_btn}
                    >
                        확인
                    </button>
                </div>
            )}
        </div>
    );
};

export default MarketCart;
