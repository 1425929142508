import React from 'react'

const TermsOfUse = ( {showTermsOfUse, termsOfUseTransitions, animated, setShowTermsOfUse, labelText, termsInfo, termsStateCheck} ) => {
    const handleTermsOfUseBack = () => {
        setShowTermsOfUse(false);
    }
  return (
    <>
        {showTermsOfUse &&
            <div className="termofuse_background">
                {termsOfUseTransitions((style, item) =>
                    item ? 
                    <animated.div className="termofuse_box" style={style}>
                        <div className='agree_header'>
                            <div>
                                <button onClick={() => handleTermsOfUseBack()}>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div>
                                    <span>[{termsStateCheck(termsInfo.termsState)}] {termsInfo.termsTitle}</span>
                                </div>
                            </div>
                        </div>
                        <div className='agree_info_content'>
                            <div dangerouslySetInnerHTML={{ __html: termsInfo.termsContent }}></div>
                        </div>
                    </animated.div>
                    : null
                )}
            </div>
        }
    </>
  );
};

export default TermsOfUse