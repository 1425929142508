import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider';
import Country from '../join/Country';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dailyduck from '../../containers/my/MyContainner.module.css';

const PasswordSearch = () => {
    const context = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const countries = context.countries;

    const [showCountry, setShowCountry] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const [certificationInputVisible, setCertificationInputVisible] = useState(false);
    const [timer, setTimer] = useState(300);
    const [name, setName] = useState('');
    
    const [mockCertificationNumber, setMockCertificationNumber] = useState('000000');
    const [userCertificationNumber, setUserCertificationNumber] = useState('');
    const [certificationSuccess, setCertificationSuccess] = useState(false); // 인증 성공 상태 추가

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('black');
    const [borderColor, setBorderColor] = useState('');

    const back = useNavigate();
    const account = useNavigate();

    const screenBack = () => {
        back(-1);
    };

    const handleCountry = () => {
        setShowCountry(true);
    };

    const handleCountryClose = () => {
        setShowCountry(false);
    };

    const handleSelectedCountry = (country) => {
        if (!country) {
            // console.log('Country not defined');
            return;
        }

        setSelectedCountry({
            name: country.name,
            number: country.number,
            img: country.img
        });

        // console.log(country.img);
    };

    const handlePhoneNumber = (e) => {
        setPhoneNumber(e.target.value);
    };

    useEffect(() => {
        if (email && selectedCountry && phoneNumber) {
            // console.log(message);
            if (message === '존재하는 이메일입니다.') {
                setIsCheck(true);
            } else {
                setIsCheck(false);
            }
        } else {
            setIsCheck(false);
        }
    }, [email, selectedCountry, phoneNumber]);

    useEffect(() => {
        // 타이머 관리: 컴포넌트가 언마운트될 때 타이머 클리어
        let interval;
        if (certificationInputVisible) {
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setCertificationInputVisible(false);
                        setIsCheck(true); // 인증번호 받기 버튼 활성화
                        return 300;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [certificationInputVisible]);

    const certificationNumber = async () => {
        setIsCheck(false); // 인증번호 받기 버튼 비활성화
        setTimer(300);

        try {
            const response = await auth.phoneAuth(phoneNumber);
            const data = response.data;

            if (data === 'SUCCESS') {
                setCertificationInputVisible(true); // 인증번호 인풋 창 활성화
            }
        } catch (error) {
            console.error('API 호출 중 오류 발생:', error); // 오류 처리
        }
    };

    const checkCertificationNumber = (e) => {
        setUserCertificationNumber(e.target.value);

        // 2초 뒤에 인증번호 검증 요청 보내기
        const timer = setTimeout(async () => {
            try {
                const response = await auth.phoneAuthCheck(phoneNumber, e.target.value);
                const data = response.data;

                if (data === 'SUCCESS') {
                    setIsCheck(true);
                    setCertificationSuccess(true); // 인증번호가 맞으면 인증 성공 상태를 true로 설정
                    setCertificationInputVisible(false);
                    clearInterval(timer); // 인증 성공 시 타이머 초기화
                } else {
                    setIsCheck(false);
                    setCertificationSuccess(false); // 인증번호가 틀리면 인증 성공 상태를 false로 설정
                }
            } catch (error) {
                console.error('인증번호 확인 중 오류 발생:', error);
            }
        }, 2000);

        // 컴포넌트가 언마운트될 때 타이머를 클리어하여 메모리 누수를 방지합니다.
        return () => clearTimeout(timer);
    };

    const resetForm = () => {
        setEmail('');
        setPhoneNumber('');
        setMessage('');
        setColor('');
        setBorderColor('');
        setCertificationSuccess(false);
        setCertificationInputVisible(false);
        setTimer(300); // 타이머 초기화
    };

    const emailSearchNext = async () => {
        try {
            const response = await auth.passwordSearch(email, phoneNumber);
            const data = await response.data;

            console.log(data)

            if (response.status === 200) {
                if (data === 'NoUser') {
                    MySwal.fire({
                        customClass: {
                            popup: `${dailyduck.my_info_address_popup}`
                        },
                        html:
                            `
                        <div>
                        <div class='${dailyduck.my_info_address_title}'><span>사용자가 존재하지 않습니다.</span></div>
                        <div class='${dailyduck.my_info_address_sub_title}'><span>이메일과 핸드폰번호를 확인해주세요.</span></div>
                        <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                            <button id='address_confirm'>확인</button>
                        </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', addressConfirm);

                    function addressConfirm(e) {
                        if (e.target && e.target.id === 'address_confirm') {
                            MySwal.close();
                            document.removeEventListener('click', addressConfirm);
                            resetForm(); // 폼과 타이머 초기화
                        }
                    }
                } else {
                    if(data.google !== null) {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.my_info_address_popup}`
                            },
                            html:
                                `
                            <div>
                            <div class='${dailyduck.my_info_address_title}'><span>구글 소셜 가입회원 입니다.</span></div>
                            <div class='${dailyduck.my_info_address_sub_title}'><span>구글 소셜 로그인을 이용하여 로그인을 진행해주세요.</span></div>
                            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                                <button id='address_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', addressConfirm);
    
                        function addressConfirm(e) {
                            if (e.target && e.target.id === 'address_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', addressConfirm);
                                // resetForm(); // 폼과 타이머 초기화
                            }
                        }
                    }
                    if(data.kakao !== null) {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.my_info_address_popup}`
                            },
                            html:
                                `
                            <div>
                            <div class='${dailyduck.my_info_address_title}'><span>카카오 소셜 가입회원 입니다.</span></div>
                            <div class='${dailyduck.my_info_address_sub_title}'><span>카카오 소셜 로그인을 이용하여 로그인을 진행해주세요.</span></div>
                            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                                <button id='address_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', addressConfirm);
    
                        function addressConfirm(e) {
                            if (e.target && e.target.id === 'address_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', addressConfirm);
                                // resetForm(); // 폼과 타이머 초기화
                            }
                        }
                    }
                    if(data.line !== null) {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.my_info_address_popup}`
                            },
                            html:
                                `
                            <div>
                            <div class='${dailyduck.my_info_address_title}'><span>라인 소셜 가입회원 입니다.</span></div>
                            <div class='${dailyduck.my_info_address_sub_title}'><span>라인 소셜 로그인을 이용하여 로그인을 진행해주세요.</span></div>
                            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                                <button id='address_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', addressConfirm);
    
                        function addressConfirm(e) {
                            if (e.target && e.target.id === 'address_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', addressConfirm);
                                // resetForm(); // 폼과 타이머 초기화
                            }
                        }
                    }

                    if(data.twitter !== null) {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.my_info_address_popup}`
                            },
                            html:
                                `
                            <div>
                            <div class='${dailyduck.my_info_address_title}'><span>트위터 소셜 가입회원 입니다.</span></div>
                            <div class='${dailyduck.my_info_address_sub_title}'><span>트위터 소셜 로그인을 이용하여 로그인을 진행해주세요.</span></div>
                            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                                <button id='address_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', addressConfirm);
    
                        function addressConfirm(e) {
                            if (e.target && e.target.id === 'address_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', addressConfirm);
                                // resetForm(); // 폼과 타이머 초기화
                            }
                        }
                    }

                    if(data.apple !== null) {
                        MySwal.fire({
                            customClass: {
                                popup: `${dailyduck.my_info_address_popup}`
                            },
                            html:
                                `
                            <div>
                            <div class='${dailyduck.my_info_address_title}'><span>애플 소셜 가입회원 입니다.</span></div>
                            <div class='${dailyduck.my_info_address_sub_title}'><span>애플 소셜 로그인을 이용하여 로그인을 진행해주세요.</span></div>
                            <div class='${dailyduck.my_info_address_confirm_btn_box}'>
                                <button id='address_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', addressConfirm);
    
                        function addressConfirm(e) {
                            if (e.target && e.target.id === 'address_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', addressConfirm);
                                // resetForm(); // 폼과 타이머 초기화
                            }
                        }
                    }

                    if(data.google === null && data.twitter === null && data.apple === null && data.google === null && data.line === null) {
                        account('/passwordChange', { state: { userStatus: data } }); // 경로와 state를 객체로 전달
                    }
                }
            } else {
                // 실패시 로직 작성 예정
            }
        } catch (error) {
            console.error(error);
        }
    };

    const emailCheck = (e) => {
        setEmail(e.target.value);
    };

    const isFormFilled = () => {
        // 이메일 형식을 검사하는 정규 표현식
        const emailRegex =
            /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        // 모든 필드가 채워져 있고, 이메일 형식도 유효한지 확인
        return selectedCountry && emailRegex.test(email) && phoneNumber;
    };

    useEffect(() => {
        // 이메일 형식을 검증하는 정규 표현식
        const emailRegex =
            /^[^\s@]+@[^\s@]+\.(com|net|co\.kr|xyz|cn|com\.cn|net\.cn|org\.cn|ph|in|co\.in|jp|co\.jp|tw|com\.tw|sq|com\.sq|hk|com\.hk|co\.id|vn|com\.vn|my|com\.my|ir|cx|af|am|com\.bd|io|co\.il|kg|la|mn|pk|ps|tj|tm|ae|us|co|bz|mx|com\.mx|com\.ar|ag|bo|com\.bo|cr|ac|com\.gt|ht|hn|com\.ni|vc|gs|gd|pa|com\.ve|ca|de|co\.uk|fr|es|com\.es|nl|it|ru|eu|be|me|dk|gr|se|ch|pl|ro|at|az|by|gl|gg|is|im|je|kz|lv|li|lt|mt|md|pt|sm|cz|com\.tr|so|mu|bi|cm|td|com\.et|cg|cd|co\.ls|mw|ma|na|com\.ng|rw|st|sc|co\.bw|tv|cc|ac|co\.ck|as|ws|com\.au|co\.nz|com\.ai|bs|com\.bs|do|com\.do|com\.fj|com\.jm|ki|ly|fm|ms|nr|nu|nf|pn|pr|sh|lccom\.sb|tt|vg|hm|tf)$/;

        if (emailRegex.test(email)) { // 정규 표현식을 사용하여 이메일 형식을 검증
            const timer = setTimeout(() => {
                auth.checkEmail(email)  // emailCheck 함수 호출
                    .then(res => {
                        if (res.data.userEmail) {
                            setMessage('존재하는 이메일입니다.');
                            setColor('green');
                            setBorderColor('green');
                        } else {
                            setMessage('해당 이메일은 존재하지 않습니다.');
                            setColor('rgba(255, 92, 92, 1)');
                            setBorderColor('rgba(255, 92, 92, 1)');
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }, 1000);

            return () => clearTimeout(timer);
        } else {
            // 이메일 형식이 아닌 경우 메시지 업데이트
            if (email !== '') {
                setMessage('유효하지 않은 이메일 형식입니다.');
                setColor('rgba(255, 92, 92, 1)');
                setBorderColor('rgba(255, 92, 92, 1)');
                isFormFilled(false);
            } else {
                setMessage('');
            }
        }
    }, [email]);

    return (
        <>
            <div className='password_search_container'>
                <div className='password_search_box'>
                    <div className='password_search_header'>
                        <div>
                            <button onClick={screenBack}>
                                <span className="material-symbols-outlined">arrow_back_ios</span>
                            </button>
                        </div>
                        <div><span>비밀번호 찾기</span></div>
                    </div>

                    <div>
                        <div className='join_input_box'>
                            <div><span>이메일</span></div>
                            <div>
                                <input type="text" placeholder='이메일' className='join_input_tag' value={email && email} onChange={(e) => emailCheck(e)} />
                                <span style={{ color: color }}>{message}</span>
                            </div>
                        </div>
                        <div className='join_input_box'>
                            <div className='pb-1'><span>국가</span></div>
                            <div className='join_input'>
                                {!selectedCountry ?
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <span className='country_name_input'>국가선택</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button> :
                                    <button type="text" className='d-flex' placeholder='국가' onClick={handleCountry}>
                                        <img src={selectedCountry?.img} alt={selectedCountry?.name} />
                                        <span className='country_name_input'>{selectedCountry?.name}</span>
                                        <span className="material-symbols-outlined">expand_more</span>
                                    </button>
                                }
                            </div>
                        </div>
                        <div className='emailsearch_input_box pb-100p'>
                            <div className='pb-1'><span>휴대폰 번호</span></div>
                            <div className='join_input phone_number_box'>
                                <input type="number" className='join_input_tag' placeholder='휴대폰 번호' value={phoneNumber && phoneNumber} onChange={(e) => handlePhoneNumber(e)} />
                                <span style={{ color: 'rgba(255, 3, 102, 1)' }}>{selectedCountry?.number}</span>
                            </div>
                            {
                                certificationInputVisible && (
                                    <div className='join_input position-relative pt-2'>
                                        <input type="number" className='join_input_tag' placeholder='인증번호' onChange={checkCertificationNumber} />
                                        <span className='cer_span'>{Math.floor(timer / 60)}:{('0' + timer % 60).slice(-2)}</span>
                                    </div>
                                )
                            }
                            {
                                certificationSuccess &&
                                <div><span style={{ color: 'green' }}>인증되었습니다.</span></div>
                            }
                        </div>
                    </div>
                </div>
                {
                    showCountry &&
                    <Country showCountry={showCountry} setShowCountry={setShowCountry} handleCountryClose={handleCountryClose}
                        searchTerm={searchTerm} setSearchTerm={setSearchTerm} countries={countries}
                        filteredCountries={filteredCountries} setFilteredCountries={setFilteredCountries}
                        setSelectedCountry={setSelectedCountry} handleSelectedCountry={handleSelectedCountry} />
                }
                <div className='position-absolute bottom-0 py-4 px-4 w-100'>
                    <div className='cer_btn_box'>
                        {/* "인증번호 받기" 버튼은 인증 성공 전에만 표시되고, 인증 성공 후에는 사라지도록 수정 */}
                        {!certificationSuccess && <button className={`${isCheck && 'cer_active'}`} onClick={certificationNumber} disabled={!isCheck}>인증번호 받기</button>}
                        {certificationSuccess && <button onClick={emailSearchNext} className='cer_active'>비밀번호 변경</button>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordSearch;
