import api from './api';

// 로그인
// export const login = (username, password) => api.post(`/login?username=${username}&password=${password}`)
export const login = (username, password) => api.post('/api/login', {
  userId: username,
  userPw: password
}, {
  headers: {
      'Content-Type': 'application/json'
  }
});

// 사용자 정보
export const info = () => api.get(`/api/users/info`)

// 회원 가입 
export const join = (data) => api.post(`/api/users`, data)

// 카카오 회원 가입 
export const kakaoJoin = (data) => api.post(`/api/users/kakao`, data)

// 트위터 회원 가입 
export const twitterJoin = (data) => api.post(`/api/users/twitter`, data)

// 라인 회원 가입 
export const lineJoin = (data) => api.post(`/api/users/line`, data)

// 구글 회원 가입 
export const googleJoin = (data) => api.post(`/api/users/google`, data)

// 애플 회원 가입 
export const appleJoin = (data) => api.post(`/api/users/apple`, data)

// 최애 등록
export const favorityAdd = (favority) => api.post('/api/users/favority', favority)

// 회원 정보 수정
export const update = (data) => api.put(`/api/users`, data)

// 회원 탈퇴
export const remove = (userId) => api.delete(`/api/users/${userId}`)

// 아이디 체크
export const checkUserId = (userId) => api.post(`/api/users/idcheck`, { userId: userId })

// 이메일 체크
export const checkEmail = (email) => api.post(`/api/users/emailcheck`, { userEmail: email })

// 닉네임 체크
export const checkNickName = (nickName) => api.post(`/api/users/nickcheck`, {userNick : nickName})

// 추천 코드 체크
export const recommend = (recommend) => api.post(`/api/users/recommendCheck`, {recommender : recommend})

// 인증번호 생성
export const cernumber = () => api.get('/api/cernumber')


// 이메일 찾기
export const emailSearch = (name, phoneNumber) => api.post(`/api/users/emailSearch`, { userName: name, userTel: phoneNumber });

// 패스워드 찾기
export const passwordSearch = (email, phoneNumber) => api.post(`/api/users/passwordSearch`, { userEmail: email, userTel: phoneNumber });

export const emailCheck = (email) => api.post('/api/request-verification', { email });

export const emailAuthNumberCheck = (email, code) => api.post('/api/verify-code', {email, code})

// 유저 정보 조회
export const userselect = (userId) => api.get(`/api/users/userselect/${userId}`)

// 패스워드 확인
export const pwCheck = (userPwCheck, userPw) => api.post('/api/users/userPwCheck', {userPwCheck : userPwCheck, userPw : userPw})

// 패스워드 변경
export const pwChange = (userPw, userId) => api.post('/api/users/userPwChange', { userPw: userPw, userId: userId }, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

// 유저 최애 정보
export const favorityList = (userId) => api.get(`/api/users/userfavority/${userId}`)

// 주소 정보 수정
export const addressChange = (user) => api.post('/api/users/addressChange', user)

// 핸드폰 번호 수정
export const phoneChange = (userTel, userId) => api.post('/api/users/phoneNumberChange', {userTel : userTel, userId: userId}, {
  headers: {
    'Content-Type': 'application/json'
  }
});

// 응원마켓 최애 / 순서 변경
export const updateFavority = (favorityNo, favorityName, favorityStatus, favorityRanking, userId) => api.post('/api/users/updateFavority', {favorityNo, favorityName, favorityStatus, favorityRanking, userId})

// 응원마켓 최애 단일 정보 가져오기
export const myFavorityUpdate = (favorityNo, userId) =>api.post('/api/users/myfavorityUpdate', {favorityNo, userId})

// 응원마켓 최애 디데이 수정
export const ddayUpdate = (favorityNo, favorityDday, userId) => api.post('/api/users/myfavorityDdayUpdate', {favorityNo, favorityDday, userId})

// 최애 삭제
export const favorityDelete = (favorityNo) => api.delete(`/api/users/favoritydelete/${favorityNo}`);

// 유저 전체 조회
export const userAllSelect = (currentPage, itemsPerPage) => api.get(`/api/users/userAll?page=${currentPage}&size=${itemsPerPage}`);

// 검색 결과 전달
export const userSearchSelect = (currentPage, itemsPerPage, formData, config) => api.post(`/api/users/userSearch?page=${currentPage}&size=${itemsPerPage}`, formData, config);
  
// 핸드폰 번호 문자 인증
export const phoneAuth = (userTel) => api.post(`/api/phone-verification`, { userTel : userTel });
export const overseasPhoneAuth = (userTel) => api.post(`/api/overseasPhoneVerification`, { userTel : userTel });

export const phoneAuthCheck = (userTel, code) => api.post(`/api/phone-verify-code`, {userTel, code});

// 셀러 회원가입
export const sellerJoin = (userInput) => api.post(`/api/users/sellerJoin`, userInput);
export const sellerSupportJoin = (userInput) => api.post(`/api/users/sellerSupportJoin`, userInput);

// 유저 예약정보 조회
export const reservationSelect = (userId) => api.get(`/api/users/reservationSelect/${userId}`);

// 서포트 작성 리뷰 조회
export const supportReviewComplete = (userId) => api.get(`/api/users/supportReviewComplete/${userId}`);

// 카카오톡 인가코드 발급
export const kakaoAuth = () => api.get('/api/auth/kakao/login-url');

// 카카오 유저 확인
export const userSearch = (id) => api.get(`/api/users/kakao/userSearch/${id}`);

// 카카오 유저 조회
export const kakaoUserSelect = (id) => api.get(`/api/users/kakao/kakaoUserSelect/${id}`);

// 소셜 로그인을 위한 토큰 발급
export const getToken = (user) => api.post('/api/users/auth/token', user);

// 트위터 로그인 URL
export const getTwitterAuthUrl = () => api.get(`/api/oauth2/twitter`);

// 트위터 유저 확인
export const twitterUserSearch = (id) => api.get(`/api/users/twitter/twitterUserSearch/${id}`);

// 트위터 유저 조회
export const twitterUserSelect = (id) => api.get(`/api/users/twitter/twitterUserSelect/${id}`);

// 라인 유저 확인
export const lineUserSearch = (id) => api.get(`/api/users/line/lineUserSearch/${id}`);

// 라인 유저 조회
export const lineUserSelect = (id) => api.get(`/api/users/line/lineUserSelect/${id}`);

// 구글 서버 인증
export const googleUserInfo = (code, state) => api.post('/api/auth/google/callback', {code, state}, {
  headers: {
    'Content-Type': 'application/json'
  }
});

// 구글 유저 확인
export const googleUserSearch = (id) => api.get(`/api/users/google/googleUserSearch/${id}`);

// 구글 유저 조회
export const googleUserSelect = (id) => api.get(`/api/users/google/googleUserSelect/${id}`);

// 애플 유저 확인
export const appleUserSearch = (id) => api.get(`/api/users/apple/appleUserSearch/${id}`);

// 애플 유저 조회
export const appleUserSelect = (id) => api.get(`/api/users/apple/appleUserSelect/${id}`);

// 유저 배송지 리스트 조회
export const userDeliverySelect = (userId) => api.get(`/api/users/userDeliverySelect/${userId}`);

// 유저 배송지 업데이트
export const deliveryUpdate = (updateDeliveryInfo) => api.put(`/api/users/deliveryUpdate`, updateDeliveryInfo);

// 유저 배송지 등록
export const deliveryAdd = (addDeliveryInfo) => api.post(`/api/users/deliveryAdd`, addDeliveryInfo);

// 유저 배송지 삭제
export const deliveryDelete = (deliveryNo) => api.delete(`/api/users/deliveryDelete/${deliveryNo}`);

// 유저 스토어 주문 이력 조회
export const storeOrderSelect = (userId) => api.get(`/api/users/storeOrderSelect/${userId}`);

// 유저 서포트 예약 이력 조회
export const supportOrderSelect = (userId) => api.get(`/api/users/supportOrderSelect/${userId}`);

// 유저 스토어 주문 취소
export const storeOrderCancel = (cancelContent) => api.put(`/api/users/storeOrderCancel`, cancelContent);

// 유저 스토어 반품 신청
export const returnAdd = (returnContent) => api.put(`/api/users/returnAdd`, returnContent);

// 유저 스토어 주문 취소 신청
export const storeOrderCancelRequest = (cancelContent) => api.put(`/api/users/storeOrderCancelRequest`, cancelContent);

// 유저 스토어 교환 신청
export const exchangeApply = (exchangeContent) => api.put(`/api/users/exchangeApply`, exchangeContent);

// 유저 쿠폰 조회
export const userCouponSelect = (userId) => api.get(`/api/users/userCouponSelect/${userId}`);

// 검색
export const searchData = (search, userId) => api.post(`/api/users/searchData`, {search : search, userId : userId});

// 유저 키워드 조회
export const keywordSelect = (userId) => api.get(`/api/users/keywordSelect/${userId}`);

// 유저 키워드 제거
export const keywordDelete = (userId, keyword) => api.delete(`/api/users/keywordDelete`, {params : {userId, keyword}})

// 전체 키워드 조회
export const allKeywordSelect = () => api.get(`/api/users/allKeywordSelect`);

// 스탬프 등록
export const stampAdd = (userId, userStampImg) => api.post(`/api/users/stampAdd`, { userId, userStampImg});

// 스탬프 수정
export const stampUpdate = (userId, userStampImg) => api.put(`/api/users/stampUpdate`, { userId, userStampImg});

// 스탬프 조회
export const userStampSelect = (userId) => api.get(`/api/users/userStampSelect/${userId}`);

// 출석 체크
export const attendanceCheck = (userId, userStampImg, attendDay) => api.post(`/api/users/attendanceCheck`, {userId, userStampImg, attendDay});

// 출석 체크 조회
export const userAttendSelect = (userId, thisMonth) => api.get(`/api/users/userAttendSelect/${userId}`, {
  params : {thisMonth}
});

// 유저 포인트 지급
export const userPointUpdate = (userId, point) => api.put(`/api/users/userPointUpdate`, {userId, point});

// 유저 닉네임 체크
export const nickNameCheck = (nickName) => api.get(`/api/users/nickNameChack/${nickName}`);

// 유저 닉네임 변경
export const nickUpdate = (userId, nickName) => api.put(`/api/users/nickUpdate`, {userId, nickName});

// 유저 포인트 이력 조회
export const userPointHistorySelect = (userId) => api.get(`/api/users/userPointHistorySelect/${userId}`);

// 유저 포인트 충전
export const userPointPayment = (newState) => api.post(`/api/users/userPointPayment`, newState);

// 유저 공지 조회
export const userNotificationSelect = () => api.get(`/api/users/userNotificationSelect`);

// 유저 발표 조회
export const userAnnouncementSelect = () => api.get(`/api/users/userAnnouncementSelect`);

export const emailChange = (userId, email) => api.post(`/api/users/emailChange`, {userId, email} );

export const cartPaymentAdd = (requestBody) => api.post(`/api/users/cartPaymentAdd`, requestBody);

export const purchaseConfirm = (item) => api.put(`/api/users/purchaseConfirm`, item);
export const deliveryPurchaseConfirm = (item) => api.put(`/api/users/deliveryPurchaseConfirm`, item);
export const supportPurchaseConfirm = (item) => api.put(`/api/users/supportPurchaseConfirm`, item);

export const storeOrderCompSelect = (userId) => api.get(`/api/users/storeOrderCompSelect/${userId}`);
export const storeReviewComplete = (userId) => api.get(`/api/users/storeReviewComplete/${userId}`);

export const storeItemSelect = (productNumber) => api.get(`/api/users/storeItemSelect/${productNumber}`);
export const supportItemSelect = (productNumber) => api.get(`/api/users/supportItemSelect/${productNumber}`);

export const pointCal = () => api.get(`/api/users/pointCal`);

export const addressRequest = (zipCode, countryCode) => api.get(`/api/users/addressRequest`, { params : {zipCode: zipCode, countryCode: countryCode}});

export const globalAddressUpdate = (newItem) => api.put(`/api/users/globalAddressUpdate`, newItem);

export const storeWishListSelect = (userId) => api.get(`/api/users/storeWishListSelect/${userId}`);
export const supportWishListSelect = (userId) => api.get(`/api/users/supportWishListSelect/${userId}`);

export const removeStoreWishList = (productNo, userId) => api.delete(`/api/users/removeStoreWishList/${productNo}`, { params : {userId: userId}});
export const removeSupportWishList = (productNo, userId) => api.delete(`/api/users/removeSupportWishList/${productNo}`, { params : {userId: userId}});

export const shopInfo = (shopId) => api.get(`/api/users/shopInfo/${shopId}`);

export const artistSelect = () => api.get(`/api/users/artistSelect`);

export const recentSearchesDelete = (userId) => api.delete(`/api/users/recentSearchesDelete/${userId}`);

export const favorityRequest = (favority, group, text, userId) => api.post(`/api/users/favorityRequest`, {favority: favority, group: group, text: text, userId: userId});

export const noticeSelect = () => api.get(`/api/users/noticeSelect`);

export const termsSelect = () => api.get(`/api/users/termsSelect`);

export const deliveryTracking = (item) => api.post(`/api/users/deliveryTracking`, item);

export const customerDelete = (no) => api.delete(`/api/users/customerDelete/${no}`);

export const sellerUserDelete = (selectedUsers) => api.post(`/api/users/sellerUserDelete`, {productNo : selectedUsers});