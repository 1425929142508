import React, { useContext, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SupportHeader from '../../../components/market/support/SupportHeader.jsx';
import SupportCategory from '../../../components/market/support/SupportCategory.jsx';
import Filter from '../../../components/market/support/Filter.jsx';
import VenueRental from '../../../components/market/support/venue/VenueRental.jsx';
import * as support from '../../../apis/support';
import * as categorys from '../../../apis/category';
import { ko } from 'date-fns/locale';
import { format, differenceInDays } from 'date-fns';
import { LoginContext } from '../../../contexts/LoginContextProvider.jsx';
import ss from '../../../containers/market/support/venue/support_shop.module.css';
import DailyduckInNavigator from '../../../components/footer/DailyduckInNavigator.jsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import DailyduckInNavigatorSupport from '../../../components/footer/DailyduckInNavigatorSupport.jsx';

const MarketSupport = () => {
  const { isLogin, login, logout, userInfo, remove } = useContext(LoginContext);

  const location = useLocation();
  const activeCategory = location.state?.activeCategory;

  const { category: routeCategory } = useParams();
  // const [category, setCategory] = useState(routeCategory || 'venue_rental');
  const [category, setCategory] = useState(routeCategory || 'advertisement');

  const [supportData, setSupportData] = useState();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [categoryList, setCategoryList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [activeSubCategory, setActiveSubCategory] = useState('all');
  const [isHeartClicked, setIsHeartClicked] = useState(false);

  let startdate = startDate ? format(startDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;
  let enddate = endDate ? format(endDate, 'yyyy.MM.dd(eee)', { locale: ko }) : null;
  const date = startdate && enddate ? `${startdate}~${enddate}` : null;

  const navigate = useNavigate();
  
  useEffect(() => {
    categorySelect();
    supportSubCategorySelect();
  }, []);

  useEffect(() => {
    setCategory(routeCategory || 'venue_rental');
    setActiveSubCategory('all');
  }, [routeCategory]);

  useEffect(() => {
    getCategory(category);
  }, [category]);  
  
  useEffect(() => {
    // '전체' 카테고리를 추가하여 첫 번째 요소로 추가
    const allCategory = {
      supportSubCategoryEngName: 'all',
      supportSubCategoryKorName: '전체'
    };
    const filteredSubCategories = [allCategory, ...subCategory.filter(sub => sub.supportMainCategoryEngName === category)];
    
    console.log('filteredSubCategories : ', filteredSubCategories);
    setSelectedSubCategory(filteredSubCategories);
  }, [subCategory, category]);

  const backNavigate = () => {
    navigate('/market');
  };

  const handleHeartClick = () => {
    setIsHeartClicked(!isHeartClicked);
  };

  const getCategory = async (category) => {
    const categoryResponse = await support.venueRental(category);
    const categoryData = await categoryResponse.data;

    // console.log("productList : ", categoryData);
    setSupportData(shuffleArray(categoryData));
  };

  const categorySelect = async () => {
    const response = await categorys.supportCategory();
    const data = response.data;

    // console.log(data);
    setCategoryList(data);
  };

  const supportSubCategorySelect = async () => {
    const response = await categorys.supportSubCategorySelect();
    const data = response.data;
    
    console.log('supportSubCategorySelect : ', data);
    setSubCategory(data);
  }

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };


  return (
    <>
      <div className='support_container'>
        <SupportHeader activeCategory={activeCategory} backNavigate={backNavigate} ss={ss} />
        <SupportCategory category={category} categoryList={categoryList} />
        {
          Array.isArray(selectedSubCategory) && selectedSubCategory.length !== 0 && (
            <div className={`${ss.support_sub_category_box}`}>
              <Swiper
                slidesPerView="auto"
                freeMode={true}
                spaceBetween={10}
                className={`${ss.support_sub_category_swiper}`}
              >
                {selectedSubCategory.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={`${ss.support_sub_category}`}>
                      <button
                        className={`${activeSubCategory === item.supportSubCategoryEngName ? ss.support_sub_category_active : ''}`}
                        onClick={() => setActiveSubCategory(item.supportSubCategoryEngName)}
                      >
                        {item.supportSubCategoryKorName}
                      </button>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )
        }
        {/* <Filter
          dateRange={dateRange} setDateRange={setDateRange} startDate={startDate} endDate={endDate}
          startdate={startdate} enddate={enddate} date={date} ko={ko} format={format} differenceInDays={differenceInDays}
        /> */}
        <VenueRental category={category} isHeartClicked={isHeartClicked} onHeartClick={handleHeartClick} supportData={supportData} date={date} userInfo={userInfo} activeSubCategory={activeSubCategory} />
        <DailyduckInNavigatorSupport />
      </div>
    </>
  );
};

export default MarketSupport;
