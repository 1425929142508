import React, { useContext, useEffect, useState } from 'react'
import seller from '../seller.module.css';
import SellerHeader from '../components/header/SellerHeader';
import LoginSection from '../components/section/LoginSection';
import LoginPwChangeSection from '../components/section/LoginPwChangeSection';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { LoginContext } from '../../contexts/LoginContextProvider';
import JoinSection from '../components/section/JoinSection';

const SellerLogin = () => {

  const { sellerLogin, isLogin, userInfo } = useContext(LoginContext);

  const [ idCheck, setIdCheck ] = useState('');
  const [ pwCheck, setPwCheck ] = useState('');
  const [ message, setMessage ] = useState('');
  const [ checkState, setCheckState ] = useState(true);
  const [ showPwChange, setShowPwChange ] = useState(false);
  const [ pwCheckState, setPwCheckState] = useState(true);
  const [ newPw, setNewPw ] = useState('');
  const [ newPwConfirm, setNewPwConfirm ] = useState('');
  const [ showJoin, setShowJoin ] = useState(false);

  
  const onBack = () => {
    setShowJoin(false);
  }

  const loginconfirm = useNavigate();
  useEffect(() => {
    if(isLogin === true) {
      // console.log('로그인상태');
      loginconfirm('/seller/supportdashboard');
    }
  }, []);

  useEffect(() => {
    if (message !== '') {
      const timer = setTimeout(() => {
        setMessage('');
      }, 1000); // 1초 후 메시지를 비웁니다.
      return () => clearTimeout(timer);
    }
  }, [message]); // 메시지가 변경될 때마다 이 효과를 실행합니다.

  useEffect(() => {
    const timer = setTimeout(() => {
      setCheckState(true);
    }, 1000); // 1초 후 메시지를 비웁니다.
    return () => clearTimeout(timer);
  }, [checkState])

  const onId = (e) => {
    // console.log(e.target.value);
    setIdCheck(e.target.value);
  }
  const onPw = (e) => {
    // console.log(e.target.value);
    setPwCheck(e.target.value);
  }

  const loginCheck = () => {
    if(idCheck == '') {
      setMessage('아이디를 입력해주세요.')
      return
    }
    if(pwCheck == '') {
      setMessage('비밀번호를 입력해주세요.')
      return  
    }

    setCheckState(false);

    sellerLogin(idCheck, pwCheck);
    
  }

  const loginPwChange = () => {
    setShowPwChange(true);
  }

  const onNewPw = (e) => {
    setNewPw(e.target.value);
  }
  const onCheckPw = (e) => {
    setNewPwConfirm(e.target.value);
  }

  useEffect(() => {
    if(newPw != newPwConfirm) {
      setPwCheckState(false);
    } else {
      setPwCheckState(true);
    }
  }, [newPw, newPwConfirm]);


  const changeSuccess = () => {
    Swal.fire({
      title: '비밀번호 변경 완료',
      text: '비밀번호가 변경되었습니다.',
      showCancelButton: false,
      confirmButtonText: '확인',
      focusConfirm: false,
      customClass: {
          popup: `${seller.seller_popup}`,
          confirmButton: `${seller.seller_btn}`,
          title: `${seller.seller_swal_title}`, 
          content: 'my-swal-text' 
      }
    }).then((result) => {
        if (result.isConfirmed) {
          setShowPwChange(false);
        }
    });
  }

  const supportSellerJoin = () => {
    setShowJoin(true);
  }


  return (
    <div className={`${seller.seller_container}`}>
        <SellerHeader seller={seller} />
        {
          !showPwChange &&
          <LoginSection seller={seller} loginCheck={loginCheck} onId={onId} onPw={onPw} message={message} checkState={checkState} loginPwChange={loginPwChange} supportSellerJoin={supportSellerJoin}/>
        }
        { 
          showPwChange &&
          <LoginPwChangeSection seller={seller} showPwChange={showPwChange} setShowPwChange={setShowPwChange}
          />

        }
        {
        showJoin &&
          <JoinSection onBack={onBack} setShowJoin={setShowJoin}/>
        }
    </div>
  )
}

export default SellerLogin