import React, { useContext, useEffect, useRef, useState } from 'react';
import admin from '../../../admin.module.css';
import { DatePicker, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as store from '../../../../apis/store';
import { format } from 'date-fns';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import * as auth from '../../../../apis/auth';

const AdminSellerManagement = () => {
    const { loginCheck } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const { RangePicker } = DatePicker;

    const [sellerList, setSellerList] = useState([]);
    const [filteredSellers, setFilteredSellers] = useState([]);
    const [selectDate, setSelectDate] = useState([]); // RangePicker에서 선택한 날짜
    const [searchValue, setSearchValue] = useState(''); // 검색 입력 값
    const [searchTriggered, setSearchTriggered] = useState(false); // 검색 버튼이 눌렸는지 확인

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(14);
    const [totalItems, setTotalItems] = useState(0);

    const [totalCount, setTotalCount] = useState(0);
    const [storeSellerCount, setStoreSellerCount] = useState(0);
    const [supportSellerCount, setSupportSellerCount] = useState(0);
    const [showRefusalToggle, setShowRefusalToggle] = useState(false);

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false); // 전체 체크박스 상태

    // 필터 상태 관리
    const [selectedFilter, setSelectedFilter] = useState('all'); // 'all', 'storeseller', 'supportseller' 중 선택

    // 페이지네이션 핸들러
    const paginate = pageNumber => setCurrentPage(pageNumber);
    const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

    // 페이지네이션과 필터링이 변경될 때마다 셀러 목록을 가져옴
    useEffect(() => {
        sellerSelect();
        sellerCount();
    }, [currentPage, selectedFilter]); 

    const sellerSelect = async () => {
        try {
            const response = await store.sellerSelect(currentPage, itemsPerPage, selectedFilter);
            const data = response.data.sellerList;
    
            // 검색과 날짜 필터를 적용한 데이터 설정
            const filteredData = data;
            setSellerList(filteredData); // 전체 리스트 저장
            setFilteredSellers(filteredData); // 필터된 리스트로 업데이트
            
            setTotalItems(response.data.totalElements);
    
        } catch (error) {
            console.error('Error fetching seller list:', error);
        }
    };

    // 서버에서 필터링된 데이터를 가져오는 함수
    const searchSellers = async () => {
        let newItem = {
            selectedFilter : selectedFilter,
            searchValue : searchValue,
            selectDate : selectDate
        }
        try {
            // 검색 조건을 서버로 전송
            const response = await store.searchSellerSelect(currentPage, itemsPerPage, newItem);

            console.log(response.data);
            const data = response.data.sellerList;
            setSellerList(data);
            setFilteredSellers(data);

            // // 총 아이템 수 업데이트
            setTotalItems(response.data.totalElements);

        } catch (error) {
            console.error('Error fetching filtered seller list:', error);
        }
    };
    
    // 셀러 카운트 계산 함수
    const sellerCount = async () => {
        const response = await store.sellerCount();
        const data = response.data;

        // 셀러 카운트 계산
        const total = data.length;
        const storeSeller = data.filter(seller => seller.userClassification === 'storeseller').length;
        const supportSeller = data.filter(seller => seller.userClassification === 'supportseller').length;

        
        if (selectedFilter === 'all') {
            console.log('selectedFilter : ', selectedFilter);
            setTotalItems(total);
        }
        if (selectedFilter === 'storeseller') {
            console.log('selectedFilter : ', selectedFilter);
            setTotalItems(storeSeller);
        }
        if (selectedFilter === 'supportseller') {
            console.log('selectedFilter : ', selectedFilter);
            setTotalItems(supportSeller);
        }
        setTotalCount(total);
        setStoreSellerCount(storeSeller);
        setSupportSellerCount(supportSeller);
    };

    useEffect(() => {
        // totalItems나 sellerList 상태가 변경될 때 동작을 확인
        if (totalItems > 0) {
            console.log('Total items:', totalItems);
        }
    }, [totalItems]);

    // 검색 입력 값 변경 핸들러
    const onSearchValue = (e) => {
        setSearchValue(e.target.value);
    };

    // RangePicker 날짜 변경 핸들러
    const handleDateChange = (dates, dateStrings) => {
        setSelectDate(dateStrings);
    };

    // 검색 버튼 클릭 핸들러
    const onSearchClick = () => {
        if(searchValue !== '') {
            if(selectDate.length !== 0) {
                setSearchTriggered(true); // 검색 버튼이 눌리면 검색 트리거를 true로 설정
                setCurrentPage(1); // 검색 시 페이지를 1로 초기화
                searchSellers(); // 검색 버튼 클릭 시 필터링 적용 후 데이터 갱신
            } else {
                setSearchTriggered(true); // 검색 버튼이 눌리면 검색 트리거를 true로 설정
                setCurrentPage(1); // 검색 시 페이지를 1로 초기화
                searchSellers(); // 검색 버튼 클릭 시 필터링 적용 후 데이터 갱신
            }
        } else {
            if(selectDate.length !== 0) {
                setSearchTriggered(true); // 검색 버튼이 눌리면 검색 트리거를 true로 설정
                setCurrentPage(1); // 검색 시 페이지를 1로 초기화
                searchSellers(); // 검색 버튼 클릭 시 필터링 적용 후 데이터 갱신
            } else {
                setSearchTriggered(true); // 검색 버튼이 눌리면 검색 트리거를 true로 설정
                setCurrentPage(1); // 검색 시 페이지를 1로 초기화
                sellerSelect(); // 검색 버튼 클릭 시 필터링 적용 후 데이터 갱신
            }
        }
    };

    // 셀러 상세 페이지로 이동
    const onItemSelect = (no) => {
        navigate(`/admin/seller/management/${no}`);
    };

    // 날짜 포맷 함수
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd');
    };

    // 필터 버튼 클릭 핸들러
    const handleFilterClick = (filterType) => {
        setSelectedFilter(filterType); // 선택한 필터 상태로 변경
        setCurrentPage(1); // 필터를 변경할 때 페이지를 1페이지로 초기화
    };

    // 승인 거절 토글 핸들러
    const onRefusalToggle = () => {
        setShowRefusalToggle(!showRefusalToggle);
    };

    // 셀러 토큰으로 로그인하는 함수
    const sellerTokenLogin = async (item) => {
        try {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${item.userId} 계정으로 로그인하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_cancel'>취소</button>
                            <button class='mx-1' id='seller_add_success'>로그인</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    const tokenResponse = await auth.getToken({ userId: item.userId });
                    const token = tokenResponse.data.token;

                    // 쿠키에 토큰 저장
                    Cookies.set("accessToken", token);
                    await loginCheck();

                    // 새 창에서 대시보드 열기
                    if (item.userClassification === 'storeseller') {
                        window.open('/admin/dashboard', '_blank');
                    } else if (item.userClassification === 'supportseller') {
                        window.open('/seller/supportdashboard', '_blank');
                    }

                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } catch (error) {
            console.error('error : ', error);
        }
    };

    // 전체 체크박스 핸들러
    const handleAllCheck = (e) => {
        const checked = e.target.checked;
        setIsAllChecked(checked);

        if (checked) {
            const allSelected = filteredSellers.map((seller) => seller.userNo);
            setSelectedUsers(allSelected); // 모든 셀러 선택
        } else {
            setSelectedUsers([]); // 선택 해제
        }
    };

    // 개별 체크박스 핸들러
    const handleCheck = (e, userNo) => {
        const checked = e.target.checked;

        if (checked) {
            setSelectedUsers((prev) => [...prev, userNo]); // 선택 추가
        } else {
            setSelectedUsers((prev) => prev.filter((id) => id !== userNo)); // 선택 해제
        }
    };

    // 선택된 셀러 삭제
    const deleteSelectedSellers = async () => {
        console.log('selectedUsers : ', selectedUsers);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_inspection_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_inspection_title}'><span>${selectedUsers.length}명의 셀러를 삭제하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_inspection_sub_title}'><span>셀러를 삭제하게되면 복구할 수 없습니다.</span></div>
                    <div class='${admin.admin_seller_inspection_modal_btn_box} py-2 d-flex w-100 justify_content_between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>삭제</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerSingleApprovalSuccess);
        document.addEventListener('click', sellerSingleApprovalCancel);

        async function sellerSingleApprovalSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {

                const response = await auth.sellerUserDelete(selectedUsers);
                const data = response.data;

                if (data === 'SUCCESS') {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedUsers.length}명의 셀러가 삭제되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            sellerSelect();
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                } else {
                    MySwal.fire({
                        customClass: {
                            popup: `${admin.admin_seller_add_popup}`
                        },
                        html: `
                        <div>
                            <div class='${admin.admin_seller_add_title}'><span>셀러 삭제에 실패하였습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                        </div>
                        `,
                        showConfirmButton: false,
                        showCancelButton: false,
                    });
                    document.addEventListener('click', sellerAddConfirm);
                    document.removeEventListener('click', sellerSingleApprovalSuccess);
                    document.removeEventListener('click', sellerSingleApprovalCancel);

                    function sellerAddConfirm(e) {
                        if (e.target && e.target.id === 'seller_add_confirm') {
                            sellerSelect();
                            MySwal.close();
                            document.removeEventListener('click', sellerAddConfirm);
                        }
                    }
                }
            }
        }
        function sellerSingleApprovalCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerSingleApprovalSuccess);
                document.removeEventListener('click', sellerSingleApprovalCancel);
            }
        }
    };

    return (
        <>
        
            {
                isLoading &&
                <div className={`${admin.admin_join_section_loading}`}>
                    <div><img src={`${process.env.PUBLIC_URL}/img/kwangduck_loading.gif`} alt="Loading..." /></div>
                </div>
            }
            <div className={`${admin.admin_seller_management_box} px-3 py-3`}>
                <div>
                    <div className={`${admin.admin_seller_management_header}`}>
                        <div><h5>셀러 회원 관리</h5></div>
                        <div className={`${admin.admin_seller_management_header_btn_box}`}>
                            <div><button onClick={() => handleFilterClick('all')}>전체 셀러 <span className={`${admin.admin_signature_color}`}>({totalCount})</span></button></div>
                            <div><button onClick={() => handleFilterClick('storeseller')}>스토어셀러 <span className={`${admin.admin_signature_color}`}>({storeSellerCount})</span></button></div>
                            <div><button onClick={() => handleFilterClick('supportseller')}>서포트셀러 <span className={`${admin.admin_signature_color}`}>({supportSellerCount})</span></button></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_sellerList_search_box} py-3`}>
                        {/* 검색 입력창 */}
                        <div className={`${admin.admin_sellerList_search_input}`}>
                            <div className={`ps-1 ${admin.admin_sellerList_search_input_txt}`}>
                                <input
                                    type="text"
                                    placeholder="검색어를 입력하세요"
                                    onChange={onSearchValue} // 입력값 변경 시 필터 적용
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            onSearchClick();
                                        }
                                    }}
                                />
                            </div>
                            <div className={`ps-1 ${admin.admin_sellerList_search_btn}`}>
                                <button onClick={onSearchClick}>검색</button> {/* 검색 버튼 클릭 시 필터 적용 */}
                            </div>
                        </div>
                        {/* RangePicker 날짜 필터 */}
                        <div className={`${admin.admin_sellerList_search_date}`}>
                            <div>
                                <RangePicker
                                    className={`${admin.admin_sellerList_datepicker}`}
                                    separator="~"
                                    placeholder={['0000.00.00', '0000.00.00']}
                                    onChange={handleDateChange} // 날짜 선택 시 필터 적용
                                />
                            </div>
                        </div>
                    </div>
                    {/* 삭제 버튼 */}
                    {selectedUsers.length > 0 && (
                        <div className={`${admin.admin_seller_delete_btn}`}>
                            <button
                                className={`${admin.admin_delete_button}`}
                                onClick={deleteSelectedSellers}
                            >
                                셀러 삭제
                            </button>
                        </div>
                    )}
                    {/* 셀러 목록 테이블 */}
                    <div className={`${admin.admin_sellerList_table_content}`}>
                        <div>
                            <div className={`${admin.admin_sellerList_table_header} border-bottom py-3`}>
                                <div>
                                    <input
                                        type="checkbox"
                                        checked={isAllChecked}
                                        onChange={handleAllCheck}
                                    />
                                </div>
                                <div><span>번호</span></div>
                                <div><span>상호</span></div>
                                <div><span>대표자명</span></div>
                                <div><span>사업자 등록번호</span></div>
                                <div><span>사업장 소재지</span></div>
                                <div><span>등록일자</span></div>
                                <div><span></span></div>
                            </div>
                            {
                                filteredSellers && filteredSellers.length > 0 ? (
                                    filteredSellers.map((item, index) => (
                                        <div className={`${admin.admin_sellerList_table_section} py-2 border-bottom`} 
                                        onClick={(e) => {
                                            if (e.target.tagName !== 'INPUT') {
                                                onItemSelect(item.userNo);
                                            }
                                        }}
                                        >
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUsers.includes(item.userNo)}
                                                    onChange={(e) => {handleCheck(e, item.userNo)}}
                                                />
                                            </div>
                                            <div><span>{totalItems - ((currentPage - 1) * itemsPerPage + index)}</span></div>
                                            <div><span>{item.businessName}</span></div>
                                            <div><span>{item.representativeName}</span></div>
                                            <div><span>{item.businessRegistrationNumber}</span></div>
                                            <div><span>{item.businessLocation}</span></div>
                                            <div><span>{formatDate(item.userRegDate)}</span></div>
                                            <div><button className={`${admin.admin_seller_list_table_login}`} onClick={(e) => { e.stopPropagation(); sellerTokenLogin(item) }}>로그인</button></div>

                                            {
                                                item.enabled === 0 && item.refusalComment === null &&
                                                <div className={`${admin.admin_sellerList_table_enabled}`}><span>승인요청</span></div>
                                            }
                                            {
                                                item.enabled === 0 && item.refusalComment !== null &&
                                                <div className={`${admin.admin_sellerList_table_refusal}`}><button onClick={(e) => { e.stopPropagation(); onRefusalToggle() }}>승인거절</button></div>
                                            }

                                        </div>
                                    ))
                                ) : (
                                    <div className={`${admin.admin_sellerList_table_content_no_data}`}>
                                        <span>셀러 데이터가 없습니다.</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <Pagination current={currentPage} total={totalItems} onChange={paginate} pageSize={itemsPerPage} />
                </div>
            </div>
        </>
    );
};

export default AdminSellerManagement;
