import React, {useState, useEffect, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import '../login/emailLogin.css'
import * as auth from '../../apis/auth';
import { LoginContext } from '../../contexts/LoginContextProvider'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailJoin from '../join/EmailJoin';

const EmailLogin = ( {showEmailLogin, animated, emailLoginTransitions, setShowEmailLogin} ) => {

    const { showAgree, setShowAgree } = useContext(LoginContext);

    const [email, setEmail] = useState(''); // 빈 문자열로 초기화
    const [id, setId] = useState(''); // 빈 문자열로 초기화
    const [password, setPassword] = useState(''); // 빈 문자열로 초기화
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [showPrevLoginInfo, setShowPrevLoginInfo] = useState(false);

    const { login } = useContext(LoginContext);

    const searchPage = useNavigate();

    const emailJoin = () => {
        setShowAgree(true);
    }

    const emailLoginBack = () => {
        setShowEmailLogin(false)
    }

    const emailInput = (e) => {
        setId(e.target.value);
    }

    const pwInput = (e) => {
        setPassword(e.target.value);
    }

    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const emailSearch = () => {
        searchPage('/myinfoserch/email');
    }
    const passwordSearch = () => {
        searchPage('/passwordSearch');
    }

    // useEffect(() => {
    //     if (email) { // email이 유효한 값일 때만 실행
    //         const userId = email.split('@')[0];
    //         setId(userId);
    //     }
    // }, [email]);

    // // console.log(id);

    const emailLoginCheck = () => {
        login( id, password );
    }

    useEffect(() => {
        if(id && password) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [id, password])

  return (
    <>
        {showEmailLogin &&
            <div className='email_login_container'>
                {emailLoginTransitions((style, item) =>
                    item ?
                    <animated.div className='email_login_box' style={style}>
                        <div className='email_login_header'>
                            <div className='position-absolute top-0 start-0 ps-4 pt-4'>
                                <button onClick={() => emailLoginBack()} className='email_login_back'>
                                    <span className="material-symbols-outlined">arrow_back_ios</span>
                                </button>
                            </div>
                            <div>
                                <div className='text-center py-4'>
                                    <h5>로그인</h5>
                                </div>
                            </div>
                        </div>
                        <div className='email_login_body'>
                            <div className='py-4 mx-4 border-bottom'>
                                <div>
                                    <div><span>아이디</span></div>
                                    <div>
                                        <input type="text" 
                                               value={id} 
                                               onChange={(e) => emailInput(e)}
                                               className='email_login'
                                               placeholder='아이디'
                                        />
                                    </div>
                                </div>
                                <div className='pt-4'>
                                    <div><span>비밀번호</span></div>
                                    <div className='position-relative'>
                                        <input type={passwordVisible ? "text" : "password"}
                                               value={password}
                                               onChange={(e) => pwInput(e)}
                                               onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    emailLoginCheck();
                                                }
                                                }} 
                                               className='password_login'
                                               placeholder='비밀번호'
                                        />
                                        <button className='password_eye_btn' onClick={handleTogglePasswordVisibility} >
                                            {passwordVisible ? <span className="material-symbols-outlined">visibility</span> : <span className="material-symbols-outlined">visibility_off</span>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center py-3 pw_email_search'>
                                <div className='px-2'><button onClick={emailSearch}>아이디 찾기</button></div>
                                <div className='px-2'>|</div>
                                <div className='px-2'><button onClick={passwordSearch}>비밀번호 찾기</button></div>
                            </div>
                        </div>
                        <div className='mx-4'>
                            <div>
                                <button onClick={emailLoginCheck} disabled={!isLogin} className={`email_login_btn ${isLogin && 'favority_login_active'} `}>로그인</button>
                            </div>
                            <div className='text-center pt-4'>
                                <button className='email_login_join' onClick={emailJoin}>데일리덕 회원가입</button>
                            </div>
                        </div>
                        <ToastContainer
                            position="top-right" // 알람 위치 지정
                            autoClose={3000} // 자동 off 시간
                            hideProgressBar={false} // 진행시간바 숨김
                            closeOnClick // 클릭으로 알람 닫기
                            rtl={false} // 알림 좌우 반전
                            pauseOnFocusLoss // 화면을 벗어나면 알람 정지
                            draggable // 드래그 가능
                            pauseOnHover // 마우스를 올리면 알람 정지
                            theme="light"
                            // limit={1} // 알람 개수 제한
                        />

                        <div className='prev_dailyduck_login_infomation_btn'>
                            <div><button onClick={() => setShowPrevLoginInfo(true)}>기존회원 로그인 안내</button></div>
                        </div>

                        {
                            showPrevLoginInfo &&
                            <div className='prev_dailyduck_login_infomation_modal_container'>
                                <div className='prev_dailyduck_login_infomation_modal_box'>
                                    <div className='prev_dailyduck_login_infomation_modal_header'>
                                        <div><span>기존회원 로그인 안내</span></div>
                                        <div><button onClick={() => setShowPrevLoginInfo(false)}><span class="material-symbols-outlined">close</span></button></div>
                                    </div>
                                    <div className='prev_dailyduck_login_infomation_modal_content'>
                                        <div><span>안녕하세요, 데일리덕입니다.</span></div>
                                        <br />
                                        <div><span>데일리덕이 신규 사이트로 이전하게 되어 로그인 방법이 변경되었습니다.</span></div>
                                        <div><span>기존 사이트에 이메일로 가입하신 회원분들께서는 아래와 같은 방법으로 로그인해 주시기 바랍니다.</span></div>
                                        <br />
                                        <div className='prev_dailyduck_login_infomation_modal_content_login_info'>
                                            <div><span>[데일리덕 이메일 로그인 방법]</span></div>
                                            <div><span>아이디: 기존 사이트에서 사용하셨던 이메일 주소를 입력해 주세요.</span></div>
                                            <div><span>비밀번호: 본인 휴대폰 뒷번호 4자리와 **공란(스페이스바)**을 입력해 주세요.</span></div>
                                            <div><span>예시: 1234 (숫자 4자리 +공란)</span></div>
                                        </div>

                                        <div className='prev_dailyduck_login_infomation_modal_content_login_info'>
                                            <div><span>[데일리덕 소셜 로그인 방법]</span></div>
                                            <div><span>기존 Apple, kakao 소셜 로그인을 사용하신 분들은</span></div>
                                            <div><span>초기 화면의 소셜 로그인을 사용하여 로그인을 진행해 주세요.</span></div>
                                        </div>
                                        <br />
                                        <div><span>위 방법으로 로그인 후, 서비스 이용이 가능하시며,</span></div>
                                        <div><span>기타 문의 사항이 있으시다면 데일리덕 카카오톡 채널로 문의 부탁드립니다.</span></div>
                                        <br />
                                        <div><span>이용에 불편을 드려 죄송하며, 데일리덕은 앞으로도 더 나은 서비스를</span></div>
                                        <div><span>제공하기 위해 최선을 다하겠습니다.</span></div>
                                        <br />
                                        <div><span>감사합니다.</span></div>
                                        <div className='prev_dailyduck_login_infomation_modal_content_hi'><span>- 데일리덕 팀 드림 -</span></div>
                                    </div>
                                </div>
                            </div>
                        }

                    </animated.div>
                    : null
                )}
                {
                    showAgree &&
                    <EmailJoin />
                }
            </div>
        }
    </>
  )
}

export default EmailLogin