
import React, { useContext, useEffect, useState } from 'react'
import AdminHeader from '../admin/components/header/AdminHeader'
import AdminSide from '../admin/components/side/AdminSide'
import admin from '../admin/admin.module.css'
import { CSSTransition } from 'react-transition-group';
import { useLocation, useNavigate } from 'react-router-dom'
import { LoginContext } from '../contexts/LoginContextProvider';
import seller from '../seller/seller.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import ErrorBoundary from './ErrorBoundary';
import * as adminApi from '../apis/admin';

function AdminLayout({ children }) {

    const { isLogin, adminlogout, userInfo, loginCheck, roles, logoutSetting } = useContext(LoginContext);

    const MySwal = withReactContent(Swal);
    const loginConfirm = useNavigate();
    const location = useLocation(); // 현재 URL 가져오기

    const [showPwChange, setShowPwChange] = useState(false);  
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [enablepwChange, setEnablepwChange] = useState(false);

    useEffect(() => {

        const accessToken = Cookies.get("accessToken");

        // accessToken (jwt) 이 없음
        if( !accessToken ) {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_date_save_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                        <div class='${seller.seller_date_save_content}'><span>로그인 후 사용해주세요.</span></div>
                        <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
          
            document.addEventListener('click', function(e) {
                if(e.target && e.target.id === 'seller_sales_save') {
                    MySwal.close();
                }
            })
            
            loginConfirm('/admin');

            return
        } else {
            if(roles.isUser || roles.isSupport) {
                MySwal.fire({
                    customClass: {
                        popup: `${seller.seller_date_save_popup}`
                    },
                    html : 
                    `
                        <div>
                            <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                            <div class='${seller.seller_date_save_content}'><span>어드민 등록 후 이용해 주세요.</span></div>
                            <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
              
                document.addEventListener('click', function(e) {
                    if(e.target && e.target.id === 'seller_sales_save') {
                        MySwal.close();
                    }
                })
                
                loginConfirm('/admin');
            }
        }

    }, [userInfo]);

    const [activeMenu, setActiveMenu] = useState(localStorage.getItem('activeMenu') || 'board');
    const [activeUser, setActiveUser] = useState(localStorage.getItem('activeUser') || 'userList');
    const [activeSeller, setActiveSeller] = useState(localStorage.getItem('activeSeller') || 'sellerAdd');
    const [activeShopping, setActiveShopping] = useState(localStorage.getItem('activeShopping') || 'product');
    const [activeOperator, setActiveOperator] = useState(localStorage.getItem('activeOperator') || 'banner');
    const [activeEvent, setActiveEvent] = useState(localStorage.getItem('activeEvent') || 'eventAdd');
    const [activeStatistics, setActiveStatistics] = useState(localStorage.getItem('activeStatistics') || 'analysisByPeriod');
    const [activeCouponPoint, setActiveCouponPoint] = useState(localStorage.getItem('activeCouponPoint') || 'coupon');
    const [activeCalculate, setActiveCalculate] = useState(localStorage.getItem('activeCalculate') || 'proceed');
    const [activeMessage, setActiveMessage] = useState(localStorage.getItem('activeMessage') || 'message');
    const [activeSupport, setActiveSupport] = useState(localStorage.getItem('activeSupport') || 'support');

    const [showMenu, setShowMenu] = useState(window.innerWidth >= 991);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 991) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
        };

        // 컴포넌트 마운트 시에 이벤트 리스너 등록
        window.addEventListener('resize', handleResize);

        // 컴포넌트 언마운트 시에 이벤트 리스너 제거
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('activeMenu', activeMenu);
        localStorage.setItem('activeUser', activeUser);
        localStorage.setItem('activeSeller', activeSeller);
        localStorage.setItem('activeShopping', activeShopping);
        localStorage.setItem('activeOperator', activeOperator);
        localStorage.setItem('activeEvent', activeEvent);
        localStorage.setItem('activeStatistics', activeStatistics);
        localStorage.setItem('activeCouponPoint', activeCouponPoint);
        localStorage.setItem('activeCalculate', activeCalculate);
        localStorage.setItem('activeMessage', activeMessage);
        localStorage.setItem('activeSupport', activeSupport);
    }, [activeMenu, activeUser, activeSeller, activeShopping, activeOperator, activeEvent, activeStatistics, activeCouponPoint, activeCalculate, activeMessage, activeSupport]);

    const navigate = useNavigate();

    const handleMenuClick = (menu) => {

        const currentPath = location.pathname;

        if(
            currentPath === '/admin/shop/product/add' ||
            currentPath.startsWith('/admin/shop/product/')
            ) {
            console.log('진입');
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_edit_move_popup}`,
                },
                html: `
                    <div>
                        <div class='${seller.seller_edit_move_title}'><span>페이지를 이동하시겠습니까?</span></div>
                        <div class='${seller.seller_edit_move_sub_title}'><span>작성중인 내용은 저장되지 않습니다.</span></div>
                        <div class='${seller.seller_edit_move_btn}'>
                            <button id='confirm_move' class='btn btn-primary'>확인</button>
                            <button id='cancel_move' class='btn btn-secondary'>취소</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                    const confirmButton = document.getElementById('confirm_move');
                    const cancelButton = document.getElementById('cancel_move');

                    confirmButton.addEventListener('click', () => {
                        setActiveMenu(menu); // 상태 설정
                        MySwal.close();
                        navigateState(menu); // 상태 업데이트 후 navigateState 호출
                    });
                    

                    cancelButton.addEventListener('click', () => {
                        MySwal.close(); // 취소 시 팝업 닫기
                    });
                },
            });
        } else {
            navigateState(menu);
        }
    }

    const navigateState = (menu) => {
        setActiveMenu(menu);
            
        if(menu === 'board') {
            navigate('/admin/dashboard');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        
        if(menu === 'user'){
            navigate('/admin/user');
            setActiveUser('userList');
            setActiveSeller('');
            setActiveShopping('')
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'seller'){
            navigate('/admin/seller/add');
            setActiveUser('');
            setActiveSeller('sellerAdd');
            setActiveShopping('')
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'shopSetting') {
            navigate('/admin/shopsetting');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('')
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'shopping'){
            navigate('/admin/shop/product');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('product')
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'support'){
            navigate('/admin/support/product');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('')
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
            setActiveSupport('supportProduct');
        }
        if(menu === 'operate'){
            navigate('/admin/operate/banner');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('banner');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'event'){
            navigate('/admin/event/add');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('eventAdd');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'statistics'){
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('analysisByPeriod');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
        if(menu === 'couponPoint'){
            navigate('/admin/give/coupon');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('coupon');
            setActiveCalculate('');
        }
        if(menu === 'calculate'){
            if(roles.isAdmin) {
                navigate('/admin/cal/adminproceed');
                setActiveCalculate('adminproceed');
            } else {
                navigate('/admin/cal/proceed');
                setActiveCalculate('proceed');
            }
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
        }
        if(menu === 'message'){
            navigate('/admin/message/list');
            setActiveMessage('message');
            setActiveUser('');
            setActiveSeller('');
            setActiveShopping('');
            setActiveOperator('');
            setActiveEvent('');
            setActiveStatistics('');
            setActiveCouponPoint('');
            setActiveCalculate('');
        }
    }

    const changeActiveUser = (newMenu) => {

        if(newMenu === 'userList') {
            navigate('/admin/user');
        }
        if(newMenu === 'management') {
            navigate('/admin/user/management');
        }
        if(newMenu === 'dormentUser') {
            navigate('/admin/user/dorment');
        }
        if(newMenu === 'pushMessage') {
            navigate('/admin/user/message');
        }
        if(newMenu === 'userAdd') {
            navigate('/admin/user/useradd');
        }
        setActiveMenu('user');
        setActiveUser(newMenu);
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
    }
    const changeActiveSeller = (newMenu) => {
        if(newMenu === 'sellerAdd') {
            navigate('/admin/seller/add');
        }
        if(newMenu === 'sellerManage') {
            navigate('/admin/seller/management');
        }
        if(newMenu === 'sellerInspection') {
            navigate('/admin/seller/inpection');
        }
        if(newMenu === 'sellerProduct') {
            navigate('/admin/seller/product/management');
        }
        if(newMenu === 'supportProduct') {
            navigate('/admin/seller/product/support');
        }
        if(newMenu === 'sellerNotice') {
            navigate('/admin/seller/notice');
        }
        if(newMenu === 'sellerFaq') {
            navigate('/admin/seller/faq');
        }
        setActiveMenu('seller');
        setActiveUser('');
        setActiveSeller(newMenu);
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
    }
    const changeActiveShopping = (newMenu) => {

        const currentPath = location.pathname;

        if(
            currentPath === '/admin/shop/product/add' ||
            currentPath.startsWith('/admin/shop/product/')
            ) {
            console.log('진입');
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_edit_move_popup}`,
                },
                html: `
                    <div>
                        <div class='${seller.seller_edit_move_title}'><span>페이지를 이동하시겠습니까?</span></div>
                        <div class='${seller.seller_edit_move_sub_title}'><span>작성중인 내용은 저장되지 않습니다.</span></div>
                        <div class='${seller.seller_edit_move_btn}'>
                            <button id='confirm_move' class='btn btn-primary'>확인</button>
                            <button id='cancel_move' class='btn btn-secondary'>취소</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                    const confirmButton = document.getElementById('confirm_move');
                    const cancelButton = document.getElementById('cancel_move');

                    confirmButton.addEventListener('click', () => {
                        MySwal.close();
                        newMenuNavigate(newMenu); // 상태 업데이트 후 navigateState 호출
                    });
                    

                    cancelButton.addEventListener('click', () => {
                        MySwal.close(); // 취소 시 팝업 닫기
                    });
                },
            });
        } else {
            newMenuNavigate(newMenu);
        }
    }

    const newMenuNavigate = (newMenu) => {

        if(newMenu === 'product') {
            navigate('/admin/shop/product');
        }
        if(newMenu === 'order') {
            navigate('/admin/shop/order');
        }
        if(newMenu === 'reserve') {
            navigate('/admin/shop/reserve');
        }
        if(newMenu === 'cancel') {
            navigate('/admin/shop/cancel');
        }
        if(newMenu === 'return') {
            navigate('/admin/shop/return');
        }
        if(newMenu === 'change') {
            navigate('/admin/shop/change');
        }
        if(newMenu === 'review') {
            navigate('/admin/shop/review');
        }
        if(newMenu === 'delivery') {
            navigate('/admin/shop/delivery');
        }
        setActiveMenu('shopping');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping(newMenu);
        setActiveOperator('');
        setActiveEvent('');
    }

    const changeActiveSupport = (newMenu) => {

        if(newMenu === 'supportProduct') {
            navigate('/admin/support/product');
        }
        if(newMenu === 'supportOrder') {
            navigate('/admin/support/order');
        }
        setActiveMenu('support');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveSupport(newMenu);
        setActiveOperator('');
        setActiveEvent('');
    }

    const changeActiveOperator = (newMenu) => {
        if(newMenu === 'banner') {
            navigate('/admin/operate/banner');
        }
        if(newMenu === 'popup') {
            navigate('/admin/operate/popup');
        }
        if(newMenu === 'agree') {
            navigate('/admin/operate/agree');
        }
        if(newMenu === 'notification') {
            navigate('/admin/operate/notification');
        }
        if(newMenu === 'notice') {
            navigate('/admin/operate/notice');
        }
        if(newMenu === 'favority') {
            navigate('/admin/operate/favority');
        }
        if(newMenu === 'suggestKeyword') {
            navigate('/admin/operate/suggestKeyword');
        }
        if(newMenu === 'excludedKeyword') {
            navigate('/admin/operate/excludedKeyword');
        }
        if(newMenu === 'homepage') {
            navigate('/admin/operate/homepage');
        }
        if(newMenu === 'popularSection') {
            navigate('/admin/operate/popularSection');
        }
        if(newMenu === 'pointProbability') {
            navigate('/admin/operate/pointProbability');
        }
        if(newMenu === 'category') {
            navigate('/admin/operate/category');
        }
        setActiveMenu('operate');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator(newMenu);
        setActiveEvent('');
    }
    const changeActiveEvent = (newMenu) => {
        if(newMenu === 'eventAdd') {
            navigate('/admin/event/add');
        }
        if(newMenu === 'eventParticipant') {
            navigate('/admin/event/participant');
        }
        if(newMenu === 'winners') {
            navigate('/admin/event/winners');
        }
        if(newMenu === 'prevEvent') {
            navigate('/admin/event/prevEvent');
        }
        setActiveMenu('event');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent(newMenu);
    }
    const changeActiveStatistics = (newMenu) => {
        setActiveMenu('statistics');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
        setActiveStatistics(newMenu);
    }
    const changeActiveCouponPoint = (newMenu) => {
        if(newMenu === 'coupon') {
            navigate('/admin/give/coupon');
        }
        if(newMenu === 'point') {
            navigate('/admin/give/point');
        }
        setActiveMenu('couponPoint');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
        setActiveStatistics('');
        setActiveCouponPoint(newMenu);
    }

    const changeActiveCalculate = (newMenu) => {
        if(newMenu === 'proceed') {
            navigate('/admin/cal/proceed');
        }
        if(newMenu === 'settle') {
            navigate('/admin/cal/settle');
        }
        if(newMenu === 'adminproceed') {
            navigate('/admin/cal/adminproceed');
        }
        if(newMenu === 'adminsettle') {
            navigate('/admin/cal/adminsettle');
        }
        if(newMenu === 'feesetting') {
            navigate('/admin/cal/feesetting');
        }
        if(roles.isAdmin) {
            setActiveMenu('adminproceed');
        } else {
            setActiveMenu('proceed');
        }
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
        setActiveStatistics('');
        setActiveCouponPoint('');
        setActiveCalculate(newMenu);
    }
    const changeActiveMessage = (newMenu) => {
        if(newMenu === 'message') {
            navigate('/admin/message/list');
        }
        if(newMenu === 'messagePayment') {
            navigate('/admin/message/payment');
        }
        setActiveMenu('message');
        setActiveUser('');
        setActiveSeller('');
        setActiveShopping('');
        setActiveOperator('');
        setActiveEvent('');
        setActiveStatistics('');
        setActiveCouponPoint('');
        setActiveCalculate('');
        setActiveMessage(newMenu);
    }

    const enhancedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
            handleMenuClick, // 메뉴 변경 함수 전달
            setActiveShopping, // 쇼핑 메뉴 변경 함수 전달
            });
        }
        return child;
    });

    // 정규식: 영어, 숫자, 특수문자만 허용
    const handleInputChange = (event, setState) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+=-]*$/; // 허용할 문자들 정의
        if (regex.test(value)) {
        setState(value); // 값이 유효할 때만 상태 업데이트
        }
    };

    useEffect(() => {
        if (password === confirmPassword && password.length > 0) {
            setEnablepwChange(true);
        } else {
            setEnablepwChange(false);
        }
    }, [password, confirmPassword]);

    const pwChangeSuccess = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>패스워드를 변경 하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>변경</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.sellerConfirmPwChange(userInfo.userId, password);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>패스워드가 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_add_sub_title}'><span>변경된 패스워드로 로그인 해주세요.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                logoutSetting();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                navigate('/admin')
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>패스워드 변경에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    return (
    <>
        <div className={`${admin.admin_container}`}>
          <AdminHeader admin={admin} setShowPwChange={setShowPwChange} />
          <div className={`${admin.admin_side_menu_btn} ${showMenu ? admin.admin_side_menu_active : ''}`} onClick={() => setShowMenu(!showMenu)}>
            <span className="material-symbols-outlined">{showMenu ? 'keyboard_double_arrow_left' : 'keyboard_double_arrow_right'}</span>
          </div>
          <div className='d-flex'>
          <CSSTransition in={showMenu} timeout={500} classNames="adminMenu" unmountOnExit>
            <AdminSide admin={admin} activeMenu={activeMenu} setActiveMenu={setActiveMenu} handleMenuClick={handleMenuClick}
                       activeUser={activeUser} changeActiveUser={changeActiveUser} setActiveUser={setActiveUser} activeSeller={activeSeller}
                       setActiveSeller={setActiveSeller} changeActiveSeller={changeActiveSeller} activeShopping={activeShopping}
                       setActiveShopping={setActiveShopping} changeActiveShopping={changeActiveShopping} activeOperator={activeOperator}
                       setActiveOperator={setActiveOperator} changeActiveOperator={changeActiveOperator} activeEvent={activeEvent}
                       setActiveEvent={setActiveEvent} changeActiveEvent={changeActiveEvent} activeStatistics={activeStatistics}
                       setActiveStatistics={setActiveStatistics} changeActiveStatistics={changeActiveStatistics} activeCouponPoint={activeCouponPoint}
                       setActiveCouponPoint={setActiveCouponPoint} changeActiveCouponPoint={changeActiveCouponPoint} roles={roles} userInfo={userInfo}
                       activeCalculate={activeCalculate} setActiveCalculate={setActiveCalculate} changeActiveCalculate={changeActiveCalculate}
                       activeMessage={activeMessage} setActiveMessage={setActiveMessage} changeActiveMessage={changeActiveMessage}
                       activeSupport={activeSupport} changeActiveSupport={changeActiveSupport}
            />
          </CSSTransition>
            <div className={`${admin.admin_dashboard_container}`} admin={admin}>
                <ErrorBoundary>
                    {enhancedChildren} {/* 복제된 children 반환 */}
                </ErrorBoundary>
            </div>
          </div>
        </div>
        {
            showPwChange &&
            <div className={`${admin.admin_header_pw_change_container}`}>
                <div className={`${admin.admin_header_pw_change_box}`}>
                    <div className={`${admin.admin_header_pw_change_header}`}>
                    <div>
                        <span>비밀번호 변경</span>
                    </div>
                    <div>
                        <button onClick={() => setShowPwChange(false)}>
                            <span className="material-symbols-outlined">close</span>
                        </button>
                    </div>
                    </div>
                    <div className={`${admin.admin_header_pw_change_content}`}>
                    <div>
                        <div>
                        <span>변경할 비밀번호</span>
                        </div>
                        <div>
                        <input
                            type="text"
                            value={password}
                            onChange={(e) => handleInputChange(e, setPassword)}
                        />
                        </div>
                    </div>
                    <div>
                        <div>
                        <span>비밀번호 확인</span>
                        </div>
                        <div>
                        <input
                            type="text"
                            value={confirmPassword}
                            onChange={(e) => handleInputChange(e, setConfirmPassword)}
                        />
                        </div>
                    </div>
                    </div>
                    <div className={`${admin.admin_header_pw_change_footer}`}>
                    <div>
                        <button className={`${enablepwChange ? admin.admin_active_pw_confirm : ''}`} onClick={() => pwChangeSuccess()} disabled={!enablepwChange}>변경하기</button>
                    </div>
                    </div>
                </div>
            </div>
        }
    </>
    );
  }
  
  export default AdminLayout;