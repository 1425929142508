import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainContainer from './containers/MainContainer';
import LoginContextProvider from './contexts/LoginContextProvider';
import LoadingPage from './containers/loading/LoadingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeContainer from './containers/HomeContainer';
import MarketContainer from './containers/market/MarketContainer';
import SearchContainer from './containers/search/SearchContainer';
import MessageContainer from './containers/messege/MessageContainer';
import MyContainer from './containers/my/MyContainer';
import MarketSupport from './containers/market/support/MarketSupport';
import VenueDetail from './containers/market/support/venue/VenueDetail';
import FaqDetail from './components/market/support/FaqDetail';
import PaymentScreen from './containers/market/support/PaymentContainer';
import VenueRequest from './components/market/support/venue/VenueRequest';
import EmailSearch from './components/login/EmailSearch';
import AccountSearch from './components/login/AccountSearch';
import PasswordSearch from './components/login/PasswordSearch';
import PasswordChange from './components/login/PasswordChange';
import PayDBComplete from './components/market/support/payment/PayDBComplete';
import MyOrderDetail from './containers/my/MyOrderDetail';
import SellerLogin from './seller/containers/SellerLogin';
import Layout from './layout/Layout';
import AdminLogin from './admin/containers/login/AdminLogin';
import SellerStoreDashBoard from './seller/containers/board/SellerStoreDashBoard';
import SellerSupportDashBoard from './seller/containers/board/SellerSupportDashBoard';
import MyInfo from './containers/my/MyInfo';
import UserWithDraw from './components/my/UserWithDraw';
import MyFavority from './containers/my/MyFavority';
import MyOrderDelivery from './containers/my/MyOrderDelivery';
import MyCouponPoint from './containers/my/MyCouponPoint';
import MyPointRecharge from './containers/my/MyPointRecharge';
import ActiviryDetail from './containers/my/ActiviryDetail';
import MyReview from './containers/my/MyReview';
import ReviewWrite from './components/my/ReviewWrite';
import MySave from './components/my/MySave';
import MyNotice from './containers/my/MyNotice';
import MyCustomerService from './containers/my/MyCustomerService';
import MyStoreEntry from './containers/my/MyStoreEntry';
import MyTerms from './containers/my/MyTerms';
import MyNotification from './containers/my/MyNotification';
import MarketStore from './containers/market/store/MarketStore';
import StoreDetail from './containers/market/store/StoreDetail';
import StoreFaqDetail from './components/market/store/detail/StoreFaqDetail';
import ShopInfo from './containers/market/shop/ShopInfo';
import MarketCart from './components/market/MarketCart';
import AdminDashBoard from './admin/containers/board/AdminDashBoard';
import EventContainer from './containers/event/EventContainer';
import EventVote from './components/event/EventVote';
import EventRaffle from './components/event/EventRaffle';
import EventInput from './components/event/EventInput';
import EventAttendance from './components/event/EventAttendance';
import EventPlan from './components/event/EventPlan';
import RaffleSubscribe from './components/event/RaffleSubscribe';
import StorePaymentContent from './containers/event/EventPaymentContent';
import EventPaymentContent from './containers/event/EventPaymentContent';
import AdminLayout from './layout/AdminLayout';
import AdminMain from './admin/components/sectoion/AdminMain';
import AdminUserList from './admin/components/sectoion/user/AdminUserList';
import AdminManagement from './admin/components/sectoion/user/AdminManagement';
import AdminDormentUser from './admin/components/sectoion/user/AdminDormentUser';
import AdminMessagePush from './admin/components/sectoion/user/AdminMessagePush';
import AdminSellerAdd from './admin/components/sectoion/seller/AdminSellerAdd';
import AdminSellerManagement from './admin/components/sectoion/seller/AdminSellerManagement';
import AdminSellerManagementDetail from './admin/components/sectoion/seller/AdminSellerManagementDetail';
import AdminSellerUpdate from './admin/components/sectoion/seller/AdminSellerUpdate';
import AdminSellerProductInspection from './admin/components/sectoion/seller/AdminSellerProductInspection';
import AdminSellerProductDetail from './admin/components/sectoion/seller/AdminSellerProductDetail';
import AdminSellerProductManagement from './admin/components/sectoion/seller/AdminSellerProductManagement';
import AdminSellerNotice from './admin/components/sectoion/seller/AdminSellerNotice';
import AdminSellerFaq from './admin/components/sectoion/seller/AdminSellerFaq';
import AdminSellerNoticeAdd from './admin/components/sectoion/seller/AdminSellerNoticeAdd';
import AdminSellerNoticeRead from './admin/components/sectoion/seller/AdminSellerNoticeRead';
import AdminSellerNoticeUpdate from './admin/components/sectoion/seller/AdminSellerNoticeUpdate';
import AdminProduct from './admin/components/sectoion/shop/AdminProduct';
import AdminProductAdd from './admin/components/sectoion/shop/AdminProductAdd';
import AdminOrder from './admin/components/sectoion/shop/AdminOrder';
import AdminReserves from './admin/components/sectoion/shop/AdminReserves';
import AdminCancel from './admin/components/sectoion/shop/AdminCancel';
import AdminReturn from './admin/components/sectoion/shop/AdminReturn';
import AdminChange from './admin/components/sectoion/shop/AdminChange';
import AdminReview from './admin/components/sectoion/shop/AdminReview';
import AdminDeliverySetting from './admin/components/sectoion/shop/AdminDeliverySetting';
import AdminBanner from './admin/components/sectoion/operate/AdminBanner';
import AdminPopup from './admin/components/sectoion/operate/AdminPopup';
import AdminAgree from './admin/components/sectoion/operate/AdminAgree';
import AdminInquiry from './admin/components/sectoion/operate/AdminInquiry';
import AdminFavority from './admin/components/sectoion/operate/AdminFavority';
import AdminPageSetting from './admin/components/sectoion/operate/AdminPageSetting';
import AdminEvent from './admin/components/sectoion/event/AdminEvent';
import AdminWinner from './admin/components/sectoion/event/AdminWinner';
import AdminEndEvent from './admin/components/sectoion/event/AdminEndEvent';
import AdminCoupon from './admin/components/sectoion/give/AdminCoupon';
import AdminPoint from './admin/components/sectoion/give/AdminPoint';
import AdminDeliveryAdd from './admin/components/sectoion/shop/AdminDeliveryAdd';
import AdminProductRead from './admin/components/sectoion/shop/AdminProductRead';
import SellerDashBoardMain from './seller/components/section/supportdashboard/SellerDashBoardMain';
import SellerLayout from './layout/SellerLayout';
import SellerBoardMain from './seller/components/section/storedashboard/board/SellerBoardMain';
import SellerFaqMain from './seller/components/section/storedashboard/board/SellerFaqMain';
import SellerSettlementMain from './seller/components/section/supportdashboard/settlement/SellerSettlementMain';
import SellerCaculatorMain from './seller/components/section/supportdashboard/settlement/SellerCaculatorMain';
import SellerReviewMain from './seller/components/section/supportdashboard/review/SellerReviewMain';
import SellerStoreMain from './seller/components/section/supportdashboard/store/SellerStoreMain';
import SellerStoreOperating from './seller/components/section/supportdashboard/store/SellerStoreOperating';
import SellerStoreDateSetting from './seller/components/section/supportdashboard/store/SellerStoreDateSetting';
import SellerStoreBusinessInfo from './seller/components/section/supportdashboard/store/SellerStoreBusinessInfo';
import SellerEditMain from './seller/components/section/supportdashboard/edit/SellerEditMain';
import SellerSalesMain from './seller/components/section/supportdashboard/sales/SellerSalesMain';
import SellerProductMain from './seller/components/section/supportdashboard/product/SellerProductMain';
import SellerReservatiomMain from './seller/components/section/supportdashboard/reservation/SellerReservationMain';
import AdminShopSetting from './admin/components/sectoion/shop/AdminShopSetting';
import MessageChat from './components/message/MessageChat';
import SellerMessage from './seller/components/section/supportdashboard/message/SellerMessage';
import MessagePaymentScreen from './containers/messege/MessagePaymentContainer';
import MessagePaymentComplete from './components/message/MessagePaymentComplete';
import KakaoLogin from './containers/login/KakaoLogin';
import KakaoJoin from './components/join/KakaoJoin';
import TwitterUserInfo from './components/join/TwitterUserInfo';
import TwitterJoin from './components/join/TwitterJoin';
import LineUserInfo from './components/join/LineUserInfo';
import LineJoin from './components/join/LineJoin';
import GoogleCallback from './components/join/GoogleCallback';
import GoogleJoin from './components/join/GoogleJoin';
import AppleJoin from './components/join/AppleJoin';
import StorePaymentScreen from './containers/market/store/StorePaymentContainer';
import StorePaymentComplete from './components/market/store/StorePaymentComplete';
import PaymentSuccess from './components/market/store/PaymentSuccess';
import SupportPaymentSuccess from './components/market/support/payment/SupportPaymentSuccess';
import MessagePaymentSuccess from './components/message/MessagePaymentSuccess';
import MyOrderCancel from './containers/my/MyOrderCancel';
import Refund from './components/my/Refund';
import Exchange from './components/my/Exchange';
import EventPaymentSuccess from './components/event/EventPaymentSuccess';
import EventPaymentComplete from './components/event/EventPaymentComplete';
import EventPlanDetail from './components/event/EventPlanDetail';
import PlanPaymentScreen from './containers/event/PlanPaymentContainer';
import PlanPaymentSuccess from './components/event/PlanPaymentSuccess';
import PlanPaymentComplete from './components/event/PlanPaymentComplete';
import AdminParticipant from './admin/components/sectoion/event/AdminParticipant';
import StoreMore from './components/search/contens/StoreMore';
import SupportMore from './components/search/contens/SupportMore';
import EventMore from './components/search/contens/EventMore';
import AdminSuggest from './admin/components/sectoion/operate/AdminSuggest';
import ExchangePayment from './components/my/ExchangePayment';
import PointPayment from './containers/my/PointPayment';
import AdminNotification from './admin/components/sectoion/operate/AdminNotification';
import EventNotification from './components/event/EventNotification';
import EventAnnouncement from './components/event/EventAnnouncement';
import SellertMessagePayment from './seller/components/section/supportdashboard/message/SellertMessagePayment';
import AdminExcluded from './admin/components/sectoion/operate/AdminExcluded';
import CartPaymentContainer from './containers/market/CartPaymentContainer';
import CartPaymentSuccess from './components/market/CartPaymentSuccess';
import CartPaymentComplete from './components/market/CartPaymentComplete';
import AdminPopularSection from './admin/components/sectoion/operate/AdminPopularSection';
import ReviewWritten from './components/my/ReviewWritten';
import AdminProceed from './admin/components/sectoion/calculate/AdminProceed';
import AdminSettle from './admin/components/sectoion/calculate/AdminSettle';
import AdminFeeSetting from './admin/components/sectoion/calculate/AdminFeeSetting';
import AdminAuthProceed from './admin/components/sectoion/calculate/AdminAuthProceed';
import AdminAuthSettle from './admin/components/sectoion/calculate/AdminAuthSettle';
import AdminMessage from './admin/components/sectoion/message/AdminMessage';
import AdminMessagePayment from './admin/components/sectoion/message/AdminMessagePayment';
import AdminPointProbability from './admin/components/sectoion/operate/AdminPointProbability';
import RedirectionShop from './containers/market/shop/RedirectionShop';
import PopularStoreMore from './components/main/PopularStoreMore';
import PopularSupportMore from './components/main/PopularSupportMore';
import StoreDiscountMore from './components/main/StoreDiscountMore';
import StoreSuggestMore from './components/main/StoreSuggestMore';
import ErrorBoundary from './layout/ErrorBoundary';
import AppleCallback from './components/join/AppleCallback';
import MyFavorityAdd from './components/my/MyFavorityAdd';
import FavorityDday from './components/my/FavorityDday';
import FavorityAdd from './components/my/FavorityAdd';
import MonthlyCalendar from './admin/components/sectoion/monthly/MonthlyCalendar';
import AdminSellerSupportInspection from './admin/components/sectoion/seller/AdminSellerSupportInspection';
import AdminCategory from './admin/components/sectoion/operate/AdminCategory';
import SupportFaqDetail from './components/market/support/FaqDetail';
import AdminUserAdd from './admin/components/sectoion/user/AdminUserAdd';
import AdminSupportPropduct from './admin/components/sectoion/support/AdminSupportPropduct';
import AdminSupportOrder from './admin/components/sectoion/support/AdminSupportOrder';


function App() {
  return (
    <>
      <BrowserRouter>
        <LoginContextProvider>
            
            <Routes>
                
                {/* user */}
                <Route path='/' element={ <Layout><MainContainer /></Layout> }></Route>

                {/* redirection */}
                <Route path='/:partnerNo' element={ <Layout><ShopInfo /></Layout> }></Route>

                {/* twitter */}
                <Route path="/twitter/userinfo" element={<Layout><TwitterUserInfo /></Layout>} />
                <Route path="/twitter/join" element={<Layout><TwitterJoin /></Layout>} />
                
                {/* kakao */}
                <Route path='/oauth2/code/kakao' element={ <Layout><KakaoLogin /></Layout>}></Route>
                <Route path='/kakao/join' element={ <Layout><KakaoJoin /></Layout>}></Route>


                {/* Line */}
                <Route path='/line/userinfo' element={<Layout><LineUserInfo /></Layout>}></Route>
                <Route path="/line/join" element={<Layout><LineJoin /></Layout>} />

                {/* Google */}
                <Route path="/auth/google/callback" element={<Layout><GoogleCallback /></Layout>} />
                <Route path="/google/join" element={<Layout><GoogleJoin /></Layout>} />

                {/* Apple */}
                <Route path="/auth/apple/callback" element={<Layout><AppleCallback /></Layout>} />
                <Route path="/apple/join" element={<Layout><AppleJoin /></Layout>} />

                {/* cart */}
                <Route path='/cart' element={ <Layout><MarketCart /></Layout>}></Route>
                
                {/* Navigator */}
                <Route path='/market' element={ <Layout><MarketContainer /></Layout> }></Route>
                <Route path='/event' element={ <Layout><EventContainer /></Layout> }></Route>
                <Route path='/search' element={ <Layout><SearchContainer /></Layout> }></Route>
                <Route path='/message' element={ <Layout><MessageContainer /></Layout> }></Route>
                <Route path='/my' element={ <Layout><MyContainer /></Layout> }></Route>

                {/* Popular */}
                <Route path='/market/popular/product' element={ <Layout><PopularStoreMore /></Layout> }></Route>
                <Route path='/market/popular/support' element={ <Layout><PopularSupportMore /></Layout> }></Route>
                <Route path='/market/dicount/product' element={ <Layout><StoreDiscountMore /></Layout> }></Route>
                {/* <Route path='/market/popular/review' element={ <Layout><PopularReviewMore /></Layout> }></Route> */}
                <Route path='/market/suggest/product' element={ <Layout><StoreSuggestMore /></Layout> }></Route>
                

                {/* My */}
                <Route path='/my/myinfo' element={ <Layout><MyInfo /></Layout> }></Route>
                <Route path='/my/myinfo/withdraw' element={ <Layout><UserWithDraw /></Layout> }></Route>
                <Route path='/my/favority' element={ <Layout><MyFavority /></Layout> }></Route>
                <Route path='/my/favority/add' element={ <Layout><MyFavorityAdd /></Layout> }></Route>
                <Route path='/my/favority/request' element={ <Layout><FavorityAdd /></Layout> }></Route>
                <Route path='/my/favority/dday' element={ <Layout><FavorityDday /></Layout> }></Route>
                <Route path='/my/od' element={ <Layout><MyOrderDelivery /></Layout> }></Route>
                <Route path='/my/cp' element={ <Layout><MyCouponPoint /></Layout> }></Route>
                <Route path='/my/cpr' element={ <Layout><MyPointRecharge /></Layout> }></Route>
                <Route path='/my/ad' element={ <Layout><ActiviryDetail /></Layout> }></Route>
                <Route path='/my/review' element={ <Layout><MyReview /></Layout> }></Route>
                <Route path='/my/review/write' element={ <Layout><ReviewWrite /></Layout> }></Route>
                <Route path='/my/review/written' element={ <Layout><ReviewWritten /></Layout> }></Route>
                <Route path='/my/save' element={ <Layout><MySave /></Layout> }></Route>
                <Route path='/smiming' element={ <Layout><HomeContainer /></Layout> }></Route>
                <Route path='/my/notice' element={ <Layout><MyNotice /></Layout> }></Route>
                <Route path='/my/service' element={ <Layout><MyCustomerService /></Layout> }></Route>
                <Route path='/my/entry' element={ <Layout><MyStoreEntry /></Layout> }></Route>
                <Route path='/my/terms' element={ <Layout><MyTerms /></Layout> }></Route>
                <Route path='/my/notification' element={ <Layout><MyNotification /></Layout> }></Route>
                <Route path='/my/order/cancel' element={ <Layout><MyOrderCancel /></Layout> }></Route>
                <Route path='/my/order/refund' element={ <Layout><Refund /></Layout> }></Route>
                <Route path='/my/order/exchange' element={ <Layout><Exchange /></Layout> }></Route>

                {/* Search */}
                <Route path='/event/store/more' element={ <Layout><StoreMore /></Layout> }></Route>
                <Route path='/event/support/more' element={ <Layout><SupportMore /></Layout> }></Route>
                <Route path='/event/event/more' element={ <Layout><EventMore /></Layout> }></Route>


                {/* Support Category */}
                <Route path='/support/:category' element={ <Layout><MarketSupport /></Layout> } />

                {/* Store Category */}
                <Route path='/store/:category' element={ <Layout><MarketStore /></Layout> } />

                <Route path='/store/product/:productNo' element={ <Layout><StoreDetail /></Layout> } />
                <Route path='/store/FAQ/:no' element={ <Layout><StoreFaqDetail /></Layout> } />

                {/* shop info */}
                <Route path='/shopinfo/:partnerNo' element={ <Layout><ShopInfo /></Layout> } />

                {/* venue */}
                <Route path='/venue/:no' element={ <Layout><VenueDetail /></Layout> } />
                <Route path='/venue/FAQ/:no' element={ <Layout><SupportFaqDetail /></Layout> } />
                <Route path='/venue/request' element={ <Layout><VenueRequest /></Layout> } />

                {/* payment */}
                <Route path='/payment/:no' element={ <Layout><PaymentScreen /></Layout> } />
                <Route path='/message/payment/:no' element={ <Layout><MessagePaymentScreen /></Layout> } />
                <Route path='/store/payment/:no' element={ <Layout><StorePaymentScreen /></Layout> } />
                <Route path='/cart/payment' element={ <Layout><CartPaymentContainer /></Layout> } />

                {/* email / password search */}
                <Route path='/myinfoserch/email' element={ <Layout><EmailSearch /></Layout> } />
                <Route path='/account' element={ <Layout><AccountSearch /></Layout> } />
                <Route path='/passwordSearch' element={ <Layout><PasswordSearch /></Layout> } />
                <Route path='/passwordChange' element={ <Layout><PasswordChange /></Layout> } />

                {/* toss payments */}
                <Route path='/payment/success/:reservationNo' element={ <Layout><PayDBComplete /></Layout> } />
                <Route path='/support/payment/successcomplete' element={ <Layout><SupportPaymentSuccess /></Layout> } />
                <Route path='/message/payment/success/:paymentNumber' element={ <Layout><MessagePaymentComplete /></Layout> } />
                <Route path='/message/payment/successcomplete' element={ <Layout><MessagePaymentSuccess /></Layout> } />
                <Route path='/store/payment/success/:paymentNumber' element={ <Layout><StorePaymentComplete /></Layout> } />
                <Route path='/store/payment/successcomplete' element={ <Layout><PaymentSuccess /></Layout> } />
                <Route path='/event/payment/success/:paymentNumber' element={ <Layout><EventPaymentComplete /></Layout> } />
                <Route path='/event/payment/successcomplete' element={ <Layout><EventPaymentSuccess /></Layout> } />
                <Route path='/cart/payment/success' element={ <Layout><CartPaymentComplete /></Layout> } />
                <Route path='/cart/payment/successcomplete' element={ <Layout><CartPaymentSuccess /></Layout> } />
                
                <Route path='/exchange/payment/successcomplete' element={ <Layout><ExchangePayment /></Layout> } />
                <Route path='/point/payment/successcomplete' element={ <Layout><PointPayment /></Layout> } />

                {/* event */}
                <Route path='/event/vote/:eventNumber' element={ <Layout><EventVote /></Layout> } />
                <Route path='/event/lucked/:eventNumber' element={ <Layout><EventRaffle /></Layout> } />
                <Route path='/event/plan/:eventNumber' element={ <Layout><EventPlan /></Layout> } />
                <Route path='/event/input/:eventNumber' element={ <Layout><EventInput /></Layout> } />
                <Route path='/event/attendance/:eventNumber' element={ <Layout><EventAttendance /></Layout> } />
                <Route path='/event/notification' element={ <Layout><EventNotification /></Layout> } />
                <Route path='/event/announcement' element={ <Layout><EventAnnouncement /></Layout> } />

                {/* lucked */}
                <Route path='/lucked/:eventno' element={ <Layout><RaffleSubscribe /></Layout> } />
                <Route path='/lucked/payment/:eventNumber' element={ <Layout><EventPaymentContent /></Layout> } />

                {/* plan */}
                <Route path='/plan/product/:productNo' element={ <Layout><EventPlanDetail /></Layout> } />
                <Route path='/plan/payment/:no' element={ <Layout><PlanPaymentScreen /></Layout> } />
                <Route path='/plan/payment/success/:paymentNumber' element={ <Layout><PlanPaymentComplete /></Layout> } />
                <Route path='/plan/payment/successcomplete' element={ <Layout><PlanPaymentSuccess /></Layout> } />

                {/* message */}
                <Route path='/message/chat/:no' element={ <Layout><MessageChat /></Layout> } />


                {/* Seller */}
                <Route path='/seller' element={ <SellerLogin /> } />
                <Route path='/seller/storedashboard' element={ <SellerStoreDashBoard /> } />
                <Route path='/seller/supportdashboard' element={ <SellerLayout><SellerDashBoardMain /></SellerLayout> } />
                <Route path='/seller/board' element={ <SellerLayout><SellerBoardMain /></SellerLayout> } />
                <Route path='/seller/faq' element={ <SellerLayout><SellerFaqMain /></SellerLayout> } />
                <Route path='/seller/proceeds' element={ <SellerLayout><SellerSettlementMain /></SellerLayout> } />
                <Route path='/seller/calculate' element={ <SellerLayout><SellerCaculatorMain /></SellerLayout> } />
                <Route path='/seller/review' element={ <SellerLayout><SellerReviewMain /></SellerLayout> } />
                <Route path='/seller/shop/profile' element={ <SellerLayout><SellerStoreMain /></SellerLayout> } />
                <Route path='/seller/shop/operating' element={ <SellerLayout><SellerStoreOperating /></SellerLayout> } />
                <Route path='/seller/shop/schedule' element={ <SellerLayout><SellerStoreDateSetting /></SellerLayout> } />
                <Route path='/seller/shop/business' element={ <SellerLayout><SellerStoreBusinessInfo /></SellerLayout> } />
                <Route path='/seller/sales/faq' element={ <SellerLayout><SellerSalesMain /></SellerLayout> } />
                <Route path='/seller/productAdd' element={ <SellerLayout><SellerEditMain /></SellerLayout> } />
                <Route path='/seller/products' element={ <SellerLayout><SellerProductMain /></SellerLayout> } />
                <Route path='/seller/reservation' element={ <SellerLayout><SellerReservatiomMain /></SellerLayout> } />
                <Route path='/seller/message' element={ <SellerLayout><SellerMessage /></SellerLayout> } />
                <Route path='/seller/messagePayment' element={ <SellerLayout><SellertMessagePayment /></SellerLayout> } />

              
                {/* Admin */}
                <Route path='/admin' element={ <AdminLogin /> } />
                <Route path='/admin/dashboard' element={ <AdminLayout><AdminMain /></AdminLayout> } />
                <Route path='/admin/dashboard/monthly' element={ <AdminLayout><MonthlyCalendar /></AdminLayout> } />
                {/* Admin User */}
                <Route path='/admin/user' element={ <AdminLayout><AdminUserList /></AdminLayout> } />
                <Route path='/admin/user/management' element={ <AdminLayout><AdminManagement /></AdminLayout> } />
                <Route path='/admin/user/dorment' element={ <AdminLayout><AdminDormentUser /></AdminLayout> } />
                <Route path='/admin/user/message' element={ <AdminLayout><AdminMessagePush /></AdminLayout> } />
                <Route path='/admin/user/useradd' element={ <AdminLayout><AdminUserAdd /></AdminLayout> } />
                {/* Admin Seller */}
                <Route path='/admin/seller/add' element={ <AdminLayout><AdminSellerAdd /></AdminLayout> } />
                <Route path='/admin/seller/management' element={ <AdminLayout><AdminSellerManagement /></AdminLayout> } />
                <Route path='/admin/seller/management/:no' element={ <AdminLayout><AdminSellerManagementDetail /></AdminLayout> } />
                <Route path='/admin/seller/management/update/:no' element={ <AdminLayout><AdminSellerUpdate /></AdminLayout> } />
                <Route path='/admin/seller/inpection' element={ <AdminLayout><AdminSellerProductInspection /></AdminLayout> } />
                <Route path='/admin/seller/product/support' element={ <AdminLayout><AdminSellerSupportInspection /></AdminLayout> } />
                <Route path='/admin/seller/product/detail/:no' element={ <AdminLayout><AdminSellerProductDetail /></AdminLayout> } />
                <Route path='/admin/seller/product/management' element={ <AdminLayout><AdminSellerProductManagement /></AdminLayout> } />
                <Route path='/admin/seller/notice' element={ <AdminLayout><AdminSellerNotice /></AdminLayout> } />
                <Route path='/admin/seller/notice/add' element={ <AdminLayout><AdminSellerNoticeAdd /></AdminLayout> } />
                <Route path='/admin/seller/notice/:no' element={ <AdminLayout><AdminSellerNoticeRead /></AdminLayout> } />
                <Route path='/admin/seller/notice/update/:no' element={ <AdminLayout><AdminSellerNoticeUpdate /></AdminLayout> } />
                <Route path='/admin/seller/faq' element={ <AdminLayout><AdminSellerFaq /></AdminLayout> } />
                <Route path='/admin/shopsetting' element={ <AdminLayout><AdminShopSetting /></AdminLayout> } />
                {/* Admin shopping */}
                <Route path='/admin/shop/product' element={ <AdminLayout><AdminProduct /></AdminLayout> } />
                <Route path='/admin/shop/product/:productNumber' element={ <AdminLayout><AdminProductRead /></AdminLayout> } />
                <Route path='/admin/shop/product/add' element={ <AdminLayout><AdminProductAdd /></AdminLayout> } />
                <Route path='/admin/shop/order' element={ <AdminLayout><AdminOrder /></AdminLayout> } />
                <Route path='/admin/shop/reserve' element={ <AdminLayout><AdminReserves /></AdminLayout> } />
                <Route path='/admin/shop/cancel' element={ <AdminLayout><AdminCancel /></AdminLayout> } />
                <Route path='/admin/shop/return' element={ <AdminLayout><AdminReturn /></AdminLayout> } />
                <Route path='/admin/shop/change' element={ <AdminLayout><AdminChange /></AdminLayout> } />
                <Route path='/admin/shop/review' element={ <AdminLayout><AdminReview /></AdminLayout> } />
                <Route path='/admin/shop/delivery' element={ <AdminLayout><AdminDeliverySetting /></AdminLayout> } />
                <Route path='/admin/shop/delivery/add' element={ <AdminLayout><AdminDeliveryAdd /></AdminLayout> } />
                {/* Admin Support */}
                <Route path='/admin/support/product' element={ <AdminLayout><AdminSupportPropduct /></AdminLayout> } />
                <Route path='/admin/support/order' element={ <AdminLayout><AdminSupportOrder /></AdminLayout> } />
                {/* Admin Operate */}
                <Route path='/admin/operate/banner' element={ <AdminLayout><AdminBanner /></AdminLayout> } />
                <Route path='/admin/operate/popup' element={ <AdminLayout><AdminPopup /></AdminLayout> } />
                <Route path='/admin/operate/agree' element={ <AdminLayout><AdminAgree /></AdminLayout> } />
                <Route path='/admin/operate/notification' element={ <AdminLayout><AdminNotification /></AdminLayout> } />
                <Route path='/admin/operate/notice' element={ <AdminLayout><AdminInquiry /></AdminLayout> } />
                <Route path='/admin/operate/favority' element={ <AdminLayout><AdminFavority /></AdminLayout> } />
                <Route path='/admin/operate/suggestKeyword' element={ <AdminLayout><AdminSuggest /></AdminLayout> } />
                <Route path='/admin/operate/excludedKeyword' element={ <AdminLayout><AdminExcluded /></AdminLayout> } />
                <Route path='/admin/operate/homepage' element={ <AdminLayout><AdminPageSetting /></AdminLayout> } />
                <Route path='/admin/operate/popularSection' element={ <AdminLayout><AdminPopularSection /></AdminLayout> } />
                <Route path='/admin/operate/pointProbability' element={ <AdminLayout><AdminPointProbability /></AdminLayout> } />
                <Route path='/admin/operate/category' element={ <AdminLayout><AdminCategory /></AdminLayout> } />
                {/* Admin Event */}
                <Route path='/admin/event/add' element={ <AdminLayout><AdminEvent /></AdminLayout> } />
                <Route path='/admin/event/participant' element={ <AdminLayout><AdminParticipant /></AdminLayout> } />
                <Route path='/admin/event/winners' element={ <AdminLayout><AdminWinner /></AdminLayout> } />
                <Route path='/admin/event/prevEvent' element={ <AdminLayout><AdminEndEvent /></AdminLayout> } />
                {/* Admin Give */}
                <Route path='/admin/give/coupon' element={ <AdminLayout><AdminCoupon /></AdminLayout> } />
                <Route path='/admin/give/point' element={ <AdminLayout><AdminPoint /></AdminLayout> } />
                {/* Admin Calculate */}
                <Route path='/admin/cal/proceed' element={ <AdminLayout><AdminProceed /></AdminLayout> } />
                <Route path='/admin/cal/settle' element={ <AdminLayout><AdminSettle /></AdminLayout> } />
                <Route path='/admin/cal/adminproceed' element={ <AdminLayout><AdminAuthProceed /></AdminLayout> } />
                <Route path='/admin/cal/adminsettle' element={ <AdminLayout><AdminAuthSettle /></AdminLayout> } />
                <Route path='/admin/cal/feesetting' element={ <AdminLayout><AdminFeeSetting /></AdminLayout> } />
                {/* Admin Message */}
                <Route path='/admin/message/list' element={ <AdminLayout><AdminMessage /></AdminLayout> } />
                <Route path='/admin/message/payment' element={ <AdminLayout><AdminMessagePayment /></AdminLayout> } />
            </Routes>
        </LoginContextProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
