import React, { useContext, useEffect, useState } from 'react';
import SellerHeader from '../seller/components/header/SellerHeader';
import seller from '../seller/seller.module.css';
import { CSSTransition } from 'react-transition-group';
import SupportSellerSide from '../seller/components/side/SupportSellerSide';
import { LoginContext } from '../contexts/LoginContextProvider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'js-cookie';
import admin from '../admin/admin.module.css';
import * as adminApi from '../apis/admin';

function SellerLayout({ children }) {

    const { isLogin, sellerlogout, userInfo, loginCheck, roles, logoutSetting } = useContext(LoginContext);

    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(window.innerWidth >= 820);
    const [ activeMenu, setActiveMenu ] = useState(localStorage.getItem('activeMenu') || 'dashboard');
    const [ activeBoard, setActiveBoard ] = useState(localStorage.getItem('activeBoard') || 'board');
    const [ activeSettlement, setActiveSettlement ] = useState(localStorage.getItem('activeSettlement') || 'proceeds');
    const [ activeShopManagement, setActiveShopManagement] = useState(localStorage.getItem('activeShopManagement') || 'profile');
    const [ activeSalesManagement, setActiveSalesManagement] = useState(localStorage.getItem('activeSalesManagement') || 'faqs');

    const [openStates, setOpenStates] = useState(false);
    const [openSettlementStates, setOpenSettlementStates] = useState(false);
    const [openShopState, setOpenShopState] = useState(false);
    const [openSalesState, setOpenSalesState] = useState(false);

    const [showPwChange, setShowPwChange] = useState(false);  
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [enablepwChange, setEnablepwChange] = useState(false);

    const loginConfirm = useNavigate();

    useEffect(() => {

        const accessToken = Cookies.get("accessToken");

        // accessToken (jwt) 이 없음
        if( !accessToken ) {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_date_save_popup}`
                },
                html : 
                `
                    <div>
                        <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                        <div class='${seller.seller_date_save_content}'><span>로그인 후 사용해주세요.</span></div>
                        <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
          
            document.addEventListener('click', function(e) {
                if(e.target && e.target.id === 'seller_sales_save') {
                    MySwal.close();
                }
            })
            
            loginConfirm('/seller');

            return
        } else {
            if(roles.isUser || roles.isStore) {
                MySwal.fire({
                    customClass: {
                        popup: `${seller.seller_date_save_popup}`
                    },
                    html : 
                    `
                        <div>
                            <div class='${seller.seller_date_save_title}'><span>접근 제한</span></div>
                            <div class='${seller.seller_date_save_content}'><span>서포트 셀러 등록 후 이용해 주세요.</span></div>
                            <div class='${seller.seller_date_save_btn} py-2'><button id='seller_sales_save'>확인</button></div>
                        </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                });
              
                document.addEventListener('click', function(e) {
                    if(e.target && e.target.id === 'seller_sales_save') {
                        MySwal.close();
                    }
                })
                
                loginConfirm('/seller');
            }
        }

    }, [userInfo]);

    const onMenuClick = () => {
        setShowMenu(!showMenu);
    }
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 820) {
                setShowMenu(true);
            } else {
                setShowMenu(false);
            }
        };

        // 컴포넌트 마운트 시에 이벤트 리스너 등록
        window.addEventListener('resize', handleResize);

        // 컴포넌트 언마운트 시에 이벤트 리스너 제거
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('activeMenu', activeMenu);
        localStorage.setItem('activeBoard', activeBoard);
        localStorage.setItem('activeSettlement', activeSettlement);
        localStorage.setItem('activeShopManagement', activeShopManagement);
        localStorage.setItem('activeSalesManagement', activeSalesManagement);
    }, [activeMenu, activeBoard, activeSettlement, activeShopManagement, activeSalesManagement]);


    const toggleOpen = () => {
        setOpenSettlementStates(false);
        setOpenShopState(false);
        setOpenSalesState(false);
        setOpenStates(!openStates);
    };
    const toggleSettlementOpen = () => {
        setOpenStates(false);
        setOpenShopState(false);
        setOpenSalesState(false);
        setOpenSettlementStates(!openSettlementStates);
    };
    const toggleShopOpen = () => {
        setOpenSettlementStates(false);
        setOpenStates(false);
        setOpenSalesState(false);
        setOpenShopState(!openShopState);
    };
    const toggleSalesOpen = () => {
        setOpenShopState(false);
        setOpenSettlementStates(false);
        setOpenStates(false);
        setOpenSalesState(!openSalesState);

    };

    // 메뉴 버튼을 클릭했을 때 호출될 함수
    const handleMenuClick = (menuName) => {
        // 현재 activeMenu가 'edit'이고 다른 메뉴로 이동하려는 경우
        if (activeMenu === 'edit' && menuName !== 'edit') {
            MySwal.fire({
                customClass: {
                    popup: `${seller.seller_edit_move_popup}`,
                },
                html: `
                    <div>
                        <div class='${seller.seller_edit_move_title}'><span>페이지를 이동하시겠습니까?</span></div>
                        <div class='${seller.seller_edit_move_sub_title}'><span>작성중인 내용은 저장되지 않습니다.</span></div>
                        <div class='${seller.seller_edit_move_btn}'>
                            <button id='confirm_move' class='btn btn-primary'>확인</button>
                            <button id='cancel_move' class='btn btn-secondary'>취소</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
                didOpen: () => {
                    // 버튼 이벤트 리스너 추가
                    const confirmButton = document.getElementById('confirm_move');
                    const cancelButton = document.getElementById('cancel_move');
    
                    confirmButton.addEventListener('click', () => {
                        MySwal.close(); // Swal 닫기
                        navigateToMenu(menuName); // 메뉴 이동
                    });
    
                    cancelButton.addEventListener('click', () => {
                        MySwal.close(); // Swal 닫기
                    });
                },
            });
    
            return; // Swal 표시 후 함수 종료
        }
    
        // 메뉴 변경 로직 실행
        navigateToMenu(menuName);
    };

    const navigateToMenu = (menuName) => {
        setActiveMenu(menuName);

        if(menuName === 'dashboard') {
            navigate('/seller/supportdashboard');
            setActiveBoard('')
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        
        if(menuName === 'review') {
            navigate('/seller/review');
            setActiveBoard('')
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);

        }

        if(menuName === 'board') {
            navigate('/seller/board');
            setActiveBoard('board');
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }

        if(menuName === 'settlement') {
            navigate('/seller/proceeds');
            setActiveSettlement('proceeds');
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }

        if(menuName === 'store') {
            navigate('/seller/shop/profile');
            setActiveShopManagement('profile');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        
        if(menuName === 'edit') {
            navigate('/seller/productAdd');
            setActiveShopManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
            
        }
        
        if(menuName === 'sales') {
            navigate('/seller/sales/faq');
            setActiveSalesManagement('faqs');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
        }
        
        if(menuName === 'product') {
            navigate('/seller/products');
            setActiveShopManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }

        if(menuName === 'reservation') {
            navigate('/seller/reservation');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        if(menuName === 'message') {
            navigate('/seller/message');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
        if(menuName === 'messagePayment') {
            navigate('/seller/messagePayment');
            setActiveSalesManagement('');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
            setOpenStates(false);
            setOpenSettlementStates(false);
            setOpenShopState(false);
            setOpenSalesState(false);
        }
    }

    const changeActiveBoard = (newBoard) => {
        setActiveBoard(newBoard); // 하위 메뉴 상태 변경
        if(newBoard === 'board') {
            navigate('/seller/board');
            setActiveMenu('board'); // 상위 메뉴 상태도 'board'로 변경
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
        if(newBoard === 'faq') {
            navigate('/seller/faq');
            setActiveMenu('board'); // 상위 메뉴 상태도 'board'로 변경
            setActiveSettlement('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveSettlement = (newSettlement) => {
        if(newSettlement === 'proceeds') {
            navigate('/seller/proceeds');
            setActiveSettlement(newSettlement); // 하위 메뉴 상태 변경
            setActiveMenu('settlement'); // 상위 메뉴 상태도 'settlement'로 변경
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
        if(newSettlement === 'calculate') {
            navigate('/seller/calculate');
            setActiveSettlement(newSettlement); // 하위 메뉴 상태 변경
            setActiveMenu('settlement'); // 상위 메뉴 상태도 'settlement'로 변경
            setActiveBoard('');
            setActiveShopManagement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveShopManagement = (newShopMangement) => {
        if(newShopMangement === 'profile') {
            navigate('/seller/shop/profile')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'operating') {
            navigate('/seller/shop/operating')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'dateSetting') {
            navigate('/seller/shop/schedule')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
        if(newShopMangement === 'businessinfo') {
            navigate('/seller/shop/business')
            setActiveShopManagement(newShopMangement);
            setActiveMenu('store');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveSalesManagement('');
        }
    }
    const changeActiveSalesManagement = (newSalesMangement) => {
        if(newSalesMangement === 'faqs') {
            navigate('/seller/sales/faq')
            setActiveSalesManagement(newSalesMangement);
            setActiveMenu('sales');
            setActiveBoard('');
            setActiveSettlement('');
            setActiveShopManagement('');
        }
    }

    const enhancedChildren = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
            handleMenuClick, // 메뉴 변경 함수 전달
            setActiveMenu, // 쇼핑 메뉴 변경 함수 전달
            });
        }
        return child;
    });

    // 정규식: 영어, 숫자, 특수문자만 허용
    const handleInputChange = (event, setState) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z0-9!@#$%^&*()_+=-]*$/; // 허용할 문자들 정의
        if (regex.test(value)) {
        setState(value); // 값이 유효할 때만 상태 업데이트
        }
    };

    useEffect(() => {
        if (password === confirmPassword && password.length > 0) {
            setEnablepwChange(true);
        } else {
            setEnablepwChange(false);
        }
    }, [password, confirmPassword]);

    const pwChangeSuccess = async () => {
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>패스워드를 변경 하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>변경</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.sellerConfirmPwChange(userInfo.userId, password);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>패스워드가 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_add_sub_title}'><span>변경된 패스워드로 로그인 해주세요.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                logoutSetting();
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                                navigate('/seller')
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>패스워드 변경에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    return (
        <div className={`${seller.seller_container}`}>
            <SellerHeader seller={seller} onMenuClick={onMenuClick} showMenu={showMenu} setShowPwChange={setShowPwChange}/>
            <div className=''>
                <CSSTransition in={showMenu} timeout={500} classNames="menu" unmountOnExit>
                        <SupportSellerSide seller={seller} activeMenu={activeMenu} handleMenuClick={handleMenuClick} activeBoard={activeBoard} setActiveBoard={setActiveBoard}
                                        changeActiveBoard={changeActiveBoard} activeSettlement={activeSettlement} setActiveSettlement={setActiveSettlement} changeActiveSettlement={changeActiveSettlement}
                                        activeShopManagement={activeShopManagement} setActiveShopManagement={setActiveShopManagement} changeActiveShopManagement={changeActiveShopManagement}
                                        setActiveSalesManagement={setActiveSalesManagement} changeActiveSalesManagement={changeActiveSalesManagement} activeSalesManagement={activeSalesManagement}
                                        sellerlogout={sellerlogout} userInfo={userInfo} openStates={openStates} openSettlementStates={openSettlementStates}
                                        openShopState={openShopState} openSalesState={openSalesState} toggleOpen={toggleOpen}
                                        toggleSettlementOpen={toggleSettlementOpen} toggleShopOpen={toggleShopOpen} toggleSalesOpen={toggleSalesOpen} setShowPwChange={setShowPwChange}
                        />
                </CSSTransition>
                <div className={`${seller.seller_content_container}`}>
                    {enhancedChildren}
                </div>
            </div>

            {
                showPwChange &&
                <div className={`${admin.admin_header_pw_change_container}`}>
                    <div className={`${admin.admin_header_pw_change_box}`}>
                        <div className={`${admin.admin_header_pw_change_header}`}>
                        <div>
                            <span>비밀번호 변경</span>
                        </div>
                        <div>
                            <button onClick={() => setShowPwChange(false)}>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                        </div>
                        <div className={`${admin.admin_header_pw_change_content}`}>
                        <div>
                            <div>
                            <span>변경할 비밀번호</span>
                            </div>
                            <div>
                            <input
                                type="text"
                                value={password}
                                onChange={(e) => handleInputChange(e, setPassword)}
                            />
                            </div>
                        </div>
                        <div>
                            <div>
                            <span>비밀번호 확인</span>
                            </div>
                            <div>
                            <input
                                type="text"
                                value={confirmPassword}
                                onChange={(e) => handleInputChange(e, setConfirmPassword)}
                            />
                            </div>
                        </div>
                        </div>
                        <div className={`${admin.admin_header_pw_change_footer}`}>
                        <div>
                            <button className={`${enablepwChange ? admin.admin_active_pw_confirm : ''}`} onClick={() => pwChangeSuccess()} disabled={!enablepwChange}>변경하기</button>
                        </div>
                        </div>
                    </div>
                    </div>
            }
        </div>
    );
}

export default SellerLayout