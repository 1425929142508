import React, { useContext, useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as adminApi from '../../../../apis/admin';
import { Pagination, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Option } from 'antd/es/mentions';
import { LoginContext } from '../../../../contexts/LoginContextProvider';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const AdminOrder = () => {
    const navigate = useNavigate();
    const { isLogin, adminlogout, userInfo, loginCheck, roles } = useContext(LoginContext);
    const MySwal = withReactContent(Swal);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [totalItems, setTotalItems] = useState(0);
    const [activeButton, setActiveButton] = useState('newOrder');
    const [openOtherOrder, setOpenOtherOrder] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [filteredOrderList, setFilteredOrderList] = useState([]);
    const [deliveryCompanies, setDeliveryCompanies] = useState([]);
    const [deliveryInfo, setDeliveryInfo] = useState({});
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDeliveryDelayModal, setShowDeliveryDelayModal] = useState(false);
    const [deliveryDelayData, setDeliveryDelayData] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(null);

    const [kakaoBizTemplate, setKakaoBizTemplate] = useState(null);

    const [showMobileStateBtn, setShowMobileStateBtn] = useState(false);
    const [showOtherOrder, setShowOtherOrder] = useState(false);

    const [orderUserInfo, setOrderUserInfo] = useState({});
    const [singleOrderInfo, setSingleOrderInfo] = useState({});
    const [showOrderUser, setShowOrderUser] = useState(false);

    // 페이지네이션 함수
    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        console.log('deliveryDelayData : ', deliveryDelayData);
    }, [deliveryDelayData])

    useEffect(() => {
        if(roles.isAdmin) {
            adminOrderSelect();
        } else {
            orderSelect();
        }
        deliveryCompanySelect();
        fetchExchangeRate();
        kakaoBizSelect();
    }, [userInfo]);

    useEffect(() => {
        if (orderList.length > 0) {
            applyFilters(); // activeButton이나 검색어가 변경될 때마다 필터를 적용
        }
    }, [activeButton, searchTerm, orderList]); // orderList가 초기화된 후에도 필터를 적용

    const kakaoBizSelect = async () => {
        const response = await adminApi.kakaoBizTemplateSelect();
        const data = response.data;

        console.log('kakaoBizSelect : ', data);
        const filterBizData = data.list.find(item => item.templtCode === "TW_3326");

        setKakaoBizTemplate(filterBizData);
    }

    // 관리자용 주문 데이터 가져오기
    const adminOrderSelect = async () => {
        const response = await adminApi.adminOrderSelect();
        const data = response.data;
        console.log('orderData : ', data);
        initializeOrderData(data);
    };

    // 일반 사용자용 주문 데이터 가져오기
    const orderSelect = async () => {
        const response = await adminApi.orderSelect(userInfo?.userId);
        const data = response.data;
        console.log('orderData : ', data);
        initializeOrderData(data);
    };

    // 주문 데이터 초기화
    const initializeOrderData = (data) => {
        const initialDeliveryInfo = data.reduce((acc, order) => {
            acc[order.paymentNo] = {
                deliveryCompany: order.deliveryCompany || '',
                waybillNumber: order.waybillNumber || ''
            };
            return acc;
        }, {});

        setOrderList(data);
        // setFilteredOrderList(data);
        setTotalItems(data.length);
        setDeliveryInfo(initialDeliveryInfo);

        // 초기 화면에서 newOrder 상태에 대한 필터 적용
        setActiveButton('newOrder'); 
    };

    const deliveryCompanySelect = async () => {
        try {
            const response = await fetch('https://apis.tracker.delivery/carriers');
            const data = await response.json();

            console.log('delivery : ', data);
            setDeliveryCompanies(data);
        } catch (error) {
            console.error('Error fetching delivery companies:', error);
        }
    };

    const toggleOtherOrder = () => {
        setOpenOtherOrder(!openOtherOrder);
    };

    const handleDeliveryCompanyChange = (value, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                deliveryCompany: value
            }
        }));
    };

    const handleWaybillNumberChange = (e, paymentNo) => {
        setDeliveryInfo(prev => ({
            ...prev,
            [paymentNo]: {
                ...prev[paymentNo],
                waybillNumber: e.target.value
            }
        }));
    };

    const handleSelectOrder = (paymentNo) => {
        setSelectedOrders(prev => {
            const newSelectedOrders = prev.includes(paymentNo)
                ? prev.filter(no => no !== paymentNo)
                : [...prev, paymentNo];
            setAllSelected(newSelectedOrders.length === filteredOrderList.length);
            return newSelectedOrders;
        });
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedOrders([]);
        } else {
            const allOrderNos = filteredOrderList.map(order => order.paymentNo);
            setSelectedOrders(allOrderNos);
        }
        setAllSelected(!allSelected);
    };

    const handleDispatch = () => {
        console.log('selectedOrders : ', selectedOrders);
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    
        selectedOrders.forEach(paymentNo => {
            const info = deliveryInfo[paymentNo];
            console.log(`Payment No: ${paymentNo}, Delivery Company: ${info?.deliveryCompany}, Waybill Number: ${info?.waybillNumber}`);
    
            if (!info?.deliveryCompany || !info?.waybillNumber) {
                hasEmptyFields = true;
            }
    
            deliveryProcessing.push({
                paymentNo: paymentNo,
                deliveryCompany: info?.deliveryCompany,
                waybillNumber: info?.waybillNumber
            });
        });
    
        if (hasEmptyFields) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>선택된 상품의 택배사와 운송장번호를 입력해주세요</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }
    
        console.log('deliveryProcessing : ', deliveryProcessing);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 발송 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>발송처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const payload = {
                        deliveryProcessing,
                        kakaoBizTemplate
                    }

                    console.log('payload : ', payload);
                    const response = await adminApi.deliveryUpdate(deliveryProcessing);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 발송 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 발송 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const handleDeliveryReady = () => {
        console.log('selectedOrders : ', selectedOrders);
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 배송준비중으로 변경 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>배송준비중 처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.paymentStateUpdate(selectedOrders);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 배송 준비중으로 변경 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 발송 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleEdit = (paymentNo) => {
        const info = deliveryInfo[paymentNo];
        console.log(`Payment No: ${paymentNo}, Delivery Company: ${info?.deliveryCompany}, Waybill Number: ${info?.waybillNumber}`);

    };

    const counts = {
        newOrder: orderList.filter(order => ['입금대기', '결제대기', '결제완료', '배송준비'].includes(order.paymentState)).length,
        depositWait: orderList.filter(order => ['입금대기', '결제대기'].includes(order.paymentState)).length,
        completePayment: orderList.filter(order => order.paymentState === '결제완료').length,
        deliveryPreparing: orderList.filter(order => order.paymentState === '배송준비').length,
        shipping: orderList.filter(order => order.paymentState === '배송중').length,
        deliveryComplete: orderList.filter(order => order.paymentState === '배송완료').length,
    };

    // const filteredOrderList = orderList.filter(order => {
    //     if (activeButton === 'newOrder') {
    //         return ['입금대기', '결제대기', '결제완료', '배송준비'].includes(order.paymentState);
    //     }
    //     if (activeButton === 'depositWait') {
    //         return ['입금대기', '결제대기'].includes(order.paymentState);
    //     }
    //     if (activeButton === 'completePayment') {
    //         return order.paymentState === '결제완료';
    //     }
    //     if (activeButton === 'deliveryPreparing') {
    //         return order.paymentState === '배송준비';
    //     }
    //     if (activeButton === 'shipping') {
    //         return order.paymentState === '배송중';
    //     }
    //     if (activeButton === 'deliveryComplete') {
    //         return order.paymentState === '배송완료';
    //     }
    //     return order.paymentState === activeButton;
    // });

    const formatDate = (date) => {
        if (!date) return '';
        const parsedDate = new Date(date);
      
        if (isNaN(parsedDate)) {
          console.warn('Invalid date value:', date);
          return '';
        }
      
        // UTC 시간 그대로 표시
        return parsedDate.toISOString().replace('T', ' ').substring(0, 19);
    };
      

    const onWaybillBlur = (order, deliveryCompany, deliveryNumber) => {
        const deliveryProcessing = [];
    
        let hasEmptyFields = false;
    

            if (!deliveryCompany || !deliveryNumber) {
                hasEmptyFields = true;
            } else {
                deliveryProcessing.push({
                    paymentNo: order.paymentNo,
                    deliveryCompany: deliveryCompany,
                    waybillNumber: deliveryNumber
                });
            }
            
    
        if (hasEmptyFields) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `<div><div class='${admin.admin_seller_add_title}'><span>해당 상품의 택배사와 운송장번호를 입력해주세요</span></div></div>`,
                showConfirmButton: false,
                showCancelButton: false,
                timer: 2000
            });
            return;
        }
    
        console.log('deliveryProcessing : ', deliveryProcessing);

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>해당 상품을 발송 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>발송처리</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const payload = {
                        deliveryProcessing,
                        kakaoBizTemplate
                    }
    
                    console.log('payload : ', payload);

                    const response = await adminApi.deliveryUpdate(payload);
                    const data = response.data;
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>해당 상품이 발송 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {

                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>해당 상품 발송 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const getFilteredAndPaginatedOrders = () => {
        return filteredOrderList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    };

    const totalItemsCount = filteredOrderList.length;

    const handleForcedDeliveryCom = async () => {

        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails);

        const newItem = [];
        selectedOrderDetails.forEach(order => {
            console.log(`Payment No: ${order.paymentNo}, Payment Key: ${order.paymentKey}, Refund Amount: ${order.refundAmount}`);

            if(order.deliveryCompany === '' | order.waybillNumber === '') {
                alert('택배사와 운송장 번호를 입력해주세요. ( 택배사와 운송장 번호가 없을 시 임의의 값을 넣고 진행해주세요.');
                
                return;
            }
        });


        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 강제 배송완료 처리 하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_sub_title}'><span>강제 배송완료 처리를 하면 구매자에게 배송완료 알림이 가게됩니다.</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>강제배송완료</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.forcedDeliveryCompleted(selectedOrderDetails);
                    const data = response.data;

                    console.log(data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 강제 배송완료 처리 되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품 반품완료 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    // 필터링 로직 - 주문 상태 및 검색어 적용
    const applyFilters = () => {
        let filteredOrders = orderList.filter(order => {
            if (activeButton === 'newOrder') {
                return ['입금대기', '결제대기', '결제완료', '배송준비'].includes(order.paymentState);
            }
            if (activeButton === 'depositWait') {
                return ['입금대기', '결제대기'].includes(order.paymentState);
            }
            if (activeButton === 'completePayment') {
                return order.paymentState === '결제완료';
            }
            if (activeButton === 'deliveryPreparing') {
                return order.paymentState === '배송준비';
            }
            if (activeButton === 'shipping') {
                return order.paymentState === '배송중';
            }
            if (activeButton === 'deliveryComplete') {
                return order.paymentState === '배송완료';
            }
            return order.paymentState === activeButton;
        });

        // 검색어 필터 적용
        if (searchTerm.trim()) {
            filteredOrders = filteredOrders.filter(order => 
                (order.paymentProductName && order.paymentProductName.includes(searchTerm)) ||
                (order.paymentState && order.paymentState.includes(searchTerm)) ||
                (order.paymentType && order.paymentType.includes(searchTerm)) ||
                (order.paymentNumber && order.paymentNumber.includes(searchTerm)) ||
                (order.paymentRegDate && formatDate(order.paymentRegDate).includes(searchTerm)) ||
                (order.shopName && order.shopName.includes(searchTerm)) ||
                (order.userId && order.userId.includes(searchTerm)) ||
                (order.paymentPrice && order.paymentPrice.toString().includes(searchTerm)) ||
                (order.orderId && order.orderId.includes(searchTerm)) ||
                (order.deliveryUser && order.deliveryUser.includes(searchTerm)) ||
                (order.deliveryAddress && order.deliveryAddress.includes(searchTerm)) ||
                (order.deliveryPhone && order.deliveryPhone.includes(searchTerm))
            );
        }

        setFilteredOrderList(filteredOrders); // 필터링된 결과를 상태로 저장
        setCurrentPage(1); // 검색 후 페이지 리셋
    };

    // 검색 핸들러
    const handleSearch = () => {
        applyFilters();
    };
    
    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    // 주문 상태 필터 선택
    const handleFilterClick = (status) => {
        setActiveButton(status);
        // applyFilters(); // 필터 적용
    };

    const handleDepositWait = () => {
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails);

        if(selectedOrderDetails.length === 0) {
            alert('선택된 주문이 없습니다.');

            return;
        }

        // "카드" 또는 "간편결제"인 항목이 있는지 확인
        const hasCardOrEasyPay = selectedOrderDetails.some(order => 
            order.paymentType === "카드" || order.paymentType === "간편결제"
        );

        const hasPaymentStateCheck = selectedOrderDetails.some(order => 
            order.paymentState === "입금대기"
        );

        if (hasCardOrEasyPay) {
            alert("결제 방법이 카드이거나 간편결제인 상품이 포함되어있습니다.");
            return;
        }

        if(hasPaymentStateCheck) {
            alert("상태가 입금대기인 상품이 포함되어 있습니다.");

            return;
        }

        console.log('여기');
        
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 입금대기 상태로 변경하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>변경</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.depositWaitUpdate(selectedOrderDetails);
                    const data = response.data;

                    console.log(data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 입금대기 상태로 변경되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 입금대기 처리에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    }

    const handleDeliveryPreparing = () => {
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails);

        if(selectedOrderDetails.length === 0) {
            alert('선택된 주문이 없습니다.');

            return;
        }

        const hasPaymentStateCheck = selectedOrderDetails.some(order => 
            order.paymentState === "입금대기"
        );

        const hasPaymentStateCheck2 = selectedOrderDetails.some(order => 
            order.paymentState === "배송준비"
        );

        if(hasPaymentStateCheck2) {
            alert('배송준비 상품이 포함되어있습니다.');
            
            return;
        }

        if(hasPaymentStateCheck) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입금대기 상품이 포함되어있습니다. 무시하고 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.deliveryPreparingUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 배송준비 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 배송준비 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 배송준비 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.deliveryPreparingUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 배송준비 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 배송준비 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    const handlePaymentComple = () => {
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        console.log('selectedOrderDetails : ', selectedOrderDetails);

        if(selectedOrderDetails.length === 0) {
            alert('선택된 주문이 없습니다.');

            return;
        }

        const hasPaymentStateCheck = selectedOrderDetails.some(order => 
            order.paymentState === "입금대기"
        );

        const hasPaymentStateCheck2 = selectedOrderDetails.some(order => 
            order.paymentState === "결제완료"
        );

        if(hasPaymentStateCheck2) {
            alert('결제완료 상품이 포함되어있습니다.');
            
            return;
        }

        if(hasPaymentStateCheck) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입금대기 상품이 포함되어있습니다. 무시하고 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.paymentCompleUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 결제완료 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 결제완료 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 결제완료 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.paymentCompleUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 결제완료 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 결제완료 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }
    const handleInDelivery = () => {
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));

        if(selectedOrderDetails.length === 0) {
            alert('선택된 주문이 없습니다.');

            return;
        }

        const hasPaymentStateCheck = selectedOrderDetails.some(order => 
            order.paymentState === "입금대기"
        );

        const hasPaymentStateCheck2 = selectedOrderDetails.some(order => 
            order.paymentState === "배송중"
        );

        if(hasPaymentStateCheck2) {
            alert('배송중인 상품이 포함되어있습니다.');
            
            return;
        }

        if(hasPaymentStateCheck) {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>입금대기 상품이 포함되어있습니다. 무시하고 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.inDeliveryUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 배송중 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 배송중 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        } else {
            MySwal.fire({
                customClass: {
                    popup: `${admin.admin_seller_add_popup}`
                },
                html: `
                    <div>
                        <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품을 배송중 상태로 변경하시겠습니까?</span></div>
                        <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                          <button class='mx-1' id='seller_add_cancel'>취소</button>
                          <button class='mx-1' id='seller_add_success'>변경</button>
                        </div>
                    </div>
                `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);
        
            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    try {
                        const response = await adminApi.inDeliveryUpdate(selectedOrderDetails);
                        const data = response.data;
    
                        console.log(data);
            
                        if (data === 'SUCCESS') {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품이 배송중 상태로 변경되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        } else {
                            MySwal.fire({
                                customClass: {
                                    popup: `${admin.admin_seller_add_popup}`
                                },
                                html: `
                                <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedOrders.length}개 상품의 배송중 처리에 실패했습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                                </div>
                                `,
                                showConfirmButton: false,
                                showCancelButton: false,
                            });
                            document.addEventListener('click', sellerAddConfirm);
            
                            function sellerAddConfirm(e) {
                                if (e.target && e.target.id === 'seller_add_confirm') {
                                    if(roles.isAdmin) {
                                        adminOrderSelect();
                                    } else {
                                        orderSelect();
                                    }
                                    MySwal.close();
                                    document.removeEventListener('click', sellerAddConfirm);
                                    document.removeEventListener('click', sellerAddSuccess);
                                    document.removeEventListener('click', sellerAddCancel);
                                }
                            }
                        }
                    } catch (error) {
                        console.error('error : ', error);
                    }
                }
            }
        
            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }
    }

    const defaultText = "[Web발신]\n데일리덕 발송지연 안내\n'앞 멘트는 제외하고\n보내실 내용만 입력해주세요.'"; // 기본값

    const handleDeliveryDelay = () => {
        const selectedOrderDetails = orderList.filter(order => selectedOrders.includes(order.paymentNo));
    
        console.log('selectedOrderDetails : ', selectedOrderDetails);
    
        if (selectedOrderDetails.length === 0) {
            alert('선택된 주문이 없습니다.');
            return;
        }
    
        // 기본값과 함께 데이터 설정
        const updatedData = selectedOrderDetails.map(order => ({
            ...order,
            deliveryDelayText: "" // 기본 텍스트 설정
        }));
    
        setDeliveryDelayData(updatedData);
        setShowDeliveryDelayModal(!showDeliveryDelayModal);
    };

    // 바이트 수 계산 함수
    const calculateByteSize = (str) => {
        return new Blob([str]).size; // UTF-8 인코딩 기준 바이트 계산
    };

    const handleTextChange = (e, index) => {
        const newText = e.target.value;
    
        // 현재 입력된 전체 텍스트
        const fullText = newText;
    
        // 바이트 수 계산
        const byteCount = calculateByteSize(fullText);
    
        // 90바이트를 넘지 않으면 업데이트
        if (byteCount <= 47) {
            const updatedData = [...deliveryDelayData];
            updatedData[index].deliveryDelayText = newText; // 기본 텍스트를 제외한 나머지 텍스트를 저장
            setDeliveryDelayData(updatedData);
        }
    };
    
    const handleSMSForwarding = () => {
        let hasDelayText = false;
    
        deliveryDelayData.forEach(order => {
            if (order.deliveryDelayText === '') { // deliveryDelayText가 빈 값인 경우
                hasDelayText = true;
            }
        });
    
        if (hasDelayText) {
            alert('값이 비어있는 항목이 있습니다.');

            return; // 빈 값이 있을 경우 함수 종료
        }
    
        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                <div>
                    <div class='${admin.admin_seller_add_title}'><span>입력한 내용을 문자 발송하시겠습니까?</span></div>
                    <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                      <button class='mx-1' id='seller_add_cancel'>취소</button>
                      <button class='mx-1' id='seller_add_success'>발송</button>
                    </div>
                </div>
            `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);
    
        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.deliveryDelaGuide(deliveryDelayData);
                    const data = response.data;

                    console.log(data);
        
                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>문자가 정상적으로 발송되었습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowDeliveryDelayModal(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                            <div>
                            <div class='${admin.admin_seller_add_title}'><span>문자 발송에 실패했습니다.</span></div>
                            <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                            <button class='mx-1' id='seller_add_confirm'>확인</button>
                            </div>
                            </div>
                            `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);
        
                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                if(roles.isAdmin) {
                                    adminOrderSelect();
                                } else {
                                    orderSelect();
                                }
                                setShowDeliveryDelayModal(false);
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }
    
        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
        
    };

    const fetchExchangeRate = async () => {
        try {
            const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
            setExchangeRate(response.data.rates.KRW);
        } catch (error) {
            console.error("환율 정보를 가져오는 중 오류 발생", error);
        }
    };

    
    

    const handleMobileState = () => {
        setShowMobileStateBtn(!showMobileStateBtn);
    }
    const handleOtherOrder = () => {
        setShowOtherOrder(!showOtherOrder);
    }

    const groupOptionsByOptionId = (options) => {
        return options.reduce((acc, option) => {
            const { optionId } = option;
            if (!acc[optionId]) {
                acc[optionId] = []; // 새로운 그룹 생성
            }
            acc[optionId].push(option); // 해당 optionId에 속하는 옵션 추가
            return acc;
        }, {});
    };
    
    const onOrderUserShow = (order) => {
        setOrderUserInfo(order.user);
        setSingleOrderInfo(order);
        setShowOrderUser(true);
    }

    return (
        <div className={`${admin.admin_seller_product_management_box} px-3 py-3 ${showDeliveryDelayModal && admin.admin_overflow_y_hidden} ${showOrderUser && admin.admin_overflow_y_hidden}`}>
            <div>
                <div className={`${admin.admin_shop_product_management_header} py-3`}>
                    <div><span>주문 관리</span></div>
                </div>
                <div className={`${admin.admin_shop_product_tap}`}>
                    <div className={`${activeButton === 'newOrder' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('newOrder')}>신규주문({counts.newOrder})</button>
                    </div>
                    <div className={`${activeButton === 'depositWait' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('depositWait')}>입금대기({counts.depositWait})</button>
                    </div>
                    <div className={`${activeButton === 'completePayment' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('completePayment')}>결제완료({counts.completePayment})</button>
                    </div>
                    <div className={`${activeButton === 'deliveryPreparing' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('deliveryPreparing')}>배송준비({counts.deliveryPreparing})</button>
                    </div>
                    <div className={`${activeButton === 'shipping' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('shipping')}>배송중({counts.shipping})</button>
                    </div>
                    <div className={`${activeButton === 'deliveryComplete' ? admin.admin_shop_product_tap_active : ''}`}>
                        <button onClick={() => handleFilterClick('deliveryComplete')}>배송완료({counts.deliveryComplete})</button>
                    </div>
                </div>
                <div className={`${admin.admin_shop_product_mobile_active_tap}`}>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={10}
                        freeMode={true}
                        className={`${admin.admin_shop_product_tap_swiper}`}
                    >
                        <SwiperSlide>
                            <div className={`${activeButton === 'newOrder' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('newOrder')}>신규주문({counts.newOrder})</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'depositWait' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('depositWait')}>입금대기({counts.depositWait})</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'completePayment' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('completePayment')}>결제완료({counts.completePayment})</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'deliveryPreparing' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('deliveryPreparing')}>배송준비({counts.deliveryPreparing})</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'shipping' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('shipping')}>배송중({counts.shipping})</button>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={`${activeButton === 'deliveryComplete' ? admin.admin_shop_product_tap_active : ''}`}>
                                <button onClick={() => handleFilterClick('deliveryComplete')}>배송완료({counts.deliveryComplete})</button>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div>
                    <div className={`${admin.admin_seller_order_management_top} py-2`}>
                        <div className={`${admin.admin_seller_order_management_state_btn}`}>
                            <div>
                                <button onClick={handleDispatch}>발송처리</button>
                            </div>
                            <div>
                                <button onClick={handleDeliveryReady}>배송준비 처리</button>
                            </div>
                            {/* <div>
                                <button>입금확인 처리</button>
                            </div> */}
                            <div className='position-relative'>
                                <div>
                                    <button className='d-flex align-items-center' onClick={() => { toggleOtherOrder() }}>기타주문 처리<span className="material-symbols-outlined">{openOtherOrder ? 'arrow_drop_up' : 'arrow_drop_down'}</span></button>
                                </div>
                                <CSSTransition in={openOtherOrder} timeout={300} classNames="review" unmountOnExit>
                                    <div className={`${admin.admin_shop_order_other_btn}`}>
                                        <div><button onClick={() => handleDepositWait()}>입금대기로 되돌리기</button></div>
                                        <div><button onClick={() => handleDeliveryPreparing()}>배송준비로 되돌리기</button></div>
                                        <div><button onClick={() => handlePaymentComple()}>결제완료로 되돌리기</button></div>
                                        <div><button onClick={() => handleInDelivery()}>배송중으로 되돌리기</button></div>
                                        <div><button onClick={() => handleForcedDeliveryCom()}>강제 배송완료 처리</button></div>
                                        <div><button onClick={() => handleDeliveryDelay()}>발송지연 처리</button></div>
                                    </div>
                                </CSSTransition>
                            </div>
                            <div className='d-flex justify-content-center align-items-center ps-3'>
                                <div><button>취소처리</button></div>
                                <div><button>반품처리</button></div>
                                <div><button>교환처리</button></div>
                            </div>
                        </div>
                        <div className={`${admin.admin_shop_product_mobile_tap}`}>
                            <div><button onClick={handleMobileState}>상태처리</button></div>
                            {
                                showMobileStateBtn &&
                                <div className={`${admin.admin_shop_product_mobile_tap_content}`}>
                                    <div><button onClick={handleDispatch}>발송처리</button></div>
                                    <div><button onClick={handleDeliveryReady}>배송준비 처리</button></div>
                                    <div>
                                        <div>
                                            <button onClick={handleOtherOrder}>기타주문 처리<span className="material-symbols-outlined">{showOtherOrder ? 'arrow_drop_up' : 'arrow_drop_down'}</span></button>
                                        </div>
                                        {
                                            showOtherOrder &&
                                            <div>
                                                <div><button onClick={() => handleDepositWait()}>입금대기로 되돌리기</button></div>
                                                <div><button onClick={() => handleDeliveryPreparing()}>배송준비로 되돌리기</button></div>
                                                <div><button onClick={() => handlePaymentComple()}>결제완료로 되돌리기</button></div>
                                                <div><button onClick={() => handleInDelivery()}>배송중으로 되돌리기</button></div>
                                                <div><button onClick={() => handleForcedDeliveryCom()}>강제 배송완료 처리</button></div>
                                                <div><button onClick={() => handleDeliveryDelay()}>발송지연 처리</button></div>
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <div><button>취소처리</button></div>
                                        <div><button>반품처리</button></div>
                                        <div><button>교환처리</button></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className={`${admin.admin_order_inspection_search_box}`}>
                            <button className={`${admin.admin_seller_inspection_download}`}>
                                <div><span>내보내기</span></div>
                                <div><span className="material-symbols-outlined">download</span></div>
                            </button>
                            <div className={`${admin.admin_seller_inspection_input}`}>
                                <input 
                                    type="text" 
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)} 
                                    onKeyDown={handleSearchKeyDown}
                                />
                                <button className={`${admin.admin_seller_inspection_search_btn}`} onClick={handleSearch}>
                                    <span className="material-symbols-outlined">search</span>
                                </button>

                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_order_content_box}`}>
                        <div>
                            <div className={`${admin.admin_order_header} pt-3 pb-2`}>
                                <div><input type="checkbox" checked={allSelected} onChange={handleSelectAll} /></div>
                                <div><span>주문번호/시각</span></div>
                                <div><span>주문 상품</span></div>
                                <div><span>금액</span></div>
                                <div><span>배송</span></div>
                                <div><span>상태</span></div>
                                <div><span>운송장번호</span></div>
                                <div><span>배송정보</span></div>
                                <div><span>결제 내역</span></div>
                            </div>

                            {
                                Array.isArray(filteredOrderList) && getFilteredAndPaginatedOrders().map((order, index) => {
                                    // 소계 계산
                                    const subtotal = order.options.length > 0
                                    ? (order.paymentPrice - order.deliveryPrice)
                                    :
                                    (order.principalAmount * order.totalQuantity)
                                    ;

                                    // optionId 기준으로 그룹화
                                    const groupedOptionsByOptionId = groupOptionsByOptionId(order.options);

                                    return (
                                        <div className={`${admin.admin_order_content}`} key={index + 1 + (currentPage - 1) * itemsPerPage}>
                                            <div><input type="checkbox" checked={selectedOrders.includes(order.paymentNo)} onChange={() => handleSelectOrder(order.paymentNo)} /></div>
                                            <div>
                                                <div><span>{order.paymentNumber}</span></div>
                                                <div className={`${admin.admin_font_date_font}`}><span>{formatDate(order.paymentRegDate)}</span></div>
                                            </div>
                                            <div>
                                            {order.options.length > 0 ? (
                                                // 옵션이 있는 경우 각 orderId 그룹별로 출력
                                                Object.keys(groupedOptionsByOptionId).map((optionId) => (
                                                    <div className={`${admin.admin_order_content_product}`} key={optionId}>
                                                        <div>
                                                            <div className={`${admin.admin_order_content_product_order}`}>
                                                                <div><input type="checkbox" /></div>
                                                                <div><img src={order.paymentImg} alt="앨범이미지" /></div>
                                                                <div className='text-start'>
                                                                    <div className={`${admin.admin_gray_color}`}><span>{order.paymentProductNumber}</span></div>
                                                                    <div><span>{order.paymentProductName}</span></div>
                                                                    <div className={`${admin.admin_gray_color} py-1`}>
                                                                        <span>옵션:</span>
                                                                        {groupedOptionsByOptionId[optionId].map((item, idx) => (
                                                                            <span key={idx}>{item.selectionOptionName}/{item.selectionOptionValue}{idx < groupedOptionsByOptionId[optionId].length - 1 ? ', ' : ''}</span>
                                                                        ))}
                                                                    </div>
                                                                    <div className={`${admin.admin_gray_color} py-1`}>
                                                                        <span>수량: {groupedOptionsByOptionId[optionId][0].quantity}개</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div><span>{(order.principalAmount + groupedOptionsByOptionId[optionId][0].addPrice).toLocaleString()}원</span></div>
                                                        <div>
                                                            <div><span>{order.deliveryType}</span></div>
                                                            <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                                                        </div>
                                                        <div className={`${admin.admin_signature_color2}`}>
                                                            <span>{order.paymentState}</span>
                                                            <br />
                                                            {order.paymentState === '배송완료' && order.purchaseConfirmation === true && <span>(구매확정)</span>}
                                                            {order.deliveryDelayText && <span>(발송지연)</span>}
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                // 옵션이 없는 경우 기본 상품 정보만 출력
                                                <div className={`${admin.admin_order_content_product}`} key={order.paymentNo}>
                                                    <div>
                                                        <div className={`${admin.admin_order_content_product_order}`}>
                                                            <div><input type="checkbox" /></div>
                                                            <div><img src={order.paymentImg} alt="결제이미지" /></div>
                                                            <div className='text-start'>
                                                                <div className={`${admin.admin_gray_color}`}><span>{order.paymentProductNumber}</span></div>
                                                                <div><span>{order.paymentProductName}</span></div>
                                                                <div className={`${admin.admin_gray_color} py-1`}><span>수량: {order.totalQuantity}개</span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div><span>{order.principalAmount.toLocaleString()}원</span></div>
                                                    <div>
                                                        <div><span>{order.deliveryType}</span></div>
                                                        <div><span>{order.deliveryPrice.toLocaleString()}원</span></div>
                                                    </div>
                                                    <div className={`${admin.admin_signature_color2}`}>
                                                        <span>{order.paymentState}</span>
                                                        <br/>
                                                        {order.paymentState === '배송완료' && order.purchaseConfirmation === true && <span>(구매확정)</span>}
                                                    </div>
                                                </div>
                                            )}
                                            </div>
                                            <div className='px-1'>
                                                <div>
                                                    <Select
                                                        className={`${admin.admin_order_delivery_select}`}
                                                        placeholder='택배사 선택'
                                                        onChange={(value) => handleDeliveryCompanyChange(value, order.paymentNo)}
                                                        value={deliveryInfo[order.paymentNo]?.deliveryCompany || order.deliveryCompany || undefined}
                                                    >
                                                        {deliveryCompanies.map((company) => (
                                                            <Option key={company.id} value={company.name}>
                                                                {company.name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div className='mt-1'>
                                                    <input
                                                        type="number"
                                                        placeholder='송장번호'
                                                        onChange={(e) => handleWaybillNumberChange(e, order.paymentNo)}
                                                        value={deliveryInfo[order.paymentNo]?.waybillNumber || order.waybillNumber || ''}
                                                        onBlur={() => onWaybillBlur(order, deliveryInfo[order.paymentNo]?.deliveryCompany, deliveryInfo[order.paymentNo]?.waybillNumber)}
                                                    />
                                                </div>
                                                {
                                                    order.paymentState === '배송중' &&
                                                    <div className={`${admin.admin_order_delivery_info_update}`}><button onClick={(e) => {e.stopPropagation(); handleEdit(order)}}>수정</button></div>
                                                }
                                                {
                                                    order.options.length > 1 &&
                                                    <div><button className={admin.admin_order_each_delivery} onClick={(e) => e.stopPropagation()}>개별송장입력</button></div>
                                                }
                                            </div>
                                            <div>
                                                <div><span>{order.deliveryUser}</span></div>
                                                <div><span>{order.deliveryPhone}</span></div>
                                                <div><span>{order.userId}</span></div>
                                                <div><span>{order.deliveryAddress}</span></div>
                                                <div><span>우&#41;{order.zipcode}</span></div>
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>총 결제금액</span></div>
                                                    <div><span>{order.paymentType !== '해외간편결제' ? parseInt(order.paymentPrice).toLocaleString() + '원' : order.paymentPrice + ' USD'}</span></div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>소계</span></div>
                                                    <div><span>{order.paymentType !== '해외간편결제' ? subtotal.toLocaleString() + '원' : (subtotal / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>배송비</span></div>
                                                    <div><span>{order.paymentType !== '해외간편결제' ? order.deliveryPrice.toLocaleString() + '원' : (order.deliveryPrice / exchangeRate).toFixed(2) + ' USD'}</span></div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>포인트 사용</span></div>
                                                    <div><span>-{order.point.toLocaleString()}원</span></div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>쿠폰 사용</span></div>
                                                    <div><span>-{order.coupon.toLocaleString()}원</span></div>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div><span>결제 방법</span></div>
                                                    <div><span>{order.paymentType}</span></div>
                                                </div>
                                            </div>
                                            {
                                                order.eventType !== null &&
                                                <div className={admin.admin_order_event_label}>
                                                    <span>이벤트 상품</span>
                                                </div>
                                            }
                                            <div className={`${admin.admin_order_content_user_info}`}><button onClick={(e) => {e.stopPropagation(); onOrderUserShow(order);}}><span className="material-symbols-outlined">person</span>{order && order.user && order.user.userName}</button></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Pagination current={currentPage} total={totalItemsCount} onChange={paginate} pageSize={itemsPerPage} />
            </div>
            {showDeliveryDelayModal && (
            <div className={`${admin.admin_seller_order_delivery_delay_container}`}>
                <div className={`${admin.admin_seller_order_delivery_delay_box}`}>
                    <div className={`${admin.admin_seller_order_delivery_delay_header}`}>
                        <div><span>발송지연 안내</span></div>
                        <div>
                            <button onClick={() => setShowDeliveryDelayModal(false)}>
                                <span className="material-symbols-outlined">close</span>
                            </button>
                        </div>
                    </div>
                    <div className={`${admin.admin_seller_order_delivery_delay_content}`}>
                        {Array.isArray(deliveryDelayData) && deliveryDelayData.map((item, index) => {
                            const fullText = item.deliveryDelayText;
                            const byteCount = calculateByteSize(fullText); // 기본 텍스트와 함께 바이트 수 계산
                            const remainingBytes = Math.max(0, 47 - byteCount); // 남은 바이트 계산

                            return (
                                <div className={`${admin.admin_seller_order_delivery_delay_item}`} key={index}>
                                    <div className={`${admin.admin_seller_order_delivery_delay_item_info}`}>
                                        <div><img src={item.paymentImg} alt="" /></div>
                                        <div>
                                            <div><span>{item.paymentNumber}</span></div>
                                            <div><span>{item.paymentProductName}</span></div>
                                            <div><span>주문아이디 : {item.userId}</span></div>
                                        </div>
                                    </div>
                                    <div className={`${admin.admin_seller_order_delivery_delay_text_area}`}>
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            value={item.deliveryDelayText} // 기본 텍스트를 제외한 텍스트만 표시
                                            onChange={(e) => handleTextChange(e, index)} // 텍스트 변경 핸들러
                                            placeholder={defaultText}
                                        />
                                        <div>
                                            <span>{byteCount}/47byte</span> {/* 바이트 수 표시 */}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={`${admin.admin_seller_order_delivery_delay_footer}`}>
                        <div><button onClick={() => setShowDeliveryDelayModal(false)}>취소</button></div>
                        <div><button onClick={handleSMSForwarding}>문자발송</button></div>
                    </div>
                </div>
            </div>
        )}
        {
            showOrderUser &&
            <div className={`${admin.admin_order_user_modal_container}`}>
                <div className={`${admin.admin_order_user_modal_box}`}>
                    <div className={`${admin.admin_order_user_modal_header}`}>
                        <div><span>주문자정보</span></div>
                        <div><button onClick={() => setShowOrderUser(false)}><span className="material-symbols-outlined">close</span></button></div>
                    </div>
                    <div className={`${admin.admin_order_user_modal_content}`}>
                        <div className={`${admin.admin_order_user_modal_content_user_profile}`}>
                            <div className={`${admin.admin_order_user_modal_content_user_profile_img}`}>
                                <div><img src={`${process.env.REACT_APP_API_SERVER}/img?file=${orderUserInfo.userProfile}`} alt="userProfile" /></div>
                                <div><button>문자보내기</button></div>
                            </div>
                            <div className={`${admin.admin_order_user_modal_content_user_profile_info}`}>
                                <div>
                                    <div><span>닉네임</span></div>
                                    <div><span>{orderUserInfo.userNick}</span></div>
                                </div>
                                <div>
                                    <div><span>이름</span></div>
                                    <div><span>{orderUserInfo.userName}</span></div>
                                </div>
                                <div>
                                    <div><span>주문자 이름</span></div>
                                    <div><span>{singleOrderInfo.deliveryUser}</span></div>
                                </div>
                                <div>
                                    <div><span>배송 주소지</span></div>
                                    <div><span>{singleOrderInfo.deliveryAddress}</span></div>
                                </div>
                                <div>
                                    <div><span>이메일</span></div>
                                    <div><span>{orderUserInfo.userEmail}</span></div>
                                </div>
                                <div>
                                    <div><span>전화번호</span></div>
                                    <div><span>{orderUserInfo.userTel}</span></div>
                                </div>
                            </div>
                        </div>
                        <div className={`${admin.admin_order_user_modal_content_user_orders}`}>
                            <div><span>주문 내역</span></div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    );
};

export default AdminOrder;
