import React, { useState, useRef, useEffect } from 'react'
// core version + navigation, pagination modules:
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link, useNavigate } from 'react-router-dom';
import ReviewComment from '../ReviewComment.jsx';
import { FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import VenueProductExp from './VenueProductExp';
import FaqScreen from '../FaqScreen.jsx';
import BusinessInfo from '../BusinessInfo.jsx';
import VenueReservation from './VenueReservation.jsx';
import * as store from '../../../../apis/store.js';
import storeStyle from '../../../../containers/market/store/store.module.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast, ToastContainer } from 'react-toastify';

const ARRAY = [0, 1, 2, 3, 4];

const VenueProduct = ( 
    {
        imgSlide, venueDetail, support, reservation, setReservation, reservationSelectOption,
        setReservationSelectOption, reservationSelectQuestion, setReservationSelectQuestion,
        reservationEssentialQuestion, setReservationEssentialQuestion, reservationRequest,
        setReservationRequest, isLogin, date, questions, setQuestions, supportNo,
        essentialQuestions, setEssentialQuestions, operatingList, shopInfo, ss, holidays, temporarys, userInfo,
        sellerUser, showDate, setShowDate, supportDetail, isHeartClicked, setIsHeartClicked, favorityCheck, supportApi
    } 
) => {
    const navigete = useNavigate();
    const MySwal = withReactContent(Swal);
    const sliderRef = useRef();
    const [reviewList, setReviewList] = useState([]);
    const [reviewCount, setReviewCount] = useState(0);
    const [ratingAverage, setRatingAverage] = useState(0);
    const [averageRatingDecimal, setAverageRatingDecimal] = useState(0);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [reviewFilterActive, setReviewFilterActive] = useState('basic');

    const [clicked, setClicked] = useState(
        ARRAY.map((_, idx) => idx < ratingAverage)
    );

    const [ratings, setRatings] = useState({
        5: 0,
        4: 0,
        3: 0,
        2: 0,
        1: 0
    });
    const [highestCount, setHighestCount] = useState(0);

    useEffect(() => {
        // ratings가 업데이트 될 때마다 highestCount를 업데이트
        setHighestCount(Math.max(...Object.values(ratings)));
    }, [ratings]);

    useEffect(() => {
        // console.log('ratings : ', ratings);
    },[ratings]);

    useEffect(() => {
        supportSelectReview();
    }, [supportNo]);

    useEffect(() => {
        setClicked(ARRAY.map((_, idx) => idx < ratingAverage));
    }, [ratingAverage]);


    const supportSelectReview = async () => {
        try {
            const response = await supportDetail.supportSelectReview(supportNo);
            const data = response.data;

            // console.log('reviewSelect : ', data);

            // data가 배열인지 확인하고, 그렇지 않다면 빈 배열로 설정
            const reviews = Array.isArray(data) ? data : [];

            setReviewList(reviews);

            // 리뷰 수 설정
            setReviewCount(reviews.length);

            // 데이터에서 rating 값을 추출하여 배열로 만들기
            const ratingValues = reviews.map(review => review.rating);

            // 소수점 첫째 자리까지 평균 계산
            const averageRatingWithDecimal = (
                ratingValues.reduce((acc, curr) => acc + curr, 0) / ratingValues.length
            ).toFixed(1);

            // 정수로 평균 계산
            const averageRating = Math.floor(averageRatingWithDecimal);

            // 기존 ratings 상태 업데이트
            setRatings(prevRatings => {
                const updatedRatings = { ...prevRatings };
                // 새로 가져온 rating 값들에 대한 카운트 업데이트
                ratingValues.forEach(rating => {
                    updatedRatings[rating] = (updatedRatings[rating] || 0) + 1;
                });
                return updatedRatings;
            });

            // 평균값 로그 출력
            // console.log('Average Rating (Integer):', averageRating);
            // console.log('Average Rating (Decimal):', averageRatingWithDecimal);

            // 상태 업데이트
            setRatingAverage(averageRating);
            setAverageRatingDecimal(averageRatingWithDecimal);
        } catch (error) {
            console.error('Failed to fetch reviews:', error);
            // 에러가 발생할 경우 적절한 상태 초기화 또는 에러 처리 로직 추가
            setReviewList([]);
            setReviewCount(0);
            setRatingAverage(0);
            setAverageRatingDecimal(0);
        }
    };

    const scroll = (start, end, duration) => {
        if (!sliderRef.current) return;
        const change = end - start;
        const startTime = performance.now();
        let requestId;
  
        const animateScroll = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            sliderRef.current.scrollLeft = start + change * progress;
            if (progress < 1) {
                requestId = requestAnimationFrame(animateScroll);
            } else {
                cancelAnimationFrame(requestId);
            }
        };
  
        requestId = requestAnimationFrame(animateScroll);
    };
    const scrollLeft = () => {
        if (!sliderRef.current) return;
        const end = sliderRef.current.scrollLeft - 100;
        scroll(sliderRef.current.scrollLeft, end, 300);
    };
  
    const scrollRight = () => {
        if (!sliderRef.current) return;
        const end = sliderRef.current.scrollLeft + 100;
        scroll(sliderRef.current.scrollLeft, end, 300);
    };

    const [activeTab, setActiveTab] = useState('상품설명');

    useEffect(() => {
        if (venueDetail && venueDetail.length > 0) {
            setReservation({
                ...reservation,
                reservationPlaceName: venueDetail[0].supportShopName ? venueDetail[0].supportShopName : ''
            });
        }
    }, [venueDetail]);

    // Extracting minimum price from mainItem array
    const getMinPrice = (items) => {
        return items.reduce((min, item) => Math.min(min, item.mainItemPrice), items[0].mainItemPrice);
    }

    // Check if mainItemOption exists
    const hasOptions = venueDetail.length > 0 && venueDetail[0].mainItemOption && venueDetail[0].mainItemOption.length > 0;

    const onmessage = async () => {

        if(!isLogin) {
            MySwal.fire({
                customClass: {
                    popup: `${ss.support_login_check_popup}`
                },
                html: `
                      <div>
                          <div class='${ss.support_login_check_popup_title}'><span>로그인 후 사용 가능합니다.</span></div>
                          <div class='${ss.support_login_check_popup_sub_title}'><span>로그인하여 메세지 기능을 사용해보세요!</span></div>
                          <div class='${ss.support_login_check_popup_btn}'>
                            <button id='seller_add_cancel'>취소</button>
                            <button id='seller_add_success'>로그인</button>
                          </div>
                      </div>
                  `,
                showConfirmButton: false,
                showCancelButton: false,
            });
            document.addEventListener('click', sellerAddSuccess);
            document.addEventListener('click', sellerAddCancel);

            async function sellerAddSuccess(e) {
                if (e.target && e.target.id === 'seller_add_success') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                    navigete('/');
                }
            }

            function sellerAddCancel(e) {
                if (e.target && e.target.id === 'seller_add_cancel') {
                    MySwal.close();
                    document.removeEventListener('click', sellerAddSuccess);
                    document.removeEventListener('click', sellerAddCancel);
                }
            }
        }

        if(isLogin) {
            const response = await store.messageCheck(venueDetail && venueDetail[0].productNumber, userInfo && userInfo.userId, venueDetail && venueDetail[0].userId);
            const data = response.data;
    
            console.log(data);
    
            if(data != 'FAIL') {
                navigete(`/message/chat/${data}`);
            }
        }
    }

    const handleHeartClick = async (productNo) => {
        // console.log('productNo : ', productNo);

        if (!userInfo?.userId) {
            toast.error('로그인 후 사용해 주세요');
            return;
        }

        if(isHeartClicked) {
            // console.log('해제');
            
            const response = await supportApi.supportWishlistRemove(productNo, userInfo?.userId);
            const data = response.data;
            // console.log('해제 : ', data);

            if(data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${ss.my_support_detail_favority_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${ss.my_support_detail_favority_title}'><span>찜 목록에서 제거되었습니다.</span></div>
                    <div class='${ss.my_support_detail_favority_confirm_btn_box}'>
                        <button id='favority_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', favorityConfirm);
                  
                  function favorityConfirm(e) {
                      if(e.target && e.target.id == 'favority_confirm') {
                          MySwal.close();
                          document.removeEventListener('click', favorityConfirm);
                        //   favorityCheck();
                        
                        setIsHeartClicked(!isHeartClicked);
                      }
                  }
            }
        } else {
            // console.log('등록');

            const response = await supportApi.supportWishlistAdd(productNo, userInfo?.userId);
            const data = response.data;

            if(data === 'SUCCESS') {
                MySwal.fire({
                    customClass: {
                        popup: `${ss.my_support_detail_favority_popup}`
                    },
                    html : 
                    `
                    <div>
                    <div class='${ss.my_support_detail_favority_title}'><span>찜 목록에 등록되었습니다.</span></div>
                    <div class='${ss.my_support_detail_favority_confirm_btn_box}'>
                        <button id='favority_confirm'>확인</button>
                    </div>
                    </div>
                    `,
                    showConfirmButton: false,
                    showCancelButton: false,
                  });
                  document.addEventListener('click', favorityConfirm);
                  
                  function favorityConfirm(e) {
                      if(e.target && e.target.id == 'favority_confirm') {
                          MySwal.close();
                          document.removeEventListener('click', favorityConfirm);
                        //   favorityCheck();
                        setIsHeartClicked(!isHeartClicked);
                      }
                  }
            }
        }
    }

    const handleFilterModal = () => {
        setShowFilterModal(true);
    }

    return (
        <div>
            <div className='swiper_img_box'>
                <div className={`${ss.support_favority_heart_box}`}>
                    <button
                        className='heart_btn'
                        onClick={(e) => {
                            e.stopPropagation();
                            handleHeartClick(venueDetail[0].productNo);
                        }}
                        >
                            {isHeartClicked ? (
                            <img
                                className='icon_size'
                                src={`${process.env.PUBLIC_URL}/img/icon/venue/heart_fill.png`}
                                alt='찜O'
                                />
                            ) : (
                            <img
                                className='icon_size'
                                src={`${process.env.PUBLIC_URL}/img/icon/venue/white_heart.png`}
                                alt='찜X'
                            />
                        )}
                        </button>
                </div>
                <div>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                        clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className={`${ss.support_main_my_swiper}`}
                    >
                        {Array.isArray(imgSlide) && imgSlide.map((slide, index) => (
                            <SwiperSlide key={index}><img src={slide} alt='이미지' /></SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            {venueDetail.map((venue, index) => (
                <div key={index} className={`position-relative ${ss.detail_contents}`}>
                    <div className='ps-3 pt-3 position-relative'>
                        <Link to="" className={`d-flex ${ss.shop_title_box}`}>
                            <div><img src={shopInfo.shopProfileImg ? shopInfo.shopProfileImg : `${process.env.PUBLIC_URL}/img/shop/default_profile.png`} alt="프로필" /></div>
                            <div><span className='ps-2'>{venue.shopName}</span></div>
                            {/* <div><span className="material-symbols-outlined pt-1">chevron_right</span></div> */}
                        </Link>
                    </div>
                    <div className={`${ss.message_btn}`}>
                        <div className='reservation_button_box1 mx-2'>
                            <button onClick={() => onmessage()}>
                                <div><img src="../img/icon/venue/message.png" alt="메세지" /></div>
                                <div className='ps-1'><span>메세지</span></div>
                            </button>
                        </div>
                    </div>
                    <div className={`px-3 pt-2 ${ss.shop_product_name}`}>
                        <div className='pt-1'><h5>{venue.productName}</h5></div>
                    </div>
                    <div className={`ps-3 ${ss.shop_hashtag_list}`}>
                        {/* <span>#{shopInfo.shopDetailContent}</span> */}
                        <span>{venue.productInfo}</span>
                    </div>
                    <div>
                        <Wrap className='ps-3'>
                            <Stars>
                                {ARRAY.map((el, idx) => {
                                    return (
                                        <FaStar
                                            key={idx}
                                            size="20"
                                            className={clicked[el] && 'yellowStar'}
                                        />
                                    );
                                })}
                            </Stars>
                            <Link onClick={() => setActiveTab('리뷰')}>
                                <RatingText>리뷰 {reviewCount ? reviewCount : 0}개 보기</RatingText>
                            </Link>
                        </Wrap>
                    </div>
                    <div className='d-flex venus_price_box'>
                        {/* <div className='py-2 signature_color bold px-3'>
                            <span>{venue.supportRate}%</span>
                        </div> */}
                        <div className='py-2 px-3 fs-5'>
                            <span className='bold'>
                                &#8361;{getMinPrice(venue.mainItem).toLocaleString()}
                                {hasOptions ? ' ~' : ''}
                            </span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between text-center pt-4 px-3'>
                        <div className={`${ss.product_category}`}>
                            <Link className={activeTab === '상품설명' ? 'check bold' : 'bold'} onClick={() => setActiveTab('상품설명')}>
                                상품설명
                            </Link>
                        </div>
                        <div className={`${ss.product_category}`}>
                            <Link className={activeTab === '리뷰' ? 'check bold' : 'bold'} onClick={() => setActiveTab('리뷰')}>
                                리뷰
                            </Link>
                        </div>
                        <div className={`${ss.product_category}`}>
                            <Link className={activeTab === 'FAQ' ? 'check bold' : 'bold'} onClick={() => setActiveTab('FAQ')}>
                                FAQ
                            </Link>
                        </div>
                        <div className={`${ss.product_category}`}>
                            <Link className={activeTab === '사업자정보' ? 'check bold' : 'bold'} onClick={() => setActiveTab('사업자정보')}>
                                사업자정보
                            </Link>
                        </div>
                    </div>
                    <div className='bottom_line pt-1'></div>
                    {activeTab === '상품설명' && <VenueProductExp highestCount={highestCount} Wrap2={Wrap2} sellerUser={sellerUser}
                                          Stars={Stars} ARRAY={ARRAY} clicked={clicked} ratings={ratings} scrollLeft={scrollLeft}
                                          sliderRef={sliderRef} scrollRight={scrollRight} content={venue.detailContents} operatingList={operatingList} 
                                          holidays={holidays} ss={ss} temporarys={temporarys} averageRatingDecimal={averageRatingDecimal} exchangeReturn={venue.exchangeReturn}
                                          /> }
                    {
                        activeTab === '리뷰' && <ReviewComment reviewList={reviewList} reviewCount={reviewCount} ratingAverage={ratingAverage} averageRatingDecimal={averageRatingDecimal} ARRAY={ARRAY} FaStar={FaStar} clicked={clicked}
                                                        ratings={ratings} highestCount={highestCount} store={storeStyle} ss={ss} handleFilterModal={handleFilterModal}
                                                        reviewFilterActive={reviewFilterActive}
                                                />
                    }
                    {activeTab === 'FAQ' && <FaqScreen venueDetail={venueDetail} support={support} />}
                    {activeTab === '사업자정보' && <BusinessInfo ss={ss} sellerUser={sellerUser}/>}
                </div>
            ))}
            <VenueReservation imgSlide={imgSlide} venueDetail={venueDetail} support={support} 
                              reservation={reservation} setReservation={setReservation}
                              reservationSelectOption={reservationSelectOption} setReservationSelectOption={setReservationSelectOption}
                              reservationSelectQuestion={reservationSelectQuestion} setReservationSelectQuestion={setReservationSelectQuestion}
                              reservationEssentialQuestion={reservationEssentialQuestion} setReservationEssentialQuestion={setReservationEssentialQuestion}
                              reservationRequest={reservationRequest} setReservationRequest={setReservationRequest} isLogin={isLogin} date={date}
                              questions={questions} setQuestions={setQuestions} supportNo={supportNo} essentialQuestions={essentialQuestions} setEssentialQuestions={setEssentialQuestions}
                              ss={ss} userInfo={userInfo} temporarys={temporarys} holidays={holidays} showDate={showDate} setShowDate={setShowDate} shopInfo={shopInfo}
            />
            {
                showFilterModal &&
                <div className={`${ss.reservation_review_filter_modal_container}`}>
                    <div className={`${ss.reservation_review_filter_modal_box}`}>
                        <div className={`${ss.reservation_review_filter_modal_header}`}>
                            <div><button onClick={() => setShowFilterModal(false)}><span className="material-symbols-outlined">close</span></button></div>
                        </div>
                        <div className={`${ss.reservation_review_filter_modal_content}`}>
                            <div><button className={`${reviewFilterActive === 'basic' ? ss.reservation_filter_active : ''}`} onClick={() => {setReviewFilterActive('basic'); setShowFilterModal(false);}}>기본순</button></div>
                            <div><button className={`${reviewFilterActive === 'popular' ? ss.reservation_filter_active : ''}`} onClick={() => {setReviewFilterActive('popular'); setShowFilterModal(false);}}>인기순</button></div>
                            <div><button className={`${reviewFilterActive === 'rateHigh' ? ss.reservation_filter_active : ''}`} onClick={() => {setReviewFilterActive('rateHigh'); setShowFilterModal(false);}}>별점 높은 순</button></div>
                            <div><button className={`${reviewFilterActive === 'rateRow' ? ss.reservation_filter_active : ''}`} onClick={() => {setReviewFilterActive('rateRow'); setShowFilterModal(false);}}>별점 낮은 순</button></div>
                        </div>
                    </div>
                </div>
            }
            
            <ToastContainer
                position="bottom-center"
                autoClose={1000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                // toastClassName={`${ss.custom_toast}`}
            />
        </div>
    )
}

export default VenueProduct

const Wrap = styled.div`
  display: flex;
  // flex-direction: column;
  padding-top: 5px;
`;
const Wrap2 = styled.div`
  display: flex;
  justify-content: center;
//   padding-top: 15px;/
`;

const RatingText = styled.div`
  color: #787878;
  font-size: 0.9em;
  font-weight: 400;
  border-bottom: 1px solid #787878;
  padding-top: 2px;
  margin-left: 10px;
`;

const Stars = styled.div`
  display: flex;
//   padding-top: 5px;

  & svg {
    color: #e4e4e4;
    // cursor: pointer;
  }

  :hover svg {
    // color: #fcc419;
  }

  & svg:hover ~ svg {
    // color: gray;
  }

  .yellowStar {
    color: #fcc419;
  }
`;
