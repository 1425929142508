import React from 'react'

const LoginSection = ( {seller, loginCheck, onId, onPw, message, checkState, loginPwChange, supportSellerJoin} ) => {
  return (
    <div>
        <div className={`${seller.seller_login_box} ${checkState ? '' : seller.h_480}`}>
            <div className='d-flex justify-content-center align-items-center pt-5 pb-4'><img src="../img/seller/dailyduck_black_logo.png" alt="데일리덕로고" /></div>
            <div className={`px-3 ${message == '아이디를 입력해주세요.' ? 'pt-2' : 'py-2'}`}>
                <input type="text" className={`${seller.seller_login_input}`} placeholder='아이디' onChange={(e) => onId(e)} />
                {
                    message == '아이디를 입력해주세요.' &&
                    <span className='dd-fs-7 signature_color ps-2'>{message}</span>
                }
            </div>
            <div className={`px-3 ${message == '비밀번호를 입력해주세요.' ? 'pt-2' : 'py-2'}`}>
                <input type="password" className={`${seller.seller_login_input}`} 
                    placeholder='비밀번호' 
                    onChange={(e) => onPw(e)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            loginCheck();
                        }
                    }} 
                />
                {
                    message == '비밀번호를 입력해주세요.' &&
                    <span className='dd-fs-7 signature_color ps-2'>{message}</span>
                }
            </div>
            {
                checkState ? '' :
                <div className='px-3'>
                    <div className={`${seller.seller_idpw_check}`}>
                        <div className='ps-3'><img src="../img/seller/warning.png" alt="경고" /></div>
                        <div className='d-flex align-items-center ps-2'><span>아이디 혹은 비밀번호가 일치하지 않습니다.</span></div>
                    </div>
                </div>
            }
            <div className={`${seller.seller_login_join_pw_box}`}>
                <div className={`${seller.seller_login_check_box} dd-fs-7`}><input type="checkbox" id='login_check' /><label htmlFor="login_check" className='ps-2'>로그인 상태 유지</label></div>
                <div>
                    <div className={`${seller.seller_login_pwchange_btn} dd-fs-7`}><button onClick={() => supportSellerJoin()}>회원가입</button></div>
                    <div className={`${seller.seller_login_pwchange_btn} dd-fs-7`}><button onClick={() => loginPwChange()}>비밀번호 변경</button></div>
                </div>
            </div>
            <div className={`${seller.seller_login_btn} px-3`}><button onClick={() => loginCheck()}>로그인</button></div>
        </div>
    </div>
  )
}

export default LoginSection