import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import { Select } from 'antd';
import { Option } from 'antd/es/mentions';
import PostModal from './PostModal';
import PostModal2 from './PostModal2';
import { useNavigate } from 'react-router-dom';
import * as delivery from '../../../../apis/delivery';

const AdminDeliveryAddPopup = ({ setShowDelivery, showDelivery, userInfo, onSave, deliveryData, setDeliveryData }) => {
    const navigate = useNavigate();

    const [addressCheck, setAddressCheck] = useState(false);
    const [addressCheck2, setAddressCheck2] = useState(false);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVisible2, setModalVisible2] = useState(false);
    const [zoneCode, setZoneCode] = useState('');
    const [zoneCode2, setZoneCode2] = useState('');
    const [roadAddress, setroadAddress] = useState('');
    const [roadAddress2, setroadAddress2] = useState('');
    const [sigungu, setSigungu] = useState('');
    const [sigungu2, setSigungu2] = useState('');
    const [sido, setSido] = useState('');
    const [sido2, setSido2] = useState('');
    const [detailAddress, setDetailAddress] = useState();
    const [detailAddress2, setDetailAddress2] = useState();
    const [shippingLocation, setShippingLocation] = useState('');
    const [shippingLocation2, setShippingLocation2] = useState('');

    const [isFormValid, setIsFormValid] = useState(false);

    const [isOverseasShippingEnabled, setIsOverseasShippingEnabled] = useState(false);
    const [overseasShippingCosts, setOverseasShippingCosts] = useState([]);

    useEffect(() => {
        // console.log('deliveryData : ', deliveryData);
        validateForm();
    }, [deliveryData]);

    const validateForm = () => {
        const { dtName, dtDeliveryType, dtDeliveryPm, dtDeliveryCompany, dtDeliveryPrice, dtDeliveryFree,
            dtJejuAddPrice, dtOtherAddPrice, dtReturnDeliveryPrice, dtExchangeDeliveryPrice, dtShippingAddress,
            dtShippingAddressDetail, dtShippingAddressZipcode, dtReturnLocation, dtReturnLocationDetail,
            dtReturnLocationZipcode, dtCompanyTel } = deliveryData;

        const isValid = dtName && dtDeliveryType && dtDeliveryPm && dtDeliveryCompany && dtDeliveryPrice &&
            dtDeliveryFree && dtJejuAddPrice && dtOtherAddPrice && dtReturnDeliveryPrice && dtExchangeDeliveryPrice &&
            dtShippingAddress && dtShippingAddressDetail && dtShippingAddressZipcode && dtReturnLocation &&
            dtReturnLocationDetail && dtReturnLocationZipcode && dtCompanyTel;

        setIsFormValid(isValid);
    };

    const handleAddress = (data) => {
        setZoneCode(data.zonecode);
        setSigungu(data.sigungu);
        setSido(data.sido);

        let refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');
        setroadAddress(refinedRoadAddress);
        setAddress(data.address); // 전체 주소
        setDeliveryData({
            ...deliveryData, dtShippingAddress: data.address, dtShippingAddressZipcode: data.zonecode
        });

        setShippingLocation(data.sido + ' ' + data.sigungu + ' ' + data.address);
    };

    const handleAddress2 = (data) => {
        setZoneCode2(data.zonecode);
        setSigungu2(data.sigungu);
        setSido2(data.sido);

        let refinedRoadAddress = data.roadAddress.replace(`${data.sido} `, '').replace(`${data.sigungu} `, '');
        setroadAddress2(refinedRoadAddress);
        setAddress2(data.address); // 전체 주소

        setDeliveryData({
            ...deliveryData, dtReturnLocation: data.address, dtReturnLocationZipcode: data.zonecode
        });

        setShippingLocation2(data.sido + ' ' + data.sigungu + ' ' + data.address);
    };

    const onDetailAddress = (e) => {
        setDetailAddress(e.target.value);

        setDeliveryData({
            ...deliveryData, dtShippingAddressDetail: e.target.value
        });
    };

    const onDetailAddress2 = (e) => {
        setDetailAddress2(e.target.value);

        setDeliveryData({
            ...deliveryData, dtReturnLocationDetail: e.target.value
        });
    };

    const handleCloseModal = () => {
        if (address === '') {
            setAddressCheck(false);
        }
        setModalVisible(false);
    };

    const handleCloseModal2 = () => {
        if (address2 === '') {
            setAddressCheck2(false);
        }
        setModalVisible2(false);
    };

    useEffect(() => {
        setAddressCheck(address !== '');
        setAddressCheck2(address2 !== '');
    }, [address, address2]);

    const prevBtn = () => {
        setShowDelivery(false);
    };

    const [deliveryType, setDeliveryType] = useState([
        { deliveryTypeNo: 1, deliveryTypeEngName: 'parcel', deliveryTypeName: '택배/소포/등기' },
        { deliveryTypeNo: 2, deliveryTypeEngName: 'pick', deliveryTypeName: '방문수령' },
        { deliveryTypeNo: 3, deliveryTypeEngName: 'quickservice', deliveryTypeName: '퀵서비스' },
        { deliveryTypeNo: 4, deliveryTypeEngName: 'direct', deliveryTypeName: '직접배송(화물, 배달)' },
        { deliveryTypeNo: 5, deliveryTypeEngName: 'nondelivery', deliveryTypeName: '배송없음' },
    ]);
    const [deliveryPayMethod, setDeliveryPayMethod] = useState([
        { deliveryMethodNo: 1, deliveryMethodEngName: 'prepayment', deliveryMethodName: '선결제' },
        { deliveryMethodNo: 2, deliveryMethodEngName: 'cashondelivery', deliveryMethodName: '착불' },
    ]);
    const [deliveryCompany, setDeliveryCompany] = useState([
        { dcNo: 1, dcEngName: 'cj', dcName: 'CJ대한통운' },
        { dcNo: 2, dcEngName: 'epost', dcName: '우체국택배' },
        { dcNo: 3, dcEngName: 'hanjin', dcName: '한진택배' },
        { dcNo: 4, dcEngName: 'lotte', dcName: '롯대택배' },
        { dcNo: 5, dcEngName: 'logen', dcName: '로젠택배' },
        { dcNo: 6, dcEngName: 'kdexp', dcName: '경동택배' },
        { dcNo: 7, dcEngName: 'registered', dcName: '우체국등기' },
        { dcNo: 8, dcEngName: 'ems', dcName: 'EMS' }
    ]);

    const onTemplateName = (e) => {
        setDeliveryData({
            ...deliveryData, dtName: e.target.value
        });
    };

    // 배송 방법
    const onShippingMethod = (value) => {
        const selectedType = deliveryType.find(item => item.deliveryTypeEngName === value);
        setDeliveryData({
            ...deliveryData,
            dtDeliveryEngType: value,
            dtDeliveryType: selectedType ? selectedType.deliveryTypeName : ''
        });
    };

    // 결제 방법
    const onPaymentMethod = (value) => {
        const selectedMethod = deliveryPayMethod.find(item => item.deliveryMethodEngName === value);
        setDeliveryData({
            ...deliveryData,
            dtDeliveryEngPm: value,
            dtDeliveryPm: selectedMethod ? selectedMethod.deliveryMethodName : ''
        });
    };

    // 기본 택배사
    const onDeliverySelect = (value) => {
        const selectedCompany = deliveryCompany.find(item => item.dcEngName === value);
        setDeliveryData({
            ...deliveryData,
            dtDeliveryEngCompany: value,
            dtDeliveryCompany: selectedCompany ? selectedCompany.dcName : ''
        });
    };

    // 기본 배송비
    const onDtPrice = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtDeliveryPrice: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 무료 배송 금액
    const onDtFree = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtDeliveryFree: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 제주도 추가 배송비
    const onDtJeju = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtJejuAddPrice: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 도서산간 추가 배송비
    const onDtOther = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtOtherAddPrice: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 반품 배송비
    const onDtReturn = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtReturnDeliveryPrice: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 교환 배송비
    const onDtExchange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0) {
            setDeliveryData({
                ...deliveryData, dtExchangeDeliveryPrice: value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = ''; // 입력값 무효화
        }
    };

    // 대표 연락처
    const onDtTel = (e) => {
        if (!isNaN(e.target.value) && e.target.value >= 0) {
            setDeliveryData({
                ...deliveryData, dtCompanyTel: e.target.value
            });
        } else {
            alert('음수값은 입력할 수 없습니다.');
            e.target.value = '';
        }
    };

    
    const toggleOverseasShipping = (value) => {
        if(value === 'true') {
            setIsOverseasShippingEnabled(value === 'true');
            setDeliveryData({
                ...deliveryData,
                overseasShippingState: true
            });
        }
        if (value === 'false') {
            setOverseasShippingCosts([]); // 초기화
            setDeliveryData({
                ...deliveryData,
                overseasShippingState: false,
                overseasShippingCost: '',
                overseasShippingKg: '' // 초기화
            });
        }
    };


    const addOverseasShippingField = () => {
        setOverseasShippingCosts([...overseasShippingCosts, { kg: '', price: '' }]);
    };

    const handleOverseasShippingCostChange = (index, field, value) => {
        const updatedCosts = [...overseasShippingCosts];
        updatedCosts[index][field] = value;
        setOverseasShippingCosts(updatedCosts);

        // 금액과 kg 배열로 분리하여 저장
        setDeliveryData({
            ...deliveryData,
            overseasShippingCost: updatedCosts.map((cost) => cost.price).join(';'),
            overseasShippingKg: updatedCosts.map((cost) => cost.kg).join(';')
        });
    };

    const removeOverseasShippingField = (index) => {
        const updatedCosts = overseasShippingCosts.filter((_, i) => i !== index);
        setOverseasShippingCosts(updatedCosts);

        // 금액과 kg 배열로 분리하여 저장
        setDeliveryData({
            ...deliveryData,
            overseasShippingCost: updatedCosts.map((cost) => cost.price).join(';'),
            overseasShippingKg: updatedCosts.map((cost) => cost.kg).join(';')
        });
    };

    return (
        <div className={`${admin.admin_shop_delivery_add_box}`}>
            <div className='px-3 py-3'>
                <div className={`${admin.admin_shop_delivery_add_title}`}><span>배송템플릿 등록</span></div>
                <div className='py-3'>
                    <div className={`${admin.admin_shop_delivery_add_templrate_title}`}>
                        <div><span>템플릿 이름</span></div>
                        <div>
                            <input type="text" onChange={(e) => onTemplateName(e)} />
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>배송 및 결제방법</span></div>
                        <div>
                            <div>
                                <div><span>배송방법</span></div>
                                <div>
                                    <Select
                                        className={`${admin.admin_shop_delivery_add_deliverytype_select}`}
                                        onChange={onShippingMethod}
                                    >
                                        {
                                            deliveryType.map((item, index) => (
                                                <Option value={item.deliveryTypeEngName} key={index}>{item.deliveryTypeName}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div>
                                <div><span>결제방법</span></div>
                                <div>
                                    <Select
                                        className={`${admin.admin_shop_delivery_add_paymethod_select}`}
                                        onChange={onPaymentMethod}
                                    >
                                        {
                                            deliveryPayMethod.map((item, index) => (
                                                <Option value={item.deliveryMethodEngName} key={index}>{item.deliveryMethodName}</Option>
                                            ))
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>기본 택배사</span></div>
                        <div>
                            <Select
                                className={`${admin.admin_shop_delivery_add_dc_select}`}
                                onChange={onDeliverySelect}
                            >
                                {
                                    deliveryCompany.map((item, index) => (
                                        <Option value={item.dcEngName} key={index}>{item.dcName}</Option>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>기본 배송비</span></div>
                        <div className='d-flex align-items-center'>
                            <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                <input type="number" onChange={(e) => onDtPrice(e)} />
                                <div><span>&#8361;</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>무료배송 조건</span></div>
                        <div className='d-flex align-items-center'>
                            <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                <input type="number" onChange={(e) => onDtFree(e)} />
                                <div><span>&#8361;</span></div>
                            </div>
                            <div className='ps-2'>이상 구매시</div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>지역별 배송비</span></div>
                        <div>
                            <div>
                                <div><span>제주도 추가 배송비</span></div>
                                <div className='d-flex align-items-center'>
                                    <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                        <input type="number" onChange={(e) => onDtJeju(e)} />
                                        <div><span>&#8361;</span></div>
                                    </div>
                                </div>
                            </div>
                            <div className='py-2'>
                                <div><span>도서산간 추가 배송비</span></div>
                                <div className='d-flex align-items-center'>
                                    <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                        <input type="number" onChange={(e) => onDtOther(e)} />
                                        <div><span>&#8361;</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>해외 배송비</span></div>
                        <div>
                            <div className={`${admin.admin_shop_delivery_oversease_radio}`}>
                                <div>
                                    <input
                                        type="radio"
                                        name="overseas"
                                        id="overseas_true"
                                        onChange={() => toggleOverseasShipping('true')}
                                    />
                                    <label htmlFor="overseas_true">사용</label>
                                </div>
                                <div>
                                    <input
                                        type="radio"
                                        name="overseas"
                                        id="overseas_false"
                                        onChange={() => toggleOverseasShipping('false')}
                                    />
                                    <label htmlFor="overseas_false">사용안함</label>
                                </div>
                            </div>
                            {isOverseasShippingEnabled && (
                                <div>
                                    {overseasShippingCosts.map((cost, index) => (
                                        <div
                                            className={`${admin.admin_shop_delivery_oversease_add_input}`}
                                            key={index}
                                        >
                                            <div>
                                                <input
                                                    type="number"
                                                    placeholder="Kg"
                                                    value={cost.kg}
                                                    onChange={(e) =>
                                                        handleOverseasShippingCostChange(
                                                            index,
                                                            'kg',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <span>kg 이상</span>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    placeholder="금액"
                                                    value={cost.price}
                                                    onChange={(e) =>
                                                        handleOverseasShippingCostChange(
                                                            index,
                                                            'price',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                                <span>&#8361;</span>
                                            </div>
                                            <button
                                                onClick={() => removeOverseasShippingField(index)}
                                                className={`${admin.admin_shop_delivery_oveseaes_remove_btn}`}
                                            >
                                                <span className="material-symbols-outlined">remove</span>
                                            </button>
                                        </div>
                                    ))}
                                    <div className={`${admin.admin_shop_delivery_oversease_add_btn}`}>
                                        <button onClick={addOverseasShippingField}>
                                            <span className="material-symbols-outlined">add</span>
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>반품 배송비</span></div>
                        <div className='d-flex align-items-center'>
                            <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                <input type="number" onChange={(e) => onDtReturn(e)} />
                                <div><span>&#8361;</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>교환 배송비</span></div>
                        <div className='d-flex align-items-center'>
                            <div className={`${admin.admin_shop_delivery_add_number_input}`}>
                                <input type="number" onChange={(e) => onDtExchange(e)} />
                                <div><span>&#8361;</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>출고지</span></div>
                        <div className={`${admin.admin_shop_delivery_add_address_btn}`}>
                            {
                                shippingLocation !== '' &&
                                <div>
                                    <div><span>{shippingLocation !== '' ? shippingLocation : null}</span></div>
                                    <div><input type="text" placeholder='상세주소 입력' onChange={(e) => onDetailAddress(e)} /></div>
                                    <div><span>{zoneCode !== '' ? zoneCode : null}</span></div>
                                </div>
                            }
                            <div><button onClick={() => { setModalVisible(true); setAddressCheck(true); }} className='mx-1'>주소검색</button></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>반품/교환지</span></div>
                        <div className={`${admin.admin_shop_delivery_add_address_btn}`}>
                            {
                                shippingLocation2 !== '' &&
                                <div>
                                    <div><span>{shippingLocation2 !== '' ? shippingLocation2 : null}</span></div>
                                    <div><input type="text" placeholder='상세주소 입력' onChange={(e) => onDetailAddress2(e)} /></div>
                                    <div><span>{zoneCode2 !== '' ? zoneCode2 : null}</span></div>
                                </div>
                            }
                            <div><button onClick={() => { setModalVisible2(true); setAddressCheck2(true); }} className='mx-1'>주소검색</button></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_input_box}`}>
                        <div><span>대표 연락처</span></div>
                        <div>
                            <div><input type="number" onChange={(e) => onDtTel(e)} /></div>
                            <div className={`${admin.admin_shop_delivery_input_hipen}`}><span>* "-"(하이픈)은 빼고 입력해주세요</span></div>
                        </div>
                    </div>
                    <div className={`${admin.admin_shop_delivery_add_btn_box}`}>
                        <div><button onClick={() => prevBtn()}>이전으로</button></div>
                        <div><button className={isFormValid ? admin.admin_shop_delivery_add_btn_active : ''} onClick={onSave} disabled={!isFormValid}>등록하기</button></div>
                    </div>
                </div>
            </div>
            {
                modalVisible && (
                    <PostModal
                        onClose={handleCloseModal}
                        onComplete={handleAddress}
                        admin={admin}
                    />
                )
            }
            {
                modalVisible2 && (
                    <PostModal2
                        onClose={handleCloseModal2}
                        onComplete={handleAddress2}
                        admin={admin}
                    />
                )
            }
        </div>
    );
};

export default AdminDeliveryAddPopup;
