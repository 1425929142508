import React, { useEffect, useState } from 'react';
import admin from '../../../admin.module.css';
import * as support from '../../../../apis/support';
import * as adminApi from '../../../../apis/admin';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Pagination, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { LoginContext } from '../../../../contexts/LoginContextProvider';


const AdminMessagePush = () => {
    const MySwal = withReactContent(Swal);
    const [activeButton, setActiveButton] = useState('push');
    const [imgPreview, setImgPreview] = useState('');
    const [appPushInfo, setAppPushInfo] = useState({});
    const [appPushTitle, setAppPushTitle] = useState('');
    const [appPushContent, setAppPushContent] = useState('');
    const [appPushLink, setAppPushLink] = useState('');
    const [tokenUsers, setTokenUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [kakaoMessageActive, setKakaoMessageActive] = useState('biz');
    const [kakaoBizTemplateList, setKakaoBizTemplateList] = useState({});
    const [bizSelectedCode, setBizSelectedCode] = useState({});
    const [bizSelectedTemplate, setBizSelectedTemplate] = useState(null);
    const [kakaoBizUserList, setKakaoBizUserList] = useState([]);

    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [itemsPerPage] = useState(10); // 페이지당 항목 수

    const [checkedUsers, setCheckedUsers] = useState([]);
    const [searchBizUser, setSearchBizUser] = useState('');

    useEffect(() => {
        if(kakaoMessageActive === 'biz') {
            kakaoBizTemplate();
            userSelect();
        }
    }, [kakaoMessageActive])

    useEffect(() => {
        tokenUserSelect();
    }, []);

    const kakaoBizTemplate = async () => {
        const response = await adminApi.kakaoBizTemplateSelect();
        const data = response.data;

        console.log(data);
        setKakaoBizTemplateList(data);
    }

    const userSelect = async () => {
        const response = await adminApi.kakaoBizUserSelect();
        const data = response.data;

        console.log('user : ', data);
        setKakaoBizUserList(data);
    }

    const tokenUserSelect = async () => {
        const response = await adminApi.tokenUserSelect();
        const data = response.data;
        setTokenUsers(data);
    };

    const handleSelectAll = (e) => {
        setSelectedUsers(e.target.checked ? tokenUsers.map(user => user.userId) : []);
    };

    const handleSelectUser = (userId) => {
        setSelectedUsers((prevSelected) =>
            prevSelected.includes(userId)
                ? prevSelected.filter(id => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const handleCheckedAll = (e) => {
        setCheckedUsers(e.target.checked ? paginatedData.map(user => user) : []);
    }

    const handleCheckedUser = (user) => {
        const { userId } = user;
        
        setCheckedUsers((prevChecked) => {
            const isSelected = prevChecked.some(u => u.userId === userId);
            return isSelected
                ? prevChecked.filter(u => u.userId !== userId)
                : [...prevChecked, user];
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };
    
    const handleBizSearch = (e) => {
        setSearchBizUser(e.target.value);
    };

    const filteredUsers = Array.isArray(tokenUsers) && tokenUsers.filter(user => {
        const gender = user.userGender === 'male' ? '남자' : user.userGender === 'female' ? '여자' : '';
        return (
            (user.userId || '').includes(searchTerm) ||
            (user.userName || '').includes(searchTerm) ||
            (user.userNick || '').includes(searchTerm) ||
            gender.includes(searchTerm) ||
            (user.userBirth || '').includes(searchTerm) ||
            (user.userTel || '').includes(searchTerm) ||
            (user.userEmail || '').includes(searchTerm) ||
            (user.userRegDate || '').includes(searchTerm) ||  // 가입일
            (user.favoritys || '').includes(searchTerm)       // 최애 이름 추가
        );
    });

    const bizFilteredUsers = Array.isArray(kakaoBizUserList) && kakaoBizUserList.filter(user => {
        const gender = user.userGender === 'male' ? '남자' : user.userGender === 'female' ? '여자' : '';
        return (
            (user.userId || '').includes(searchBizUser) ||
            (user.userName || '').includes(searchBizUser) ||
            (user.userNick || '').includes(searchBizUser) ||
            gender.includes(searchBizUser) ||
            (user.userBirth || '').includes(searchBizUser) ||
            (user.userTel || '').includes(searchBizUser) ||
            (user.userEmail || '').includes(searchBizUser) ||
            (user.userRegDate || '').includes(searchBizUser) ||  // 가입일
            (user.favoritys || '').includes(searchBizUser)       // 최애 이름 추가
        );
    });

    const handleImgInputClick = () => {
        document.getElementById('hiddenImgInput').click();
    };

    const imageAdd = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();

        formData.append('image', file);
        
        const config = { headers: { 'content-type': 'multipart/form-data' } };

        const response = await support.uploadImageToServer(formData, config);
        const data = response.data;

        const imageUrl = `${process.env.REACT_APP_API_SERVER}/img?file=${encodeURIComponent(data)}`;

        // console.log('imageUrl : ', imageUrl);
        setImgPreview(imageUrl);

        setAppPushInfo(appPushInfo => ({
            ...appPushInfo,
            imageUrl: imageUrl
        }));
    };

    const onAppPushTitle = (e) => {
        // console.log(e.target.value);
        setAppPushTitle(e.target.value);
        setAppPushInfo({
            ...appPushInfo, title : e.target.value
        })
    }
    const onAppPushContent = (e) => {
        // console.log(e.target.value);
        setAppPushContent(e.target.value);
        setAppPushInfo({
            ...appPushInfo, body : e.target.value
        })
    }
    const onAppPushLink = (e) => {
        console.log(e.target.value);
        setAppPushLink(e.target.value);
        setAppPushInfo({
            ...appPushInfo, pageLink : e.target.value
        })
    }

    const handlePushNotification = () => {
        const selectedUserData = tokenUsers.filter(user => selectedUsers.includes(user.userId));
        // console.log('Selected Users:', selectedUserData);

        let newItem = {
            ...appPushInfo, users : selectedUserData
        }

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>앱 푸시를 발송하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>발송</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    const response = await adminApi.appPushRequest(newItem);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>${selectedUserData.length}명에게 앱 푸시가 발송되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                setAppPushInfo({});
                                setAppPushContent('');
                                setAppPushTitle('');
                                setSelectedUsers([]);
                                setImgPreview('');
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>앱 푸시 발송에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
    };

    const onBizTemplateSelect = (value) => {
        console.log('선택된 템플릿 코드 : ', value);
        setBizSelectedCode(value);

            // 선택된 템플릿 필터링
        const selectedTemplate = kakaoBizTemplateList.list.find(
            (item) => item.templtCode === value
        );

        // 상태값 업데이트
        if (selectedTemplate) {
            setBizSelectedTemplate(selectedTemplate);
            console.log('선택된 템플릿 데이터: ', selectedTemplate);
        } else {
            console.error('템플릿 데이터를 찾을 수 없습니다.');
        }
    }

    const onBizMessagSend = async () => {
        console.log('checkedUsers : ', checkedUsers);

        if(checkedUsers.length === 0) {
            alert('선택된 유저가 없습니다.');
            return;
        }

        MySwal.fire({
            customClass: {
                popup: `${admin.admin_seller_add_popup}`
            },
            html: `
                  <div>
                      <div class='${admin.admin_seller_add_title}'><span>카카오 알림톡을 발송하시겠습니까?</span></div>
                      <div class='${admin.admin_seller_add_btn_box} py-2 d-flex w-100 justify-content-between'>
                        <button class='mx-1' id='seller_add_cancel'>취소</button>
                        <button class='mx-1' id='seller_add_success'>발송</button>
                      </div>
                  </div>
              `,
            showConfirmButton: false,
            showCancelButton: false,
        });
        document.addEventListener('click', sellerAddSuccess);
        document.addEventListener('click', sellerAddCancel);

        async function sellerAddSuccess(e) {
            if (e.target && e.target.id === 'seller_add_success') {
                try {
                    let payload = {
                        bizSelectedTemplate,
                        checkedUsers,
                        bizSelectedCode
                    }

                    const response = await adminApi.kakaoBizRequest(payload);
                    const data = response.data;

                    if (data === 'SUCCESS') {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>${checkedUsers.length}명에게 알림톡이 발송되었습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    } else {
                        MySwal.fire({
                            customClass: {
                                popup: `${admin.admin_seller_add_popup}`
                            },
                            html: `
                              <div>
                                <div class='${admin.admin_seller_add_title}'><span>알림톡 발송에 실패하였습니다.</span></div>
                                <div class='${admin.admin_seller_confirm_btn_box} py-2 d-flex w-100 justify-content-between'>
                                    <button class='mx-1' id='seller_add_confirm'>확인</button>
                                </div>
                              </div>
                              `,
                            showConfirmButton: false,
                            showCancelButton: false,
                        });
                        document.addEventListener('click', sellerAddConfirm);

                        function sellerAddConfirm(e) {
                            if (e.target && e.target.id === 'seller_add_confirm') {
                                MySwal.close();
                                document.removeEventListener('click', sellerAddConfirm);
                                document.removeEventListener('click', sellerAddSuccess);
                                document.removeEventListener('click', sellerAddCancel);
                            }
                        }
                    }
                } catch (error) {
                    console.error('error : ', error);
                }
            }
        }

        function sellerAddCancel(e) {
            if (e.target && e.target.id === 'seller_add_cancel') {
                MySwal.close();
                document.removeEventListener('click', sellerAddSuccess);
                document.removeEventListener('click', sellerAddCancel);
            }
        }
        
    }

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedData = bizFilteredUsers.slice(startIndex, endIndex);

    return (
        <div className={`${admin.admin_message_push_box} px-3 py-3`}>
            <div>
                <div className='border-bottom'>
                    <div className='px-3 py-3'><span>메시지 보내기</span></div>
                    <div className={`${admin.admin_message_push_tap_box}`}>
                        <div className={`${activeButton === 'push' ? admin.admin_message_push_active : ''}`}><button onClick={() => {setActiveButton('push')}}>PUSH 알림</button></div>
                        <div className={`${activeButton === 'kakao' ? admin.admin_message_push_active : ''} ms-1`}><button onClick={() => {setActiveButton('kakao')}}>KAKAO 알림</button></div>
                        <div className={`${activeButton === 'sms' ? admin.admin_message_push_active : ''} ms-1`}><button onClick={() => {setActiveButton('sms')}}>SMS</button></div>
                    </div>
                </div>
                <div className={`${admin.admin_message_push_content}`}>
                    {activeButton === 'push' && (
                        <>
                            <div className={`${admin.admin_message_push_app_push_box}`}>
                                <div>
                                    <div><span>앱 push 알림</span>&nbsp;<span className={`${admin.admin_app_push_info_txt}`}>(*제목과 내용은 최대한 간략하게 입력하는게 좋습니다.)</span></div>
                                    <div><button onClick={handlePushNotification}>Push 알림 전송</button></div>
                                </div>
                                <div>
                                    <div>
                                        <div><span>제목</span></div>
                                        <div><input type="text" value={appPushTitle !== '' ? appPushTitle : ''} onChange={(e) => onAppPushTitle(e)} /></div>
                                    </div>
                                    <div>
                                        <div><span>내용</span></div>
                                        <div><textarea type="text" value={appPushContent !== '' ? appPushContent : ''} onChange={(e) => onAppPushContent(e)} /></div>
                                    </div>
                                    <div>
                                        <div><span>링크</span></div>
                                        <div><input type="text" value={appPushLink !== '' ? appPushLink : ''} onChange={(e) => onAppPushLink(e)} /></div>
                                    </div>
                                    <div>
                                        <input
                                            id="hiddenImgInput"
                                            type="file"
                                            onChange={(event) => imageAdd(event)}
                                            style={{display: 'none'}}
                                        />
                                        <div><span>이미지</span></div>
                                        <div><button onClick={() => handleImgInputClick()} >등록</button></div>
                                    </div>
                                    { imgPreview !== '' &&
                                        <div className={`${admin.admin_message_push_app_push_img}`}>
                                            <img src={imgPreview === '' ? '' : imgPreview} alt="" />
                                        </div>
                                    }
                                </div>
                                <div className={`${admin.admin_message_push_app_push_preview}`}>
                                    <div>
                                        <div>
                                            <img src={`${process.env.PUBLIC_URL}/img/dailyduck_logo.png`} alt="" />
                                        </div>
                                        <div>
                                            <div><span>{appPushTitle !== '' ? appPushTitle : ''}</span></div>
                                            <div><span>{appPushContent !== '' ? appPushContent : ''}</span></div>
                                        </div>
                                        <div>
                                            {
                                                imgPreview !== '' &&
                                                <img src={`${imgPreview}`} alt="" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${admin.admin_message_push_app_push_agree_user}`}>
                                <div className={`${admin.admin_message_push_app_push_agree_user_title}`}><span>앱 푸시 동의 고객목록</span></div>
                                <div className={`${admin.admin_message_push_app_push_agree_user_container}`}>
                                    <div className={`${admin.admin_message_push_app_push_agree_user_search}`}>
                                        <div><span>검색</span></div>
                                        <div><input type="text" value={searchTerm} onChange={handleSearch} /></div>
                                    </div>
                                    <div className={`${admin.admin_message_push_app_push_agree_user_header}`}>
                                        <div><input type="checkbox" onChange={handleSelectAll} checked={selectedUsers.length === tokenUsers.length} /></div>
                                        <div><span>아이디</span></div>
                                        <div><span>이름</span></div>
                                        <div><span>이메일</span></div>
                                        <div><span>닉네임</span></div>
                                        <div><span>생일</span></div>
                                        <div><span>최애</span></div>
                                        <div><span>가입일</span></div>
                                    </div>
                                    {filteredUsers.length > 0 ? filteredUsers.map((user, index) => (
                                        <div className={`${admin.admin_message_push_app_push_agree_user_content}`} key={index}>
                                            <div>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedUsers.includes(user.userId)}
                                                    onChange={() => handleSelectUser(user.userId)}
                                                />
                                            </div>
                                            <div><span>{user.userId}</span></div>
                                            <div><span>{user.userName}</span></div>
                                            <div><span>{user.userEmail}</span></div>
                                            <div><span>{user.userNick}</span></div>
                                            <div><span>{user.userBirth}</span></div>
                                            <div><span>{user.favoritys}</span></div>
                                            <div><span>{user.userRegDate}</span></div>
                                        </div>
                                    )) : (
                                        <div className={`${admin.admin_message_push_app_push_agree_user_no_data}`}><span>앱 푸시 동의 고객이 없습니다.</span></div>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                    {
                        activeButton === 'kakao' &&
                        <div>
                            <div className={`${admin.admin_kakao_message_select_btn_box}`}>
                                <div><button className={`${kakaoMessageActive === 'biz' ? admin.admin_kakao_message_active: ''}`} onClick={() => setKakaoMessageActive('biz')}>알림톡(KakaoTalk biz Message)</button></div>
                                <div><button className={`${kakaoMessageActive === 'friend' ? admin.admin_kakao_message_active: ''}`} onClick={() => {setKakaoMessageActive('friend'); setBizSelectedTemplate(null)}}>친구톡(KakaoTalk friend Message)</button></div>
                            </div>
                            {
                                kakaoMessageActive === 'biz' &&
                                <div className={`${admin.admin_kakao_message_biz_box}`}>
                                    <div className={`${admin.admin_kakao_message_biz_box_header}`}>
                                        <div><span>알림톡발송</span></div>
                                        <div><span>※ 카카오톡 채널을 추가하지 않은 사용자에게도 발송 가능한 정보성 메세지 이며 등록된 템플릿 이외에는 사용 할 수 없습니다.</span></div>
                                    </div>
                                    <div className={`${admin.admin_kakao_message_biz_box_content}`}>
                                        <div className={`${admin.admin_kakao_message_biz_box_content_template}`}>
                                            <div><span>등록된 템플릿</span></div>
                                            <div>
                                            <Select
                                                className={`${admin.admin_kakao_biz_template_select}`}
                                                onChange={(value) => onBizTemplateSelect(value)}
                                            >
                                                {
                                                    kakaoBizTemplateList && kakaoBizTemplateList.list && kakaoBizTemplateList.list.map((item, index) => (
                                                        <Option value={item.templtCode}>{item.templtName}</Option>
                                                    ))
                                                }
                                            </Select>
                                            </div>
                                        </div>
                                        {
                                            bizSelectedTemplate &&
                                            <>
                                                <div className={`${admin.admin_kakao_biz_template_preview}`}>
                                                    <div className={`${admin.admin_kakao_biz_template_preview_img}`}><img src={bizSelectedTemplate.templtImageUrl} alt="" /></div>
                                                    <div className={`${admin.admin_kakao_biz_template_preview_content}`}><pre>{bizSelectedTemplate.templtContent}</pre></div>
                                                    <div className={`${admin.admin_kakao_biz_template_preview_extra}`}><span>{bizSelectedTemplate.templateExtra}</span></div>
                                                    <div className={`${admin.admin_kakao_biz_template_preview_advert}`}><span>{bizSelectedTemplate.templateAdvert}</span></div>
                                                    <div className={`${admin.admin_kakao_biz_template_preview_btn}`}>
                                                        {
                                                            bizSelectedTemplate.buttons && bizSelectedTemplate.buttons.map((item, index) => (
                                                                    <button key={index} className={`${item.linkTypeName === '채널 추가' ? admin.admin_kakao_biz_chennel_add : ''}`}>{item.name}</button>
                                                                ))
                                                        }
                                                    </div>
                                                </div> 
                                                <div className={`${admin.admin_kakao_biz_template_user_box}`}>
                                                    <button className={`${admin.admin_kakao_message_biz_sendbtn}`} onClick={() => onBizMessagSend()}>알림톡 발송</button>
                                                    <div className={`${admin.admin_message_push_app_push_agree_user_search}`}>
                                                        <div><span>검색</span></div>
                                                        <div><input type="text" value={searchBizUser} onChange={handleBizSearch} /></div>
                                                    </div>
                                                    <div>
                                                        <div className={`${admin.admin_message_push_app_push_agree_user_header}`}>
                                                            <div><input type="checkbox" onChange={handleCheckedAll} checked={checkedUsers.length === paginatedData.length} /></div>
                                                            <div><span>아이디</span></div>
                                                            <div><span>이름</span></div>
                                                            <div><span>이메일</span></div>
                                                            <div><span>닉네임</span></div>
                                                            <div><span>생일</span></div>
                                                            <div><span>최애</span></div>
                                                            <div><span>가입일</span></div>
                                                        </div>
                                                        {
                                                            bizSelectedTemplate && paginatedData.map((user, index) => {

                                                                return (
                                                                    <div className={`${admin.admin_message_push_app_push_agree_user_content}`} key={index}>
                                                                        <div>
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={checkedUsers.includes(user)}
                                                                                onChange={() => handleCheckedUser(user)}
                                                                            />
                                                                        </div>
                                                                        <div><span>{user.userId}</span></div>
                                                                        <div><span>{user.userName}</span></div>
                                                                        <div><span>{user.userEmail}</span></div>
                                                                        <div><span>{user.userNick}</span></div>
                                                                        <div><span>{user.userBirth}</span></div>
                                                                        <div><span>{user.favoritys}</span></div>
                                                                        <div><span>{user.userRegDate}</span></div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <Pagination
                                                    current={currentPage}
                                                    pageSize={itemsPerPage}
                                                    total={kakaoBizUserList.length}
                                                    onChange={(page) => setCurrentPage(page)}
                                                    style={{ marginTop: "10px", textAlign: "center" }}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {
                        activeButton === 'sms' &&
                        <div>
                            <div><span>SMS 보내기</span></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default AdminMessagePush;
