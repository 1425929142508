import React, { useContext, useEffect, useState } from 'react'
import admin from '../../admin.module.css'
import LoginSection from '../../components/sectoion/LoginSection'
import LoginPwChangeSection from '../../components/sectoion/LoginPwChangeSection';
import Swal from 'sweetalert2';
import { LoginContext } from '../../../contexts/LoginContextProvider';
import JoinSection from '../../components/sectoion/JoinSection';
import Cookies from 'js-cookie';

const AdminLogin = () => {

  const { adminLogin, isLogin, userInfo, adminlogout } = useContext(LoginContext);

  const [ id, setId ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ stateCheck, setStateCheck ] = useState(false);
  const [ showJoin, setShowJoin ] = useState(false);
  const [ showPwChange, setShowPwChange ] = useState(false);
  
  const adminLoginBtn = () => {
    if(id != '' && password != '') {
      setStateCheck(true);
    } else {
      setStateCheck(false);
    }

    adminLogin(id, password);
  }

  useEffect(() => {
    refreshOnce();
  }, []);

  const refreshOnce = () => {
      const isRefreshed = sessionStorage.getItem("isPageRefreshed");
      // console.log("isPageRefreshed:", isRefreshed);

      if (!isRefreshed || isRefreshed !== "true") {
          // console.log("로그인 화면에서 강제로 새로고침 실행");
          sessionStorage.setItem("isPageRefreshed", "true");
          window.location.reload(true); // 강제로 새로고침
      } else {
          // 새로고침 후 초기화
          sessionStorage.removeItem("isPageRefreshed");
          // console.log("isPageRefreshed 값 초기화");
      }
  };
  const onAdminPwChange = () => {
    setShowPwChange(true);
  }
  
  const onId = (e) => {
    setId(e.target.value);
  }

  const onPw = (e) => {
    setPassword(e.target.value);
  }
  

  const onAdminJoin = () => {
    setShowJoin(true);
    }
  const onBack = () => {
    setShowJoin(false);
  }

  const onAdminIdSearch = () => {

  }

  return (
    <div className={`${admin.admin_container}`}>
      {
        !showPwChange && !showJoin &&
        <LoginSection admin={admin} adminLoginBtn={adminLoginBtn} onId={onId} onPw={onPw} stateCheck={stateCheck} onAdminPwChange={onAdminPwChange} onAdminJoin={onAdminJoin} id={id} password={password} onAdminIdSearch={onAdminIdSearch}/>
      }
      { 
        showPwChange &&
        <LoginPwChangeSection admin={admin} showPwChange={showPwChange} setShowPwChange={setShowPwChange} />
      }
      {
        showJoin &&
        <JoinSection admin={admin} onBack={onBack} setShowJoin={setShowJoin}/>
      }
    </div>
  )
}

export default AdminLogin